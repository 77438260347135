/* eslint-disable no-unused-vars */
import axios from "axios";
import Swal from "sweetalert2";

export const updateRateOne = async (id) => {
  try {
    // Obtener los datos del usuario a editar del servidor
    const response = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/api/rate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      }
    );
    const rateData = response.data.rate;

    const result = await Swal.fire({
      title: `Editar tarifa ${rateData.service}`,
      html:
        '<div class="forma">' +
        '<ul class="nav nav-tabs" id="myTabs">' +
        ' <li class="nav-item">' +
        '<a class="nav-link active" data-tab="tab1" href="#">Tabla A</a>' +
        "</li>" +
        '<li class="nav-item">' +
        '<a class="nav-link" data-tab="tab2" href="#">Tabla B</a>' +
        "</li>" +
        '<li class="nav-item">' +
        '<a class="nav-link" data-tab="tab3" href="#">Tabla Especial</a>' +
        "</li>" +
        "</ul>" +
        '<div class="tab-content">' +
        '<div class="tab-pane active" id="tab1">' +
        "<label>Tabla A</label>" +
        "</br>" +
        "<div class='fields' >" +
        '<div class="input-container">' +
        '<label for="A1kg">A 1kg: </label>' +
        `<input type="text" id="A-A1kg" class="swal2-input" value="${rateData.tarifas.tarifaA.A["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A45kg">A +45kg: </label>' +
        `<input type="text" id="A-A45kg" class="swal2-input" value="${rateData.tarifas.tarifaA.A["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A100kg">A +100kg: </label>' +
        `<input type="text" id="A-A100kg" class="swal2-input" value="${rateData.tarifas.tarifaA.A["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A300kg">A +300kg: </label>' +
        `<input type="text" id="A-A300kg" class="swal2-input" value="${rateData.tarifas.tarifaA.A["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A+500kg">A +500kg: </label>' +
        `<input type="text" id="A-A+500kg" class="swal2-input" value="${rateData.tarifas.tarifaA.A["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minA">A MIN: </label>' +
        `<input type="text" id="A-minA" class="swal2-input" value="${rateData.tarifas.tarifaA.A["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B1kg">B 1kg: </label>' +
        `<input type="text" id="A-B1kg" class="swal2-input" value="${rateData.tarifas.tarifaA.B["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B45kg">B +45kg: </label>' +
        `<input type="text" id="A-B45kg" class="swal2-input" value="${rateData.tarifas.tarifaA.B["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B100kg">B +100kg: </label>' +
        `<input type="text" id="A-B100kg" class="swal2-input" value="${rateData.tarifas.tarifaA.B["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B300kg">B +300kg: </label>' +
        `<input type="text" id="A-B300kg" class="swal2-input" value="${rateData.tarifas.tarifaA.B["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B+500kg">B +500kg: </label>' +
        `<input type="text" id="A-B+500kg" class="swal2-input" value="${rateData.tarifas.tarifaA.B["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minB">B MIN: </label>' +
        `<input type="text" id="A-minB" class="swal2-input" value="${rateData.tarifas.tarifaA.B["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C1kg">C 1kg: </label>' +
        `<input type="text" id="A-C1kg" class="swal2-input" value="${rateData.tarifas.tarifaA.C["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C45kg">C +45kg: </label>' +
        `<input type="text" id="A-C45kg" class="swal2-input" value="${rateData.tarifas.tarifaA.C["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C100kg">C +100kg: </label>' +
        `<input type="text" id="A-C100kg" class="swal2-input" value="${rateData.tarifas.tarifaA.C["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C300kg">C +300kg: </label>' +
        `<input type="text" id="A-C300kg" class="swal2-input" value="${rateData.tarifas.tarifaA.C["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C+500kg">C +500kg: </label>' +
        `<input type="text" id="A-C+500kg" class="swal2-input" value="${rateData.tarifas.tarifaA.C["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minC">C MIN: </label>' +
        `<input type="text" id="A-minC" class="swal2-input" value="${rateData.tarifas.tarifaA.C["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D1kg">D 1kg: </label>' +
        `<input type="text" id="A-D1kg" class="swal2-input" value="${rateData.tarifas.tarifaA.D["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D45kg">D +45kg: </label>' +
        `<input type="text" id="A-D45kg" class="swal2-input" value="${rateData.tarifas.tarifaA.D["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D100kg">D +100kg: </label>' +
        `<input type="text" id="A-D100kg" class="swal2-input" value="${rateData.tarifas.tarifaA.D["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D300kg">D +300kg: </label>' +
        `<input type="text" id="A-D300kg" class="swal2-input" value="${rateData.tarifas.tarifaA.D["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D+500kg">D +500kg: </label>' +
        `<input type="text" id="A-D+500kg" class="swal2-input" value="${rateData.tarifas.tarifaA.D["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minD">D MIN: </label>' +
        `<input type="text" id="A-minD" class="swal2-input" value="${rateData.tarifas.tarifaA.D["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E1kg">E 1kg: </label>' +
        `<input type="text" id="A-E1kg" class="swal2-input" value="${rateData.tarifas.tarifaA.E["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E45kg">E +45kg: </label>' +
        `<input type="text" id="A-E45kg" class="swal2-input" value="${rateData.tarifas.tarifaA.E["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E100kg">E +100kg: </label>' +
        `<input type="text" id="A-E100kg" class="swal2-input" value="${rateData.tarifas.tarifaA.E["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E300kg">E +300kg: </label>' +
        `<input type="text" id="A-E300kg" class="swal2-input" value="${rateData.tarifas.tarifaA.E["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E+500kg">E +500kg: </label>' +
        `<input type="text" id="A-E+500kg" class="swal2-input" value="${rateData.tarifas.tarifaA.E["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minE">E MIN: </label>' +
        `<input type="text" id="A-minE" class="swal2-input" value="${rateData.tarifas.tarifaA.E["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F1kg">F 1kg: </label>' +
        `<input type="text" id="A-F1kg" class="swal2-input" value="${rateData.tarifas.tarifaA.F["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F45kg">F +45kg: </label>' +
        `<input type="text" id="A-F45kg" class="swal2-input" value="${rateData.tarifas.tarifaA.F["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F100kg">F +100kg: </label>' +
        `<input type="text" id="A-F100kg" class="swal2-input" value="${rateData.tarifas.tarifaA.F["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F300kg">F +300kg: </label>' +
        `<input type="text" id="A-F300kg" class="swal2-input" value="${rateData.tarifas.tarifaA.F["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F+500kg">F +500kg: </label>' +
        `<input type="text" id="A-F+500kg" class="swal2-input" value="${rateData.tarifas.tarifaA.F["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minF">F MIN: </label>' +
        `<input type="text" id="A-minF" class="swal2-input" value="${rateData.tarifas.tarifaA.F["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G1kg">G 1kg: </label>' +
        `<input type="text" id="A-G1kg" class="swal2-input" value="${rateData.tarifas.tarifaA.G["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G45kg">G +45kg: </label>' +
        `<input type="text" id="A-G45kg" class="swal2-input" value="${rateData.tarifas.tarifaA.G["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G100kg">G +100kg: </label>' +
        `<input type="text" id="A-G100kg" class="swal2-input" value="${rateData.tarifas.tarifaA.G["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G300kg">G +300kg: </label>' +
        `<input type="text" id="A-G300kg" class="swal2-input" value="${rateData.tarifas.tarifaA.G["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G+500kg">G +500kg: </label>' +
        `<input type="text" id="A-G+500kg" class="swal2-input" value="${rateData.tarifas.tarifaA.G["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minG">G MIN: </label>' +
        `<input type="text" id="A-minG" class="swal2-input" value="${rateData.tarifas.tarifaA.G["MIN"]}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="tab-pane" id="tab2">' +
        "<label>Tabla B</label>" +
        "<br>" +
        "<div class='fields'>" +
        '<div class="input-container">' +
        '<label for="A1kg">A 1kg: </label>' +
        `<input type="text" id="B-A1kg" class="swal2-input" value="${rateData.tarifas.tarifaB.A["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A45kg">A +45kg: </label>' +
        `<input type="text" id="B-A45kg" class="swal2-input" value="${rateData.tarifas.tarifaB.A["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A100kg">A +100kg: </label>' +
        `<input type="text" id="B-A100kg" class="swal2-input" value="${rateData.tarifas.tarifaB.A["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A300kg">A +300kg: </label>' +
        `<input type="text" id="B-A300kg" class="swal2-input" value="${rateData.tarifas.tarifaB.A["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A+500kg">A +500kg: </label>' +
        `<input type="text" id="B-A+500kg" class="swal2-input" value="${rateData.tarifas.tarifaB.A["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minA">A MIN: </label>' +
        `<input type="text" id="B-minA" class="swal2-input" value="${rateData.tarifas.tarifaB.A["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B1kg">B 1kg: </label>' +
        `<input type="text" id="B-B1kg" class="swal2-input" value="${rateData.tarifas.tarifaB.B["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B45kg">B +45kg: </label>' +
        `<input type="text" id="B-B45kg" class="swal2-input" value="${rateData.tarifas.tarifaB.B["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B100kg">B +100kg: </label>' +
        `<input type="text" id="B-B100kg" class="swal2-input" value="${rateData.tarifas.tarifaB.B["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B300kg">B +300kg: </label>' +
        `<input type="text" id="B-B300kg" class="swal2-input" value="${rateData.tarifas.tarifaB.B["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B+500kg">B +500kg: </label>' +
        `<input type="text" id="B-B+500kg" class="swal2-input" value="${rateData.tarifas.tarifaB.B["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minB">B MIN: </label>' +
        `<input type="text" id="B-minB" class="swal2-input" value="${rateData.tarifas.tarifaB.B["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C1kg">C 1kg: </label>' +
        `<input type="text" id="B-C1kg" class="swal2-input" value="${rateData.tarifas.tarifaB.C["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C45kg">C +45kg: </label>' +
        `<input type="text" id="B-C45kg" class="swal2-input" value="${rateData.tarifas.tarifaB.C["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C100kg">C +100kg: </label>' +
        `<input type="text" id="B-C100kg" class="swal2-input" value="${rateData.tarifas.tarifaB.C["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C300kg">C +300kg: </label>' +
        `<input type="text" id="B-C300kg" class="swal2-input" value="${rateData.tarifas.tarifaB.C["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C+500kg">C +500kg: </label>' +
        `<input type="text" id="B-C+500kg" class="swal2-input" value="${rateData.tarifas.tarifaB.C["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minC">C MIN: </label>' +
        `<input type="text" id="B-minC" class="swal2-input" value="${rateData.tarifas.tarifaB.C["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D1kg">D 1kg: </label>' +
        `<input type="text" id="B-D1kg" class="swal2-input" value="${rateData.tarifas.tarifaB.D["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D45kg">D +45kg: </label>' +
        `<input type="text" id="B-D45kg" class="swal2-input" value="${rateData.tarifas.tarifaB.D["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D100kg">D +100kg: </label>' +
        `<input type="text" id="B-D100kg" class="swal2-input" value="${rateData.tarifas.tarifaB.D["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D300kg">D +300kg: </label>' +
        `<input type="text" id="B-D300kg" class="swal2-input" value="${rateData.tarifas.tarifaB.D["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D+500kg">D +500kg: </label>' +
        `<input type="text" id="B-D+500kg" class="swal2-input" value="${rateData.tarifas.tarifaB.D["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minD">D MIN: </label>' +
        `<input type="text" id="B-minD" class="swal2-input" value="${rateData.tarifas.tarifaB.D["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E1kg">E 1kg: </label>' +
        `<input type="text" id="B-E1kg" class="swal2-input" value="${rateData.tarifas.tarifaB.E["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E45kg">E +45kg: </label>' +
        `<input type="text" id="B-E45kg" class="swal2-input" value="${rateData.tarifas.tarifaB.E["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E100kg">E +100kg: </label>' +
        `<input type="text" id="B-E100kg" class="swal2-input" value="${rateData.tarifas.tarifaB.E["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E300kg">E +300kg: </label>' +
        `<input type="text" id="B-E300kg" class="swal2-input" value="${rateData.tarifas.tarifaB.E["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E+500kg">E +500kg: </label>' +
        `<input type="text" id="B-E+500kg" class="swal2-input" value="${rateData.tarifas.tarifaB.E["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minE">E MIN: </label>' +
        `<input type="text" id="B-minE" class="swal2-input" value="${rateData.tarifas.tarifaB.E["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F1kg">F 1kg: </label>' +
        `<input type="text" id="B-F1kg" class="swal2-input" value="${rateData.tarifas.tarifaB.F["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F45kg">F +45kg: </label>' +
        `<input type="text" id="B-F45kg" class="swal2-input" value="${rateData.tarifas.tarifaB.F["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F100kg">F +100kg: </label>' +
        `<input type="text" id="B-F100kg" class="swal2-input" value="${rateData.tarifas.tarifaB.F["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F300kg">F +300kg: </label>' +
        `<input type="text" id="B-F300kg" class="swal2-input" value="${rateData.tarifas.tarifaB.F["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F+500kg">F +500kg: </label>' +
        `<input type="text" id="B-F+500kg" class="swal2-input" value="${rateData.tarifas.tarifaB.F["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minF">F MIN: </label>' +
        `<input type="text" id="B-minF" class="swal2-input" value="${rateData.tarifas.tarifaB.F["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G1kg">G 1kg: </label>' +
        `<input type="text" id="B-G1kg" class="swal2-input" value="${rateData.tarifas.tarifaB.G["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G45kg">G +45kg: </label>' +
        `<input type="text" id="B-G45kg" class="swal2-input" value="${rateData.tarifas.tarifaB.G["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G100kg">G +100kg: </label>' +
        `<input type="text" id="B-G100kg" class="swal2-input" value="${rateData.tarifas.tarifaB.G["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G300kg">G +300kg: </label>' +
        `<input type="text" id="B-G300kg" class="swal2-input" value="${rateData.tarifas.tarifaB.G["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G+500kg">G +500kg: </label>' +
        `<input type="text" id="B-G+500kg" class="swal2-input" value="${rateData.tarifas.tarifaB.G["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minG">G MIN: </label>' +
        `<input type="text" id="B-minG" class="swal2-input" value="${rateData.tarifas.tarifaB.G["MIN"]}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="tab-pane" id="tab3">' +
        "<label>Tabla Especial</label>" +
        "<br>" +
        "<div class='fields'>" +
        '<div class="input-container">' +
        '<label for="MEXLAP1kg">MEXLAP 1kg: </label>' +
        `<input type="text" id="MEXLAP1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLAP45kg">MEXLAP +45kg: </label>' +
        `<input type="text" id="MEXLAP45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLAP100kg">MEXLAP +100kg: </label>' +
        `<input type="text" id="MEXLAP100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLAP300kg">MEXLAP +300kg: </label>' +
        `<input type="text" id="MEXLAP300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLAP+500kg">MEXLAP +500kg: </label>' +
        `<input type="text" id="MEXLAP+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXLAP">MEXLAP MIN: </label>' +
        `<input type="text" id="minMEXLAP" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUL1kg">MEXCUL 1kg: </label>' +
        `<input type="text" id="MEXCUL1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUL45kg">MEXCUL +45kg: </label>' +
        `<input type="text" id="MEXCUL45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUL100kg">MEXCUL +100kg: </label>' +
        `<input type="text" id="MEXCUL100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUL300kg">MEXCUL +300kg: </label>' +
        `<input type="text" id="MEXCUL300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUL+500kg">MEXCUL +500kg: </label>' +
        `<input type="text" id="MEXCUL+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXCUL">MEXCUL MIN: </label>' +
        `<input type="text" id="minMEXCUL" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMXL1kg">MEXMXL 1kg: </label>' +
        `<input type="text" id="MEXMXL1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMXL45kg">MEXMXL +45kg: </label>' +
        `<input type="text" id="MEXMXL45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMXL100kg">MEXMXL +100kg: </label>' +
        `<input type="text" id="MEXMXL100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMXL300kg">MEXMXL +300kg: </label>' +
        `<input type="text" id="MEXMXL300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMXL+500kg">MEXMXL +500kg: </label>' +
        `<input type="text" id="MEXMXL+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXMXL">MEXMXL MIN: </label>' +
        `<input type="text" id="minMEXMXL" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUU1kg">MEXCUU 1kg: </label>' +
        `<input type="text" id="MEXCUU1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUU45kg">MEXCUU +45kg: </label>' +
        `<input type="text" id="MEXCUU45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUU100kg">MEXCUU +100kg: </label>' +
        `<input type="text" id="MEXCUU100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUU300kg">MEXCUU +300kg: </label>' +
        `<input type="text" id="MEXCUU300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUU+500kg">MEXCUU +500kg: </label>' +
        `<input type="text" id="MEXCUU+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXCUU">MEXCUU MIN: </label>' +
        `<input type="text" id="minMEXCUU" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTGZ1kg">MEXTGZ 1kg: </label>' +
        `<input type="text" id="MEXTGZ1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTGZ45kg">MEXTGZ +45kg: </label>' +
        `<input type="text" id="MEXTGZ45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTGZ100kg">MEXTGZ +100kg: </label>' +
        `<input type="text" id="MEXTGZ100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTGZ300kg">MEXTGZ +300kg: </label>' +
        `<input type="text" id="MEXTGZ300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTGZ+500kg">MEXTGZ +500kg: </label>' +
        `<input type="text" id="MEXTGZ+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXTGZ">MEXTGZ MIN: </label>' +
        `<input type="text" id="minMEXTGZ" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["MIN"]}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "my-custom-modal",
      },
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve({
            service: rateData.service,
            tarifas: {
              tarifaA: {
                A: {
                  "1kg": parseFloat(document.getElementById("A-A1kg").value),
                  "+45kg": parseFloat(document.getElementById("A-A45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("A-A100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("A-A300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("A-A+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("A-minA").value),
                },
                B: {
                  "1kg": parseFloat(document.getElementById("A-B1kg").value),
                  "+45kg": parseFloat(document.getElementById("A-B45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("A-B100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("A-B300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("A-B+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("A-minB").value),
                },
                C: {
                  "1kg": parseFloat(document.getElementById("A-C1kg").value),
                  "+45kg": parseFloat(document.getElementById("A-C45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("A-C100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("A-C300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("A-C+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("A-minC").value),
                },
                D: {
                  "1kg": parseFloat(document.getElementById("A-D1kg").value),
                  "+45kg": parseFloat(document.getElementById("A-D45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("A-D100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("A-D300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("A-D+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("A-minD").value),
                },
                E: {
                  "1kg": parseFloat(document.getElementById("A-E1kg").value),
                  "+45kg": parseFloat(document.getElementById("A-E45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("A-E100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("A-E300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("A-E+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("A-minE").value),
                },
                F: {
                  "1kg": parseFloat(document.getElementById("A-F1kg").value),
                  "+45kg": parseFloat(document.getElementById("A-F45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("A-F100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("A-F300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("A-F+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("A-minF").value),
                },
                G: {
                  "1kg": parseFloat(document.getElementById("A-G1kg").value),
                  "+45kg": parseFloat(document.getElementById("A-G45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("A-G100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("A-G300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("A-G+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("A-minG").value),
                },
              },
              tarifaB: {
                A: {
                  "1kg": parseFloat(document.getElementById("B-A1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-A45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-A100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-A300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-A+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minA").value),
                },
                B: {
                  "1kg": parseFloat(document.getElementById("B-B1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-B45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-B100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-B300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-B+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minB").value),
                },
                C: {
                  "1kg": parseFloat(document.getElementById("B-C1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-C45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-C100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-C300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-C+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minC").value),
                },
                D: {
                  "1kg": parseFloat(document.getElementById("B-D1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-D45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-D100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-D300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-D+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minD").value),
                },
                E: {
                  "1kg": parseFloat(document.getElementById("B-E1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-E45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-E100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-E300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-E+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minE").value),
                },
                F: {
                  "1kg": parseFloat(document.getElementById("B-F1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-F45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-F100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-F300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-F+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minF").value),
                },
                G: {
                  "1kg": parseFloat(document.getElementById("B-G1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-G45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-G100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-G300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-G+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minG").value),
                },
              },
              tarifaEspecial: {
                MEXLAP: {
                  "1kg": parseFloat(document.getElementById("MEXLAP1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXLAP45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXLAP100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXLAP300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXLAP+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXLAP").value),
                },
                MEXCUL: {
                  "1kg": parseFloat(document.getElementById("MEXCUL1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXCUL45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXCUL100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXCUL300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXCUL+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXCUL").value),
                },
                MEXMXL: {
                  "1kg": parseFloat(document.getElementById("MEXMXL1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXMXL45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXMXL100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXMXL300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXMXL+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXMXL").value),
                },
                MEXCUU: {
                  "1kg": parseFloat(document.getElementById("MEXCUU1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXCUU45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXCUU100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXCUU300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXCUU+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXCUU").value),
                },
                MEXTGZ: {
                  "1kg": parseFloat(document.getElementById("MEXTGZ1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXTGZ45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXTGZ100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXTGZ300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXTGZ+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXTGZ").value),
                },
              },
            },
          });
        });
      },
      didRender: () => {
        // Agregar evento para cambiar de pestaña después de que se renderice el cuadro de diálogo
        document
          .getElementById("myTabs")
          .addEventListener("click", function (event) {
            const tabId = event.target.getAttribute("data-tab");
            if (tabId) {
              // Ocultar todas las pestañas y luego mostrar la pestaña seleccionada
              document.querySelectorAll(".tab-pane").forEach(function (tab) {
                tab.classList.remove("active");
              });
              document.getElementById(tabId).classList.add("active");
            }
          });
      },
    });

    if (result.isConfirmed) {
      const updatedUserData = result.value;

      const updateResponse = await axios.put(
        `${process.env.REACT_APP_URL_BACKEND}/api/rate/update/${id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      Swal.fire(
        "¡Actualizado!",
        "El usuario ha sido actualizado exitosamente.",
        "success"
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelado",
        "No se ha realizado ninguna actualización.",
        "error"
      );
    }
  } catch (error) {
    Swal.fire("Error", "Hubo un error", "error");
  }
};

export const updateRateTwo = async (id) => {
  try {
    // Obtener los datos del usuario a editar del servidor
    const response = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/api/rate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      }
    );
    const rateData = response.data.rate;

    const result = await Swal.fire({
      title: `Editar tarifa ${rateData.service}`,
      html:
        '<div class="form-grid">' +
        '<div class="input-container">' +
        '<label for="tarifa">Tarifa: </label>' +
        `<input type="text" id="tarifa" class="swal2-input" value="${rateData.tarifas}">` +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve({
            service: rateData.service,
            tarifas: document.getElementById("tarifa").value,
          });
        });
      },
    });

    if (result.isConfirmed) {
      const updatedUserData = result.value;

      const updateResponse = await axios.put(
        `${process.env.REACT_APP_URL_BACKEND}/api/rate/update/${id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      Swal.fire(
        "¡Actualizado!",
        "El usuario ha sido actualizado exitosamente.",
        "success"
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelado",
        "No se ha realizado ninguna actualización.",
        "error"
      );
    }
  } catch (error) {
    Swal.fire("Error", "Hubo un error", "error");
  }
};

export const updateRateThree = async (id) => {
  try {
    // Obtener los datos del usuario a editar del servidor
    const response = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/api/rate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      }
    );
    const rateData = response.data.rate;

    const result = await Swal.fire({
      title: `Editar tarifa ${rateData.service}`,
      html:
        '<div class="forma">' +
        '<ul class="nav nav-tabs" id="myTabs">' +
        '<li class="nav-item">' +
        '<a class="nav-link active" data-tab="tab1" href="#">Tabla A</a>' +
        "</li>" +
        '<li class="nav-item">' +
        '<a class="nav-link" data-tab="tab2" href="#">Tabla B</a>' +
        "</li>" +
        '<li class="nav-item">' +
        '<a class="nav-link" data-tab="tab3" href="#">Tabla Especial</a>' +
        "</li>" +
        "</ul>" +
        '<div class="tab-content">' +
        '<div class="tab-pane active" id="tab1">' +
        "<label>Tabla A</label>" +
        "</br>" +
        "<div class='fields' >" +
        '<div class="input-container">' +
        '<label for="A1kg">A 1kg: </label>' +
        `<input type="text" id="A-A1kg" class="swal2-input" value="${rateData.tarifas.tablaA.A["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A45kg">A +45kg: </label>' +
        `<input type="text" id="A-A45kg" class="swal2-input" value="${rateData.tarifas.tablaA.A["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A100kg">A +100kg: </label>' +
        `<input type="text" id="A-A100kg" class="swal2-input" value="${rateData.tarifas.tablaA.A["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A300kg">A +300kg: </label>' +
        `<input type="text" id="A-A300kg" class="swal2-input" value="${rateData.tarifas.tablaA.A["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A+500kg">A +500kg: </label>' +
        `<input type="text" id="A-A+500kg" class="swal2-input" value="${rateData.tarifas.tablaA.A["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minA">A MIN: </label>' +
        `<input type="text" id="A-minA" class="swal2-input" value="${rateData.tarifas.tablaA.A["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B1kg">B 1kg: </label>' +
        `<input type="text" id="A-B1kg" class="swal2-input" value="${rateData.tarifas.tablaA.B["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B45kg">B +45kg: </label>' +
        `<input type="text" id="A-B45kg" class="swal2-input" value="${rateData.tarifas.tablaA.B["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B100kg">B +100kg: </label>' +
        `<input type="text" id="A-B100kg" class="swal2-input" value="${rateData.tarifas.tablaA.B["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B300kg">B +300kg: </label>' +
        `<input type="text" id="A-B300kg" class="swal2-input" value="${rateData.tarifas.tablaA.B["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B+500kg">B +500kg: </label>' +
        `<input type="text" id="A-B+500kg" class="swal2-input" value="${rateData.tarifas.tablaA.B["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minB">B MIN: </label>' +
        `<input type="text" id="A-minB" class="swal2-input" value="${rateData.tarifas.tablaA.B["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C1kg">C 1kg: </label>' +
        `<input type="text" id="A-C1kg" class="swal2-input" value="${rateData.tarifas.tablaA.C["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C45kg">C +45kg: </label>' +
        `<input type="text" id="A-C45kg" class="swal2-input" value="${rateData.tarifas.tablaA.C["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C100kg">C +100kg: </label>' +
        `<input type="text" id="A-C100kg" class="swal2-input" value="${rateData.tarifas.tablaA.C["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C300kg">C +300kg: </label>' +
        `<input type="text" id="A-C300kg" class="swal2-input" value="${rateData.tarifas.tablaA.C["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C+500kg">C +500kg: </label>' +
        `<input type="text" id="A-C+500kg" class="swal2-input" value="${rateData.tarifas.tablaA.C["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minC">C MIN: </label>' +
        `<input type="text" id="A-minC" class="swal2-input" value="${rateData.tarifas.tablaA.C["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D1kg">D 1kg: </label>' +
        `<input type="text" id="A-D1kg" class="swal2-input" value="${rateData.tarifas.tablaA.D["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D45kg">D +45kg: </label>' +
        `<input type="text" id="A-D45kg" class="swal2-input" value="${rateData.tarifas.tablaA.D["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D100kg">D +100kg: </label>' +
        `<input type="text" id="A-D100kg" class="swal2-input" value="${rateData.tarifas.tablaA.D["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D300kg">D +300kg: </label>' +
        `<input type="text" id="A-D300kg" class="swal2-input" value="${rateData.tarifas.tablaA.D["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D+500kg">D +500kg: </label>' +
        `<input type="text" id="A-D+500kg" class="swal2-input" value="${rateData.tarifas.tablaA.D["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minD">D MIN: </label>' +
        `<input type="text" id="A-minD" class="swal2-input" value="${rateData.tarifas.tablaA.D["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E1kg">E 1kg: </label>' +
        `<input type="text" id="A-E1kg" class="swal2-input" value="${rateData.tarifas.tablaA.E["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E45kg">E +45kg: </label>' +
        `<input type="text" id="A-E45kg" class="swal2-input" value="${rateData.tarifas.tablaA.E["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E100kg">E +100kg: </label>' +
        `<input type="text" id="A-E100kg" class="swal2-input" value="${rateData.tarifas.tablaA.E["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E300kg">E +300kg: </label>' +
        `<input type="text" id="A-E300kg" class="swal2-input" value="${rateData.tarifas.tablaA.E["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E+500kg">E +500kg: </label>' +
        `<input type="text" id="A-E+500kg" class="swal2-input" value="${rateData.tarifas.tablaA.E["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minE">E MIN: </label>' +
        `<input type="text" id="A-minE" class="swal2-input" value="${rateData.tarifas.tablaA.E["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F1kg">F 1kg: </label>' +
        `<input type="text" id="A-F1kg" class="swal2-input" value="${rateData.tarifas.tablaA.F["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F45kg">F +45kg: </label>' +
        `<input type="text" id="A-F45kg" class="swal2-input" value="${rateData.tarifas.tablaA.F["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F100kg">F +100kg: </label>' +
        `<input type="text" id="A-F100kg" class="swal2-input" value="${rateData.tarifas.tablaA.F["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F300kg">F +300kg: </label>' +
        `<input type="text" id="A-F300kg" class="swal2-input" value="${rateData.tarifas.tablaA.F["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F+500kg">F +500kg: </label>' +
        `<input type="text" id="A-F+500kg" class="swal2-input" value="${rateData.tarifas.tablaA.F["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minF">F MIN: </label>' +
        `<input type="text" id="A-minF" class="swal2-input" value="${rateData.tarifas.tablaA.F["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G1kg">G 1kg: </label>' +
        `<input type="text" id="A-G1kg" class="swal2-input" value="${rateData.tarifas.tablaA.G["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G45kg">G +45kg: </label>' +
        `<input type="text" id="A-G45kg" class="swal2-input" value="${rateData.tarifas.tablaA.G["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G100kg">G +100kg: </label>' +
        `<input type="text" id="A-G100kg" class="swal2-input" value="${rateData.tarifas.tablaA.G["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G300kg">G +300kg: </label>' +
        `<input type="text" id="A-G300kg" class="swal2-input" value="${rateData.tarifas.tablaA.G["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G+500kg">G +500kg: </label>' +
        `<input type="text" id="A-G+500kg" class="swal2-input" value="${rateData.tarifas.tablaA.G["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minG">G MIN: </label>' +
        `<input type="text" id="A-minG" class="swal2-input" value="${rateData.tarifas.tablaA.G["MIN"]}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="tab-pane" id="tab2">' +
        "<label>Tabla B</label>" +
        "<br>" +
        "<div class='fields'>" +
        '<div class="input-container">' +
        '<label for="A1kg">A 1kg: </label>' +
        `<input type="text" id="B-A1kg" class="swal2-input" value="${rateData.tarifas.tablaB.A["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A45kg">A +45kg: </label>' +
        `<input type="text" id="B-A45kg" class="swal2-input" value="${rateData.tarifas.tablaB.A["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A100kg">A +100kg: </label>' +
        `<input type="text" id="B-A100kg" class="swal2-input" value="${rateData.tarifas.tablaB.A["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A300kg">A +300kg: </label>' +
        `<input type="text" id="B-A300kg" class="swal2-input" value="${rateData.tarifas.tablaB.A["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="A+500kg">A +500kg: </label>' +
        `<input type="text" id="B-A+500kg" class="swal2-input" value="${rateData.tarifas.tablaB.A["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minA">A MIN: </label>' +
        `<input type="text" id="B-minA" class="swal2-input" value="${rateData.tarifas.tablaB.A["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B1kg">B 1kg: </label>' +
        `<input type="text" id="B-B1kg" class="swal2-input" value="${rateData.tarifas.tablaB.B["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B45kg">B +45kg: </label>' +
        `<input type="text" id="B-B45kg" class="swal2-input" value="${rateData.tarifas.tablaB.B["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B100kg">B +100kg: </label>' +
        `<input type="text" id="B-B100kg" class="swal2-input" value="${rateData.tarifas.tablaB.B["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B300kg">B +300kg: </label>' +
        `<input type="text" id="B-B300kg" class="swal2-input" value="${rateData.tarifas.tablaB.B["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="B+500kg">B +500kg: </label>' +
        `<input type="text" id="B-B+500kg" class="swal2-input" value="${rateData.tarifas.tablaB.B["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minB">B MIN: </label>' +
        `<input type="text" id="B-minB" class="swal2-input" value="${rateData.tarifas.tablaB.B["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C1kg">C 1kg: </label>' +
        `<input type="text" id="B-C1kg" class="swal2-input" value="${rateData.tarifas.tablaB.C["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C45kg">C +45kg: </label>' +
        `<input type="text" id="B-C45kg" class="swal2-input" value="${rateData.tarifas.tablaB.C["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C100kg">C +100kg: </label>' +
        `<input type="text" id="B-C100kg" class="swal2-input" value="${rateData.tarifas.tablaB.C["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C300kg">C +300kg: </label>' +
        `<input type="text" id="B-C300kg" class="swal2-input" value="${rateData.tarifas.tablaB.C["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="C+500kg">C +500kg: </label>' +
        `<input type="text" id="B-C+500kg" class="swal2-input" value="${rateData.tarifas.tablaB.C["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minC">C MIN: </label>' +
        `<input type="text" id="B-minC" class="swal2-input" value="${rateData.tarifas.tablaB.C["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D1kg">D 1kg: </label>' +
        `<input type="text" id="B-D1kg" class="swal2-input" value="${rateData.tarifas.tablaB.D["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D45kg">D +45kg: </label>' +
        `<input type="text" id="B-D45kg" class="swal2-input" value="${rateData.tarifas.tablaB.D["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D100kg">D +100kg: </label>' +
        `<input type="text" id="B-D100kg" class="swal2-input" value="${rateData.tarifas.tablaB.D["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D300kg">D +300kg: </label>' +
        `<input type="text" id="B-D300kg" class="swal2-input" value="${rateData.tarifas.tablaB.D["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="D+500kg">D +500kg: </label>' +
        `<input type="text" id="B-D+500kg" class="swal2-input" value="${rateData.tarifas.tablaB.D["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minD">D MIN: </label>' +
        `<input type="text" id="B-minD" class="swal2-input" value="${rateData.tarifas.tablaB.D["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E1kg">E 1kg: </label>' +
        `<input type="text" id="B-E1kg" class="swal2-input" value="${rateData.tarifas.tablaB.E["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E45kg">E +45kg: </label>' +
        `<input type="text" id="B-E45kg" class="swal2-input" value="${rateData.tarifas.tablaB.E["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E100kg">E +100kg: </label>' +
        `<input type="text" id="B-E100kg" class="swal2-input" value="${rateData.tarifas.tablaB.E["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E300kg">E +300kg: </label>' +
        `<input type="text" id="B-E300kg" class="swal2-input" value="${rateData.tarifas.tablaB.E["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="E+500kg">E +500kg: </label>' +
        `<input type="text" id="B-E+500kg" class="swal2-input" value="${rateData.tarifas.tablaB.E["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minE">E MIN: </label>' +
        `<input type="text" id="B-minE" class="swal2-input" value="${rateData.tarifas.tablaB.E["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F1kg">F 1kg: </label>' +
        `<input type="text" id="B-F1kg" class="swal2-input" value="${rateData.tarifas.tablaB.F["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F45kg">F +45kg: </label>' +
        `<input type="text" id="B-F45kg" class="swal2-input" value="${rateData.tarifas.tablaB.F["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F100kg">F +100kg: </label>' +
        `<input type="text" id="B-F100kg" class="swal2-input" value="${rateData.tarifas.tablaB.F["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F300kg">F +300kg: </label>' +
        `<input type="text" id="B-F300kg" class="swal2-input" value="${rateData.tarifas.tablaB.F["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="F+500kg">F +500kg: </label>' +
        `<input type="text" id="B-F+500kg" class="swal2-input" value="${rateData.tarifas.tablaB.F["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minF">F MIN: </label>' +
        `<input type="text" id="B-minF" class="swal2-input" value="${rateData.tarifas.tablaB.F["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G1kg">G 1kg: </label>' +
        `<input type="text" id="B-G1kg" class="swal2-input" value="${rateData.tarifas.tablaB.G["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G45kg">G +45kg: </label>' +
        `<input type="text" id="B-G45kg" class="swal2-input" value="${rateData.tarifas.tablaB.G["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G100kg">G +100kg: </label>' +
        `<input type="text" id="B-G100kg" class="swal2-input" value="${rateData.tarifas.tablaB.G["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G300kg">G +300kg: </label>' +
        `<input type="text" id="B-G300kg" class="swal2-input" value="${rateData.tarifas.tablaB.G["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="G+500kg">G +500kg: </label>' +
        `<input type="text" id="B-G+500kg" class="swal2-input" value="${rateData.tarifas.tablaB.G["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minG">G MIN: </label>' +
        `<input type="text" id="B-minG" class="swal2-input" value="${rateData.tarifas.tablaB.G["MIN"]}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="tab-pane" id="tab3">' +
        "<label>Tabla Especial</label>" +
        "<br>" +
        "<div class='fields'>" +
        '<div class="input-container">' +
        '<label for="MEXCUL1kg">MEXCUL 1kg: </label>' +
        `<input type="text" id="MEXCUL1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUL45kg">MEXCUL +45kg: </label>' +
        `<input type="text" id="MEXCUL45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUL100kg">MEXCUL +100kg: </label>' +
        `<input type="text" id="MEXCUL100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUL300kg">MEXCUL +300kg: </label>' +
        `<input type="text" id="MEXCUL300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUL+500kg">MEXCUL +500kg: </label>' +
        `<input type="text" id="MEXCUL+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXCUL">MEXCUL MIN: </label>' +
        `<input type="text" id="minMEXCUL" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUL["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCME1kg">MEXCME 1kg: </label>' +
        `<input type="text" id="MEXCME1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCME["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCME45kg">MEXCME +45kg: </label>' +
        `<input type="text" id="MEXCME45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCME["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCME100kg">MEXCME +100kg: </label>' +
        `<input type="text" id="MEXCME100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCME["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCME300kg">MEXCME +300kg: </label>' +
        `<input type="text" id="MEXCME300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCME["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCME+500kg">MEXCME +500kg: </label>' +
        `<input type="text" id="MEXCME+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCME["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXCME">MEXCME MIN: </label>' +
        `<input type="text" id="minMEXCME" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCME["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSLW1kg">MEXSLW 1kg: </label>' +
        `<input type="text" id="MEXSLW1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLW["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSLW45kg">MEXSLW +45kg: </label>' +
        `<input type="text" id="MEXSLW45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLW["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSLW100kg">MEXSLW +100kg: </label>' +
        `<input type="text" id="MEXSLW100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLW["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSLW300kg">MEXSLW +300kg: </label>' +
        `<input type="text" id="MEXSLW300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLW["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSLW+500kg">MEXSLW +500kg: </label>' +
        `<input type="text" id="MEXSLW+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLW["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXSLW">MEXSLW MIN: </label>' +
        `<input type="text" id="minMEXSLW" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLW["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUN1kg">MEXCUN 1kg: </label>' +
        `<input type="text" id="MEXCUN1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUN["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUN45kg">MEXCUN +45kg: </label>' +
        `<input type="text" id="MEXCUN45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUN["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUN100kg">MEXCUN +100kg: </label>' +
        `<input type="text" id="MEXCUN100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUN["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUN300kg">MEXCUN +300kg: </label>' +
        `<input type="text" id="MEXCUN300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUN["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUN+500kg">MEXCUN +500kg: </label>' +
        `<input type="text" id="MEXCUN+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUN["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXCUN">MEXCUN MIN: </label>' +
        `<input type="text" id="minMEXCUN" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUN["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMTY1kg">MEXMTY 1kg: </label>' +
        `<input type="text" id="MEXMTY1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTY["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMTY45kg">MEXMTY +45kg: </label>' +
        `<input type="text" id="MEXMTY45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTY["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMTY100kg">MEXMTY +100kg: </label>' +
        `<input type="text" id="MEXMTY100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTY["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMTY300kg">MEXMTY +300kg: </label>' +
        `<input type="text" id="MEXMTY300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTY["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMTY+500kg">MEXMTY +500kg: </label>' +
        `<input type="text" id="MEXMTY+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTY["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXMTY">MEXMTY MIN: </label>' +
        `<input type="text" id="minMEXMTY" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTY["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXBJX1kg">MEXBJX 1kg: </label>' +
        `<input type="text" id="MEXBJX1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXBJX["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXBJX45kg">MEXBJX +45kg: </label>' +
        `<input type="text" id="MEXBJX45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXBJX["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXBJX100kg">MEXBJX +100kg: </label>' +
        `<input type="text" id="MEXBJX100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXBJX["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXBJX300kg">MEXBJX +300kg: </label>' +
        `<input type="text" id="MEXBJX300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXBJX["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXBJX+500kg">MEXBJX +500kg: </label>' +
        `<input type="text" id="MEXBJX+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXBJX["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXBJX">MEXBJX MIN: </label>' +
        `<input type="text" id="minMEXBJX" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXBJX["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXPBC1kg">MEXPBC 1kg: </label>' +
        `<input type="text" id="MEXPBC1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPBC["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXPBC45kg">MEXPBC +45kg: </label>' +
        `<input type="text" id="MEXPBC45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPBC["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXPBC100kg">MEXPBC +100kg: </label>' +
        `<input type="text" id="MEXPBC100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPBC["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXPBC300kg">MEXPBC +300kg: </label>' +
        `<input type="text" id="MEXPBC300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPBC["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXPBC+500kg">MEXPBC +500kg: </label>' +
        `<input type="text" id="MEXPBC+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPBC["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXPBC">MEXPBC MIN: </label>' +
        `<input type="text" id="minMEXPBC" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPBC["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSLP1kg">MEXSLP 1kg: </label>' +
        `<input type="text" id="MEXSLP1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLP["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSLP45kg">MEXSLP +45kg: </label>' +
        `<input type="text" id="MEXSLP45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLP["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSLP100kg">MEXSLP +100kg: </label>' +
        `<input type="text" id="MEXSLP100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLP["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSLP300kg">MEXSLP +300kg: </label>' +
        `<input type="text" id="MEXSLP300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLP["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSLP+500kg">MEXSLP +500kg: </label>' +
        `<input type="text" id="MEXSLP+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLP["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXSLP">MEXSLP MIN: </label>' +
        `<input type="text" id="minMEXSLP" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSLP["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXQRO1kg">MEXQRO 1kg: </label>' +
        `<input type="text" id="MEXQRO1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXQRO["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXQRO45kg">MEXQRO +45kg: </label>' +
        `<input type="text" id="MEXQRO45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXQRO["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXQRO100kg">MEXQRO +100kg: </label>' +
        `<input type="text" id="MEXQRO100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXQRO["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXQRO300kg">MEXQRO +300kg: </label>' +
        `<input type="text" id="MEXQRO300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXQRO["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXQRO+500kg">MEXQRO +500kg: </label>' +
        `<input type="text" id="MEXQRO+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXQRO["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXQRO">MEXQRO MIN: </label>' +
        `<input type="text" id="minMEXQRO" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXQRO["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXGDL1kg">MEXGDL 1kg: </label>' +
        `<input type="text" id="MEXGDL1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXGDL["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXGDL45kg">MEXGDL +45kg: </label>' +
        `<input type="text" id="MEXGDL45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXGDL["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXGDL100kg">MEXGDL +100kg: </label>' +
        `<input type="text" id="MEXGDL100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXGDL["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXGDL300kg">MEXGDL +300kg: </label>' +
        `<input type="text" id="MEXGDL300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXGDL["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXGDL+500kg">MEXGDL +500kg: </label>' +
        `<input type="text" id="MEXGDL+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXGDL["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXGDL">MEXGDL MIN: </label>' +
        `<input type="text" id="minMEXGDL" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXGDL["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCJS1kg">MEXCJS 1kg: </label>' +
        `<input type="text" id="MEXCJS1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCJS["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCJS45kg">MEXCJS +45kg: </label>' +
        `<input type="text" id="MEXCJS45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCJS["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCJS100kg">MEXCJS +100kg: </label>' +
        `<input type="text" id="MEXCJS100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCJS["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCJS300kg">MEXCJS +300kg: </label>' +
        `<input type="text" id="MEXCJS300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCJS["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCJS+500kg">MEXCJS +500kg: </label>' +
        `<input type="text" id="MEXCJS+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCJS["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXCJS">MEXCJS MIN: </label>' +
        `<input type="text" id="minMEXCJS" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCJS["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXPVR1kg">MEXPVR 1kg: </label>' +
        `<input type="text" id="MEXPVR1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPVR["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXPVR45kg">MEXPVR +45kg: </label>' +
        `<input type="text" id="MEXPVR45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPVR["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXPVR100kg">MEXPVR +100kg: </label>' +
        `<input type="text" id="MEXPVR100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPVR["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXPVR300kg">MEXPVR +300kg: </label>' +
        `<input type="text" id="MEXPVR300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPVR["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXPVR+500kg">MEXPVR +500kg: </label>' +
        `<input type="text" id="MEXPVR+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPVR["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXPVR">MEXPVR MIN: </label>' +
        `<input type="text" id="minMEXPVR" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXPVR["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMTT1kg">MEXMTT 1kg: </label>' +
        `<input type="text" id="MEXMTT1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTT["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMTT45kg">MEXMTT +45kg: </label>' +
        `<input type="text" id="MEXMTT45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTT["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMTT100kg">MEXMTT +100kg: </label>' +
        `<input type="text" id="MEXMTT100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTT["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMTT300kg">MEXMTT +300kg: </label>' +
        `<input type="text" id="MEXMTT300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTT["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMTT+500kg">MEXMTT +500kg: </label>' +
        `<input type="text" id="MEXMTT+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTT["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXMTT">MEXMTT MIN: </label>' +
        `<input type="text" id="minMEXMTT" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMTT["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSJD1kg">MEXSJD 1kg: </label>' +
        `<input type="text" id="MEXSJD1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSJD["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSJD45kg">MEXSJD +45kg: </label>' +
        `<input type="text" id="MEXSJD45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSJD["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSJD100kg">MEXSJD +100kg: </label>' +
        `<input type="text" id="MEXSJD100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSJD["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSJD300kg">MEXSJD +300kg: </label>' +
        `<input type="text" id="MEXSJD300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSJD["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXSJD+500kg">MEXSJD +500kg: </label>' +
        `<input type="text" id="MEXSJD+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSJD["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXSJD">MEXSJD MIN: </label>' +
        `<input type="text" id="minMEXSJD" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXSJD["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTAP1kg">MEXTAP 1kg: </label>' +
        `<input type="text" id="MEXTAP1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAP["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTAP45kg">MEXTAP +45kg: </label>' +
        `<input type="text" id="MEXTAP45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAP["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTAP100kg">MEXTAP +100kg: </label>' +
        `<input type="text" id="MEXTAP100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAP["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTAP300kg">MEXTAP +300kg: </label>' +
        `<input type="text" id="MEXTAP300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAP["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTAP+500kg">MEXTAP +500kg: </label>' +
        `<input type="text" id="MEXTAP+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAP["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXTAP">MEXTAP MIN: </label>' +
        `<input type="text" id="minMEXTAP" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAP["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXVER1kg">MEXVER 1kg: </label>' +
        `<input type="text" id="MEXVER1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVER["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXVER45kg">MEXVER +45kg: </label>' +
        `<input type="text" id="MEXVER45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVER["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXVER100kg">MEXVER +100kg: </label>' +
        `<input type="text" id="MEXVER100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVER["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXVER300kg">MEXVER +300kg: </label>' +
        `<input type="text" id="MEXVER300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVER["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXVER+500kg">MEXVER +500kg: </label>' +
        `<input type="text" id="MEXVER+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVER["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXVER">MEXVER MIN: </label>' +
        `<input type="text" id="minMEXVER" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVER["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTAM1kg">MEXTAM 1kg: </label>' +
        `<input type="text" id="MEXTAM1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAM["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTAM45kg">MEXTAM +45kg: </label>' +
        `<input type="text" id="MEXTAM45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAM["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTAM100kg">MEXTAM +100kg: </label>' +
        `<input type="text" id="MEXTAM100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAM["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTAM300kg">MEXTAM +300kg: </label>' +
        `<input type="text" id="MEXTAM300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAM["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTAM+500kg">MEXTAM +500kg: </label>' +
        `<input type="text" id="MEXTAM+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAM["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXTAM">MEXTAM MIN: </label>' +
        `<input type="text" id="minMEXTAM" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTAM["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMXL1kg">MEXMXL 1kg: </label>' +
        `<input type="text" id="MEXMXL1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMXL45kg">MEXMXL +45kg: </label>' +
        `<input type="text" id="MEXMXL45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMXL100kg">MEXMXL +100kg: </label>' +
        `<input type="text" id="MEXMXL100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMXL300kg">MEXMXL +300kg: </label>' +
        `<input type="text" id="MEXMXL300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMXL+500kg">MEXMXL +500kg: </label>' +
        `<input type="text" id="MEXMXL+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXMXL">MEXMXL MIN: </label>' +
        `<input type="text" id="minMEXMXL" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMXL["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXOAX1kg">MEXOAX 1kg: </label>' +
        `<input type="text" id="MEXOAX1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXOAX["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXOAX45kg">MEXOAX +45kg: </label>' +
        `<input type="text" id="MEXOAX45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXOAX["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXOAX100kg">MEXOAX +100kg: </label>' +
        `<input type="text" id="MEXOAX100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXOAX["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXOAX300kg">MEXOAX +300kg: </label>' +
        `<input type="text" id="MEXOAX300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXOAX["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXOAX+500kg">MEXOAX +500kg: </label>' +
        `<input type="text" id="MEXOAX+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXOAX["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXOAX">MEXOAX MIN: </label>' +
        `<input type="text" id="minMEXOAX" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXOAX["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMZT1kg">MEXMZT 1kg: </label>' +
        `<input type="text" id="MEXMZT1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMZT["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMZT45kg">MEXMZT +45kg: </label>' +
        `<input type="text" id="MEXMZT45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMZT["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMZT100kg">MEXMZT +100kg: </label>' +
        `<input type="text" id="MEXMZT100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMZT["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMZT300kg">MEXMZT +300kg: </label>' +
        `<input type="text" id="MEXMZT300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMZT["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMZT+500kg">MEXMZT +500kg: </label>' +
        `<input type="text" id="MEXMZT+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMZT["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXMZT">MEXMZT MIN: </label>' +
        `<input type="text" id="minMEXMZT" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMZT["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTGZ1kg">MEXTGZ 1kg: </label>' +
        `<input type="text" id="MEXTGZ1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTGZ45kg">MEXTGZ +45kg: </label>' +
        `<input type="text" id="MEXTGZ45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTGZ100kg">MEXTGZ +100kg: </label>' +
        `<input type="text" id="MEXTGZ100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTGZ300kg">MEXTGZ +300kg: </label>' +
        `<input type="text" id="MEXTGZ300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTGZ+500kg">MEXTGZ +500kg: </label>' +
        `<input type="text" id="MEXTGZ+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXTGZ">MEXTGZ MIN: </label>' +
        `<input type="text" id="minMEXTGZ" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTGZ["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXREX1kg">MEXREX 1kg: </label>' +
        `<input type="text" id="MEXREX1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXREX["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXREX45kg">MEXREX +45kg: </label>' +
        `<input type="text" id="MEXREX45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXREX["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXREX100kg">MEXREX +100kg: </label>' +
        `<input type="text" id="MEXREX100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXREX["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXREX300kg">MEXREX +300kg: </label>' +
        `<input type="text" id="MEXREX300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXREX["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXREX+500kg">MEXREX +500kg: </label>' +
        `<input type="text" id="MEXREX+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXREX["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXREX">MEXREX MIN: </label>' +
        `<input type="text" id="minMEXREX" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXREX["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTRC1kg">MEXTRC 1kg: </label>' +
        `<input type="text" id="MEXTRC1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTRC["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTRC45kg">MEXTRC +45kg: </label>' +
        `<input type="text" id="MEXTRC45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTRC["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTRC100kg">MEXTRC +100kg: </label>' +
        `<input type="text" id="MEXTRC100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTRC["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTRC300kg">MEXTRC +300kg: </label>' +
        `<input type="text" id="MEXTRC300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTRC["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTRC+500kg">MEXTRC +500kg: </label>' +
        `<input type="text" id="MEXTRC+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTRC["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXTRC">MEXTRC MIN: </label>' +
        `<input type="text" id="minMEXTRC" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTRC["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLAP1kg">MEXLAP 1kg: </label>' +
        `<input type="text" id="MEXLAP1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLAP45kg">MEXLAP +45kg: </label>' +
        `<input type="text" id="MEXLAP45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLAP100kg">MEXLAP +100kg: </label>' +
        `<input type="text" id="MEXLAP100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLAP300kg">MEXLAP +300kg: </label>' +
        `<input type="text" id="MEXLAP300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLAP+500kg">MEXLAP +500kg: </label>' +
        `<input type="text" id="MEXLAP+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXLAP">MEXLAP MIN: </label>' +
        `<input type="text" id="minMEXLAP" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLAP["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCPE1kg">MEXCPE 1kg: </label>' +
        `<input type="text" id="MEXCPE1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCPE["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCPE45kg">MEXCPE +45kg: </label>' +
        `<input type="text" id="MEXCPE45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCPE["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCPE100kg">MEXCPE +100kg: </label>' +
        `<input type="text" id="MEXCPE100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCPE["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCPE300kg">MEXCPE +300kg: </label>' +
        `<input type="text" id="MEXCPE300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCPE["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCPE+500kg">MEXCPE +500kg: </label>' +
        `<input type="text" id="MEXCPE+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCPE["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXCPE">MEXCPE MIN: </label>' +
        `<input type="text" id="minMEXCPE" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCPE["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXZIH1kg">MEXZIH 1kg: </label>' +
        `<input type="text" id="MEXZIH1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXZIH["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXZIH45kg">MEXZIH +45kg: </label>' +
        `<input type="text" id="MEXZIH45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXZIH["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXZIH100kg">MEXZIH +100kg: </label>' +
        `<input type="text" id="MEXZIH100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXZIH["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXZIH300kg">MEXZIH +300kg: </label>' +
        `<input type="text" id="MEXZIH300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXZIH["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXZIH+500kg">MEXZIH +500kg: </label>' +
        `<input type="text" id="MEXZIH+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXZIH["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXZIH">MEXZIH MIN: </label>' +
        `<input type="text" id="minMEXZIH" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXZIH["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTIJ1kg">MEXTIJ 1kg: </label>' +
        `<input type="text" id="MEXTIJ1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTIJ["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTIJ45kg">MEXTIJ +45kg: </label>' +
        `<input type="text" id="MEXTIJ45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTIJ["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTIJ100kg">MEXTIJ +100kg: </label>' +
        `<input type="text" id="MEXTIJ100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTIJ["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTIJ300kg">MEXTIJ +300kg: </label>' +
        `<input type="text" id="MEXTIJ300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTIJ["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXTIJ+500kg">MEXTIJ +500kg: </label>' +
        `<input type="text" id="MEXTIJ+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTIJ["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXTIJ">MEXTIJ MIN: </label>' +
        `<input type="text" id="minMEXTIJ" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXTIJ["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXHMO1kg">MEXHMO 1kg: </label>' +
        `<input type="text" id="MEXHMO1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXHMO["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXHMO45kg">MEXHMO +45kg: </label>' +
        `<input type="text" id="MEXHMO45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXHMO["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXHMO100kg">MEXHMO +100kg: </label>' +
        `<input type="text" id="MEXHMO100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXHMO["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXHMO300kg">MEXHMO +300kg: </label>' +
        `<input type="text" id="MEXHMO300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXHMO["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXHMO+500kg">MEXHMO +500kg: </label>' +
        `<input type="text" id="MEXHMO+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXHMO["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXHMO">MEXHMO MIN: </label>' +
        `<input type="text" id="minMEXHMO" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXHMO["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMID1kg">MEXMID 1kg: </label>' +
        `<input type="text" id="MEXMID1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMID["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMID45kg">MEXMID +45kg: </label>' +
        `<input type="text" id="MEXMID45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMID["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMID100kg">MEXMID +100kg: </label>' +
        `<input type="text" id="MEXMID100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMID["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMID300kg">MEXMID +300kg: </label>' +
        `<input type="text" id="MEXMID300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMID["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXMID+500kg">MEXMID +500kg: </label>' +
        `<input type="text" id="MEXMID+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMID["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXMID">MEXMID MIN: </label>' +
        `<input type="text" id="minMEXMID" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXMID["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXAGU1kg">MEXAGU 1kg: </label>' +
        `<input type="text" id="MEXAGU1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXAGU["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXAGU45kg">MEXAGU +45kg: </label>' +
        `<input type="text" id="MEXAGU45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXAGU["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXAGU100kg">MEXAGU +100kg: </label>' +
        `<input type="text" id="MEXAGU100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXAGU["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXAGU300kg">MEXAGU +300kg: </label>' +
        `<input type="text" id="MEXAGU300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXAGU["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXAGU+500kg">MEXAGU +500kg: </label>' +
        `<input type="text" id="MEXAGU+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXAGU["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXAGU">MEXAGU MIN: </label>' +
        `<input type="text" id="minMEXAGU" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXAGU["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCEN1kg">MEXCEN 1kg: </label>' +
        `<input type="text" id="MEXCEN1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCEN["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCEN45kg">MEXCEN +45kg: </label>' +
        `<input type="text" id="MEXCEN45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCEN["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCEN100kg">MEXCEN +100kg: </label>' +
        `<input type="text" id="MEXCEN100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCEN["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCEN300kg">MEXCEN +300kg: </label>' +
        `<input type="text" id="MEXCEN300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCEN["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCEN+500kg">MEXCEN +500kg: </label>' +
        `<input type="text" id="MEXCEN+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCEN["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXCEN">MEXCEN MIN: </label>' +
        `<input type="text" id="minMEXCEN" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCEN["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXDGO1kg">MEXDGO 1kg: </label>' +
        `<input type="text" id="MEXDGO1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXDGO["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXDGO45kg">MEXDGO +45kg: </label>' +
        `<input type="text" id="MEXDGO45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXDGO["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXDGO100kg">MEXDGO +100kg: </label>' +
        `<input type="text" id="MEXDGO100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXDGO["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXDGO300kg">MEXDGO +300kg: </label>' +
        `<input type="text" id="MEXDGO300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXDGO["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXDGO+500kg">MEXDGO +500kg: </label>' +
        `<input type="text" id="MEXDGO+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXDGO["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXDGO">MEXDGO MIN: </label>' +
        `<input type="text" id="minMEXDGO" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXDGO["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLMM1kg">MEXLMM 1kg: </label>' +
        `<input type="text" id="MEXLMM1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLMM["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLMM45kg">MEXLMM +45kg: </label>' +
        `<input type="text" id="MEXLMM45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLMM["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLMM100kg">MEXLMM +100kg: </label>' +
        `<input type="text" id="MEXLMM100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLMM["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLMM300kg">MEXLMM +300kg: </label>' +
        `<input type="text" id="MEXLMM300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLMM["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXLMM+500kg">MEXLMM +500kg: </label>' +
        `<input type="text" id="MEXLMM+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLMM["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXLMM">MEXLMM MIN: </label>' +
        `<input type="text" id="minMEXLMM" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXLMM["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUU1kg">MEXCUU 1kg: </label>' +
        `<input type="text" id="MEXCUU1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUU45kg">MEXCUU +45kg: </label>' +
        `<input type="text" id="MEXCUU45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUU100kg">MEXCUU +100kg: </label>' +
        `<input type="text" id="MEXCUU100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUU300kg">MEXCUU +300kg: </label>' +
        `<input type="text" id="MEXCUU300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXCUU+500kg">MEXCUU +500kg: </label>' +
        `<input type="text" id="MEXCUU+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXCUU">MEXCUU MIN: </label>' +
        `<input type="text" id="minMEXCUU" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXCUU["MIN"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXVSA1kg">MEXVSA 1kg: </label>' +
        `<input type="text" id="MEXVSA1kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVSA["1kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXVSA45kg">MEXVSA +45kg: </label>' +
        `<input type="text" id="MEXVSA45kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVSA["+45kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXVSA100kg">MEXVSA +100kg: </label>' +
        `<input type="text" id="MEXVSA100kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVSA["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXVSA300kg">MEXVSA +300kg: </label>' +
        `<input type="text" id="MEXVSA300kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVSA["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="MEXVSA+500kg">MEXVSA +500kg: </label>' +
        `<input type="text" id="MEXVSA+500kg" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVSA["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="minMEXVSA">MEXVSA MIN: </label>' +
        `<input type="text" id="minMEXVSA" class="swal2-input" value="${rateData.tarifas.tarifaEspecial.MEXVSA["MIN"]}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "my-custom-modal",
      },
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve({
            service: rateData.service,
            tarifas: {
              tablaA: {
                A: {
                  "1kg": parseFloat(document.getElementById("B-A1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-A45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-A100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-A300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-A+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minA").value),
                },
                B: {
                  "1kg": parseFloat(document.getElementById("B-B1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-B45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-B100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-B300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-B+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minB").value),
                },
                C: {
                  "1kg": parseFloat(document.getElementById("B-C1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-C45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-C100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-C300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-C+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minC").value),
                },
                D: {
                  "1kg": parseFloat(document.getElementById("B-D1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-D45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-D100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-D300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-D+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minD").value),
                },
                E: {
                  "1kg": parseFloat(document.getElementById("B-E1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-E45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-E100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-E300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-E+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minE").value),
                },
                F: {
                  "1kg": parseFloat(document.getElementById("B-F1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-F45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-F100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-F300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-F+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minF").value),
                },
                G: {
                  "1kg": parseFloat(document.getElementById("B-G1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-G45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-G100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-G300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-G+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minG").value),
                },
              },
              tablaB: {
                A: {
                  "1kg": parseFloat(document.getElementById("B-A1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-A45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-A100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-A300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-A+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minA").value),
                },
                B: {
                  "1kg": parseFloat(document.getElementById("B-B1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-B45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-B100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-B300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-B+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minB").value),
                },
                C: {
                  "1kg": parseFloat(document.getElementById("B-C1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-C45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-C100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-C300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-C+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minC").value),
                },
                D: {
                  "1kg": parseFloat(document.getElementById("B-D1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-D45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-D100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-D300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-D+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minD").value),
                },
                E: {
                  "1kg": parseFloat(document.getElementById("B-E1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-E45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-E100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-E300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-E+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minE").value),
                },
                F: {
                  "1kg": parseFloat(document.getElementById("B-F1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-F45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-F100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-F300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-F+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minF").value),
                },
                G: {
                  "1kg": parseFloat(document.getElementById("B-G1kg").value),
                  "+45kg": parseFloat(document.getElementById("B-G45kg").value),
                  "+100kg": parseFloat(
                    document.getElementById("B-G100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("B-G300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("B-G+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("B-minG").value),
                },
              },
              tarifaEspecial: {
                MEXCUL: {
                  "1kg": parseFloat(document.getElementById("MEXCUL1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXCUL45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXCUL100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXCUL300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXCUL+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXCUL").value),
                },
                MEXCME: {
                  "1kg": parseFloat(document.getElementById("MEXCME1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXCME45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXCME100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXCME300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXCME+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXCME").value),
                },
                MEXSLW: {
                  "1kg": parseFloat(document.getElementById("MEXSLW1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXSLW45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXSLW100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXSLW300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXSLW+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXSLW").value),
                },
                MEXCUN: {
                  "1kg": parseFloat(document.getElementById("MEXCUN1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXCUN45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXCUN100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXCUN300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXCUN+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXCUN").value),
                },
                MEXMTY: {
                  "1kg": parseFloat(document.getElementById("MEXMTY1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXMTY45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXMTY100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXMTY300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXMTY+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXMTY").value),
                },
                MEXBJX: {
                  "1kg": parseFloat(document.getElementById("MEXBJX1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXBJX45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXBJX100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXBJX300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXBJX+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXBJX").value),
                },
                MEXPBC: {
                  "1kg": parseFloat(document.getElementById("MEXPBC1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXPBC45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXPBC100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXPBC300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXPBC+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXPBC").value),
                },
                MEXSLP: {
                  "1kg": parseFloat(document.getElementById("MEXSLP1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXSLP45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXSLP100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXSLP300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXSLP+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXSLP").value),
                },
                MEXQRO: {
                  "1kg": parseFloat(document.getElementById("MEXQRO1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXQRO45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXQRO100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXQRO300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXQRO+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXQRO").value),
                },
                MEXGDL: {
                  "1kg": parseFloat(document.getElementById("MEXGDL1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXGDL45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXGDL100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXGDL300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXGDL+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXGDL").value),
                },
                MEXCJS: {
                  "1kg": parseFloat(document.getElementById("MEXCJS1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXCJS45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXCJS100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXCJS300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXCJS+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXCJS").value),
                },
                MEXPVR: {
                  "1kg": parseFloat(document.getElementById("MEXPVR1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXPVR45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXPVR100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXPVR300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXPVR+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXPVR").value),
                },
                MEXMTT: {
                  "1kg": parseFloat(document.getElementById("MEXMTT1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXMTT45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXMTT100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXMTT300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXMTT+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXMTT").value),
                },
                MEXSJD: {
                  "1kg": parseFloat(document.getElementById("MEXSJD1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXSJD45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXSJD100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXSJD300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXSJD+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXSJD").value),
                },
                MEXTAP: {
                  "1kg": parseFloat(document.getElementById("MEXTAP1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXTAP45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXTAP100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXTAP300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXTAP+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXTAP").value),
                },
                MEXVER: {
                  "1kg": parseFloat(document.getElementById("MEXVER1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXVER45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXVER100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXVER300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXVER+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXVER").value),
                },
                MEXTAM: {
                  "1kg": parseFloat(document.getElementById("MEXTAM1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXTAM45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXTAM100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXTAM300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXTAM+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXTAM").value),
                },
                MEXMXL: {
                  "1kg": parseFloat(document.getElementById("MEXMXL1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXMXL45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXMXL100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXMXL300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXMXL+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXMXL").value),
                },
                MEXOAX: {
                  "1kg": parseFloat(document.getElementById("MEXOAX1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXOAX45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXOAX100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXOAX300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXOAX+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXOAX").value),
                },
                MEXMZT: {
                  "1kg": parseFloat(document.getElementById("MEXMZT1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXMZT45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXMZT100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXMZT300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXMZT+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXMZT").value),
                },
                MEXTGZ: {
                  "1kg": parseFloat(document.getElementById("MEXTGZ1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXTGZ45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXTGZ100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXTGZ300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXTGZ+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXTGZ").value),
                },
                MEXREX: {
                  "1kg": parseFloat(document.getElementById("MEXREX1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXREX45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXREX100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXREX300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXREX+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXREX").value),
                },
                MEXTRC: {
                  "1kg": parseFloat(document.getElementById("MEXTRC1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXTRC45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXTRC100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXTRC300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXTRC+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXTRC").value),
                },
                MEXLAP: {
                  "1kg": parseFloat(document.getElementById("MEXLAP1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXLAP45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXLAP100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXLAP300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXLAP+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXLAP").value),
                },
                MEXCPE: {
                  "1kg": parseFloat(document.getElementById("MEXCPE1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXCPE45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXCPE100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXCPE300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXCPE+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXCPE").value),
                },
                MEXZIH: {
                  "1kg": parseFloat(document.getElementById("MEXZIH1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXZIH45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXZIH100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXZIH300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXZIH+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXZIH").value),
                },
                MEXTIJ: {
                  "1kg": parseFloat(document.getElementById("MEXTIJ1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXTIJ45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXTIJ100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXTIJ300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXTIJ+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXTIJ").value),
                },
                MEXHMO: {
                  "1kg": parseFloat(document.getElementById("MEXHMO1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXHMO45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXHMO100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXHMO300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXHMO+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXHMO").value),
                },
                MEXMID: {
                  "1kg": parseFloat(document.getElementById("MEXMID1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXMID45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXMID100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXMID300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXMID+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXMID").value),
                },
                MEXAGU: {
                  "1kg": parseFloat(document.getElementById("MEXAGU1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXAGU45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXAGU100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXAGU300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXAGU+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXAGU").value),
                },
                MEXCEN: {
                  "1kg": parseFloat(document.getElementById("MEXCEN1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXCEN45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXCEN100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXCEN300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXCEN+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXCEN").value),
                },
                MEXDGO: {
                  "1kg": parseFloat(document.getElementById("MEXDGO1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXDGO45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXDGO100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXDGO300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXDGO+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXDGO").value),
                },
                MEXLMM: {
                  "1kg": parseFloat(document.getElementById("MEXLMM1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXLMM45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXLMM100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXLMM300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXLMM+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXLMM").value),
                },
                MEXCUU: {
                  "1kg": parseFloat(document.getElementById("MEXCUU1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXCUU45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXCUU100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXCUU300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXCUU+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXCUU").value),
                },
                MEXVSA: {
                  "1kg": parseFloat(document.getElementById("MEXVSA1kg").value),
                  "+45kg": parseFloat(
                    document.getElementById("MEXVSA45kg").value
                  ),
                  "+100kg": parseFloat(
                    document.getElementById("MEXVSA100kg").value
                  ),
                  "+300kg": parseFloat(
                    document.getElementById("MEXVSA300kg").value
                  ),
                  "+500kg": parseFloat(
                    document.getElementById("MEXVSA+500kg").value
                  ),
                  MIN: parseFloat(document.getElementById("minMEXVSA").value),
                },
              },
            },
          });
        });
      },
      didRender: () => {
        // Agregar evento para cambiar de pestaña después de que se renderice el cuadro de diálogo
        document
          .getElementById("myTabs")
          .addEventListener("click", function (event) {
            const tabId = event.target.getAttribute("data-tab");
            if (tabId) {
              // Ocultar todas las pestañas y luego mostrar la pestaña seleccionada
              document.querySelectorAll(".tab-pane").forEach(function (tab) {
                tab.classList.remove("active");
              });
              document.getElementById(tabId).classList.add("active");
            }
          });
      },
    });

    if (result.isConfirmed) {
      const updatedUserData = result.value;

      const updateResponse = await axios.put(
        `${process.env.REACT_APP_URL_BACKEND}/api/rate/update/${id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      Swal.fire(
        "¡Actualizado!",
        "El usuario ha sido actualizado exitosamente.",
        "success"
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelado",
        "No se ha realizado ninguna actualización.",
        "error"
      );
    }
  } catch (error) {
    Swal.fire("Error", "Hubo un error", "error");
  }
};

export const updateRateFour = async (id) => {
  try {
    // Obtener los datos del usuario a editar del servidor
    const response = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/api/rate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      }
    );
    const rateData = response.data.rate;

    const result = await Swal.fire({
      title: `Editar tarifa ${rateData.service}`,
      html:
        '<div class="forma">' +
        '<ul class="nav nav-tabs" id="myTabs">' +
        '<li class="nav-item">' +
        '<a class="nav-link active" data-tab="tab1" href="#">Perros y Gatos</a>' +
        "</li>" +
        '<li class="nav-item">' +
        '<a class="nav-link" data-tab="tab2" href="#">Aves</a>' +
        "</li>" +
        '<li class="nav-item">' +
        '<a class="nav-link" data-tab="tab3" href="#">Otros</a>' +
        "</li>" +
        "</ul>" +
        '<div class="tab-content">' +
        '<div class="tab-pane active" id="tab1">' +
        "<label>Perros y Gatos</label>" +
        "</br>" +
        "<div class='fields' >" +
        '<div class="input-container">' +
        '<label for="origenEspkg">Origen Especial kg: </label>' +
        `<input type="text" id="origenEspkg" class="swal2-input" value="${rateData.tarifas.perros.origenEspecial.kg}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="origenEspMin">Origen Especial MIN: </label>' +
        `<input type="text" id="origenEspMin" class="swal2-input" value="${rateData.tarifas.perros.origenEspecial.min}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="otroOrginkg">Otros Origenes kg: </label>' +
        `<input type="text" id="otroOrginkg" class="swal2-input" value="${rateData.tarifas.perros.otrosOrigenes.kg}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="otroOrginMin">Otros Origenes MIN: </label>' +
        `<input type="text" id="otroOrginMin" class="swal2-input" value="${rateData.tarifas.perros.otrosOrigenes.min}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="tab-pane" id="tab2">' +
        "<label>Aves</label>" +
        "<br>" +
        "<div class='fields'>" +
        '<div class="input-container">' +
        '<label for="avesKg">Aves kg: </label>' +
        `<input type="text" id="avesKg" class="swal2-input" value="${rateData.tarifas.aves.kg}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="avesmin">Aves MIN: </label>' +
        `<input type="text" id="avesmin" class="swal2-input" value="${rateData.tarifas.aves.min}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="tab-pane" id="tab3">' +
        "<label>Otros animales</label>" +
        "<br>" +
        "<div class='fields'>" +
        '<div class="input-container">' +
        '<label for="otrosKg">Otros Kg: </label>' +
        `<input type="text" id="otrosKg" class="swal2-input" value="${rateData.tarifas.otros.kg}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="otrosmin">Otros MIN: </label>' +
        `<input type="text" id="otrosmin" class="swal2-input" value="${rateData.tarifas.otros.min}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "my-custom-modal",
      },
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve({
            service: rateData.service,
            tarifas: {
              perros: {
                origenEspecial: {
                  min: parseFloat(document.getElementById("origenEspkg").value),
                  kg: parseFloat(document.getElementById("origenEspMin").value),
                },
                otrosOrigenes: {
                  kg: parseFloat(document.getElementById("otroOrginkg").value),
                  min: parseFloat(
                    document.getElementById("otroOrginMin").value
                  ),
                },
              },
              aves: {
                kg: parseFloat(document.getElementById("avesKg").value),
                min: parseFloat(document.getElementById("avesmin").value),
              },
              otros: {
                kg: parseFloat(document.getElementById("otrosKg").value),
                min: parseFloat(document.getElementById("otrosmin").value),
              },
            },
          });
        });
      },
      didRender: () => {
        // Agregar evento para cambiar de pestaña después de que se renderice el cuadro de diálogo
        document
          .getElementById("myTabs")
          .addEventListener("click", function (event) {
            const tabId = event.target.getAttribute("data-tab");
            if (tabId) {
              // Ocultar todas las pestañas y luego mostrar la pestaña seleccionada
              document.querySelectorAll(".tab-pane").forEach(function (tab) {
                tab.classList.remove("active");
              });
              document.getElementById(tabId).classList.add("active");
            }
          });
      },
    });

    if (result.isConfirmed) {
      const updatedUserData = result.value;

      const updateResponse = await axios.put(
        `${process.env.REACT_APP_URL_BACKEND}/api/rate/update/${id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      Swal.fire(
        "¡Actualizado!",
        "El usuario ha sido actualizado exitosamente.",
        "success"
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelado",
        "No se ha realizado ninguna actualización.",
        "error"
      );
    }
  } catch (error) {
    Swal.fire("Error", "Hubo un error", "error");
  }
};

export const updateRateFive = async (id) => {
  try {
    // Obtener los datos del usuario a editar del servidor
    const response = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/api/rate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      }
    );
    const rateData = response.data.rate;

    const result = await Swal.fire({
      title: `Editar tarifa ${rateData.service}`,
      html:
        '<div class="forma">' +
        '<ul class="nav nav-tabs" id="myTabs">' +
        '<li class="nav-item">' +
        '<a class="nav-link active" data-tab="tab1" href="#">Tarifas</a>' +
        "</li>" +
        "</ul>" +
        '<div class="tab-content">' +
        '<div class="tab-pane active" id="tab1">' +
        "<label>Tarifa</label>" +
        "</br>" +
        "<div class='fields' >" +
        '<div class="input-container">' +
        '<label for="kg">kg: </label>' +
        `<input type="text" id="kg" class="swal2-input" value="${rateData.tarifas.kg}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="min">MIN: </label>' +
        `<input type="text" id="min" class="swal2-input" value="${rateData.tarifas.min}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "my-custom-modal",
      },
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve({
            service: rateData.service,
            tarifas: {
              min: parseFloat(document.getElementById("min").value),
              kg: parseFloat(document.getElementById("kg").value),
            },
          });
        });
      },
      didRender: () => {
        // Agregar evento para cambiar de pestaña después de que se renderice el cuadro de diálogo
        document
          .getElementById("myTabs")
          .addEventListener("click", function (event) {
            const tabId = event.target.getAttribute("data-tab");
            if (tabId) {
              // Ocultar todas las pestañas y luego mostrar la pestaña seleccionada
              document.querySelectorAll(".tab-pane").forEach(function (tab) {
                tab.classList.remove("active");
              });
              document.getElementById(tabId).classList.add("active");
            }
          });
      },
    });

    if (result.isConfirmed) {
      const updatedUserData = result.value;

      const updateResponse = await axios.put(
        `${process.env.REACT_APP_URL_BACKEND}/api/rate/update/${id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      Swal.fire(
        "¡Actualizado!",
        "El usuario ha sido actualizado exitosamente.",
        "success"
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelado",
        "No se ha realizado ninguna actualización.",
        "error"
      );
    }
  } catch (error) {
    Swal.fire("Error", "Hubo un error", "error");
  }
};

export const updateRateSIx = async (id) => {
  try {
    // Obtener los datos del usuario a editar del servidor
    const response = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/api/rate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      }
    );
    const rateData = response.data.rate;

    const result = await Swal.fire({
      title: `Editar tarifa ${rateData.service}`,
      html:
        '<div class="forma">' +
        '<ul class="nav nav-tabs" id="myTabs">' +
        '<li class="nav-item">' +
        '<a class="nav-link active" data-tab="tab1" href="#">Tarifas</a>' +
        "</li>" +
        "</ul>" +
        '<div class="tab-content">' +
        '<div class="tab-pane active" id="tab1">' +
        "<label>Tarifa</label>" +
        "</br>" +
        "<div class='fields' >" +
        '<div class="input-container">' +
        '<label for="kg">kg: </label>' +
        `<input type="text" id="kg" class="swal2-input" value="${rateData.tarifas.kg}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="cargoFijo">Cargo Fijo: </label>' +
        `<input type="text" id="cargoFijo" class="swal2-input" value="${rateData.tarifas.cargoFijo}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "my-custom-modal",
      },
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve({
            service: rateData.service,
            tarifas: {
              cargoFijo: parseFloat(document.getElementById("cargoFijo").value),
              kg: parseFloat(document.getElementById("kg").value),
            },
          });
        });
      },
      didRender: () => {
        // Agregar evento para cambiar de pestaña después de que se renderice el cuadro de diálogo
        document
          .getElementById("myTabs")
          .addEventListener("click", function (event) {
            const tabId = event.target.getAttribute("data-tab");
            if (tabId) {
              // Ocultar todas las pestañas y luego mostrar la pestaña seleccionada
              document.querySelectorAll(".tab-pane").forEach(function (tab) {
                tab.classList.remove("active");
              });
              document.getElementById(tabId).classList.add("active");
            }
          });
      },
    });

    if (result.isConfirmed) {
      const updatedUserData = result.value;

      const updateResponse = await axios.put(
        `${process.env.REACT_APP_URL_BACKEND}/api/rate/update/${id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      Swal.fire(
        "¡Actualizado!",
        "El usuario ha sido actualizado exitosamente.",
        "success"
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelado",
        "No se ha realizado ninguna actualización.",
        "error"
      );
    }
  } catch (error) {
    Swal.fire("Error", "Hubo un error", "error");
  }
};

export const updateRateSeven = async (id) => {
  try {
    // Obtener los datos del usuario a editar del servidor
    const response = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/api/rate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      }
    );
    const rateData = response.data.rate;

    const result = await Swal.fire({
      title: `Editar tarifa ${rateData.service}`,
      html:
        '<div class="forma">' +
        '<ul class="nav nav-tabs" id="myTabs">' +
        '<li class="nav-item">' +
        '<a class="nav-link active" data-tab="tab1" href="#">destino Especial </a>' +
        "</li>" +
        '<li class="nav-item">' +
        '<a class="nav-link" data-tab="tab2" href="#">otros Destino</a>' +
        "</li>" +
        "</ul>" +
        '<div class="tab-content">' +
        '<div class="tab-pane active" id="tab1">' +
        "<label>destino Especial</label>" +
        "</br>" +
        "<div class='fields' >" +
        '<div class="input-container">' +
        '<label for="0kg">0 kg: </label>' +
        `<input type="text" id="0kg" class="swal2-input" value="${rateData.tarifas.destinoEspecial["0kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+21kg">+21kg: </label>' +
        `<input type="text" id="+21kg" class="swal2-input" value="${rateData.tarifas.destinoEspecial["+21kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+50kg">+50kg: </label>' +
        `<input type="text" id="+50kg" class="swal2-input" value="${rateData.tarifas.destinoEspecial["+50kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+300kg">+300kg: </label>' +
        `<input type="text" id="+300kg" class="swal2-input" value="${rateData.tarifas.destinoEspecial["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+500kg">+500kg: </label>' +
        `<input type="text" id="+500kg" class="swal2-input" value="${rateData.tarifas.destinoEspecial["+500kg"]}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="tab-pane" id="tab2">' +
        "<label>Otros Destino</label>" +
        "<br>" +
        "<div class='fields'>" +
        '<div class="input-container">' +
        '<label for="0kg">0kg: </label>' +
        `<input type="text" id="0kg" class="swal2-input" value="${rateData.tarifas.otrosDestino["0kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+10kg">+10kg: </label>' +
        `<input type="text" id="+10kg" class="swal2-input" value="${rateData.tarifas.otrosDestino["+10kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+25kg">+25kg: </label>' +
        `<input type="text" id="+25kg" class="swal2-input" value="${rateData.tarifas.otrosDestino["+25kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+50kg">+50kg: </label>' +
        `<input type="text" id="+50kg" class="swal2-input" value="${rateData.tarifas.otrosDestino["+50kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+100kg">+100kg: </label>' +
        `<input type="text" id="+100kg" class="swal2-input" value="${rateData.tarifas.otrosDestino["+100kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+300kg">+300kg: </label>' +
        `<input type="text" id="+300kg" class="swal2-input" value="${rateData.tarifas.otrosDestino["+300kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+500kg">+500kg: </label>' +
        `<input type="text" id="+500kg" class="swal2-input" value="${rateData.tarifas.otrosDestino["+500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+1000kg">+1000kg: </label>' +
        `<input type="text" id="+1000kg" class="swal2-input" value="${rateData.tarifas.otrosDestino["+1000kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="+1500kg">+1500kg: </label>' +
        `<input type="text" id="+1500kg" class="swal2-input" value="${rateData.tarifas.otrosDestino["+1500kg"]}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="2000kg">2000kg: </label>' +
        `<input type="text" id="2000kg" class="swal2-input" value="${rateData.tarifas.otrosDestino["2000kg"]}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "my-custom-modal",
      },
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve({
            service: rateData.service,
            tarifas: {
              destinoEspecial: {
                "0kg": parseFloat(document.getElementById("0kg").value),
                "+21kg": parseFloat(document.getElementById("+21kg").value),
                "+50kg": parseFloat(document.getElementById("+21kg").value),
                "+300kg": parseFloat(document.getElementById("+300kg").value),
                "+500kg": parseFloat(document.getElementById("+500kg").value),
              },
              otrosDestino: {
                "0kg": parseFloat(document.getElementById("0kg").value),
                "+10kg": parseFloat(document.getElementById("+10kg").value),
                "+25kg": parseFloat(document.getElementById("+25kg").value),
                "+50kg": parseFloat(document.getElementById("+50kg").value),
                "+100kg": parseFloat(document.getElementById("+100kg").value),
                "+300kg": parseFloat(document.getElementById("+300kg").value),
                "+500kg": parseFloat(document.getElementById("+500kg").value),
                "+1000kg": parseFloat(document.getElementById("+1000kg").value),
                "+1500kg": parseFloat(document.getElementById("+1500kg").value),
                "2000kg": parseFloat(document.getElementById("2000kg").value),
              },
            },
          });
        });
      },
      didRender: () => {
        // Agregar evento para cambiar de pestaña después de que se renderice el cuadro de diálogo
        document
          .getElementById("myTabs")
          .addEventListener("click", function (event) {
            const tabId = event.target.getAttribute("data-tab");
            if (tabId) {
              // Ocultar todas las pestañas y luego mostrar la pestaña seleccionada
              document.querySelectorAll(".tab-pane").forEach(function (tab) {
                tab.classList.remove("active");
              });
              document.getElementById(tabId).classList.add("active");
            }
          });
      },
    });

    if (result.isConfirmed) {
      const updatedUserData = result.value;

      const updateResponse = await axios.put(
        `${process.env.REACT_APP_URL_BACKEND}/api/rate/update/${id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      Swal.fire(
        "¡Actualizado!",
        "El usuario ha sido actualizado exitosamente.",
        "success"
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelado",
        "No se ha realizado ninguna actualización.",
        "error"
      );
    }
  } catch (error) {
    Swal.fire("Error", "Hubo un error", "error");
  }
};

export const updateRateEight = async (id) => {
  try {
    // Obtener los datos del usuario a editar del servidor
    const response = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/api/rate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      }
    );
    const rateData = response.data.rate;

    const result = await Swal.fire({
      title: `Editar tarifa ${rateData.service}`,
      html:
        '<div class="forma">' +
        '<ul class="nav nav-tabs" id="myTabs">' +
        '<li class="nav-item">' +
        '<a class="nav-link active" data-tab="tab1" href="#">origenes</a>' +
        "</li>" +
        '<li class="nav-item">' +
        '<a class="nav-link" data-tab="tab2" href="#">destinos</a>' +
        "</li>" +
        '<li class="nav-item">' +
        '<a class="nav-link" data-tab="tab3" href="#">Ruta PBC-SLW</a>' +
        "</li>" +
        '<li class="nav-item">' +
        '<a class="nav-link" data-tab="tab4" href="#">Ruta SLW-PBC</a>' +
        "</li>" +
        "</ul>" +
        '<div class="tab-content">' +
        '<div class="tab-pane active" id="tab1">' +
        "<label>origenes</label>" +
        "</br>" +
        "<div class='fields' >" +
        '<div class="input-container">' +
        '<label for="origenesKg">kg: </label>' +
        `<input type="text" id="origenesKg" class="swal2-input" value="${rateData.tarifas.origenes.kg}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="origenesMin">min: </label>' +
        `<input type="text" id="origenesMin" class="swal2-input" value="${rateData.tarifas.origenes.min}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="tab-pane" id="tab2">' +
        "<label>Destinos</label>" +
        "<br>" +
        "<div class='fields'>" +
        '<div class="input-container">' +
        '<label for="destinosKg">kg: </label>' +
        `<input type="text" id="destinosKg" class="swal2-input" value="${rateData.tarifas.destinos.kg}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="destinosMin">Min: </label>' +
        `<input type="text" id="destinosMin" class="swal2-input" value="${rateData.tarifas.destinos.min}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="tab-pane" id="tab3">' +
        "<label>PBC-SLW</label>" +
        "<br>" +
        "<div class='fields'>" +
        '<div class="input-container">' +
        '<label for="PBCSLWKg">kg: </label>' +
        `<input type="text" id="PBCSLWKg" class="swal2-input" value="${rateData.tarifas.PBCSLW.kg}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="PBCSLWMin">Min: </label>' +
        `<input type="text" id="PBCSLWMin" class="swal2-input" value="${rateData.tarifas.PBCSLW.min}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="tab-pane" id="tab4">' +
        "<label>SLW-PBC</label>" +
        "<br>" +
        "<div class='fields'>" +
        '<div class="input-container">' +
        '<label for="SLWPBCKg">kg: </label>' +
        `<input type="text" id="SLWPBCKg" class="swal2-input" value="${rateData.tarifas.SLWPBC.kg}">` +
        "</div>" +
        '<div class="input-container">' +
        '<label for="SLWPBCMin">Min: </label>' +
        `<input type="text" id="SLWPBCMin" class="swal2-input" value="${rateData.tarifas.SLWPBC.min}">` +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "my-custom-modal",
      },
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve({
            service: rateData.service,
            tarifas: {
              origenes: {
                kg: parseFloat(document.getElementById("origenesKg").value),
                min: parseFloat(document.getElementById("origenesMin").value),
              },
              destinos: {
                kg: parseFloat(document.getElementById("destinosKg").value),
                min: parseFloat(document.getElementById("destinosMin").value),
              },
              PBCSLW: {
                kg: parseFloat(document.getElementById("PBCSLWKg").value),
                min: parseFloat(document.getElementById("PBCSLWMin").value),
              },
              SLWPBC: {
                kg: parseFloat(document.getElementById("SLWPBCKg").value),
                min: parseFloat(document.getElementById("SLWPBCMin").value),
              },
            },
          });
        });
      },
      didRender: () => {
        // Agregar evento para cambiar de pestaña después de que se renderice el cuadro de diálogo
        document
          .getElementById("myTabs")
          .addEventListener("click", function (event) {
            const tabId = event.target.getAttribute("data-tab");
            if (tabId) {
              // Ocultar todas las pestañas y luego mostrar la pestaña seleccionada
              document.querySelectorAll(".tab-pane").forEach(function (tab) {
                tab.classList.remove("active");
              });
              document.getElementById(tabId).classList.add("active");
            }
          });
      },
    });

    if (result.isConfirmed) {
      const updatedUserData = result.value;

      const updateResponse = await axios.put(
        `${process.env.REACT_APP_URL_BACKEND}/api/rate/update/${id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      Swal.fire(
        "¡Actualizado!",
        "El usuario ha sido actualizado exitosamente.",
        "success"
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelado",
        "No se ha realizado ninguna actualización.",
        "error"
      );
    }
  } catch (error) {
    Swal.fire("Error", "Hubo un error", "error");
  }
};
