import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import compliance from "../../assets/imgs/compliance_imgs/compliance.svg";
import { FaArrowRight } from "react-icons/fa";
import hand from "../../assets/imgs/compliance_imgs/hand.svg";
import plane from "../../assets/imgs/compliance_imgs/plane.png";
import download from "../../assets/imgs/compliance_imgs/download.svg";
import phone from "../../assets/imgs/compliance_imgs/phone.svg";
import check from "../../assets/imgs/compliance_imgs/check.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ButtonWithModal from "./button-modal";
import anticorruptionEsp from "../../assets/pdfs/A_PO_GALE_09.pdf";
import anticorruptionEng from "../../assets/pdfs/A_PO_GALE_09.pdf";
import pdfcomplianceEsp from "../../assets/pdfs/005_ABRIL_Codigo_de_conducta_24_02.pdf";
import pdfcomplianceEng from "../../assets/pdfs/007_ABRIL_Codigo_de_conducta_ing_24_02.pdf";
import { saveAs } from "file-saver";
import navBarEn from "../../translations/en/navbar.json";
import navBarEs from "../../translations/es/navbar.json";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import "./compliance.css";
import "animate.css";
import ScrollToDynamic from "../../components/scrollToDynamic/scroolToDynamic";
import Commitment from "./commitment";
import Ambassadors from "./ambassadors";

function Compliance() {
  const [tCompliance] = useTranslation("compliance");
  const [tSMetaData] = useTranslation("metaData");
  const [scrollY, setScrollY] = useState(0);
  const [animation1, setAnimation1] = useState(false);
  const [animation2, setAnimation2] = useState(false);
  // const [animation3, setAnimation3] = useState(false);
  const [animation4, setAnimation4] = useState(false);
  const [animation5, setAnimation5] = useState(false);
  const [animation6, setAnimation6] = useState(false);
  const [confidentialComplaintBtn, setConfidentialComplaintBtn] = useState('https://etica.resguarda.com/aeromexico/es/main.html')

  useEffect(() => {
    if(localStorage.getItem("lng")) {
      if(localStorage.getItem("lng") === 'us') {
        setConfidentialComplaintBtn('https://etica.resguarda.com/aeromexico/en/main.html');
      } else {
        setConfidentialComplaintBtn('https://etica.resguarda.com/aeromexico/es/main.html');
      }
    }
  })

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].compliance}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].compliance}`
  );

  const handlescroll = () => {
    setScrollY(window.scrollY || window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handlescroll);

    return () => {
      window.removeEventListener("scroll", handlescroll);
    };
  }, []);

  useEffect(() => {
    setAnimation1(scrollY >= 220);
  }, [scrollY]);

  useEffect(() => {
    setAnimation2(scrollY >= 640);
  }, [scrollY]);

  // useEffect(() => {
  //   setAnimation3(scrollY >= 1170);
  // }, [scrollY]);

  useEffect(() => {
    setAnimation4(scrollY >= 1300);
  }, [scrollY]);

  useEffect(() => {
    setAnimation5(scrollY >= 2000);
  }, [scrollY]);

  useEffect(() => {
    setAnimation6(scrollY >= 2500);
  }, [scrollY]);

  const showPdf = () => {
    if (localStorage.getItem("lng")) {
      localStorage.getItem("lng") === "es"
        ? window.open(pdfcomplianceEsp, "_blank")
        : window.open(pdfcomplianceEng, "_blank");
    } else {
      window.open(pdfcomplianceEsp, "_blank");
    }
    // saveAs(pdfcompliance, "Compliance");
  };

  const showInfoBooklet = () => {
    if (localStorage.getItem("lng")) {
      localStorage.getItem("lng") === "es"
        ? window.open(anticorruptionEsp, "_blank")
        : window.open(anticorruptionEng, "_blank");
    } else {
      window.open(anticorruptionEsp, "_blank");
    }
  };

  const redirectConduct = () => {
    window.scrollTo({ top: 1000, behavior: "smooth" });
  };

  const redirectEthics = () => {
    window.scrollTo({ top: 2400, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>{tSMetaData("compliance.title")}</title>
        <meta
          name="description"
          content={tSMetaData("compliance.description")}
        />
      </Helmet>
      <ScrollToDynamic />
      <Navbar />

      <main className="compliance">
        <div className="compliance_navigation">
          <div className="row">
            <div className="col-lg-6">
              <div className="compliance_nav_logo">
                <a href={tCompliance("body.linkCompliance")} target="_blank" id="complianceLink">
                  <img src={compliance} alt=""></img>
                </a>
              </div>
            </div>

            <div className="col-lg-6 compliance_menu">
              <nav>
                <ul>
                  <li>
                    <Link onClick={redirectConduct} id="codigoConductaLink">
                      {tCompliance("nav.conducta")}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={redirectEthics} id="lineaEticaLink">
                      {tCompliance("nav.etica")}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <section className="compliance_cover">
          <div className="compl_cover_text mt-4">
            <div className="row">
              <div className="col-lg-6">
                <h1>{tCompliance("body.compliance")}</h1>
                <p>
                  {tCompliance("body.colaborar")}
                  <strong> {tCompliance("body.empresa")} </strong>
                  {tCompliance("body.cargo")}
                  <strong> {tCompliance("body.grupo")}</strong>.
                </p>

                <ButtonWithModal />
              </div>
            </div>
          </div>

          <div className="compli__cover__filter"></div>
          <div className="compli_cover_image bg_img"></div>
        </section>

        <section className="compliance_conduct">
          <article className="comp_conduct_intro">
            <div className="comp_cond_conta">
              <img src={hand} alt=""></img>
              <h2
                className={
                  animation1 ? "animate__animated animate__fadeInUp" : ""
                }
              >
                {tCompliance("body.am")}
              </h2>
              <p
                className={
                  animation1 ? "animate__animated animate__fadeInUp" : ""
                }
              >
                {tCompliance("body.cv")}
              </p>
              <button
                href="#"
                className={
                  animation1
                    ? "button button-primary btn-anticirruption animate__animated animate__fadeInUp"
                    : ""
                }
                onClick={showInfoBooklet}
                id="btnPoliticaAnticorrupcion"
              >
                {tCompliance("body.politica")}
              </button>
            </div>
          </article>

          <article className="comp_conduct_info">
            <div className="comp__conduct_info__image">
              <img src={plane} alt="" className="img-fluid"></img>
            </div>

            <div
              className="comp_conduct_info_conta"
              id="comp_conduct_info_conta"
            >
              <h4
                className={
                  animation2 ? "animate__animated animate__fadeInUp" : ""
                }
              >
                {tCompliance("body.conducta")}
              </h4>
              <p
                className={
                  animation2 ? "animate__animated animate__fadeInUp" : ""
                }
              >
                {tCompliance("body.version")}
              </p>

              <a
                href="#####"
                onClick={(e) => {
                  e.preventDefault();
                  showPdf();
                }}
                id="btnConductaDescarga"
                className={
                  animation2
                    ? "button button-primary animate__animated animate__fadeInUp"
                    : "button button-primary"
                }
                role="button"
              >
                <img src={download} alt=""></img>
                {tCompliance("body.descargar")}
              </a>
            </div>
          </article>
        </section>

        <Commitment />
        <Ambassadors />

        <section className="compliance_ethics" id="compliance_ethics">
          <div className="comp_ethics_background"></div>
          <div className="compl_ethics_container">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div
                      className={
                        animation4
                          ? "compl_ethics_image animate__animated animate__fadeInUp"
                          : "compl_ethics_image"
                      }
                    >
                      <img src={phone} alt="" className="img-fluid"></img>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-12 d-flex flex-column">
                    <article className="compliance_ethics_information">
                      <h4
                        className={
                          animation5
                            ? "animate__animated animate__fadeInUp"
                            : ""
                        }
                      >
                        {tCompliance("body.linea")}
                      </h4>
                      <p
                        className={
                          animation5
                            ? "animate__animated animate__fadeInUp"
                            : ""
                        }
                      >
                        {tCompliance("body.reporta")}
                      </p>
                      <ul
                        className={
                          animation5
                            ? "compl_ethics_info_list animate__animated animate__fadeInUp"
                            : "compl_ethics_info_list"
                        }
                      >
                        <li>
                          <img src={check} alt=""></img>
                          <span>{tCompliance("body.actos")}</span>
                        </li>
                        <li>
                          <img src={check} alt=""></img>
                          <span>{tCompliance("body.sobornos")}</span>
                        </li>
                        <li>
                          <img src={check} alt=""></img>
                          <span>{tCompliance("body.discrminacion")}</span>
                        </li>
                        <li>
                          <img src={check} alt=""></img>
                          <span>{tCompliance("body.acosos")}</span>
                        </li>
                        <li>
                          <img src={check} alt=""></img>
                          <span>{tCompliance("body.negligencias")}</span>
                        </li>
                      </ul>

                      <div className="compliance_ethics_footer">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="compliance_ethics_footer_button">
                              <a
                                className={
                                  animation5
                                    ? "button button-primary animate__animated animate__fadeInUp"
                                    : "button button-primary"
                                }
                                id="btnDenunciaConfidencial"
                                role="button"
                                target="_blank"
                                href={confidentialComplaintBtn}
                                rel="noreferrer"
                              >
                                {tCompliance("body.denuncia")}
                              </a>
                            </div>
                          </div>

                          <div className="col-lg-8 box_ethics_phone">
                            <ul className="complience_ethics_footer_phone">
                              <li>
                                <label
                                  className={
                                    animation5
                                      ? "animate__animated animate__fadeInUp"
                                      : ""
                                  }
                                >
                                  {tCompliance("body.mex")}
                                </label>
                                <a href="##" role="button" target="_blank">
                                  800-112-0585
                                </a>
                              </li>

                              <li>
                                <label
                                  className={
                                    animation5
                                      ? "animate__animated animate__fadeInUp"
                                      : ""
                                  }
                                >
                                  {tCompliance("body.usa")}
                                </label>
                                <a
                                  href="##"
                                  role="button"
                                  target="_blank"
                                  className="fade-in-up"
                                >
                                  (00)1 800 921 2240
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="compliance_ethics_footer_text">
          <div className="compl_ethics_footer_conta">
            <div className="compl_ethics_footer_logo">
              <img src={compliance} alt=""></img>
              <p
                className={
                  animation6 ? "animate__animated animate__fadeInUp" : ""
                }
              >
                {tCompliance("body.link")}
              </p>
              <a
                className={
                  animation6
                    ? "button button-primary animate__animated animate__fadeInUp btn_compliance_mexico"
                    : "button button-primary btn_compliance_mexico"
                }
                id="btnAMCompliance"
                href={tCompliance("body.linkCompliance")}
                role="button"
                target="_blanck"
              >
                {tCompliance("body.compl")}{" "}
                <li>
                  <FaArrowRight />
                </li>
              </a>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default Compliance;
