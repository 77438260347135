import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEdit, FaTimes } from "react-icons/fa";
import { updateRateOne, updateRateTwo, updateRateThree, updateRateFour, updateRateFive, updateRateSIx, updateRateSeven, updateRateEight} from "./funtions"
import "./style.css";

function Tarifario() {
  const [tarifas, setTarifas] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [loading, setLoading] = useState(true);

  const getTarifas = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/rate`, {
        headers: {
          authorization: `${process.env.REACT_APP_PUBLIC_KEY}`, // Agrega la cadena de texto para la authorization en endponids publicos"
        },
      });
      if (res.data.code === 1) {
        setTarifas(res.data.rates);
        setLoading(false);
      }
    } catch (error) {
      setLoading(true)
      Swal.fire("Error", "Hubo un error", "error");
    }
  };

  const updateRate = async (id) => {
    try {
      // Obtener los datos del usuario a editar del servidor
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/rate/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      });
      const rateData = response.data.rate;
      
      switch (rateData.service) {
        case '12 + 12':
          updateRateOne(id);
          setUpdateTrigger(!updateTrigger);
          break;
      
        case 'Tarifas Pharma STD (FAD)':
          updateRateThree(id);
          setUpdateTrigger(!updateTrigger);
          break;
          
        case 'Animales Vivos':
          updateRateFour(id);
          setUpdateTrigger(!updateTrigger);
          break;
          
        case 'Cargo por Combustible (MY)':
        case 'Gold':
        case 'Pharma XPS':
          updateRateFive(id);
          setUpdateTrigger(!updateTrigger);
          break;
        
        case 'Cargo por acarreo (PU)':
          updateRateSIx(id);
          setUpdateTrigger(!updateTrigger);
          break;
        
        case 'Otros Cargos (DDLV)':
          updateRateSeven(id);
          setUpdateTrigger(!updateTrigger);
          break;
        
        case 'Operación Terrestre (MC)':
          updateRateEight(id);
          setUpdateTrigger(!updateTrigger);
          break;
      
        default:
          updateRateTwo(id);
          setUpdateTrigger(!updateTrigger);
          break;
      }

    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
    }
  };
  
  const deleteRate = async (id) => {
    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_URL_BACKEND}/api/rate/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
            },
          }
        );

        Swal.fire("¡Eliminado!", "La tarifa ha sido eliminado.", "success");
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelado",
          "No se elimino Ninguna tarifa)",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
    }
  }

  useEffect(() => {
    getTarifas();
  }, [updateTrigger]);

  return (
    <>
      <div className="table-responsive">
        <table className="table table-preve" id="tableToSearch">
          <thead>
            <tr>
              <th>Tarifa</th>
              <td className="accions">Acciones</td>
            </tr>
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <td>Cargando...</td>
              </tr>
            ) : (
              tarifas.map((tarifa) => (
                <tr key={tarifa.id} data-master-id={tarifa.id}>
                  <td>{tarifa.service}</td>
                  <td>
                    <FaEdit
                      className="editButton"
                      onClick={() => updateRate(tarifa.id)}
                    />
                    <FaTimes className="deleteButton" onClick={() => deleteRate(tarifa.id)}/>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Tarifario;
