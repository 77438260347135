import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function LabelGenMain() {
  const navigate = useNavigate();
  const windowOpened = useRef(false);
  
  useEffect(() => {
    if (!windowOpened.current) {
      window.open("https://prdskyam.mercator.com/skychain/app?service=page/nwp:BarcodePrint");

      if(!sessionStorage.getItem("routeEn") || !sessionStorage.getItem("routeEs") || !localStorage.getItem("lng")) {
        navigate("/");
      } else {
        if(localStorage.getItem("lng") === "us") {
          navigate(`${sessionStorage.getItem("routeEn")}`)
        } else {
          navigate(`${sessionStorage.getItem("routeEs")}`)
        }
      }

      windowOpened.current = true;
    }
  }, []);

  return null;
}

export default LabelGenMain;