import React from 'react'
import "./compliance.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import pdfInfo from "../../assets/pdfs/Comunicado_Embajadores.pdf"
import embajadores from "../../assets/imgs/compliance_imgs/embajadores_compliance.png"
import Swal from 'sweetalert2';

const Ambassadors =  () => {
    const [tCompliance] = useTranslation("compliance");
    const [scrollY, setScrollY] = useState(0);
  
    const [animation6, setAnimation3] = useState(false);

    const showComunicate = () => {
      window.open(pdfInfo, "_blank")
    };
  
    const handlescroll = () => {
      setScrollY(window.scrollY || window.pageYOffset);
    };
  
    useEffect(() => {
      window.addEventListener("scroll", handlescroll);
  
      return () => {
        window.removeEventListener("scroll", handlescroll);
      };
    }, []);
  
    useEffect(() => {
      setAnimation3(scrollY >= 1650);
    }, [scrollY]);

    const openVideoPopup = () => {
      Swal.fire({
        html: `
        <iframe width="460" height="340" src="https://www.youtube.com/embed/P1CtbyHZRRg?autoplay=1" frameborder="0" allowfullscreen></iframe>
        `,
        showCloseButton: true,
        showConfirmButton: false,
        focusConfirm: false,
        customClass: {
          popup: 'sweetalert-video',
        },
      });
    };
  
    return (
      <div className="container-fluid bg-compliance-white py-2">
        <div className="compl_ethics_footer_conta">
          <div className="compliance-acl mb-0">
            <div class="row my-3">
              <div class="col-12 col-md-4">
                <img src={embajadores} alt="Andrés Conesa Labastida" className="img-andres"></img>
              </div>
              <div class="col-12 col-md-8 text-start py-0 py-lg-2 d-flex align-items-center">
                <div>
                  <h3 
                  className={
                      animation6 ? "animate__animated animate__fadeInUp text-am-blue mt-3" : " text-am-blue"
                  }
                  >{tCompliance("body.embajadores")}</h3>
                  <p>{tCompliance("body.texto-embajadres")}</p>
                  <div className='d-flex py-3'>
                    <button className={
                      animation6
                        ? "btn-comunicate me-2 animate__animated animate__fadeInUp"
                        : "btn-comunicate me-2"
                      }
                      id='btnComunicadoCompliance'
                      rel="noreferrer"
                      target="_blank"
                      href="#"
                      onClick={showComunicate}
                    >
                      {tCompliance("body.btn-comunicate")}
                    </button>
                    <button className={
                      animation6
                        ? "btn-video animate__animated animate__fadeInUp"
                        : "btn-video"
                      }
                      id='btnVideoCompliance'
                      rel="noreferrer"
                      target="_blank"
                      onClick={openVideoPopup}
                    >
                      {tCompliance("body.btn-video")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default Ambassadors