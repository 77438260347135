import { React, useEffect, useState} from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import { FaEdit, FaTimes } from "react-icons/fa";
import './styles.css'


function Permisos() {
  const [banners, setBanners] = useState([])
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    
    const getBanners = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/permissions`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        });
        if (res.data.code === 1){
          setBanners(res.data.permissions)
          setLoading(false);
        }
        
      } catch (error) {
        console.error(error);
      }
    };
    let optionsUsers = [];
    const getUsers = async () => {
      try {
        
        
      } catch (error) {
        console.error(error);
      }
    };

    const createPermission = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        });
        if (res.data.code === 1) {
              optionsUsers = res.data.users.map(user => (
                `<option key=${user.email} value=${user.email}>${user.email}</option>`
              )).join('');
            }

        const result = await Swal.fire({
          title: "Agregar Permisos",
          html:
            '<div class="form-grid text-start">' +
            "<select id='c_user'>"+
              "<option value=''>Elija usuario</option>"+
              optionsUsers+
            "</select><br><br>"+
            "<label>Elija permisos: </label><br><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='1'> Usuario</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='2'> Permisos</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='3'> Banners</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='4'> Textos</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='5'> Contactos Web</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='6'> Tarifario Nacional</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='7'> Cotizaciónes</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='8'> Códigos postales</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='9'> Estaciones</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='10'> Servicios</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='11'> Pdf logger</label><br>"+
            "<label><input name='c_permissions[]' type='checkbox' value='12'> Estaciones Internacionales</label><br>"+
            "</div>",
          showCancelButton: true,
          confirmButtonText: "Agregar",
          cancelButtonText: "Cancelar",
          preConfirm: function () {
            const email = document.getElementById('c_user').value;
            if (email === "") {
              Swal.showValidationMessage("Debes seleccionar un correo");
              return false;
            }

            const checkboxes = document.querySelectorAll('input[name="c_permissions[]"]:checked');
            const checkedValues = Array.from(checkboxes).map(checkbox => checkbox.value);
            
            return new Promise(function (resolve) {
              resolve({
                permissions: checkedValues,
                user: email,
              });
            });
          },
        });
    
        if (result.isConfirmed) {
          const userData = result.value;
    
          const response = await axios.post(
            `${process.env.REACT_APP_URL_BACKEND}/api/permissions/create`,
            userData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            }
          );
    
          Swal.fire(
            "¡Creado!",
            "El banner ha sido creado exitosamente.",
            "success"
          );
          setUpdateTrigger(!updateTrigger);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "Accion cancelada.", "error");
        }
      } catch (error) {
        if (error.response.data.code === 0) {
          Swal.fire("Error al crear el banner", "Revisar información.", "error");
        } else {
          Swal.fire(
            "Error al crear el banner",
            "No se ha creado ningún banner."
          );
        }
        console.error("Error al crear el banner:", error.response);
        console.error("Status:", error.response.status);
      }
    };
    
    const handleSearch = () => {
      const input = document.getElementById("searchInput");
      setSearchValue(input.value.trim());
    };
    
    const deletePermission = async ( id ) => {
        try {
          const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "¡No podrás revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí, eliminarlo",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
            buttonsStyling: true,
          });

          if (result.isConfirmed) {
            await axios.delete(
              `${process.env.REACT_APP_URL_BACKEND}/api/permissions/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
                },
              }
            );

            Swal.fire("¡Eliminado!", "El/los permisos ha(n) sido eliminado.", "success");
            setUpdateTrigger(!updateTrigger);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              "Cancelado",
              "error"
            );
          }
        } catch (error) {
          console.error("Error al Eliminar el usuario:", error);
        }
      }
      
    const updatePermission = async (id) => {
      try {
        // Obtener los datos del usuario a editar del servidor
        const response = await axios.get(
          `${process.env.REACT_APP_URL_BACKEND}/api/permissions/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
            },
          }
        );
        let permissionData = response.data.permission.permissions;

        const result = await Swal.fire({
          title: "Editar Banner",
          html:
            '<div class="form-grid text-start">' +
              "<label>Elija permisos: </label><br><br>"+
              `<label><input name='c_permissions[]' type='checkbox' value='1' ${permissionData.includes('1') ? 'checked' : ''}> Usuario</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='2' ${permissionData.includes('2') ? 'checked' : ''}> Permisos</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='3' ${permissionData.includes('3') ? 'checked' : ''}> Banners</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='4' ${permissionData.includes('4') ? 'checked' : ''}> Textos</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='5' ${permissionData.includes('5') ? 'checked' : ''}> Contactos Web</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='6' ${permissionData.includes('6') ? 'checked' : ''}> Tarifario Nacional</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='7' ${permissionData.includes('7') ? 'checked' : ''}> Cotizaciónes</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='8' ${permissionData.includes('8') ? 'checked' : ''}> Códigos postales</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='9' ${permissionData.includes('9') ? 'checked' : ''}> Estaciones</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='10' ${permissionData.includes('10') ? 'checked' : ''}> Servicios</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='11' ${permissionData.includes('11') ? 'checked' : ''}> Pdf logger</label><br>`+
              `<label><input name='c_permissions[]' type='checkbox' value='12' ${permissionData.includes('12') ? 'checked' : ''}> Estaciones Internacionales</label><br>`+
            "</div>",
          showCancelButton: true,
          confirmButtonText: "Actualizar",
          cancelButtonText: "Cancelar",
          preConfirm: function () {
            const checkboxes = document.querySelectorAll('input[name="c_permissions[]"]:checked');
            const checkedValues = Array.from(checkboxes).map(checkbox => checkbox.value);
            return new Promise(function (resolve) {
              resolve({
                permissions: checkedValues,
                user: id,
              });
            });
          },
        });

        if (result.isConfirmed) {
          const updatedUserData = result.value;
          // Realizar la solicitud PUT al servidor para actualizar el usuario
          const updateResponse = await axios.put(
            `${process.env.REACT_APP_URL_BACKEND}/api/permissions/update`,
            updatedUserData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
                'Content-Type': 'application/json',
              },
            }
          );
          
          Swal.fire(
            "¡Actualizado!",
            "Los permisos han sido actualizados exitosamente.",
            "success"
          );
          setUpdateTrigger(!updateTrigger);

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            "Cancelado",
            "No se ha realizado ninguna actualización.",
            "error"
          );
        }
      } catch (error) {
        console.error("Error al actualizar usuario:", error);
      }
    };

    const filteredUsers = searchValue
      ? banners.filter(
          (banner) =>
            banner.image.toUpperCase().includes(searchValue.toUpperCase())
        )
      : banners

    useEffect(() => {
      getBanners();
      getUsers();
    }, [updateTrigger]);

    return (
      <>
      <div className='table-responsive'>
      <div className="panel-table">
      <div className='colum-left'>
        <input type="text" className='searchInput' id="searchInput" placeholder="Buscar..." />
        <button className='btnn' onClick={handleSearch}>Buscar</button>
      </div>
      <div className='colum-rigth'>
        <button className="btnn" onClick={createPermission}>
          Agregar Permisos
        </button>
      </div>
      </div>
        <table className='table table-preve' id='tableToSearch'>
          <thead>
            <tr>
              <th>Usuario</th>
              <td className='accions'>Acciones</td>
            </tr>
          </thead>
          <tbody>
            {loading ? (<tr><td>Cargando</td></tr>) : 
            ( filteredUsers.map((banner) => (
              <tr key={banner.user} data-master-id={banner.user}>
                <td>{banner.user}</td>
                <td>
                  <FaEdit className='editButton' onClick={() =>{ updatePermission(banner.user)}}/>
                  <FaTimes className='deleteButton' onClick={() =>{ deletePermission(banner.user)}}/>
                </td>
              </tr>
            )))}
          </tbody>
        </table>
      </div>
      </>
    )
}

export default Permisos