import { Link, useLocation, useNavigate } from "react-router-dom";
import "./track-not-found.css";
import { useTranslation } from "react-i18next";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import { useEffect } from "react";
import ScrollToTop from "../../../components/scrollToTop/scrollToTop";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/Footer";

function TrackNotFound() {
  const [tNabvar] = useTranslation("navbar");
  const [tTrack] = useTranslation("trackPage");
  const navigate = useNavigate();
  const location = useLocation();
  const {prefix, waybillNumber, data} = location.state?.formData || "";

  useEffect(() => {
    let redirectPage = '';
    if(!prefix || !waybillNumber || !data) {
      if(localStorage.getItem("lng")) {
        if(localStorage.getItem("lng") === 'us') {
          redirectPage = `${navBarEn["category-services"].track}`;
        } else {
          redirectPage = `${navBarEs["category-services"].track}`;
        }
      } else {
        redirectPage = `${navBarEs["category-services"].track}`;
      }
      navigate(`/${redirectPage}`)
    }
  })

  const handleResetWaybill = () => {
    if(localStorage.getItem("lng")) {
      if(localStorage.getItem("lng") === 'us') {
        navigate(`/${navBarEn["category-services"].track}`)
      } else {
        navigate(`/${navBarEs["category-services"].track}`)
      }
    } else {
      navigate(`/${navBarEs["category-services"].track}`)
    }
  };

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-6">
            <p className="trackNFTitle">{tTrack("not-found.title")}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p className="trackNFText1">{tTrack("not-found.text-1")}</p>
            <p className="trackNFText2">
              {tTrack("not-found.text-2")} ({prefix}-
              {waybillNumber})
            </p>
            <p className="trackNFText2">{tTrack("not-found.text-3")}</p>
          </div>
        </div>
        <div className="trackNFBtn">
          <Link to={`/${tNabvar("category-services.contact")}`}>
            <button>{tTrack("not-found.btn-contac")}</button>
          </Link>
          <button onClick={handleResetWaybill}>
            {tTrack("details.btn-track")}
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TrackNotFound;
