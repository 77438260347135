import React, { useState, useEffect } from "react";
import "./header-home-styles.css";
import TrackGuideForm from "../forms/trackGuideForm";
import QuoteShippingForm from "../forms/quoteShippingForm";
import { useTranslation } from "react-i18next";
import Slider from "../slider/slider";
import axios from 'axios';

function HeaderHome() {
  const [banners, setBanners] = useState([])
  const [loading, setLoading] = useState(true);
  const [trackTab, setTrackTab] = useState("trackForm");
  const [tHome] = useTranslation("homePage");
  const [textHome, setTextHome] = useState({"titulo-header":{"third-title":"","principal-title":"","secondary-title":""}})
  const idHome = '29016553-ee6b-45ee-aafe-6e451d29e398';

  const selectForm = (statusForm) => {
    setTrackTab(statusForm);
  };

  const renderForm = () => {
    if (trackTab === "trackForm") {
      return (
        <TrackGuideForm />
      );
    } else {
      return (
        <QuoteShippingForm />
      );
    }
  };

  const getBanners = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/banners`, {
        headers: {
          authorization: process.env.REACT_APP_PUBLIC_KEY,
        },
      });

      if (res.data.code === 1){
        setBanners(res.data.banners)
        setLoading(false);
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  const getTexts = async () => {
    let currentLng = ''
    localStorage.getItem("lng") ? currentLng = `${localStorage.getItem("lng")}` : currentLng = 'es';
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idHome}`, {headers: {
        Authorization: process.env.REACT_APP_PUBLIC_KEY
      }});
      
      if(currentLng === 'es') {
        setTextHome(response.data.textWeb.text_es);
      } else {
        setTextHome(response.data.textWeb.text_us);
      }

    }catch(error) {
      console.error(error);
    }
  }
  
  useEffect(() => {
    getBanners();
    getTexts();
    }, []); 

  return (

    <React.StrictMode>
      <div className="containerImage">
        
        <div className="formsSection">

          <h1>
            {textHome ? textHome["titulo-header"]["principal-title"] : tHome("header.principal-title")}
            <span>{textHome ? textHome["titulo-header"]["secondary-title"] : tHome("header.secondary-title")}</span>
            <span>{textHome ? textHome["titulo-header"]["third-title"] : tHome("header.third-title")}</span>
          </h1>

          <div className="formSectionContainer">
            <div className="tabs">
              <div
                className={`tab ${trackTab === "trackForm" ? "active" : ""}`}
                onClick={() => selectForm("trackForm")}
              >
                <img src="" alt="" />
                <p>{ tHome("track-guide-form.title") }</p>
              </div>
              <div
                className={`tab ${trackTab === "shipmentForm" ? "active" : ""}`}
                onClick={() => selectForm("shipmentForm")}
              >
                <img src="" alt="" />
                <p>{ tHome("quote-shipping-form.title") }</p>
              </div>
            </div>
            <div className="formContainer">{renderForm()}</div>
          </div>
          
        </div>

        <div className="headerTextCont">

          <div className="contSlider">
            <Slider banners={banners} />
          </div>
        </div>

      </div>
    </React.StrictMode>
  );
}

export default HeaderHome;
