import "./style.css";
import React from "react";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/Footer";
import TitleSection from "../../../components/title-section/title-section";
import { useTranslation } from "react-i18next";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import pdfTarifaImpo from "../../../assets/pdfs/2023-tarifario_impo_esp.pdf";
import pdfTarifaExpo from "../../../assets/pdfs/2023-tarifario_expo_esp.pdf";
import Helmet from "react-helmet";

function BondedWarehouse() {
  const [tStationsBondedWarehouse] = useTranslation("bondedWarehouse");
  const [tSMetaData] = useTranslation("metaData");
  const information = tStationsBondedWarehouse("Information", {
    returnObjects: true,
  });
  const services = tStationsBondedWarehouse("services", {
    returnObjects: true,
  });
  const handlinCharge = tStationsBondedWarehouse("manejoCargas", {
    returnObjects: true,
  });
  const reqImport = tStationsBondedWarehouse("requisitosMostradorImportacion", {
    returnObjects: true,
  });
  const reqExport = tStationsBondedWarehouse("requisitosMostradorExportacin", {
    returnObjects: true,
  });
  const shipments = tStationsBondedWarehouse("envios", { returnObjects: true });
  const footer = tStationsBondedWarehouse("footer", { returnObjects: true });

  const showTarifaImpo = () => {
    window.open(pdfTarifaImpo, "_blank");
  };
  const showTarifaExpo = () => {
    window.open(pdfTarifaExpo, "_blank");
  };

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].stations}/${
      Object.keys(navBarEs["stations-submenu"])[2]
    }`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].stations}/${
      Object.keys(navBarEn["stations-submenu"])[2]
    }`
  );

  return (
    <>
      <Helmet>
        <title>{tSMetaData("estacionesAlmacenFiscalizado.title")}</title>
        <meta
          name="description"
          content={tSMetaData("estacionesAlmacenFiscalizado.description")}
        />
      </Helmet>
      <Navbar />
      <TitleSection title={information.title} />
      <section className="almacen__fiscal__section">
        <div className="almacen__fiscal__title">
          <h2>{information.subtitle}</h2>
        </div>
        <div className="almacen__fiscal__information">
          <p dangerouslySetInnerHTML={{ __html: information.infoText1 }} />
          <p>{information.infoText2}</p>
          <p>{information.infoText3}</p>
        </div>
        <div className="almacen__fiscal__row" role="row">
          <div className="almacen__fiscal__column">
            <div className="almacen__fiscal__column__title">
              <h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16.067 18.291"
                >
                  <g transform="translate(-1483.131 -41.259)">
                    <g transform="translate(1483.131 41.259)">
                      <g>
                        <path
                          d="M1499.5,54.9l-.82.474V63.9l7.381-4.261V51.108Z"
                          transform="translate(-1489.992 -45.606)"
                        ></path>
                        <path
                          d="M1494.3,42.9l-2.839-1.643-7.586,4.379,2.842,1.643Z"
                          transform="translate(-1483.461 -41.259)"
                        ></path>
                        <path
                          d="M1502.454,47.626l-3.271-1.863-7.582,4.379.432.224,2.839,1.639,2.823-1.628Z"
                          transform="translate(-1486.869 -43.247)"
                        ></path>
                        <path
                          d="M1487.29,55.671l-1.359-.7V52.8l-2.8-1.613v8.466l7.328,4.231V55.421L1487.29,53.6Z"
                          transform="translate(-1483.131 -45.642)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                {services.title}
              </h4>
            </div>
            <div className="almacen__fiscal__column__list">
              <ul>
                {services.services.map((service, index) => (
                  <li key={index}>{service}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="almacen__fiscal__column">
            <div className="almacen__fiscal__column__title">
              <h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16.067 18.291"
                >
                  <g transform="translate(-1483.131 -41.259)">
                    <g transform="translate(1483.131 41.259)">
                      <g>
                        <path
                          d="M1499.5,54.9l-.82.474V63.9l7.381-4.261V51.108Z"
                          transform="translate(-1489.992 -45.606)"
                        ></path>
                        <path
                          d="M1494.3,42.9l-2.839-1.643-7.586,4.379,2.842,1.643Z"
                          transform="translate(-1483.461 -41.259)"
                        ></path>
                        <path
                          d="M1502.454,47.626l-3.271-1.863-7.582,4.379.432.224,2.839,1.639,2.823-1.628Z"
                          transform="translate(-1486.869 -43.247)"
                        ></path>
                        <path
                          d="M1487.29,55.671l-1.359-.7V52.8l-2.8-1.613v8.466l7.328,4.231V55.421L1487.29,53.6Z"
                          transform="translate(-1483.131 -45.642)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                {handlinCharge.title}
              </h4>
            </div>
            <div className="almacen__fiscal__column__list">
              <ul>
                {handlinCharge.Manejo.map((m, index) => (
                  <li key={index}>{m}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="almacen__fiscal__column__footer"></div>
      </section>

      <section className="almacen__fiscal__items">
        <article className="almacen__fiscal__item">
          <div className="almacen__fiscal__item__title">
            <div className="almacen__fiscal__item__title">
              <h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16.067 18.291"
                >
                  <g transform="translate(-1483.131 -41.259)">
                    <g transform="translate(1483.131 41.259)">
                      <g>
                        <path
                          d="M1499.5,54.9l-.82.474V63.9l7.381-4.261V51.108Z"
                          transform="translate(-1489.992 -45.606)"
                        ></path>
                        <path
                          d="M1494.3,42.9l-2.839-1.643-7.586,4.379,2.842,1.643Z"
                          transform="translate(-1483.461 -41.259)"
                        ></path>
                        <path
                          d="M1502.454,47.626l-3.271-1.863-7.582,4.379.432.224,2.839,1.639,2.823-1.628Z"
                          transform="translate(-1486.869 -43.247)"
                        ></path>
                        <path
                          d="M1487.29,55.671l-1.359-.7V52.8l-2.8-1.613v8.466l7.328,4.231V55.421L1487.29,53.6Z"
                          transform="translate(-1483.131 -45.642)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                {reqImport.title}
              </h4>
            </div>
            <div className="almacen__fiscal__item__information">
              <strong>{reqImport.subtitle1}</strong>
              <ul>
                <li>
                  <ol>
                    <li>{reqImport.subtitle1Item1}</li>
                  </ol>
                </li>

                <li>
                  <ol start={2}>
                    <li>{reqImport.subtitle1Item2}</li>
                  </ol>
                </li>

                <li>
                  <ol start={3}>
                    <li>{reqImport.subtitle1Item3}</li>
                  </ol>
                </li>
              </ul>

              <strong>{reqImport.subtitle2}</strong>
              <ul>
                <li>
                  <ol>{reqImport.subtitle2Item1}</ol>
                </li>

                <li>
                  <ol start={2}>{reqImport.subtitle2Item2}</ol>
                </li>

                <li>
                  <ol start={3}>{reqImport.subtitle2Item3}</ol>
                </li>
              </ul>
              <p>
                <em>{reqImport.infotext}</em>
              </p>
              <p>{reqImport.infoTextSubtitle}</p>
              <ul>
                <li>{reqImport.infoTextItem1}</li>
                <li>{reqImport.infoTextItem2}</li>
                <li>{reqImport.infoTextItem3}</li>
              </ul>
              <p>{reqImport.infoTextIubtitle2}</p>

              <ul>
                <li>{reqImport.infoTextItem4}</li>
                <li>{reqImport.infoTextItem5}</li>
              </ul>
            </div>
          </div>

          <div className="almacen__fiscal__item__buttons">
            <a
              href="#####"
              className="button button-primary"
              id="btnTarifarioImpo"
              onClick={showTarifaImpo}
            >
              {tStationsBondedWarehouse("button")}
            </a>
          </div>
        </article>

        <article className="almacen__fiscal__item">
          <div className="almacen__fiscal__item__title">
            <div className="almacen__fiscal__item__title">
              <h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16.067 18.291"
                >
                  <g transform="translate(-1483.131 -41.259)">
                    <g transform="translate(1483.131 41.259)">
                      <g>
                        <path
                          d="M1499.5,54.9l-.82.474V63.9l7.381-4.261V51.108Z"
                          transform="translate(-1489.992 -45.606)"
                        ></path>
                        <path
                          d="M1494.3,42.9l-2.839-1.643-7.586,4.379,2.842,1.643Z"
                          transform="translate(-1483.461 -41.259)"
                        ></path>
                        <path
                          d="M1502.454,47.626l-3.271-1.863-7.582,4.379.432.224,2.839,1.639,2.823-1.628Z"
                          transform="translate(-1486.869 -43.247)"
                        ></path>
                        <path
                          d="M1487.29,55.671l-1.359-.7V52.8l-2.8-1.613v8.466l7.328,4.231V55.421L1487.29,53.6Z"
                          transform="translate(-1483.131 -45.642)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                {shipments.title}
              </h4>
            </div>
            <div className="almacen__fiscal__item__information">
              <p>{shipments.description}</p>
              <ul>
                <li>
                  <ol>
                    <li>{shipments.item1}</li>
                  </ol>
                </li>

                <li>
                  <ol start={2}>
                    <li>{shipments.item2}</li>
                  </ol>
                </li>

                <li>
                  <ol start={3}>
                    <li>{shipments.item3}</li>
                  </ol>
                </li>

                <li>
                  <ol start={4}>
                    <li>{shipments.item4}</li>
                  </ol>
                </li>

                <li>
                  <ol start={5}>
                    <li>{shipments.item5}</li>
                  </ol>
                </li>

                <li>
                  <ol start={6}>
                    <li>{shipments.item6}</li>
                  </ol>
                </li>

                <li>
                  <ol start={7}>
                    <li>{shipments.item7}</li>
                  </ol>
                </li>
              </ul>

              <p>{shipments.text1}</p>
              <p>
                <strong>{shipments.text2}</strong>
              </p>
            </div>
          </div>
        </article>

        <article className="almacen__fiscal__item">
          <div className="almacen__fiscal__item__title">
            <div className="almacen__fiscal__item__title">
              <h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16.067 18.291"
                >
                  <g transform="translate(-1483.131 -41.259)">
                    <g transform="translate(1483.131 41.259)">
                      <g>
                        <path
                          d="M1499.5,54.9l-.82.474V63.9l7.381-4.261V51.108Z"
                          transform="translate(-1489.992 -45.606)"
                        ></path>
                        <path
                          d="M1494.3,42.9l-2.839-1.643-7.586,4.379,2.842,1.643Z"
                          transform="translate(-1483.461 -41.259)"
                        ></path>
                        <path
                          d="M1502.454,47.626l-3.271-1.863-7.582,4.379.432.224,2.839,1.639,2.823-1.628Z"
                          transform="translate(-1486.869 -43.247)"
                        ></path>
                        <path
                          d="M1487.29,55.671l-1.359-.7V52.8l-2.8-1.613v8.466l7.328,4.231V55.421L1487.29,53.6Z"
                          transform="translate(-1483.131 -45.642)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                {reqExport.title}
              </h4>
            </div>
            <div className="almacen__fiscal__item__information">
              <p>{reqExport.subtitle}</p>
              <ul>
                <li>{reqExport.item1}</li>
                <li>{reqExport.item2}</li>
                <li>{reqExport.item3}</li>
                <li>{reqExport.item4}</li>
                <li>{reqExport.item5}</li>
              </ul>

              <p>{reqExport.subtitle2}</p>
              <ul>
                <li>{reqExport.item6}</li>
                <li>{reqExport.item7}</li>
                <li>{reqExport.item8}</li>
                <li>{reqExport.item9}</li>
              </ul>
              <p>{reqExport.text}</p>
            </div>
          </div>

          <div className="almacen__fiscal__item__buttons">
            <a
              href="#####"
              className="button button-primary"
              id="btnTarifarioExpo"
              onClick={showTarifaExpo}
            >
              {tStationsBondedWarehouse("button")}
            </a>
          </div>
        </article>

        <article className="almacen__fiscal__item__footer">
          <div className="almacen__fiscal__item__footer__title">
            <h4>{footer.title}</h4>
            <h5>{footer.subtitle}</h5>
          </div>

          <div className="almacen__fiscal__item__footer__information">
            <p dangerouslySetInnerHTML={{ __html: footer.text1 }} />
            <p dangerouslySetInnerHTML={{ __html: footer.text2 }} />
            <p dangerouslySetInnerHTML={{ __html: footer.text3 }} />
          </div>
        </article>
      </section>

      <Footer />
    </>
  );
}

export default BondedWarehouse;
