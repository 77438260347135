import React from 'react'
import "./compliance.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import andres from "../../assets/imgs/compliance_imgs/andres-conesa-circle.png"


const Commitment = () => {
  const [tCompliance] = useTranslation("compliance");
  const [scrollY, setScrollY] = useState(0);

  const [animation3, setAnimation3] = useState(false);

  const handlescroll = () => {
    setScrollY(window.scrollY || window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handlescroll);

    return () => {
      window.removeEventListener("scroll", handlescroll);
    };
  }, []);

  useEffect(() => {
    setAnimation3(scrollY >= 1170);
  }, [scrollY]);

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);

  };
  return (
    <div className="container-fluid bg-compliance-white pt-5 pb-3">
      <h2
        className={
          animation3 ? "animate__animated animate__fadeInUp text-center text-am-blue pb-3" : "text-center text-am-blue pb-3"
        }
      >
        {tCompliance("body.compromiso")}
      </h2>
      <div className="compl_ethics_footer_conta">
        <div className="compliance-acl mb-0">
          <div class="row my-3">
            <div class="col-12 col-md-4">
              <img src={andres} alt="Andrés Conesa Labastida" className="img-andres"></img>
            </div>
            <div class="col-12 col-md-8 text-start py-0 py-lg-2">
              <p className="font-semibold text-blue-light mt-3">{tCompliance("body.andres")}</p>
              <h3 
                className={
                  animation3 ? "animate__animated animate__fadeInUp text-am-blue" : " text-am-blue"
                }
                >{tCompliance("body.cumplir")}</h3>
              <p>{tCompliance("body.texto1")}</p>
              <p>{tCompliance("body.texto2")}</p>

              {showMore && (
              <>
                <p>{tCompliance("body.texto3")}</p>
                <p>{tCompliance("body.texto4")}</p>                
                <p>{tCompliance("body.texto5")}</p>
                <p>{tCompliance("body.texto6")}</p>
                <p>{tCompliance("body.texto7")}</p>                                      
                <p>{tCompliance("body.texto8")}</p>
                <p className="font-semibold">{tCompliance("body.texto9")}<br/>{tCompliance("body.texto10")}</p>
              </>
              )}
              <button onClick={toggleShowMore} className="btn btn-link text-blue-light p-0" id='leerMasCompromiso'>
                {showMore ? tCompliance("body.esconder") : tCompliance("body.leer-mas")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='divisior'></div>
    </div>
  )
}

export default Commitment