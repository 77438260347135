import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import "./button-modal.css"
import 'animate.css';


const ButtonWithModal = () => {
  const [tModal] = useTranslation("modal");
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [tCompliance] = useTranslation("compliance");

  return (
    <div>
    
      <Button className='btn_compliance_show_modal' onClick={handleShow} id='btnComplianceMas'>
      {tCompliance("body.ver")}
      <li><FaArrowRight /></li>
      </Button>

      <Modal show={showModal} onHide={handleClose}>
      
        <Modal.Header closeButton>
          <Modal.Title>
          <div className='modal_compliance_title'>
          <h4>{tModal("titulo.etica")}</h4>
          <p>{tModal("titulo.intro")}</p>
          </div>
          </Modal.Title>

        </Modal.Header>

        <Modal.Body>
        <div className='modal_compliance_body'>
        <p>{tModal("modBody.par1")}</p>
        <p>{tModal("modBody.par2")}</p>
        <p>{tModal("modBody.par3")}</p>
        </div>
        </Modal.Body>

        <Modal.Footer>
          <Button className='btn_compliance_show_modal_aceptar' onClick={handleClose}>
          {tModal("modBody.aceptar")}<li><FaArrowRight /></li>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ButtonWithModal;
