import React from "react";
import { Link } from "react-router-dom";
import "./fleet.css";
import Navbar from "../../../components/navbar/navbar";
import TitleSection from "../../../components/title-section/title-section";
import Footer from "../../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import Helmet from "react-helmet";

function Fleet() {
  const [tFleet] = useTranslation("fleet");
  const [tSMetaData] = useTranslation("metaData");

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"]["about-us"]}/${
      Object.keys(navBarEs["about-us"])[1]
    }`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"]["about-us"]}/${
      Object.keys(navBarEn["about-us"])[1]
    }`
  );

  return (
    <>
      <Helmet>
        <title>{tSMetaData("flota.title")}</title>
        <meta name="description" content={tSMetaData("flota.description")} />
      </Helmet>
      <Navbar />
      <TitleSection title={tFleet("title.title-page")} />
      <div className="bg-fleet">
        <div className="container">
          <div className="row ps-4 my-0 my-lg-5 py-3 py-lg-5">
            <div className="col-12 col-lg d-flex flex-column text-start py-3">
              <p className="fs-5 fw-bold font-semibold">
                {tFleet("scope.short-scope")}
              </p>
              <div className="col-6 d-flex justify-content-start text-center py-2">
                <Link className="btn-box" to="erj-190" id="link-erj-190">
                  ERJ-190
                  <p className="fs-see">{tFleet("scope.see-more")}</p>
                </Link>
              </div>
            </div>
            <div className="col-12 col-lg">
              <div className="col-12 col-lg d-flex flex-column text-start py-3">
                <p className="fs-5 fw-bold font-semibold">
                  {tFleet("scope.medium-scope")}
                </p>
                <div className="d-flex justify-content-around">
                  <div className="col-6 d-flex justify-content-start text-center py-2">
                    <Link className="btn-box" to="b737-700" id="link-b737-700">
                      B737-700
                      <p className="fs-see">{tFleet("scope.see-more")}</p>
                    </Link>
                  </div>
                  <div className="col-6 d-flex justify-content-start text-center py-2">
                    <Link className="btn-box" to="b737-800" id="link-b737-800">
                      B737-800
                      <p className="fs-see">{tFleet("scope.see-more")}</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg">
              <div className="col-12 col-lg d-flex flex-column text-start py-3">
                <p className="fs-5 fw-bold font-semibold">
                  {tFleet("scope.long-scope")}
                </p>
                <div className="d-flex justify-content-around">
                  <div className="col-6 d-flex justify-content-start text-center py-2">
                    <Link className="btn-box" to="b787-8" id="link-b787-8">
                      B787-8
                      <p className="fs-see">{tFleet("scope.see-more")}</p>
                    </Link>
                  </div>
                  <div className="col-6 d-flex justify-content-start text-center py-2">
                    <Link className="btn-box" to="b787-9" id="link-b787-9">
                      B787-9
                      <p className="fs-see">{tFleet("scope.see-more")}</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Fleet;
