import { useQuill } from "react-quilljs";
import 'quill/dist/quill.snow.css';
import toolbar from './toolbarText';
import { useEffect } from "react";

function QuillComponentText({text, onQuillDataText}) {
    const {quill, quillRef} = useQuill({
        modules: {
            toolbar: toolbar,
        }
    });

    useEffect(() => {
        if (quill && text) {
            quill.setText(text);
        }
    }, [quill, text]);

    const handleSubmit = (event) => {
        event.preventDefault();
        // const data = {
        //     content: JSON.stringify(quill.getText())
        //     content: quill.getText()
        // }
        const data = quill.getText()

        if (onQuillDataText) {
            onQuillDataText(data);
        }
    }

    return(
        <form onSubmit={handleSubmit}>
            <div className="editor">
                <div ref={quillRef}></div>
            </div>
            <button>Guardar</button>
        </form>
    )
};

export default QuillComponentText;