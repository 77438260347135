/* eslint-disable no-unused-vars */
import React, { useEffect, useState} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaInfoCircle } from "react-icons/fa";
import '../usuarios/usuarios_style.css';

function Contactos() {

  const [contacts, setContacts] = useState([])
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState("");

  const getContacts = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/contact`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token con el formato "Bearer {token}"
        },
      });
      if (res.data.code === 1){
        setContacts(res.data.contacts)
        setLoading(false);
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = () => {
    const input = document.getElementById("searchInput");
    setSearchValue(input.value.trim());
  };

  const filteredContacts = searchValue
  ? contacts.filter(
      (contact) =>    
        (contact.first_name?.toUpperCase() || '').includes(searchValue.toUpperCase()) ||
        (contact.last_name?.toUpperCase() || '').includes(searchValue.toUpperCase())
    )
  : contacts

  useEffect(() => {
    const fetchData = async () => {
      await getContacts();
    };
  
    fetchData();
  }, [updateTrigger]);

  const handleInfoIconClick = (contact) => {
  const statusOptions = [
    {"name": "Nuevo contacto", value: 1}, 
    {"name": "Contactado", value: 0} 
  ];
  
  Swal.fire({
    title: "Información",
    html: `
      <style>
        #statusSelect {
          margin-top: 10px;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
          color: #545454;
        }
      </style>
      <p><strong>Nombre:</strong><br> ${contact.first_name} ${contact.last_name}</p>
      <p><strong>Tipo de Envío:</strong><br> ${
        contact.type_shipment === '1' ? "Nacional" :
        contact.type_shipment === '2' ? "Importación" :
        contact.type_shipment === '3' ? "Exportación" :
        "Desconocido"
      }</p>
      <p><strong>Motivo de contacto:</strong><br> ${
        contact.reason_contact === '1' ? "Cotización de envíos nacionales" :
        contact.reason_contact === '2' ? "Estatus, seguimientos y solicitudes adicionales de embarques nacionales con guía documentada" :
        contact.reason_contact === '3' ? "Estatus de envíos de otros países hacia destino final República Mexicana" :
        contact.reason_contact === '4' ? "Cotizaciones de envíos de otros países hacia destino final MEX (correo)" :
        contact.reason_contact === '5' ? "Para cotizaciones de envió al exterior, contáctanos si cuentas con agente aduanal" :
        contact.reason_contact === '6' ? "No cuentas con agente aduanal" :
        contact.reason_contact === '7' ? "Estatus y solicitudes adicionales de embarques de Exportación" :
        "No definido"
      }</p>
      <p><strong>Mensaje:</strong><br> ${contact.message}</p>
      <p><strong>Estatus:</strong><br> ${
        contact.status === '1' ? "Nuevo contacto" : "Contactado"
      }</p>
      <p><strong>Actualizar estatus:</strong><br> 
        <select id="statusSelect" value="${contact.status}">
          ${statusOptions.map((option) => `<option value="${option.value}">${option.name}</option>`).join("")}
        </select>
      </p>
    `,
    showCancelButton: true,
    confirmButtonText: "Actualizar",
    cancelButtonText: "Cancelar",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const statusSelect = document.getElementById('statusSelect');
      const selectedValue = statusSelect.value;
      const sendContactData = {
        ...contact,
        status: selectedValue
      }

        try {
          const url = `${process.env.REACT_APP_URL_BACKEND}/api/contact/update`;
          const response = await axios.put(url, sendContactData, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
        });

        if (response.data.code === 1) {
          setContacts(prevContacts =>
            prevContacts.map(c =>
              c.id === contact.id ? { ...c, status: selectedValue } : c
            )
          );

          Swal.fire("Guardado", "El estado ha sido actualizado.", "success");
        } else {
          Swal.fire("No guardado", "No se ha actualizado el estado.", "error");
        } 
      } catch(error) {
          console.error(error);
          Swal.fire("No guardado", "No se ha actualizado el estado.", "error");
        }
    }
  });
};

  return (
    <>
    <div className="panel-table">
    <div className='colum-left'>
      <input type="text" className='searchInput' id="searchInput" placeholder="Buscar..." />
      <button className='btnn' onClick={handleSearch}>Buscar</button>
    </div>    
    </div>
    <div className='table-responsive'>
      <table className='table table-preve' id='tableToSearch'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th className='text-center'>Shipment type</th>
            <th className='text-center'>Status</th>
            <th className='text-center'>Created</th>
            <th className='accions'>Información</th>
          </tr>
        </thead>
        
        <tbody>
        {loading ? (<tr><td>Cargando...</td></tr>) : 
        ( filteredContacts.map((contact) => (
          <tr key={contact.id} data-master-id={contact.id}>
            <td>{`${contact.first_name} ${contact.last_name}`}</td>
            <td>{contact.email}</td>
            <td className='text-center'>
              {contact.type_shipment === '1' ? "Nacional" :
              contact.type_shipment === '2' ? "Importación" :
              contact.type_shipment === '3' ? "Exportación" :
              "Desconocido"}
            </td>
            <td className='text-center'>
              {contact.status === '1' ? "Nuevo contacto ":
              "Contactado "}</td>
            <td className='text-center'>{contact.created_at}</td>
            <td className='text-center'>
              <FaInfoCircle className='infoButton' onClick={() => handleInfoIconClick(contact)} />
           </td>
          </tr>
        )))}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default Contactos