import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./cont_nat.css";
import navBarEn from "../../translations/en/navbar.json";
import navBarEs from "../../translations/es/navbar.json";
import Helmet from "react-helmet";

function NationalContract() {
  const [tNationalContract] = useTranslation("cont_nat");
  const [tSMetaData] = useTranslation("metaData");
  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].nationalContract}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].nationalContract}`
  );

  return (
    <>
      <Helmet>
        <title>{tSMetaData("contratoCargaAereaNacional.title")}</title>
        <meta
          name="description"
          content={tSMetaData("contratoCargaAereaNacional.description")}
        />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <section className="national_box">
        <div className="row">
          <div className="p-3">
            <Link className="text-decoration-none" to="/contracts">
              {tNationalContract("nacional.button")}
            </Link>
          </div>
          <div className="title_national">
            <div className="col">
              <h1 className="carga_nacional">
                {tNationalContract("nacional.titulo")}
              </h1>
              <div className="box_nacional">
                <p className="aerea_nacional">
                  {tNationalContract("nacional.subtitulo")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="texto_box">
              <div className="container contractsContainer">
                <p>
                  <strong>{tNationalContract("texto_box.uno")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr1")}
                </p>

                <p>
                  <strong>
                    {tNationalContract("texto_box.transportista")}.
                  </strong>
                  - {tNationalContract("texto_box.parr2")}
                </p>

                <p>{tNationalContract("texto_box.mercancia_texto")}</p>

                <p>{tNationalContract("texto_box.remitente_texto")}</p>

                <p>{tNationalContract("texto_box.consignatario_texto")}</p>

                <p>{tNationalContract("texto_box.dos_texto")}</p>

                <p>
                  <strong>{tNationalContract("texto_box.tres")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr3")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.cuatro")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr4")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.cinco")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr5")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.seis")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr6")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.siete")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr7")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.ocho")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr8")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.nueve")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr9")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.diez")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr10")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.once")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr11")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.doce")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr12")}
                </p>

                <p>{tNationalContract("texto_box.transportista_texto")}</p>

                <p>
                  <strong>{tNationalContract("texto_box.trece")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr13")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.catorce")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr14")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.quince")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr15")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.dieciseis")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr16")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.diecisiete")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr17")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.dieciocho")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr18")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.diecinueve")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr19")}
                </p>

                <p>
                  <strong>{tNationalContract("texto_box.veinte")}.</strong>-{" "}
                  {tNationalContract("texto_box.parr20")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3">
          <Link className="text-decoration-none" to="/contracts">
            {tNationalContract("nacional.button")}
          </Link>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default NationalContract;
