import './title-section-pink.css';
import iconAeromexico from "../../assets/imgs/shared/icono_amc.svg";
import TrackBtnContactMb from '../trackBtnContact/trackBtnContactMb';
import { useEffect, useState } from 'react';

function TitleSectionPink({title, hiddeSecondarySection}) {
    const windowWidth = window.innerWidth;
    const [showTrackBtnContact, setShowTrackBtnContact] = useState(false);

    useEffect(() => {
        if(!hiddeSecondarySection) {
            setShowTrackBtnContact(600)
        }
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth < hiddeSecondarySection) {
                setShowTrackBtnContact(true);
            } else {
                setShowTrackBtnContact(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [showTrackBtnContact])

    return (
        <>
            <div className="titleSectionPink">
                <div className="container" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div className="titleContainer">
                        <img src={iconAeromexico} alt="aeromexico logo" />
                        <h2>{title} </h2>
                    </div>
                    <div className='containerSecondarySection'>
                        {showTrackBtnContact}
                        {showTrackBtnContact && <div className='btnTrackContactTitle'>
                            <TrackBtnContactMb />
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
};

export default TitleSectionPink;