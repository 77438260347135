import Footer from "../../../../components/footer/Footer";
import TitleSection from "../../../../components/title-section/title-section";
import { useNavigate } from 'react-router-dom';
import './user-not-found.css'
import { useTranslation } from "react-i18next";

function UserNotFound() {

    const navigate = useNavigate();
    const [tUserNotFound] = useTranslation('userNotFound');

    const redirectHome = () => {
        navigate('/');
    }

    return (
        <>
            <TitleSection title={tUserNotFound("title")} />
            <div className="container userNFContainer">
                <p className="userNFDescription">Este usuario no se encuentra en nuestra base de datos, por favor ponte en contacto con el equipo de sistemas.</p>
                <button onClick={redirectHome}>REGRESAR AL INICIO</button>
            </div>
            <Footer />
        </>
    )
}

export default UserNotFound;