import "./main-banner.css"
import logo from "../../../assets/imgs/navbar/logo_AMC.svg";


function MainBanner () {
  return (
    <div className="bg-admin">
        <div className="bg-admin-gradient d-flex">
            <div className="ps-4 align-self-center">
                <img className="logo-amc-admin" src={logo} alt="logo amc"/>
            </div>
        </div>
    </div>
  )
}

export default MainBanner 