import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import ImageHeader from "../../../../components/image-header/image-header";
import industryServiceImg from "../../../../assets/imgs/services/nationals/industry-services.jpg";
import Accordion from "../../../../components/acordion/accordion";
import { useEffect, useState } from "react";
import axios from "axios";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";

function IndustryServices() {
  const [tIndustryServices] = useTranslation("industryServicesPage");
  const [tSMetaData] = useTranslation("metaData");
  const idIndustryServices = "265e5380-2d8b-4a8c-a120-ffa9cd820e54";
  const [textDescription, setTextDescription] = useState("");
  const [accordion1, setAccordion1] = useState("");
  const [accordion2, setAccordion2] = useState("");
  const [accordion3, setAccordion3] = useState("");
  const [accordion4, setAccordion4] = useState("");

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].services}/${
      Object.keys(navBarEs["products-submenu"])[0]
    }/${Object.keys(navBarEs["products-nacionales-submenu"])[12]}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].services}/${
      Object.keys(navBarEn["products-submenu"])[0]
    }/${Object.keys(navBarEn["products-nationals-submenu"])[12]}`
  );

  useEffect(() => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    axios
      .get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idIndustryServices}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      )
      .then((response) => {
        if (currentLng === "es") {
          setTextDescription(
            response.data.textWeb.text_es["01-descripción"].content
          );
          setAccordion1(response.data.textWeb.text_es["02-REMESA"].content);
          setAccordion2(response.data.textWeb.text_es["03-PRENSA"].content);
          setAccordion3(response.data.textWeb.text_es["04-PIEZA"].content);
          setAccordion4(
            response.data.textWeb.text_es["05-MUESTRAS EXENTAS"].content
          );
        } else {
          setTextDescription(
            response.data.textWeb.text_us["01-description"].content
          );
          setAccordion1(
            response.data.textWeb.text_us["02-REMITTANCES"].content
          );
          setAccordion2(
            response.data.textWeb.text_us["03-MEDIA SERVICES"].content
          );
          setAccordion3(response.data.textWeb.text_us["04-ITEMIZED"].content);
          setAccordion4(
            response.data.textWeb.text_us["05-EXEMPT SAMPLES"].content
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{tSMetaData("servicioIndustria.title")}</title>
        <meta
          name="description"
          content={tSMetaData("servicioIndustria.description")}
        />
      </Helmet>
      <ImageHeader
        section={tIndustryServices("header.section")}
        title={tIndustryServices("header.title")}
        description={
          textDescription
            ? JSON.parse(textDescription)
            : JSON.parse(tIndustryServices("description.content"))
        }
        contactSales={tIndustryServices("header.contact-sales")}
        contactProductID="btnContactIndustry"
        imgBackground={industryServiceImg}
      />
      <Accordion
        title={tIndustryServices("accordion-1.title")}
        content={
          accordion1
            ? JSON.parse(accordion1)
            : JSON.parse(tIndustryServices("accordion-1.content"))
        }
      />
      <div className="divider"></div>
      <Accordion
        title={tIndustryServices("accordion-2.title")}
        content={
          accordion2
            ? JSON.parse(accordion2)
            : JSON.parse(tIndustryServices("accordion-2.content"))
        }
      />
      <div className="divider"></div>
      <Accordion
        title={tIndustryServices("accordion-3.title")}
        content={
          accordion3
            ? JSON.parse(accordion3)
            : JSON.parse(tIndustryServices("accordion-3.content"))
        }
      />
      <div className="divider"></div>
      <Accordion
        title={tIndustryServices("accordion-4.title")}
        content={
          accordion4
            ? JSON.parse(accordion4)
            : JSON.parse(tIndustryServices("accordion-4.content"))
        }
      />
    </>
  );
}

export default IndustryServices;
