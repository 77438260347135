import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./contract_inter.css";
import navBarEn from "../../translations/en/navbar.json";
import navBarEs from "../../translations/es/navbar.json";
import Helmet from "react-helmet";

function InternationalContract() {
  const [tInternationalContract] = useTranslation("cont_inter");
  const [tSMetaData] = useTranslation("metaData");
  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].internationalContract}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].internationalContract}`
  );

  return (
    <>
      <Helmet>
        <title>{tSMetaData("contratoCargaAereaInternacional.title")}</title>
        <meta
          name="description"
          content={tSMetaData("contratoCargaAereaInternacional.description")}
        />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <section className="international_box">
        <div className="row">
          <div className="p-3">
            <Link className="text-decoration-none" to="/contracts">
              {tInternationalContract("internacional.button")}
            </Link>
          </div>
          <div className="title_international">
            <div className="col">
              <h1 className="carga_internacional">
                {tInternationalContract("internacional.titulo")}
              </h1>
              <div className="box_internacional">
                <p className="aerea_internacional">
                  {tInternationalContract("internacional.subtitulo")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="texto_box">
              <div className="container conta-texto">
                <h2>{tInternationalContract("box_texto.resolution")}</h2>
                <h3>{tInternationalContract("box_texto.air")}</h3>
                <p>
                  <strong>{tInternationalContract("box_texto.csc")}</strong>{" "}
                  <br />{" "}
                  <strong>{tInternationalContract("box_texto.expiry")}</strong>{" "}
                  {tInternationalContract("box_texto.indefinite")} <br />{" "}
                  <strong>{tInternationalContract("box_texto.type")}</strong>{" "}
                  {tInternationalContract("box_texto.b")}
                </p>

                <h4>{tInternationalContract("box_texto.resolved")}</h4>
                <p>{tInternationalContract("box_texto.following")}</p>

                <h3>{tInternationalContract("box_texto.notice")}</h3>
                <p>{tInternationalContract("box_texto.agreed")}</p>

                <h3>{tInternationalContract("box_texto.conditions")}</h3>
                <h4>{tInternationalContract("box_texto.carrier")}</h4>
                <p>{tInternationalContract("box_texto.carriage")}</p>

                <h4>{tInternationalContract("box_texto.contract")}</h4>
                <p>
                  <strong>{tInternationalContract("box_texto.uno")}</strong>{" "}
                  {tInternationalContract("box_texto.hereon")}
                </p>
                <p>
                  <strong>
                    {tInternationalContract("box_texto.carrier1")}
                  </strong>{" "}
                  {tInternationalContract("box_texto.includes")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.drawing")}</strong>{" "}
                  {tInternationalContract("box_texto.special")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.warsaw")}</strong>{" "}
                  {tInternationalContract("box_texto.means")}
                </p>
                <p>{tInternationalContract("box_texto.convention")}</p>
                <p>{tInternationalContract("box_texto.convention1")}</p>
                <p>{tInternationalContract("box_texto.convention2")}</p>

                <p>
                  <strong>
                    {tInternationalContract("box_texto.montreal")}
                  </strong>{" "}
                  {tInternationalContract("box_texto.rules")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.2./2.1")} </strong>{" "}
                  {tInternationalContract("box_texto.such")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.2.2")} </strong>
                  {tInternationalContract("box_texto.extent")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.inc2")} </strong>
                  {tInternationalContract("box_texto.laws")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.inc3")} </strong>
                  {tInternationalContract("box_texto.limited")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.inc4")} </strong>
                  {tInternationalContract("box_texto.loss")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.inc5")} </strong>
                  {tInternationalContract("box_texto.claims")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.inc6")} </strong>
                  {tInternationalContract("box_texto.any")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.inc7")} </strong>
                  {tInternationalContract("box_texto.rules1")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.inc8")} </strong>
                  {tInternationalContract("box_texto.delay")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.3.-")} </strong>
                  {tInternationalContract("box_texto.case")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.4")} </strong>
                  {tInternationalContract("box_texto.for")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.5")} </strong>
                  {tInternationalContract("box_texto.except")}
                </p>

                <p>
                  <strong>
                    {tInternationalContract("box_texto.cincodos")}{" "}
                  </strong>
                  {tInternationalContract("box_texto.when")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.seis")} </strong>
                  {tInternationalContract("box_texto.cargo")}
                </p>

                <p>
                  <strong>
                    {tInternationalContract("box_texto.seisdos")}{" "}
                  </strong>
                  {tInternationalContract("box_texto.shall")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.siete")} </strong>
                  {tInternationalContract("box_texto.cases")}
                </p>

                <p>
                  <strong>
                    {tInternationalContract("box_texto.sietedos")}{" "}
                  </strong>
                  {tInternationalContract("box_texto.code")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.car")} </strong>
                  {tInternationalContract("box_texto.of")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.car1")} </strong>
                  {tInternationalContract("box_texto.dela")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.8")} </strong>
                  {tInternationalContract("box_texto.agents")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.9")} </strong>
                  {tInternationalContract("box_texto.face")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.10")} </strong>
                  {tInternationalContract("box_texto.receipt")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.car2")} </strong>
                  {tInternationalContract("box_texto.made")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.car3")} </strong>
                  {tInternationalContract("box_texto.after")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.car4")} </strong>
                  {tInternationalContract("box_texto.days")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.car5")} </strong>
                  {tInternationalContract("box_texto.waybill")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.car6")} </strong>
                  {tInternationalContract("box_texto.whose")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.car7")} </strong>
                  {tInternationalContract("box_texto.unless")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.car8")} </strong>
                  {tInternationalContract("box_texto.stopped")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.11")} </strong>
                  {tInternationalContract("box_texto.expense")}
                </p>

                <p>
                  <strong>{tInternationalContract("box_texto.12")} </strong>
                  {tInternationalContract("box_texto.agent")}
                </p>

                <p>{tInternationalContract("box_texto.iata")}</p>

                <p>{tInternationalContract("box_texto.car9")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3">
          <Link className="text-decoration-none" to="/contracts">
            {tInternationalContract("internacional.button")}
          </Link>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default InternationalContract;
