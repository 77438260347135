import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineCaretDown, AiOutlineSend } from "react-icons/ai";
import "./forms-style.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function QuoteShippingForm() {
  const [tHome] = useTranslation("homePage");
  const [tSGetQuoteStepOne] = useTranslation("getQuoteStepOne");
  const services = JSON.parse(localStorage.getItem("services"));
  const stations = JSON.parse(localStorage.getItem("stations"));
  const navigate = useNavigate();

  let currentLng = "es";
  localStorage.getItem("lng")
    ? (currentLng = localStorage.getItem("lng"))
    : (currentLng = "es");

  console.log(localStorage.getItem("lng"));

  const [origin, setOrigin] = useState("");
  const [destino, setDestino] = useState("");
  const [service, setService] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const listServices =
    destino === "PBC" ||
    origin === "PBC" ||
    destino === "MEX2" ||
    origin === "MEX2"
      ? services.filter(
          (service) =>
            service.name !== "Animales Vivos" && service.name !== "Live Animals"
        )
      : services;

  const listCities =
    service === "Pharma XPS"
      ? stations.filter((city) => city.thumb !== "PBC")
      : stations;

  const handleOrigen = (e) => {
    setOrigin(e.target.value);
    checkIfDisabled(e.target.value, destino, service);
  };

  const handleDestino = (e) => {
    setDestino(e.target.value);
    checkIfDisabled(origin, e.target.value, service);
  };

  const handleService = (e) => {
    setService(e.target.value);
    checkIfDisabled(origin, destino, e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/quote", { state: { origin, destino, service } });
  };

  const checkIfDisabled = (origin, destino, service) => {
    if (origin === "" || destino === "" || service === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  return (
    <>
      <form>
        <div className="row">
          <div className="col-12 shipmentFormGroup">
            <label className="shipmentLabel">
              {tHome("quote-shipping-form.step1-label")}
              <span>{tHome("quote-shipping-form.origin-label")}</span>
            </label>
            <div className="selectContainer">
              <select className="selectBox" onChange={handleOrigen}>
                <option value="">{tSGetQuoteStepOne("choose")}</option>
                {listCities
                  .filter((city) => city.lng == currentLng)
                  .map((city, index) => (
                    <option
                      key={index}
                      data-code={city.code_text}
                      value={city.code_text}
                      data-num={index + 1}
                    >
                      {city.name} ({city.code_text.substring(0, 3)})
                    </option>
                  ))}
              </select>
              <div className="iconContainer">
                <i>
                  <AiOutlineCaretDown />
                </i>
              </div>
            </div>
          </div>
          <div className="col-12  shipmentFormGroup">
            <label className="shipmentLabel">
              <span>{tHome("quote-shipping-form.destination-label")}</span>
            </label>
            <div className="selectContainer">
              <select className="selectBox" onChange={handleDestino}>
                <option value="">{tSGetQuoteStepOne("choose")}</option>
                {stations
                  .filter((city) => city.lng == currentLng)
                  .map((city, index) => (
                    <option
                      key={index}
                      data-code={city.code_text}
                      value={city.code_text}
                      data-num={index + 1}
                    >
                      {city.name} ({city.code_text.substring(0, 3)})
                    </option>
                  ))}
              </select>
              <div className="iconContainer">
                <i>
                  <AiOutlineCaretDown />
                </i>
              </div>
            </div>
          </div>
          <div className="col-12 shipmentFormGroup">
            <label className="shipmentLabel">
              {tHome("quote-shipping-form.step2-label")}
              <span>{tHome("quote-shipping-form.type-service-label")}</span>
            </label>
            <div className="selectContainer">
              <select className="selectBox" onChange={handleService}>
                <option value="">{tSGetQuoteStepOne("choose")}</option>
                {listServices.map((service, index) => (
                  <option
                    key={index}
                    value={service.name}
                    data-code={service.thumb}
                    data-num={index + 1}
                  >
                    {service.name}
                  </option>
                ))}
              </select>
              <div className="iconContainer">
                <i>
                  <AiOutlineCaretDown />
                </i>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="searchButton">
              <button
                id="btnQuoteHome"
                className={`btnShipping ${isDisabled ? "is-disabled" : ""}`}
                onClick={handleSubmit}
                disabled={isDisabled}
              >
                <p>{tHome("quote-shipping-form.btn-text")}</p>
                <AiOutlineSend />
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="textCont">
        <Link className="redirecTo" id="linkQuoteHome" to="/quote">
          {tHome("quote-shipping-form.redirect-text")}
        </Link>
      </div>
    </>
  );
}

export default QuoteShippingForm;
