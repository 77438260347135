import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Measures from "./measures";
import "./style.css";

function StepThree({ onhandleStepThree, dataOne, dataTwo, register, errors }) {
  const [volCobrable, setVolCobrable] = useState(0);
  const [volCobrableTotal, setVolCobrableTotal] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const ArrayPeso = [];
  const [med, setMed] = useState(0);
  const [tSGetQuoteStepThree] = useTranslation("getQuoteStepThree");
  const shouldShowContent =
    (dataOne.tipo_servicio === "Animales Vivos" ||
      dataOne.tipo_servicio === "Live Animals") &&
    isVisible;

  const [formData, setFormData] = useState({
    quantity: "",
    alto: "",
    largo: "",
    ancho: "",
    pesoCobrable:
      parseFloat(volCobrable) > parseFloat(volCobrableTotal)
        ? parseFloat(volCobrable)
        : parseFloat(volCobrableTotal),
  });

  const handleStepThree = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    onhandleStepThree(formData);
  }, [formData, onhandleStepThree]);

  useEffect(() => {
    const todasLasPropiedadesLlenas =
      formData.quantity !== "" &&
      formData.alto !== "" &&
      formData.largo !== "" &&
      formData.ancho !== "";

    if (todasLasPropiedadesLlenas) {
      const res =
        parseFloat(formData.alto) *
        parseFloat(formData.largo) *
        parseFloat(formData.ancho);
      const VolCob = (parseFloat(res) / 5000) * parseFloat(formData.quantity);

      setVolCobrable(Math.round(parseFloat(VolCob) * 100) / 100);
    } else {
      setVolCobrable(null);
    }
  }, [formData]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      pesoCobrable:
        parseFloat(volCobrable) > parseFloat(volCobrableTotal)
          ? parseFloat(volCobrable)
          : parseFloat(volCobrableTotal),
    }));
  }, [volCobrable, volCobrableTotal]);

  const addMed = () => {
    if (med < dataTwo.piezas - 1) {
      setMed(med + 1);
    }
  };

  const removeMed = () => {
    setMed(med - 1);
  };

  const formatWeight = (weight) => {
    const pesoFormateado = Number(weight).toFixed(2);
    return pesoFormateado;
  };

  const updateVolCobrableWithWeight = (res) => {
    if (!isNaN(res)) {
      ArrayPeso.push(parseFloat(res));
    }

    const sumaDePesos = ArrayPeso.reduce(
      (acumulador, peso) => acumulador + peso,
      0
    );
    const sumaTotal = parseFloat(sumaDePesos) + parseFloat(volCobrable);
    setVolCobrableTotal(parseFloat(sumaTotal));
  };

  return (
    <>
      <div
        className="shipment__step set fadeInUp"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        <h4>
          <span>{tSGetQuoteStepThree("step1")}</span>
          {tSGetQuoteStepThree("step2")}
        </h4>
      </div>

      <div
        className="shipment__form__dimensions__helper is-alert-important"
        role="alert"
      >
        <p>
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
          </svg>
          <strong>{tSGetQuoteStepThree("info1.text1")} </strong>
          {tSGetQuoteStepThree("info1.text2")}
          <a
            href="/about-us/fleet"
            target="blank"
          >
            {" "}
            {tSGetQuoteStepThree("info1.text3")}
          </a>
        </p>
      </div>

      <div
        className="shipment__form__dimensions__message"
        role="alert"
        style={
          shouldShowContent
            ? { visibility: "visible", opacity: "1", display: "block" }
            : { visibility: "hidden", opacity: "0", display: "none" }
        }
      >
        <div className="shipment__form__dimensions__message__button">
          <button
            className="button js-shipment-form-dimensions-message-close"
            onClick={(e) => {
              e.preventDefault();
              setIsVisible(false);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.051 5.051">
              <path d="M5.051.23,4.821,0l-2.3,2.3L.23,0,0,.23l2.3,2.3L0,4.821l.23.23,2.3-2.3,2.3,2.3.23-.23-2.3-2.3Z"></path>
            </svg>
          </button>
        </div>
        <p>
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path>
          </svg>
          <span className="shipment__form__dimensions__message__text">
            {tSGetQuoteStepThree("alertAnimals")}
          </span>
        </p>
      </div>

      <div className="shipment__form__dimensions">
        <div className="shipment__form__dimensions__count">
          <span></span>
        </div>

        <div className="column">
          <label htmlFor="quantity" data-text="cant.">
            {tSGetQuoteStepThree("Amount")}
          </label>
          <div className="shipment__form__dimensions__number">
            <input
              type="text"
              name="quantity"
              id="quantity"
              {...register("quantity", {
                required: true,
                pattern: /^[0-9]+(\.[0-9]+)?$/,
              })}
              pattern="^[0-9]+(\.[0-9]+)?$"
              maxLength={8}
              className={`js-shipment-quote-form-quantity ${
                errors.quantity?.type === "pattern" ? "missing-field" : ""
              }`}
              onChange={handleStepThree}
            />
          </div>
          {errors.quantity?.type === "required" && (
            <div
              className="shipment__form__column__message__services show_alert"
              role="alert"
            >
              {tSGetQuoteStepThree("missingField")}
            </div>
          )}
          <div className="shipment__form__column__message" role="alert"></div>
        </div>

        <div className="column">
          <label htmlFor="alto" data-text="height">
            {tSGetQuoteStepThree("Height")}
          </label>
          <div className="shipment__form__dimensions__number">
            <input
              type="text"
              name="alto"
              id="alto"
              {...register("alto", {
                required: true,
                pattern: /^[0-9]+(\.[0-9]+)?$/,
              })}
              pattern="^[0-9]+(\.[0-9]+)?$"
              maxLength="8"
              className={`js-shipment-quote-form-alto ${
                errors.alto?.type === "pattern" ? "missing-field" : ""
              }`}
              onChange={handleStepThree}
            />
          </div>
          {errors.alto?.type === "required" && (
            <div
              className="shipment__form__column__message__services show_alert"
              role="alert"
            >
              {tSGetQuoteStepThree("missingField")}
            </div>
          )}
          <div className="shipment__form__column__message" role="alert"></div>
        </div>

        <div className="column">
          <label htmlFor="largo" data-text="height">
            {tSGetQuoteStepThree("Length")}
          </label>
          <div className="shipment__form__dimensions__number">
            <input
              type="text"
              name="largo"
              id="largo"
              {...register("largo", {
                required: true,
                pattern: /^[0-9]+(\.[0-9]+)?$/,
              })}
              pattern="^[0-9]+(\.[0-9]+)?$"
              maxLength="8"
              className={`js-shipment-quote-form-largo ${
                errors.largo?.type === "pattern" ? "missing-field" : ""
              }`}
              onChange={handleStepThree}
            />
          </div>
          {errors.largo?.type === "required" && (
            <div
              className="shipment__form__column__message__services show_alert"
              role="alert"
            >
              {tSGetQuoteStepThree("missingField")}
            </div>
          )}
          <div className="shipment__form__column__message" role="alert"></div>
        </div>

        <div className="column">
          <label htmlFor="ancho" data-text="height">
            {tSGetQuoteStepThree("Width")}
          </label>
          <div className="shipment__form__dimensions__number">
            <input
              type="text"
              name="ancho"
              id="ancho"
              {...register("ancho", {
                required: true,
                pattern: /^[0-9]+(\.[0-9]+)?$/,
              })}
              pattern="^[0-9]+(\.[0-9]+)?$"
              maxLength="8"
              className={`js-shipment-quote-form-ancho ${
                errors.ancho?.type === "pattern" ? "missing-field" : ""
              }`}
              onChange={handleStepThree}
            />
          </div>
          {errors.ancho?.type === "required" && (
            <div
              className="shipment__form__column__message__services show_alert"
              role="alert"
            >
              {tSGetQuoteStepThree("missingField")}
            </div>
          )}
          <div className="shipment__form__column__message" role="alert"></div>
        </div>

        <div className="shipment__form__dimensions__result">
          <span
            className={`js-peso-cobrable-element ${
              volCobrable ? "visble" : ""
            }`}
          >
            <i className="js-peso-cobrable-text">
              {tSGetQuoteStepThree("volumetricWeight")}:{" "}
              <strong>{formatWeight(volCobrable)} kg</strong>
            </i>
            <data className="js-peso-cobrable-result"></data>
          </span>
        </div>
      </div>
      <div className="shipment__form__dimensions__elements"></div>

      {Array.from({ length: med }).map((_, index) => (
        <>
          <Measures
            removeMed={removeMed}
            formatWeight={formatWeight}
            onPesoTotal={updateVolCobrableWithWeight}
            register={register}
            errors={errors}
            key={index}
            index={index}
          />
        </>
      ))}

      <div
        className="shipment__form__dimensions__file"
        style={
          dataTwo.piezas !== 1
            ? { visibility: "visible", opacity: "1", display: "block" }
            : {}
        }
      >
        <button
          type="button"
          className="button js-shipment-add-sizes"
          onClick={addMed}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
            <g transform="translate(-103 -834)">
              <circle
                cx="7"
                cy="7"
                r="7"
                transform="translate(103 834)"
              ></circle>
              <text
                transform="translate(107 845)"
                fill="#fff"
                fontSize="11"
                fontFamily="NHaasGroteskDSPro-75Bd, Neue Haas Grotesk Display Pro"
              >
                <tspan x="0" y="0">
                  +
                </tspan>
              </text>
            </g>
          </svg>
          <span>Añadir pieza</span>
        </button>
      </div>
    </>
  );
}

export default StepThree;
