import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import {
  FaSyringe,
  FaCube,
  FaStar,
  FaClock,
  FaRegEnvelope,
  FaPagelines,
  FaDiceD20,
  FaTabletAlt,
  FaDog,
  FaDiaspora,
  FaCapsules,
  FaSuitcaseRolling,
  FaAtom,
  FaBriefcase,
} from "react-icons/fa";

import "./nationals-home.css";
import TitleSection from "../../../../components/title-section/title-section";
import Card from "../../../../components/card/card";
import { useTranslation } from "react-i18next";
import axios from "axios";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";

function NationalsHome(props) {
  const [tNational] = useTranslation("national");
  const [tSMetaData] = useTranslation("metaData");
  const dataNational = tNational("data", { returnObjects: true });
  const complemetDataNational = tNational("complementData", {
    returnObjects: true,
  });
  const idNationals = "90b413b8-6b8a-48cd-92ee-a844807ac1a4";
  const [titleNationals, setTitleNationals] = useState("");
  const [dataNationalsDB, setDataNationalsDB] = useState([]);
  const iconComponents = {
    FaSyringe,
    FaCube,
    FaStar,
    FaClock,
    FaRegEnvelope,
    FaPagelines,
    FaDiceD20,
    FaTabletAlt,
    FaDog,
    FaDiaspora,
    FaCapsules,
    FaSuitcaseRolling,
    FaAtom,
    FaBriefcase,
  };

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].services}/${
      Object.keys(navBarEs["products-submenu"])[0]
    }`
  );
  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].services}/${
      Object.keys(navBarEn["products-submenu"])[0]
    }`
  );

  const getTexts = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const resNational = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idNationals}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (currentLng === "es") {
        setTitleNationals(
          resNational.data.textWeb.text_es.title["titulo de sección"]
        );
        setDataNationalsDB([
          resNational.data.textWeb.text_es["Gold"],
          resNational.data.textWeb.text_es["12+12"],
          resNational.data.textWeb.text_es["Mensajeria"],
          resNational.data.textWeb.text_es["Perecederos"],
          resNational.data.textWeb.text_es["Alto Valor"],
          resNational.data.textWeb.text_es["Alta Tecnología"],
          resNational.data.textWeb.text_es["Animales Vivos"],
          resNational.data.textWeb.text_es["Restos Humanos"],
          resNational.data.textWeb.text_es["Pharma XPS"],
          resNational.data.textWeb.text_es["Pharma STD"],
          resNational.data.textWeb.text_es["Valija"],
          resNational.data.textWeb.text_es["Mercancía Peligrosa"],
          resNational.data.textWeb.text_es["Servicios para la Industria"],
        ]);
      } else {
        setTitleNationals(
          resNational.data.textWeb.text_us.title["titulo de sección"]
        );
        setDataNationalsDB([
          resNational.data.textWeb.text_us["Gold"],
          resNational.data.textWeb.text_us["12+12"],
          resNational.data.textWeb.text_us["Mensajeria"],
          resNational.data.textWeb.text_us["Perecederos"],
          resNational.data.textWeb.text_us["Alto Valor"],
          resNational.data.textWeb.text_us["Alta Tecnología"],
          resNational.data.textWeb.text_us["Animales Vivos"],
          resNational.data.textWeb.text_us["Restos Humanos"],
          resNational.data.textWeb.text_us["Pharma XPS"],
          resNational.data.textWeb.text_us["Pharma STD"],
          resNational.data.textWeb.text_us["Valija"],
          resNational.data.textWeb.text_us["Mercancía Peligrosa"],
          resNational.data.textWeb.text_us["Servicios para la Industria"],
        ]);
      }
    } catch (error) {
      setDataNationalsDB([...dataNational, ...complemetDataNational]);
    }
  };

  useEffect(() => {
    getTexts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCards = () => {
    return dataNationalsDB.map((card, index) => (
      <div key={index} className="col-12 col-md-6 col-xl-4">
        <div className="nationalHomeContainer">
          <Card
            title={card?.titulo || ""}
            description={card?.texto || ""}
            icon={
              iconComponents[card?.icon || ""] &&
              React.createElement(iconComponents[card?.icon || ""])
            }
            redirect={card?.["texto-botón-linea"] || ""}
            redirectHref={card?.["link-botón-linea"] || ""}
            linkId={"linkNational" + index || ""}
          />
        </div>
      </div>
    ));
  };

  return (
    <>
      <Helmet>
        <title>{tSMetaData("nacionales.title")}</title>
        <meta
          name="description"
          content={tSMetaData("nacionales.description")}
        />
      </Helmet>
      <TitleSection title={titleNationals ?? tNational("title.title-page")} />
      <div style={{ marginTop: "20px" }} className="container">
        <div className="row">{dataNationalsDB.length > 0 && renderCards()}</div>
      </div>
    </>
  );
}

export default NationalsHome;
