import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { FaPrint, FaInfo  } from "react-icons/fa";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "./style.css";

function Cotizaciones() {

  const [cotizaciones, setCotizaciones] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [tSGetQuoteShippingInformation] = useTranslation(
    "getQuoteStepShipping"
  );
  
  const getCotizaciones = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/quotation`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res.data.code === 1) {
        setCotizaciones(res.data.quotations);
        setLoading(false);
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
      setLoading(true);
    }
  };
  
  const handleSearch = () => {
    const input = document.getElementById("searchInput");
    setSearchValue(input.value.trim());
  };
  
  const getCotizacion = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/quotation/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const quotateData = response.data.quotation;
      const html = `
      <div>
      <table>
        <table style="width: 60rem; margin-top: 12px; border-collapse: collapse;margin-left: 2rem">
          <thead>
            <tr>
              <th
                style="
                  background-color: #0b2343;
                  color: white;
                  font-size: 2rem;
                  font-weight: 500;
                  text-align: left;
                  height: 3rem;
                "
              >
                Aeroméxico Cargo.
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h2
                  style="
                    font-size: 2rem;
                    color: #0b2443;
                    font-weight: 600;
                    background-color: white;
                    height: 1rem;
                    margin-bottom: 1rem;"
                    text-align: left;
                >
                  <strong>Cotización</strong>
                </h2>
              </td>
            </tr>
            <tr>
                <td>
                  <h2
                    style="
                      font-size: 1.5rem;
                      color: #65758d;
                      font-weight: 500;
                      background-color: white;
                      margin-bottom: 0;
                      text-align: left;
                    "
                  >
                    <strong style="color: #212b36; font-weight: 600; text-align: left;">Fecha:</strong>${quotateData.date}
                  </h2>
                </td>
            </tr>
            <tr>
                <td>
                  <h2
                    style="
                      font-size: 2rem;
                      color: #0b2443;
                      font-weight: 600;
                      background-color: white;
                      height: 1rem;
                      margin-bottom: 3rem;
                      text-align: left;">
                    <strong>Información del Embarque.</strong>
                  </h2>
                </td>
            </tr>
            <!-- tabla de informacion de embarque -->
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; margin-bottom: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600; text-align: left;">Producto:</strong> </br>
                ${quotateData.service}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #ffffff; color: #65758d; margin-top: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600; text-align: left;">Pagado en: </strong> </br>
                ${quotateData.payment}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; margin-bottom: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Origen:</strong> </br>
                ${quotateData.origin}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #ffffff; color: #65758d; margin-top: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Destino: </strong> </br>
                ${quotateData.destination}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; margin-bottom: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Pieza:</strong> </br>
                ${quotateData.pieces}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #ffffff; color: #65758d; margin-top: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Peso: </strong> </br>
                ${quotateData.weight}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Peso cobrable:</strong> </br>
                ${quotateData.chargeable_weight}
                </td>
            </tr>
            
            <!-- informacion Adicional -->
            
            <tr>
                <td>
                  <h2
                    style="
                      font-size: 2rem;
                      color: #0b2443;
                      font-weight: 600;
                      background-color: white;
                      height: 1rem;
                      margin-bottom: 3rem;
                      text-align: left;">
                    <strong>Información adicional.</strong>
                  </h2>
                </td>
            </tr>
            
            <!-- tabla de informacion Adicional -->
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; margin-bottom: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Descripción:</strong> </br>
                ${quotateData.description}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #ffffff; color: #65758d; margin-top: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Valor Declarado:</strong> </br>
                ${quotateData.declarate_value}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; margin-bottom: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Entrega En:</strong> </br>
                ${quotateData.delivery}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #ffffff; color: #65758d; margin-top: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Otros Cargos (DDLV):</strong> </br>
                ${quotateData.other_charges}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; margin-bottom: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Cargo por acarreo (PU):</strong> </br>
                ${quotateData.acarreo}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #ffffff; color: #65758d; margin-top: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Operación Terrestre (MC): </strong> </br>
                ${quotateData.groundOperation}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Cargo por Certifcación (FB): </strong> </br>
                ${quotateData.certificationFB}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #ffffff; color: #65758d; margin-top: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Pago al cobro (CC): </strong> </br>
                ${quotateData.pagoCC}
                </td>
            </tr>
            
            <!-- Desglosé de costo. -->
            
            <tr>
                <td>
                  <h2
                    style="
                      font-size: 2rem;
                      color: #0b2443;
                      font-weight: 600;
                      background-color: white;
                      height: 1rem;
                      margin-bottom: 3rem; text-align: left;">
                    <strong>Desglosé de costo.</strong>
                  </h2>
                </td>
            </tr>
            
            <!-- tabla de informacion Adicional -->
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; margin-bottom: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Flete: </strong> </br>
                ${quotateData.freight}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #ffffff; color: #65758d; margin-top: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Cargo Fijo (AW):</strong> </br>
                ${quotateData.FixedCharge}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; margin-bottom: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Valor Declarado (VA): </strong> </br>
                ${quotateData.valuation}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #ffffff; color: #65758d; margin-top: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">C. Combustible (MY): </strong> </br>
                ${quotateData.fuel}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; margin-bottom: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Subtotal:</strong> </br>
                ${quotateData.subTotal}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #ffffff; color: #65758d; margin-top: 0; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">IVA:</strong> </br>
                ${quotateData.iva}
                </td>
            </tr>
            
            <tr>
                <td style="border: 2px solid #dfe3e8; font-size: 20px; font-weight: 600; background-color: #f4f6f8; color: #65758d; padding: 10px; text-align: left;">
                <strong style="color: #232b36; font-weight: 600;">Total: </strong> </br>
                ${quotateData.total}
                </td>
            </tr>
          </tbody>
          
          <tfoot>
            <!-- Info final -->
            
            <tr>
                <td>
                  <h2
                    style="
                      font-size: 2rem;
                      color: #0b2443;
                      font-weight: bold;
                      background-color: white;
                      height: 1rem;
                      margin-bottom: 3rem;
                      text-align: left;">
                    Para mayor información consulte a nuestros ejecutivos en los teléfonos </br>
                    LADA SIN COSTO 800 ENVIA AM o al Tel. (55)5133 0237
                  </h2>
                  
                  <p style="color: #637488; margin-top: 8rem; font-size: 20px; text-align: left;">
                    Este Documento Es Una Estimación Conforme Los Datos Ingresados En El Formulario, El Cálculo Que Se Presenta Está En
                    Función De Nuestra Tarifa De Fletes Y Precios De Zona. La Cotización Puede Variar En Función De Las Medidas, Peso Y Manejo
                    Especial Que Pueda Requerir Su Embarque Al Momento De Documentarse. No Incluye, Servicios Adicionales.
                    Esta Cotización Es Presentada Sólo Con Fines Informativos; No Constituye Un Documento De Obligatoriedad Legal.
                  </p>
                  
                  <h4 style="color: #212f5c; text-align: left;">
                  <strong>* Precios sujetos a cambio sin previo aviso.</strong>
                  </h4>
                  
                  <h4 style="color: #637488; text-align: left;">
                    © Copyright 2023 | Todos los derechos reservados, Aeroméxico Cargo.
                    </h4>
                </td>
            </tr>
          </tfoot>
        </table>
      </table>
    </div>
      `;
  
      await Swal.fire({
        html,
        showCancelButton: false,
        confirmButtonText: "Cerrar",
        customClass: {
          popup: "cotizacionPopup",
        },
      });
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
    }
  };
  
  const filteredContizaciones = searchValue
  ? cotizaciones.filter(
      (cotizacion) =>
      cotizacion.number_quotation
          .toUpperCase()
          .includes(searchValue.toUpperCase())
    )
  : cotizaciones
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const generarPDF = async (id) => {
    try {
      
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/quotation/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const quotateData = response.data.quotation;
    
    
      const content = [
        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: "Aeroméxico Cargo.",
                  style: "header",
                },
              ],
            ],
          },
        },
        {
          text: `${tSGetQuoteShippingInformation("title")}`,
          style: "subheader",
        },
        {
          text: [{ text: "Fecha: ", style: "label" }, `${quotateData.date}`],
        },
        {
          text: `${tSGetQuoteShippingInformation("numberQuote")}: ${quotateData.number_quotation}`,
          style: "label",
        },
        {
          text: `${tSGetQuoteShippingInformation("subtitile")}`,
          style: "subheader",
        },
        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("product")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.service}`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("paidAt")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.payment}`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("origin")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.origin}`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("destination")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.destination}`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("piece")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.pieces}`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("weight")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.weight}`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "chargeableWeight"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.chargeable_weight}`,
                  ],
                  style: "cell1",
                },
              ],
            ],
          },
        },
        {
          text: "Información adicional.",
          style: "subheader",
        },
        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("description")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.description}`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("declaredValue")}: `,
                      style: "labelBold",
                    },
                    `\n $${quotateData.declarate_value} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("deliveryAt")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.delivery}`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("otherCharges")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.other_charges} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "carriageCharge"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.acarreo} MXN`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "groundOperation"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.groundOperation} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "certificationFee"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.certificationFB} MXN`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "payOnCollection"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.pagoCC} MXN`,
                  ],
                  style: "cell2",
                },
              ],
            ],
          },
        },
        {
          text: "Desglose de costo.",
          style: "subheader",
        },
        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("Freight")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.freight} MXN`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("fixedCharge")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.FixedCharge} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "declaredValue"
                      )} (VA): `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.valuation} MXN`,
                  ],
                  style: "cell1",
                },
              ],
  
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("fuelCharge")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.fuel} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("subtotal")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.subTotal} MXN`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("vat")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.iva} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("total")}: `,
                      style: "labelBold",
                    },
                    `\n ${quotateData.total} MXN`,
                  ],
                  style: "cell1",
                },
              ],
            ],
          },
        },
        {
          text: [
            "Para mayor información consulte a nuestros ejecutivos en los teléfonos LADA SIN COSTO 800 ENVIA AM o al Tel. (55)5133 0237",
            {
              text: "\n\nEste Documento Es Una Estimación Conforme Los Datos Ingresados En El Formulario, El Cálculo Que Se Presenta Está En Función De Nuestra Tarifa De Fletes Y Precios De Zona. La Cotización Puede Variar En Función De Las Medidas, Peso Y Manejo Especial Que Pueda Requerir Su Embarque Al Momento De Documentarse. No Incluye, Servicios Adicionales. Esta Cotización Es Presentada Sólo Con Fines Informativos; No Constituye Un Documento De Obligatoriedad Legal.",
              style: "footerText1",
            },
            {
              text: "\n\n* Precios sujetos a cambio sin previo aviso.",
              style: "footerText2",
            },
            {
              text: "\n\n© Copyright 2023 | Todos los derechos reservados, Aeroméxico Cargo.",
              style: "footerText3",
            },
          ],
          style: "footer",
        },
      ];
  
      const styles = {
        header: {
          fontSize: 20,
          color: "white",
          fillColor: "#0b2343",
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 18,
          color: "#0b2343",
          bold: true,
          margin: [0, 30, 40, 15],
        },
        label: {
          fontSize: 16,
          color: "#212b36",
          bold: true,
        },
        label2: {
          fontSize: 16,
          color: "#212b36",
          bold: true,
          margin: [0, 30, 40, 15],
        },
        cell1: {
          fontSize: 12,
          color: "#65758d",
          bold: true,
          border: [2, 2, 2, 2],
          fillColor: "#f4f6f8",
          padding: 10,
          margin: [3, 0, 0, 2],
        },
        cell2: {
          fontSize: 12,
          color: "#65758d",
          bold: true,
          border: [2, 2, 2, 2],
          fillColor: "#ffffff",
          padding: 10,
          margin: [3, 0, 0, 2],
        },
        footer: {
          bold: true,
          fontSize: 13,
          color: "#0b2343",
          margin: [0, 60, 0, 0],
        },
        footerText1: {
          fontSize: 10,
          color: "#637488",
        },
        footerText2: {
          fontSize: 10,
          color: "#212b36",
          bold: true,
        },
        footerText3: {
          fontSize: 10,
          color: "#0b2343",
          bold: true,
        },
        labelBold: {
          bold: true,
          fontSize: 14,
          color: "#212b36",
        },
      };
  
      const documentDefinition = {
        content: content,
        styles: styles,
      };
  
      pdfMake.createPdf(documentDefinition).open();
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
    }
  };
  
  
  useEffect(() => {
    getCotizaciones();
  }, [updateTrigger]);
  
  return (
    <>
    <div className="panel-table">
    <div className='colum-left'>
      <input type="text" className='searchInput' id="searchInput" placeholder="Buscar..." />
      <button className='btnn' onClick={handleSearch}>Buscar</button>
    </div>
    <div className='colum-rigth'>
    </div>
    </div>
      <div className="table-responsive">
        <table className="table table-preve" id="tableToSearch">
          <thead>
            <tr>
              <th>Numero de cotizacion</th>
              <th>Ruta</th>
              <td className="accions">Acciones</td>
            </tr>
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <td>Cargando...</td>
              </tr>
            ) : (
              filteredContizaciones.map((cotizacion) => (
                <tr key={cotizacion.number_quotation} data-master-id={cotizacion.number_quotation}>
                  <td>{cotizacion.number_quotation}</td>
                  <td>{cotizacion.origin}-{cotizacion.destination}</td>
                  <td>
                    <FaInfo
                      className="detallesButton"
                      onClick={() => {getCotizacion(cotizacion.number_quotation)}}
                    />
                    <FaPrint
                      className="printButton"
                      onClick={() => {generarPDF(cotizacion.number_quotation)}}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Cotizaciones