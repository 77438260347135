const toolbarText = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  
    ['clean']      
];

const handleImageUrl = function(value) {
    if (value) {
      const url = prompt('Ingrese la URL de la imagen:');
      if (url) {
        this.quill.format('image', url);
      }
    } else {
      this.quill.format('image', false);
    }
};

// Asignamos el manejador personalizado al botón de 'image-url'
toolbarText.handlers = {
  'image-url': handleImageUrl,
};

export default toolbarText;