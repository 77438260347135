
import ScrollToTop from "../../../../components/scrollToTop/scrollToTop";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Navbar from "../../../../components/navbar/navbar";
import TrackForm from "../track-form/track-form";
import Footer from "../../../../components/footer/Footer";
import TrackBtnContact from "../../../../components/trackBtnContact/trackBtnContact";
import TitleSectionPink from "../../../../components/title-section-pink/title-section-pink";

function FormPage() {
    const [tTrack] = useTranslation("trackPage");
    const [tSMetaData] = useTranslation("metaData");

    return (
        <>
            <Helmet>
                <title>{tSMetaData("rastreaGuía.title")}</title>
                <meta
                name="description"
                content={tSMetaData("rastreaGuía.description")}
                />
            </Helmet>
            <ScrollToTop />
            <Navbar />
            <TitleSectionPink title={tTrack("title.title")} hiddeSecondarySection={600} />

            <div className="trackBtnContact">
                <TrackForm />

                <div className="btnContactFormTrack container">
                    <TrackBtnContact x={-390} y={-370} />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default FormPage;