import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import ImageHeader from "../../../../components/image-header/image-header";
import pouchImg from "../../../../assets/imgs/services/nationals/pouch.jpg";
import Accordion from "../../../../components/acordion/accordion";
import { useEffect, useState } from "react";
import axios from "axios";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";

function Pouch() {
  const [tPouch] = useTranslation("pouchPage");
  const [tSMetaData] = useTranslation("metaData");
  const idPouch = "2128300e-9022-4966-907f-e50f0fad2942";
  const [textDescription, setTextDescription] = useState("");
  const [accordion1, setAccordion1] = useState("");
  const [accordion2, setAccordion2] = useState("");

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].services}/${
      Object.keys(navBarEs["products-submenu"])[0]
    }/${Object.keys(navBarEs["products-nacionales-submenu"])[10]}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].services}/${
      Object.keys(navBarEn["products-submenu"])[0]
    }/${Object.keys(navBarEn["products-nationals-submenu"])[10]}`
  );

  useEffect(() => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    axios
      .get(`${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idPouch}`, {
        headers: {
          Authorization: process.env.REACT_APP_PUBLIC_KEY,
        },
      })
      .then((response) => {
        if (currentLng === "es") {
          setTextDescription(
            response.data.textWeb.text_es["01-descripción"].content
          );
          setAccordion1(
            response.data.textWeb.text_es["02-Restricciones y Regulaciones"]
              .content
          );
          setAccordion2(
            response.data.textWeb.text_es[
              "03-Documentación, Aceptación y Recolección"
            ].content
          );
        } else {
          setTextDescription(
            response.data.textWeb.text_us["01-description"].content
          );
          setAccordion1(
            response.data.textWeb.text_us["02-Restrictions and Regulations"]
              .content
          );
          setAccordion2(
            response.data.textWeb.text_us[
              "03-Documentation, Acceptance and Delivery"
            ].content
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{tSMetaData("valija.title")}</title>
        <meta name="description" content={tSMetaData("valija.description")} />
      </Helmet>
      <ImageHeader
        section={tPouch("header.section")}
        title={tPouch("header.title")}
        description={
          textDescription
            ? JSON.parse(textDescription)
            : JSON.parse(tPouch("description.content"))
        }
        contactSales={tPouch("header.contact-sales")}
        contactProductID="btnContactPouch"
        imgBackground={pouchImg}
      />
      <Accordion
        title={tPouch("accordion-1.title")}
        content={
          accordion1
            ? JSON.parse(accordion1)
            : JSON.parse(tPouch("accordion-1.content"))
        }
      />
      <div className="divider"></div>
      <Accordion
        title={tPouch("accordion-2.title")}
        content={
          accordion2
            ? JSON.parse(accordion2)
            : JSON.parse(tPouch("accordion-2.content"))
        }
      />
    </>
  );
}

export default Pouch;
