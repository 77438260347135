import React, { useEffect, useState } from "react";
import { FaEdit, FaTimes, FaChevronDown } from "react-icons/fa";
import { FaXmark, FaArrowRotateRight } from "react-icons/fa6";
import Swal from "sweetalert2";
import axios from "axios";
import "./style.css";
import mexIcon from '../../../../assets/imgs/navbar/mex_svg.svg'
import usaIcon from '../../../../assets/imgs/navbar/usa_svg.svg';
import { useForm } from "react-hook-form";
import CreateQuillComponent from "./createQuillComponent";
import ViewQuillComponent from "./viewQuillComponent";

function Stations() {
  const [stationsEs, setStationsEs] = useState([]);
  const [stationsUs, setStationsUs] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [loadingEs, setLoadingEs] = useState(true);
  const [loadingUs, setLoadingUs] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueRestore, setSearchValueRestore] = useState("");
  const  [toggleAccEs, setToggleAccEs] = useState(false);
  const  [toggleAccUs, setToggleAccUs] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const { handleSubmit, register, reset, setValue, formState: {errors, isValid} } = useForm();
  const [schedule, setSchedule] = useState("{}");
  const [validBtn, setValidBtn] = useState(false);
  const [editQuillCreate, setEditQuillCreate] = useState(true);
  const [restoreStation, setRestoreStation] = useState([]);
  const [currentStation, setCurrentStation] = useState({
                                                        "code": "",
                                                        "code_text": "",
                                                        "add_postal_code": 0,
                                                        "schedule": "{\"ops\":[{\"insert\":\"\\n\"}]}",
                                                        "lng": "es",
                                                        "maps": "",
                                                        "marker": "no-option",
                                                        "add_quote": 0,
                                                        "address": "",
                                                        "phone": "",
                                                        "name": ""
                                                      });

  const filteredStationsEs = searchValue
    ? stationsEs.filter((station) =>
        station.name.toUpperCase().includes(searchValue.toUpperCase())
      )
    : stationsEs;

  const filteredStationsUs = searchValue
    ? stationsUs.filter((station) =>
        station.name.toUpperCase().includes(searchValue.toUpperCase())
      )
    : stationsUs;

  const filteredRestore = searchValueRestore ?
    restoreStation.filter((station) => 
      station.name.toUpperCase().includes(searchValueRestore.toUpperCase()) || station.code_text.toUpperCase().includes(searchValueRestore.toUpperCase())
    ) : restoreStation;

  useEffect(() => {
    getStations();
  }, [updateTrigger]);

  useEffect(() => {
    if(isValid && !editQuillCreate) {
      setValidBtn(true);
    } else {
      setValidBtn(false);
    }
  }, [isValid, editQuillCreate])

  React.useEffect(() => {
    Object.keys(currentStation).forEach(key => {
      setValue(key, currentStation[key]);
    });
  }, [currentStation, setValue]);

  const toggleStations = (lng) => {
    if(lng === 'es') {
      setToggleAccEs(!toggleAccEs);
      setToggleAccUs(false);
    } else {
      setToggleAccUs(!toggleAccUs);
      setToggleAccEs(false);
    }
  }

  const handleSearch = () => {
    const input = document.getElementById("searchInput");
    setSearchValue(input.value.trim());
  };

  const handlefilteredRestore = (event) => {
    setSearchValueRestore(event.target.value.trim());
  }

  const getStations = async () => {
    try {
      const resEs = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations?lng=es`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );
      const resUs = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations?lng=us`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );
      const dataEs = resEs.data.stations;
      const dataUs = resUs.data.stations;
      setStationsEs(dataEs);
      setStationsUs(dataUs);
      setLoadingEs(false);
      setLoadingUs(false);
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
      setLoadingEs(true);
      setLoadingUs(true);
    }
  };

  const statusModal = (status, id_code) => {
    if(status === "create") {
      setCreateModal(true);
      setEditQuillCreate(true);
      setSchedule("{}");
    } else if (status === "update"){
      setUpdateModal(true);
      setEditQuillCreate(false);
      getCurrentStation(id_code);
    } else if(status === "restore") {
      setRestoreModal(true);
      getRestoreList();
      setSearchValueRestore("")
    } else {
      setCreateModal(false);
      setUpdateModal(false);
      setRestoreModal(false);
      reset();
    }
  }

  const getRestoreList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/stations/delete-list`, 
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setRestoreStation(response.data.message);
    } catch(error) {
      console.error(error);
    }
  }

  const getCurrentStation = async (idCode) => {
    try{
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations/${idCode}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setCurrentStation(response.data.station);
      setSchedule(response.data.station.schedule);

    } catch(error) {
      console.error(error);
      Swal.fire(
        "Error",
        "Error al cargar los datos de la estación para actualizar"
      );
    }
  } 

  const createStation = async (data) => {
    if(!validBtn) {
      Swal.fire("Nota", "por favor guarda el horario para continuar.", "warning")
      return;
    }

    try {
      if(data['marker'] === 'no-option' || !data['marker']) {
        Swal.fire("Nota", "por favor selecciona un marker.", "warning")
        return
      }
      data['schedule'] = schedule;
      data.code_text = data.code_text.toUpperCase();
      data.add_postal_code = Number(data.add_postal_code);
      data.add_quote = Number(data.add_quote);

      const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/stations/create`, data, 
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        }
      )

      if (response.data.code === 1) {
        Swal.fire("¡Creado!", "creado exitosamente.", "success");
        setUpdateTrigger(!updateTrigger);
      } else {
        Swal.fire("Error","Hubo un problema al crear la estación, por favor, intentalo más tarde", "error");
      }
      statusModal();

    } catch(error) {
      console.error(error);
      let errorMessage = "No se ha creado ningúna estación."
      if(error.response.data.error) {
        errorMessage = error.response.data.error
      }
      Swal.fire(
        "Error al crear el Estación",
        errorMessage
      );
      // statusModal();
    }
  }

  const updateStation = async (data) => {
    
    try {
      data['schedule'] = schedule;
      data.code_text = currentStation.code_text;
      data.marker = currentStation.marker;
      data.lng = currentStation.lng;
      data.add_postal_code = Number(data.add_postal_code);
      data.add_quote = Number(data.add_quote);
      const code = currentStation.code;

      const response = await axios.put(`${process.env.REACT_APP_URL_BACKEND}/api/stations/update/${code}`, data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        }
      );

      if (response.data.code === 1) {
        Swal.fire("¡Exitoso!", "Editado exitosamente.", "success");
        setUpdateTrigger(!updateTrigger);
      }
      statusModal();
    } catch(error) {
      console.error(error);
      Swal.fire(
        "Error al editar el Estacion",
        "No se ha editado ninguna estación.",
        "error"
      );
      statusModal();
    }
  }

  const deleteStation = async (code) => {
    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "esta estacion sera eliminada",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_URL_BACKEND}/api/stations/${code}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        Swal.fire("¡Eliminado!", "Eliminado Exitosamente.", "success");
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "No se realizo ninguna accion)", "error");
      }
      statusModal();
    } catch (error) {
      Swal.fire("Error", "Algo salió mal");
    }
  };

  const deleteRestore = async (code) => {
    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "esta estacion sera eliminada de manera permanente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_URL_BACKEND}/api/stations/delete-trash/${code}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        Swal.fire("¡Eliminado!", "Eliminado Exitosamente.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "No se realizo ninguna accion)", "error");
      }
      statusModal();
    } catch (error) {
      Swal.fire("Error", "Algo salió mal");
    }
  }

  const restorageStation = async (code) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/stations/restore/${code}`, {}, 
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      Swal.fire("¡Restaurado!", "Estacion restaurada exitosamente.", "success");
      setUpdateTrigger(!updateTrigger);
      statusModal();
    } catch (error) {
      Swal.fire("Error", "Algo salió mal por favor intentalo mas tarde");
    }
  }

  const quillDataSchedule = (data) => {
    setSchedule(data);
    setEditQuillCreate(false);
  }

  return (
    <>
      <div className="panel-table">
        <div className="colum-left">
          <input
            type="text"
            className="searchInput"
            id="searchInput"
            placeholder="Buscar..."
          />
          <button className="btnn" onClick={handleSearch}>
            Buscar
          </button>
        </div>
        <div className="colum-rigth">
          <button className="btnn" onClick={() => statusModal('restore')}>
            Restaurar Estacion
          </button>
          <button className="btnn" onClick={() => statusModal('create')}>
            Crear Estacion
          </button>
        </div>
      </div>


      {/* ====================================================
      ESPAÑOL
      ==================================================== */}
      <div className={`national-stations ${toggleAccEs ? "down": "up"}`}>
        <div className="headerAccordion" onClick={() => toggleStations("es")}>
          <p>Estaciones nacionales Esp</p>
          <div className="actionBtns">
            <img className="" src={mexIcon} alt='mexico icon' />
            <div className={`icon-arrow ${!toggleAccEs ? "down": "up"}`}><FaChevronDown /></div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-preve" id="tableToSearch">
            <thead>
              <tr>
                <th>Name</th>
                <th>Cotizador</th>
                <th>Domicilio</th>
                <td className="accions">Acciones</td>
              </tr>
            </thead>

            <tbody>
              {loadingEs ? (
                <tr>
                  <td>Cargando...</td>
                </tr>
              ) : (
                filteredStationsEs.map((station) => (
                  <tr key={station.code} data-master-id={station.code}>
                    <td>{station.name}</td>
                    <td
                      style={
                        station.add_quote === 1
                          ? { color: "green", fontSize: "20px" }
                          : { color: "red", fontSize: "20px" }
                      }
                    >
                      {station.add_quote === 1 ? "✔" : "❌"}
                    </td>
                    <td
                      style={
                        station.add_postal_code === 1
                          ? { color: "green", fontSize: "20px" }
                          : { color: "red", fontSize: "20px" }
                      }
                    >
                      {station.add_postal_code === 1 ? "✔" : "❌"}
                    </td>
                    <td>
                      <FaEdit
                        className="editButton"
                        onClick={() => statusModal('update', station.code)}
                      />
                      
                      <FaTimes
                        className="deleteButton"
                        onClick={() => {
                          deleteStation(station.code);
                        }}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* ====================================================
      INGLES
      ==================================================== */}
      <div className={`national-stations ${toggleAccUs ? "down": "up"}`}>
        <div className="headerAccordion" onClick={() => toggleStations("us")}>
          <p>Estaciones nacionales Ingles</p>
          <div className="actionBtns">
            <img className="" src={usaIcon} alt='mexico icon' />
            <div className={`icon-arrow ${!toggleAccUs ? "down": "up"}`}><FaChevronDown /></div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-preve" id="tableToSearch">
            <thead>
              <tr>
                <th>Name</th>
                <th>Cotizador</th>
                <th>Domicilio</th>
                <td className="accions">Acciones</td>
              </tr>
            </thead>

            <tbody>
              {loadingUs ? (
                <tr>
                  <td>Cargando...</td>
                </tr>
              ) : (
                filteredStationsUs.map((station) => (
                  <tr key={station.code} data-master-id={station.code}>
                    <td>{station.name}</td>
                    <td
                      style={
                        station.add_quote === 1
                          ? { color: "green", fontSize: "20px" }
                          : { color: "red", fontSize: "20px" }
                      }
                    >
                      {station.add_quote === 1 ? "✔" : "❌"}
                    </td>
                    <td
                      style={
                        station.add_postal_code === 1
                          ? { color: "green", fontSize: "20px" }
                          : { color: "red", fontSize: "20px" }
                      }
                    >
                      {station.add_postal_code === 1 ? "✔" : "❌"}
                    </td>
                    <td>
                      <FaEdit
                        className="editButton"
                        onClick={() => statusModal('update', station.code)}
                      />
                      
                      <FaTimes
                        className="deleteButton"
                        onClick={() => {
                          deleteStation(station.code);
                        }}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* =====================================================
      Create Station Modal
      ===================================================== */}
      {createModal && (
        <div className="station-modal">
          <div className="bg-modal-station" onClick={statusModal}></div>

          <section>
            <div className="header-station">
              <div></div>
              <h4>Crear Estacion</h4>
              <div className="close-modal" onClick={statusModal} style={{fontSize: "1.5rem", cursor: "pointer"}}>
                <FaXmark />
              </div>
            </div>

            <div className="body-station">
                <form onSubmit={handleSubmit(createStation)}>
                  <div className="input-container-stations">
                    <label>Nombre:</label>
                    <input id="name" type="text" placeholder="" {...register("name", {required: "true"})} autoComplete="off" />
                  </div>

                  <div className="input-container-stations">
                    <label>Telefono:</label>
                    <input id="phone" type="text" placeholder="" {...register("phone", {required: true})} autoComplete="off"/>
                  </div>

                  <div className="input-container-stations">
                    <label>Direccion:</label>
                    <input id="address" type="text" placeholder="" {...register("address", {required: true})} autoComplete="off"/>
                  </div>

                  <div className="input-container-stations">
                    <label>mapa:</label>
                    <input id="maps" type="text" placeholder="" {...register("maps")} autoComplete="off"/>
                  </div>

                  <div className="input-container-stations">
                    <label>marker:</label>
                    <select id="marker" {...register("marker", {required: true})} style={{width: "100%"}} >
                      <option value={"no-option"}>Selecciona una opcion</option>
                      <option value={"Aguascalientes"}>Aguascalientes</option>
                      <option value={"sonora"}>sonora</option>
                      <option value={"chihuahua"}>chihuahua</option>
                      <option value={"campeche"}>campeche</option>
                      <option value={"sinaloa"}>sinaloa</option>
                      <option value={"quintana-roo"}>quintana-roo</option>
                      <option value={"durango"}>durango</option>
                      <option value={"jalisco"}>jalisco</option>
                      <option value={"baja-california-sur"}>baja-california-sur</option>
                      <option value={"estado-de-mexico"}>estado-de-mexico</option>
                      <option value={"yucatan"}>yucatan</option>
                      <option value={"veracruz"}>veracruz</option>
                      <option value={"nuevo-leon"}>nuevo-leon</option>
                      <option value={"baja-california"}>baja-california</option>
                      <option value={"Oaxaca"}>Oaxaca</option>
                      <option value={"Puebla"}>Puebla</option>
                      <option value={"queretaro"}>queretaro</option>
                      <option value={"tamaulipas"}>tamaulipas</option>
                      <option value={"guanajuato"}>guanajuato</option>
                      <option value={"san-luis-potosi"}>san-luis-potosi</option>
                      <option value={"chiapas"}>chiapas</option>
                      <option value={"coahuila"}>coahuila</option>
                      <option value={"tabasco"}>tabasco</option>
                      <option value={"guerrero"}>guerrero</option>
                    </select>
                  </div>

                  <div className="input-container-stations">
                    <label>Codigo:</label>
                    <input style={{textTransform:"uppercase"}} id="code_text" type="text" placeholder="" {...register("code_text", {required: true, minLength: 3, maxLength: 4})} autoComplete="off"/>
                  </div>

                  <div className="selects-container">
                    <div className="select-input">
                      <label>cotizador:</label>
                      <select id="add_quote" {...register("add_quote", {required: true})} >
                        <option value={0}>No</option>
                        <option value={1}>Si</option>
                      </select>
                    </div>

                    <div className="select-input">  
                      <label>codigo postal:</label>
                      <select id="add_postal_code" {...register("add_postal_code", {required: true})}>
                        <option value={0}>No</option>
                        <option value={1}>Si</option>
                      </select>
                    </div>
                  </div>

                  <div className="input-container-stations">
                    <label>Lenguaje:</label>
                    <select id="lng" {...register("lng", {required: true})} >
                      <option value={"es"}>Español</option>
                      <option value={"us"}>Ingles</option>
                    </select>
                  </div>

                  <div className="input-container-stations">
                    {editQuillCreate ? 
                      <CreateQuillComponent text={JSON.parse(schedule)} onQuillData={quillDataSchedule} />
                    : 
                      <div style={{display: "flex", flexDirection:"column"}}>
                        <label>Horario:</label>
                        <ViewQuillComponent content={JSON.parse(schedule)} type={"json"} />
                        <button type="button" onClick={() => {setEditQuillCreate(true)}}>Editar</button>
                      </div>
                    }
                  </div>
                  
                  <div className="btn-container-stations">
                    <button type="submit" style={{ cursor: `${validBtn ? "pointer" : "auto"}` }} className={`${validBtn ? "validClass" : "invalidClass"}`}>Enviar</button>
                    <button type="button" className="closeModal" onClick={statusModal}>Cerrar</button>
                  </div>

                </form>
            </div>
          </section>
        </div>
      )}


      {/* =====================================================
      UPDATE Station Modal
      ===================================================== */}
      {updateModal && (
        <div className="station-modal">
          <div className="bg-modal-station" onClick={statusModal}></div>

          <section>
            <div className="header-station">
              <div></div>
              <h4>Actualizar Estacion</h4>
              <div className="close-modal" onClick={statusModal} style={{fontSize: "1.5rem", cursor: "pointer"}}>
                <FaXmark />
              </div>
            </div>

            <div className="body-station">
                <form onSubmit={handleSubmit(updateStation)}>

                  <div className="input-container-stations">
                    <label>Nombre:</label>
                    <input id="name" type="text" placeholder="" {...register("name", {required: true})} autoComplete="off" defaultValue={currentStation.name} />
                  </div>

                  <div className="input-container-stations">
                    <label>Telefono:</label>
                    <input id="phone" type="text" placeholder="" {...register("phone", {required: true})} autoComplete="off" defaultValue={currentStation.phone} />
                  </div>

                  <div className="input-container-stations">
                    <label>Direccion:</label>
                    <input id="address" type="text" placeholder="" {...register("address", {required: true})} autoComplete="off" defaultValue={currentStation.address}/>
                  </div>

                  <div className="input-container-stations">
                    <label>mapa:</label>
                    <input id="maps" type="text" placeholder="" {...register("maps")} autoComplete="off" defaultValue={currentStation.maps} />
                  </div>

                  <div className="input-container-stations">
                    <label>marker:</label>
                    <input id="marker" type="text" placeholder="" {...register("marker")} autoComplete="off" defaultValue={currentStation.marker} disabled />
                  </div>

                  <div className="input-container-stations">
                    <label>Codigo:</label>
                    <input style={{textTransform:"uppercase"}} id="code_text" type="text" placeholder="" {...register("code_text")} autoComplete="off" defaultValue={currentStation.code_text} disabled />
                  </div>

                  <div className="selects-container">
                    <div className="select-input">
                      <label>cotizador:</label>
                      <select id="add_quote" {...register("add_quote", {required: true})} >
                        <option value={0} selected={currentStation.add_quote === 0}>No</option>
                        <option value={1} selected={currentStation.add_quote === 1}>Si</option>
                      </select>
                    </div>

                    <div className="select-input">  
                      <label>codigo postal:</label>
                      <select id="add_postal_code" {...register("add_postal_code", {required: true})}>
                        <option value={0} selected={currentStation.add_quote === 0}>No</option>
                        <option value={1} selected={currentStation.add_quote === 1}>Si</option>
                      </select>
                    </div>
                  </div>

                  <div className="input-container-stations">
                    <label>Lenguaje:</label>
                    <select id="lng" {...register("lng")} disabled>
                      <option value={"es"} selected={currentStation.lng === "es"}>Español</option>
                      <option value={"us"} selected={currentStation.lng === "us"}>Ingles</option>
                    </select>
                  </div>

                  <div className="input-container-stations">
                    {editQuillCreate ? 
                      <CreateQuillComponent text={JSON.parse(schedule)} onQuillData={quillDataSchedule} />
                    : 
                      <div style={{display: "flex", flexDirection:"column"}}>
                        <label>Horario:</label>
                        <ViewQuillComponent content={JSON.parse(schedule)} type={"json"} />
                        <button type="button" onClick={() => {setEditQuillCreate(true)}}>Editar</button>
                      </div>
                    }
                  </div>
                  
                  <div className="btn-container-stations">
                    <button type="submit" style={{ cursor: `${validBtn ? "pointer" : "auto"}` }} className={`${validBtn ? "validClass" : "invalidClass"}`}>Enviar</button>
                    <button type="button" className="closeModal" onClick={statusModal}>Cerrar</button>
                  </div>

                </form>
            </div>
          </section>
        </div>
      )}

      {/* =====================================================
      RESTORE Stations Modal
      ===================================================== */}
      {restoreModal && (
        <div className="station-modal">
          <div className="bg-modal-station" onClick={statusModal}></div>

          <section>
            <div className="header-station">
              <div></div>
              <h4>Restaurar estacion</h4>
              <div className="close-modal" onClick={statusModal} style={{fontSize: "1.5rem", cursor: "pointer"}}>
                <FaXmark />
              </div>
            </div>

            <div className="body-station">
                <div className="searchStationInputContainer">
                  <input type="text" placeholder="buscar" onChange={handlefilteredRestore} />
                </div>
                <div className="stationsRestoreContainer" style={{width: "100%"}}>

                  {filteredRestore.length !== 0 ? (
                    <table className="table table-preve" id="tableRestore">
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Idioma</th>
                          <th>Codigo</th>
                          <td className="accions">Acciones</td>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredRestore.map((station) => (
                          <tr>
                            <td>{station.name}</td>
                            <td className="lngStationRestore">
                              <p>{station.lng}</p>
                              {station.lng === "es" ? (<img src={mexIcon} />) : (<img src={usaIcon} />)}
                            </td>
                            <td>{station.code_text}</td>
                            <td>
                              <div className="buttonsRestore">
                                <FaArrowRotateRight
                                  className="editButton"
                                  onClick={() => restorageStation(station.code)}
                                />
                                <FaTimes
                                  className="deleteButton"
                                  onClick={() => deleteRestore(station.code)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ): (<>
                    <p style={{width: "100%", padding: "20px 0", fontSize: "1.2rem", textAlign: "center", fontWeight: "bold"}}>No hay estaciones por restaurar</p>
                  </>)}

                </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Stations;
