import { Link } from "react-router-dom";
import "./btnRightSubmenu.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function BtnRightSubmenu({ category, subCategoryKey, submenuRight }) {
    const [showMenu, setShowMenu] = useState(false);
    const [top, setTop] = useState(0);
    const [tNavbar] = useTranslation("navbar");
    const [subMenuData, setSubMenuData] = useState(undefined);
    
    const handleShowMenu = (key) => {
        if(typeof(tNavbar(`${key}-submenu`, {returnObjects: true})) !== 'string') {
            setShowMenu(true);
            setSubMenuData(tNavbar(`${key}-submenu`, {returnObjects: true}));
            setTop(66);
        }
    }

    const handleHiddenMenu = () => {
        setShowMenu(false);
    }

    const handleShowMenuRight = () => {
        setShowMenu(true);
    }
    
    return (
        <>
        <ul className="menuRightContainer">
            {Object.keys(submenuRight).map((key) => (
                <Link to={`/${category}/${subCategoryKey}/${key}`} key={key} className="nav-redirect" >
                    <li
                        key={key}
                        onMouseEnter={() => handleShowMenu(key)}
                        onMouseLeave={handleHiddenMenu}
                    >
                        {submenuRight[key]}
                    </li>
                </Link>
            ))}

            { showMenu && (
                <div className="menuRight" style={{top: `${top}px`}}>
                    <ul onMouseEnter={handleShowMenuRight}>
                        {Object.keys(subMenuData).map((key) => (
                            <Link to={`/${category}/${subCategoryKey}/${key}`} key={key} className="nav-redirect">
                                <li key={key}>{subMenuData[key]}</li>
                            </Link>
                        ))}
                    </ul>
                </div>
            )}
        </ul>
    </>
  );
}

export default BtnRightSubmenu;
