import "./restrictions.css";
import Footer from "../../../../components/footer/Footer";
import Navbar from "../../../../components/navbar/navbar";
import Restricitions from "../../../../assets/imgs/prepare-shipment/restrictions/restricitions_shipments.png";
import MerchandiseType from "../merchandise-type/merchandise-type";
import ProhibitedGoods from "../prohibited-goods/prohibited-goods";
import HazardousGoods from "../hazardous-goods/hazardous-goods";
import GoodsNotAccepted from "../goods-not-accepted/goods-not-accepted";
import PackagingConditions from "../packaging-conditions/packaging-conditions";
import PackagingGuidelines from "../packaging-guidelines/packaging-guidelines";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../../../components/scrollToTop/scrollToTop";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";
import Helmet from "react-helmet";

function RestrictionsMain() {
  const [tRestrictions] = useTranslation("restrictions");
  const [tSMetaData] = useTranslation("metaData");

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].shipments}/${
      Object.keys(navBarEs["prepare-shipment-submenu"])[0]
    }`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].shipments}/${
      Object.keys(navBarEn["prepare-shipment-submenu"])[0]
    }`
  );

  return (
    <div>
      <Helmet>
        <title>{tSMetaData("preparaEmbarqueRestricciones.title")}</title>
        <meta
          name="description"
          content={tSMetaData("preparaEmbarqueRestricciones.description")}
        />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <div>
        <div className="container-fluid bg-innovation d-flex justify-content-center align-items-center">
          <img
            className="restricitions-img"
            src={Restricitions}
            alt="Restricitions"
          />
          <div className="ps-3 text-white">
            <p className="m-0 fs-2 fw-bold">
              {tRestrictions("restrictions.text-1")}
            </p>
            <p className="m-0 fs-2">{tRestrictions("restrictions.text-2")}</p>
          </div>
        </div>
        <MerchandiseType />
        <ProhibitedGoods />
        <HazardousGoods />
        <GoodsNotAccepted />
        <PackagingConditions />
        <PackagingGuidelines />
      </div>
      <Footer />
    </div>
  );
}

export default RestrictionsMain;
