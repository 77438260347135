import RestrictionsIcons from "../../../../components/restrictions-icons/restrictions-icons";
import iconDangerousLiquids from "../../../../assets/imgs/prepare-shipment/restrictions/icon-dangerous-liquids.svg";
import iconAerosol from "../../../../assets/imgs/prepare-shipment/restrictions/icon-aerosol.svg";
import iconPyrotechnics from "../../../../assets/imgs/prepare-shipment/restrictions/icon-pyrotechnics.svg";
import iconSnubNose from "../../../../assets/imgs/prepare-shipment/restrictions/icon-snub-nose-animals.svg";
import iconPerishable from "../../../../assets/imgs/prepare-shipment/restrictions/icon-perishable.svg";
import iconFalseStatements from "../../../../assets/imgs/prepare-shipment/restrictions/icon-false-statements.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";

function MerchandiseType() {
  const [tRestrictions] = useTranslation("restrictions");
  const idComponent = "adee7153-f8ae-4881-8923-b9dc6bea5295";
  const [dataInformation, setDataInformation] = useState({
    "Por tipo de mercancía": {
      "titulo": "",
      "text-1": "",
      "text-3": "",
      "text-2": "",
      "text-5": "",
      "text-4": "",
      "text-6": "",
    },
  });

  const getTexts = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idComponent}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (currentLng === "es") {
        setDataInformation(response.data.textWeb.text_es["Por tipo de mercancía"]);
      } else {
        setDataInformation(response.data.textWeb.text_us["Por tipo de mercancía"]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTexts();
  }, []);

  return (
    <div className="container-fluid bg-light-gray">
      <div className="container p-0 d-flex flex-column">
        <p className="mt-5 mb-md-4 text-center text-premium-blue fs-3">
          {dataInformation["titulo"] ?? tRestrictions("merchandise-type.merchandise-type")}
        </p>
        <div className="row my-4 d-flex justify-content-center align-items-start">
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconDangerousLiquids}
              iconAlt="Dangerous liquids Icon"
              height="70px"
              info={dataInformation["text-1"] ?? tRestrictions("merchandise-type.text-1")}
              fontSize="14px"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconAerosol}
              iconAlt="Dangerous liquids Icon"
              height="70px"
              info={dataInformation["text-2"] ?? tRestrictions("merchandise-type.text-2")}
              fontSize="14px"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconPyrotechnics}
              iconAlt="Dangerous liquids Icon"
              height="70px"
              info={dataInformation["text-3"] ?? tRestrictions("merchandise-type.text-3")}
              fontSize="14px"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconSnubNose}
              iconAlt="Dangerous liquids Icon"
              height="70px"
              info={dataInformation["text-4"] ?? tRestrictions("merchandise-type.text-4")}
              fontSize="14px"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconPerishable}
              iconAlt="Dangerous liquids Icon"
              height="70px"
              info={dataInformation["text-5"] ?? tRestrictions("merchandise-type.text-5")}
              fontSize="14px"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconFalseStatements}
              iconAlt="Dangerous liquids Icon"
              height="70px"
              info={dataInformation["text-6"] ?? tRestrictions("merchandise-type.text-6")}
              fontSize="14px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchandiseType;
