import React from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/navbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../scrollToTop/scrollToTop";
import "./style.css";

function ErrorCotizador() {
  const [tErrorCotizador] = useTranslation("errorCotizador");
  const [tNabvar] = useTranslation("navbar");
  const navigate = useNavigate();

  const redirect = () => {
    navigate(`/${tNabvar("category-services.quote")}`);
  };

  //   href={`/${tNabvar("category-services.quote")}`}

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div className="container errorContainer">
        <p className="errorTitlte">{tErrorCotizador("title")}</p>
        <p className="errorDescription">{tErrorCotizador("description")}</p>
        <button onClick={redirect}>{tErrorCotizador("button")}</button>
      </div>
      <Footer />
    </>
  );
}

export default ErrorCotizador;
