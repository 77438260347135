import ScrollToTop from "../../../components/scrollToTop/scrollToTop";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import TrackDetails from "../track-details/track-details";
import TrackStatus from "../track-status/track-status";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import "./track-main.css";
import TitleSectionPink from "../../../components/title-section-pink/title-section-pink";

function TrackMain() {
  const [tTrack] = useTranslation("trackPage");
  const [tSMetaData] = useTranslation("metaData");
  const location = useLocation();
  const { prefix, waybillNumber, data } = location.state?.formData || "";
  const [trackStatusView, setTrackStatusView] = useState("");
  const [statusArr, setStatusArr] = useState([]);
  const [details, setDetails] = useState([]);
  const [nationalsRoutes, setNationalRoutes] = useState([]);
  const [prefixNumber, setPrefixNumber] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem(
      "routeEs",
      `/${navBarEs["category-services"].track}/resultado/${prefixNumber}`
    );
    sessionStorage.setItem(
      "routeEn",
      `/${navBarEn["category-services"].track}/result/${prefixNumber}`
    );
  }, [prefixNumber]);

  const handleNationalsRoutes = async () => {
    let stationsArr = [];
    let addedStationsInitials = {};
    let resultStationArr = [];
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      response.data.stations.forEach((stations) => {
        stationsArr.push(stations.code_text);
      });

      for (let i = 0; i < stationsArr.length; i++) {
        const currentStation = stationsArr[i];
        const initials = currentStation.substring(0, 3);

        if (!addedStationsInitials[initials]) {
          resultStationArr.push(initials);
          addedStationsInitials[initials] = true;
        }
      }
      setNationalRoutes(resultStationArr);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleNationalsRoutes();
    if (!prefix || !waybillNumber || !data) {
      const regex = /^\d{3}-\d{8}$/;
      const url = location.pathname.split("/");

      if (regex.test(url[url.length - 1])) {
        const urlPrefix = url[url.length - 1].split("-")[0];
        const urlWaybillNumber = url[url.length - 1].split("-")[1];
        setPrefixNumber(`${urlPrefix}-${urlWaybillNumber}`);
        fetchDataTrack(urlPrefix, urlWaybillNumber);
      } else {
        navigate("/error404");
      }
    } else {
      if (data) {
        setPrefixNumber(`${prefix}-${waybillNumber}`);
        setTrackDetails(data);
        setTrackStatus(data);
        setTrackStatusView("success");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataTrack = async (urlPrefix, urlWaybillNumber) => {
    let redirectPage = "";
    if (localStorage.getItem("lng")) {
      if (localStorage.getItem("lng") === "us") {
        redirectPage = `${navBarEn["category-services"].track}`;
      } else {
        redirectPage = `${navBarEs["category-services"].track}`;
      }
    } else {
      redirectPage = `${navBarEs["category-services"].track}`;
    }
    try {
      const url = `${process.env.REACT_APP_URL_BACKEND}/api/track/v3/${urlPrefix}/${urlWaybillNumber}`;
      const response = await axios.get(url);
      setTrackDetails(response.data);
      setTrackStatus(response.data);
      setTrackStatusView("success");
    } catch (error) {
      if (error.response.data.message === "Track not found") {
        navigate(`/${redirectPage}/track-not-found`, {
          state: {
            formData: {
              prefix: urlPrefix,
              waybillNumber: urlWaybillNumber,
              data: "error404",
            },
          },
        });
      } else if (error.response.data.message === "Track not information") {
        navigate(`/${redirectPage}/track-not-found`, {
          state: {
            formData: {
              prefix: urlPrefix,
              waybillNumber: urlWaybillNumber,
              data: "notInformation",
            },
          },
        });
      } else if (error.response.data.message === "server error") {
        navigate(`/${redirectPage}/track-not-found`, {
          state: {
            formData: {
              prefix: urlPrefix,
              waybillNumber: urlWaybillNumber,
              data: "serverError",
            },
          },
        });
      }
    }
  };

  function setTrackDetails(data) {
    const lines = data.split("\n");
    setDetails(lines);
  }

  function setTrackStatus(data) {
    const lines = data.split("\n");
    setStatusArr(lines);
  }

  return (
    <>
      <Helmet>
        <title>{tSMetaData("rastreaGuía.title")}</title>
        <meta
          name="description"
          content={tSMetaData("rastreaGuía.description")}
        />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <TitleSectionPink
        title={tTrack("title.title")}
        hiddeSecondarySection={990}
      />

      <div className="trackBtnContact">
        {trackStatusView === "success" && (
          <div className="container container-success-track">
            <div className="details">
              <TrackDetails details={details} />
            </div>
            <div className="status">
              <TrackStatus data={statusArr} nationalsCode={nationalsRoutes} />
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
}

export default TrackMain;
