import React, { useState, useEffect } from "react";
import "./style.css";
import TooltipComponet from "../../../components/toolTip/tooltip";
import { useTranslation } from "react-i18next";

function StepOne({ onhandleStepOne, dataTwo, register, errors }) {
  const [tSGetQuoteStepOne] = useTranslation("getQuoteStepOne");
  const services = JSON.parse(localStorage.getItem("services"));
  const listServices =
    dataTwo.destino === "PBC" ||
    dataTwo.origen === "PBC" ||
    dataTwo.destino === "MEX2" ||
    dataTwo.origen === "MEX2"
      ? services.filter(
          (service) =>
            service.name !== "Animales Vivos" && service.name !== "Live Animals"
        )
      : services;

  const [codeService, setCodeService] = useState("");
  const [alertService, setAlertService] = useState(null);
  const [descriptionService, setDescriptionService] = useState(null);
  const [showAnimalSelect, setShowAnimalSelect] = useState(false);

  const [formData, setFormData] = useState({
    tipo_servicio: "",
    tipo_servicio_extra_humanos: "",
    tipo_servicio_extra_animales: "",
  });

  const handleSelectChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];
    const code = selectedOption.getAttribute("data-code");
    const alert = services.find((service) => service.name === e.target.value) || {};
    
    if (alert) {
      if (alert.name === "Animales Vivos" || alert.name === "Live Animals") {
        setShowAnimalSelect(true);
      } else {
        setShowAnimalSelect(false);
      }

      setCodeService(code || "");
      setAlertService(alert.alert || null);
      setDescriptionService(alert.description || null);
    }
    handleStepOne(e);
  };

  const handleStepOne = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    onhandleStepOne(formData);
  }, [formData, onhandleStepOne]);

  return (
    <>
      <div
        className="shipment__step set fadeInUp"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        <h4>
          <span>{tSGetQuoteStepOne("step1")}</span>
          {tSGetQuoteStepOne("step2")}
        </h4>
      </div>

      <div className="shipment__form__servicios">
        <div className="shipment__form__servicios__row" role="row">
          <div className="colum">
            <label htmlFor="tipoServicio">
              {" "}
              {tSGetQuoteStepOne("service")}
              <TooltipComponet
                text={tSGetQuoteStepOne("infoToltip")}
                position="right"
              />
            </label>
            <div className="select">
              <select
                name="tipo_servicio"
                className={`js-shipment-quote-form-tipo-servicio ${
                  errors.tipo_servicio?.type === "pattern"
                    ? "missing-field"
                    : ""
                }`}
                id="tipo_servicio"
                {...register("tipo_servicio", {
                  pattern: /^(?!Seleccione...$|Choose...$).*$/,
                })}
                data-name="servicio"
                onChange={handleSelectChange}
              >
                <option value={tSGetQuoteStepOne("choose")} data-code="">
                  {tSGetQuoteStepOne("choose")}
                </option>
                {listServices.map((service, index) => (
                  <option
                    key={index}
                    value={service.name}
                    data-code={service.thumb}
                    data-num={index + 1}
                  >
                    {service.name}
                  </option>
                ))}
              </select>
            </div>
            {errors.tipo_servicio?.type === "pattern" && (
              <div
                className="shipment__form__column__message__services show_alert"
                role="alert"
              >
                {tSGetQuoteStepOne("missingField")}
              </div>
            )}
            <div
              className={`shipment__form__column__message__services ${
                alertService !== null || "" ? "show_alert" : ""
              }`}
              role="alert"
            >
              {alertService}
            </div>
            <div
              className={`shipment__form__column__message__description_services ${
                descriptionService !== null || "" ? "show_alert" : ""
              }`}
              role="alert"
            >
              {descriptionService}
            </div>
          </div>
          <div className="colum">
            <label htmlFor="codigo_servicio">
              <i>*</i>
              {tSGetQuoteStepOne("codeService")}
            </label>
            <input
              type="text"
              name="codigo_servicio"
              className="js-shipment-quote-form-codigo-servicio"
              placeholder={tSGetQuoteStepOne("placeholderCode")}
              data-placeholder="Code"
              value={codeService}
              maxLength={3}
              pattern="[A-Z]{1,3}"
              readOnly
              onChange={handleStepOne}
            />
            <div className="shipment__form__column__message" role="alert"></div>
          </div>
          <div
            className="colum js-shipment-quote-form-tipo-servicio-extra"
            id="humanos"
          >
            <label htmlFor="tipo_servicio_extra_humanos">
              {tSGetQuoteStepOne("service")}
            </label>
            <div className="select">
              <select
                name="tipo_servicio_extra_humanos"
                id="tipo_servicio_extra_humanos"
                required
              >
                <option defaultValue>{tSGetQuoteStepOne("choose")}</option>
              </select>
            </div>
            <div
              className="shipment__form__column__message__services"
              role="alert"
            ></div>
          </div>
          <div
            className={`colum js-shipment-quote-form-tipo-servicio-extra ${
              showAnimalSelect ? "show_Extra_Service" : ""
            }`}
            id="animales"
          >
            <label htmlFor="tipo_servicio_extra_animales">
              {tSGetQuoteStepOne("service")}
            </label>
            <div className="select">
              <select
                name="tipo_servicio_extra_animales"
                id="tipo_servicio_extra_animales"
                className="js-shipment-quote-form-tipo-servicio-extra-select"
                required
                onChange={handleStepOne}
              >
                <option defaultValue>{tSGetQuoteStepOne("choose")}.</option>
                {(dataTwo.destino || dataTwo.origen) === "TGZ" ? (
                  <option
                    value="Otros Animales (únicamente peces)"
                    className="js-shipment-quote-form-tipo-servicio-extra-select-option-others"
                    data-option-text-normal="otros Animales (peces vivos, tortugas, ratones, etc.)"
                    data-option-text-special="Otros Animales (únicamente peces)"
                  >
                    {tSGetQuoteStepOne("otherAnimals1")}
                  </option>
                ) : (
                  <>
                    <option
                      value="Perros y Gatos"
                      className="js-shipment-quote-form-tipo-servicio-extra-select-option"
                    >
                      {tSGetQuoteStepOne("otherAnimals2")}
                    </option>
                    <option
                      value="Gallos y aves de corral"
                      className="js-shipment-quote-form-tipo-servicio-extra-select-option"
                    >
                      {tSGetQuoteStepOne("otherAnimals3")}
                    </option>
                    <option
                      value="otros"
                      className="js-shipment-quote-form-tipo-servicio-extra-select-option-others"
                      data-option-text-normal="otros Animales (peces vivos, tortugas, ratones, etc.)"
                      data-option-text-special="Otros Animales (únicamente peces)"
                    >
                      {tSGetQuoteStepOne("otherAnimals4")}
                    </option>
                  </>
                )}
              </select>
            </div>
            <div
              className="shipment__form__column__message__services show_alert"
              role="alert"
            >
              {tSGetQuoteStepOne("alertAnimals")}
            </div>
            <div className="shipment__form__column__message" role="alert"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StepOne;
