import { useQuill } from "react-quilljs";
import 'quill/dist/quill.snow.css';
import { useEffect } from "react";

function ViewQuillComponent({ content, type }) {
    const {quill, quillRef} = useQuill({
        readOnly: true,
        modules: {toolbar: false}
    });
    
    useEffect(() => {
        if(quill) {
            if(type === 'json') {
                quill.setContents(content);
            } else {
                quill.setText(content);
            }
        }
    })

    return (
        <>
            <div ref={quillRef}></div>
        </>
    )
};

export default ViewQuillComponent;