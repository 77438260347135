import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToDynamic() {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if(hash === "") {
            const complianceEthicsDiv = document.querySelector(`.navbar-aero`);
            if (complianceEthicsDiv) {
                complianceEthicsDiv.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            const complianceEthicsDiv = document.querySelector(`${hash}`);
            if (complianceEthicsDiv) {
                complianceEthicsDiv.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [pathname, hash]);

    return null;
}

export default ScrollToDynamic;