import { useTranslation } from "react-i18next";
import Footer from "../../../../components/footer/Footer";
import Navbar from "../../../../components/navbar/navbar";
import TitleSection from "../../../../components/title-section/title-section";
import AddServItem from "../add-serv-item/add-serv-item";
import {
  FaTruck,
  FaDropbox,
  FaWarehouse,
  FaCoins,
  FaPhoneAlt,
} from "react-icons/fa";
import React from "react";
import { Link } from "react-router-dom";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";
import Helmet from "react-helmet";
import ScrollToTop from "../../../../components/scrollToTop/scrollToTop";

function AddServicesMain() {
  const [tNabvar] = useTranslation("navbar");
  const [tSMetaData] = useTranslation("metaData");
  const [tAddServ] = useTranslation("additionalServPage");
  const iconComponents = {
    FaTruck,
    FaDropbox,
    FaWarehouse,
    FaCoins,
  };

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].services}/${
      Object.keys(navBarEs["products-submenu"])[2]
    }`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].services}/${
      Object.keys(navBarEn["products-submenu"])[2]
    }`
  );

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{tSMetaData("serviciosAdicionales.title")}</title>
        <meta
          name="description"
          content={tSMetaData("serviciosAdicionales.description")}
        />
      </Helmet>
      <Navbar />
      <TitleSection title={tAddServ("header.title")} />
      <div className="container">
        <div className="row" style={{ marginTop: "30px" }}>
          <div className="col-12 col-md-6">
            <AddServItem
              icon={React.createElement(iconComponents.FaTruck)}
              title={tAddServ("home-delivery.title")}
              description={tAddServ("home-delivery.description")}
            />
          </div>

          <div className="col-12 col-md-6">
            <AddServItem
              icon={React.createElement(iconComponents.FaDropbox)}
              title={tAddServ("packaging.title")}
              description={tAddServ("packaging.description")}
            />
          </div>

          <div className="col-12 col-md-6">
            <AddServItem
              icon={React.createElement(iconComponents.FaWarehouse)}
              title={tAddServ("storage.title")}
              description={tAddServ("storage.description")}
            />
          </div>

          <div className="col-12 col-md-6">
            <AddServItem
              icon={React.createElement(iconComponents.FaCoins)}
              title={tAddServ("valuation.title")}
              description={tAddServ("valuation.description")}
            />
          </div>
        </div>

        <div className="divider"></div>

        <div className="more-information-container">
          <div className="more-information">
            <i>
              <FaPhoneAlt />
            </i>
            <h4>{tAddServ("more-info.more-info")}</h4>
          </div>
          <p>{tAddServ("more-info.description")}</p>
          <Link
            id="btnContactAdditionalServices"
            to={`/${tNabvar("category-services.contact")}`}
          >
            <button>{tAddServ("more-info.btn-text")}</button>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddServicesMain;
