import { React } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../../components/scrollToTop/scrollToTop";
import Navbar from "../../../components/navbar/navbar";
import TitleSection from "../../../components/title-section/title-section";
import Footer from "../../../components/footer/Footer";
import "./sky-team.css";
import iconSkyTeamCargo from "../../../assets/imgs/about-us/sky-team/skyteam.cargo.svg";
import iconArgentinaCargo from "../../../assets/imgs/about-us/sky-team/aerolineas-argentina-cargo.svg";
import iconAeromexicoCargo from "../../../assets/imgs/about-us/sky-team/aero-mexico-logo.svg";
import iconAirFranceCargo from "../../../assets/imgs/about-us/sky-team/airfrance-klm-cargo.svg";
import iconAlitaliaCargo from "../../../assets/imgs/about-us/sky-team/alitalia-cargo.svg";
import iconChinaAirlinesCargo from "../../../assets/imgs/about-us/sky-team/china-airline-cargo.svg";
import iconChinaCargo from "../../../assets/imgs/about-us/sky-team/china-cargo-logo.svg";
import iconCzechAirlinesCargo from "../../../assets/imgs/about-us/sky-team/czech-airlines-cargo.svg";
import iconDeltaCargo from "../../../assets/imgs/about-us/sky-team/delta-cargo-logo.svg";
import iconKoreanAirCargo from "../../../assets/imgs/about-us/sky-team/korean-air-cargo.svg";
import iconSaudiaCargo from "../../../assets/imgs/about-us/sky-team/saudia-cargo.svg";
import { useTranslation } from "react-i18next";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import Helmet from "react-helmet";

function SkyTeam() {
  const [tSkyTeam] = useTranslation("skyTeam");
  const [tSMetaData] = useTranslation("metaData");
  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"]["about-us"]}/${
      Object.keys(navBarEs["about-us"])[2]
    }`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"]["about-us"]}/${
      Object.keys(navBarEn["about-us"])[2]
    }`
  );

  return (
    <>
      <Helmet>
        <title>{tSMetaData("SkyTeam.title")}</title>
        <meta name="description" content={tSMetaData("SkyTeam.description")} />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <TitleSection title={tSkyTeam("title.title-page")} />
      <div className="container-fluid bg-sky">
        <div className="container px-0 px-md-5 h-100 d-flex align-items-center">
          <div className="skyteam-info-container text-white fs-14">
            <p className="font-semibold">{tSkyTeam("skyteam-info.text1")}</p>
            <p>{tSkyTeam("skyteam-info.text2")}</p>
            <p>{tSkyTeam("skyteam-info.text3")}</p>
            <div className="d-flex align-items-center pt-5">
              <Link
                className="btn-fleet"
                to={tSkyTeam("skyteam-info.link")}
                id="btnSkyteamAboutUs"
                target="blank"
              >
                {tSkyTeam("skyteam-info.text4")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid styteam-members pt-5">
        <div className="container px-0 px-md-5">
          <p className="text-center fs-34 font-semibold">
            {tSkyTeam("skyteam-info.text5")}
          </p>
          <div className="row p-4 d-flex justify-content-center align-items-center">
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconSkyTeamCargo} alt="Sky Team Cargo" />
            </div>
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconArgentinaCargo} alt="Aerolíneas Argentinas Cargo" />
            </div>
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconAeromexicoCargo} alt="Aeroméxico Cargo" />
            </div>
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconAirFranceCargo} alt="Air France KLM Cargo" />
            </div>
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconAlitaliaCargo} alt="Alitalia Cargo" />
            </div>
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconChinaAirlinesCargo} alt="China Airlines Cargo" />
            </div>
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconChinaCargo} alt="China Cargo" />
            </div>
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconCzechAirlinesCargo} alt="Czech Airlines Cargo" />
            </div>
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconDeltaCargo} alt="Delta Cargo" />
            </div>
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconKoreanAirCargo} alt="Korean Air Cargo" />
            </div>
            <div className="icon-center col-12 col-md-2 col-xl">
              <img src={iconSaudiaCargo} alt="Saudia Cargo" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SkyTeam;
