import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function HazardousGoods() {
  const [tRestrictions] = useTranslation("restrictions");

  const idComponent = "1c77175f-5556-4d23-8d5c-885fe9196efa";
  const [dataInformation, setDataInformation] = useState(() => {
    const newData = {};
    for (let i = 1; i <= 33; i++) {
      newData[`text-${i}`] = "";
    }
    newData["titulo"] = "";
    return {
      "Mercancías de uso común": newData,
    };
  });

  const getTexts = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idComponent}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (currentLng === "es") {
        setDataInformation(
          response.data.textWeb.text_es["Mercancías de uso común"]
        );
      } else {
        setDataInformation(
          response.data.textWeb.text_us["Mercancías de uso común"]
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTexts();
  }, []);

  return (
    <div>
      <div className="container">
        <p className="mb-md-4 text-center text-premium-blue fs-5">
          {dataInformation["titulo"] ??
            tRestrictions("hazardous-goods.hazardous-goods")}
        </p>
        <div className="row justify-content-center">
          <p className="col-11 col-md-10 col-lg-7 text-center text-premium-blue fs-14">
            {dataInformation["text-1"] ??
              tRestrictions("hazardous-goods.text-1")}
            <span className="fw-bold font-semibold">
              {dataInformation["text-2"] ??
                tRestrictions("hazardous-goods.text-2")}
            </span>
          </p>
        </div>
      </div>
      <div className="container px-5 border-bottom d-flex justify-content-center">
        <div className="col-10">
          <p className="mt-2 mt-lg-5 mb-md-4 fw-bold font-semibold text-premium-blue fs-16">
            {dataInformation["text-3"] ??
              tRestrictions("hazardous-goods.text-3")}
          </p>
          <div className="row">
            <div className="col-12 col-md-6 pt-3 border-end">
              <ul className="li-space text-premium-blue fs-12">
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-4"] ??
                      tRestrictions("hazardous-goods.text-4")}{" "}
                  </span>
                  {dataInformation["text-5"] ??
                    tRestrictions("hazardous-goods.text-5")}
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-6"] ??
                      tRestrictions("hazardous-goods.text-6")}
                  </span>
                  {dataInformation["text-7"] ??
                    tRestrictions("hazardous-goods.text-7")}
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-8"] ??
                      tRestrictions("hazardous-goods.text-8")}{" "}
                  </span>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-9"] ??
                      tRestrictions("hazardous-goods.text-9")}
                  </span>
                  {dataInformation["text-10"] ??
                    tRestrictions("hazardous-goods.text-10")}
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-11"] ??
                      tRestrictions("hazardous-goods.text-11")}
                  </span>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-12"] ??
                      tRestrictions("hazardous-goods.text-12")}
                  </span>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-13"] ??
                      tRestrictions("hazardous-goods.text-13")}
                  </span>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-14"] ??
                      tRestrictions("hazardous-goods.text-14")}
                  </span>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-15"] ??
                      tRestrictions("hazardous-goods.text-15")}
                  </span>
                  {dataInformation["text-16"] ??
                    tRestrictions("hazardous-goods.text-16")}
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-17"] ??
                      tRestrictions("hazardous-goods.text-17")}
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 pt-3">
              <ul className="li-space text-premium-blue fs-12">
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-18"] ??
                      tRestrictions("hazardous-goods.text-18")}
                  </span>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-19"] ??
                      tRestrictions("hazardous-goods.text-19")}
                  </span>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-20"] ??
                      tRestrictions("hazardous-goods.text-20")}
                  </span>
                  {dataInformation["text-21"] ??
                    tRestrictions("hazardous-goods.text-21")}
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-22"] ??
                      tRestrictions("hazardous-goods.text-22")}
                  </span>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-24"] ??
                      tRestrictions("hazardous-goods.text-24")}
                  </span>
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-25"] ??
                      tRestrictions("hazardous-goods.text-25")}
                  </span>
                  {dataInformation["text-26"] ??
                    tRestrictions("hazardous-goods.text-26")}
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-27"] ??
                      tRestrictions("hazardous-goods.text-27")}
                  </span>
                  {dataInformation["text-28"] ??
                    tRestrictions("hazardous-goods.text-28")}
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-29"] ??
                      tRestrictions("hazardous-goods.text-29")}
                  </span>
                  {dataInformation["text-30"] ??
                    tRestrictions("hazardous-goods.text-30")}
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-31"] ??
                      tRestrictions("hazardous-goods.text-31")}
                  </span>
                  {dataInformation["text-32"] ??
                    tRestrictions("hazardous-goods.text-32")}
                </li>
                <li>
                  <span className="fw-bold font-semibold">
                    {dataInformation["text-33"] ??
                      tRestrictions("hazardous-goods.text-33")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HazardousGoods;
