import './card-style.css';
import { Link } from "react-router-dom";
import DOMPurify from 'dompurify';

function Card(props) {
  const convertTextUpper = props.btnMessage ? props.btnMessage.toUpperCase() : '';
  const imageSrc = DOMPurify.sanitize(props.image);

  const renderButton = () => {
    if(props.btnMessage !== undefined || props.redirectBtn !== undefined) {
      return (
        <a href={props.redirectBtn} target='_blank' rel='noreferrer' className="primary-btn">{convertTextUpper}</a>
      )
    }
  }

  const renderTitle = () => {
    if(props.icon !== undefined || props.title !== undefined) {
      return (
        <>
          <i>
          {props.icon}
          </i>
          <h3>{[props.title]}</h3>
        </>
      )
    } else {
      return (
        <>
          <img className='imgTitle' src={imageSrc} alt='AMC Internationals' />
        </>
      )
    }
  }

  return (
    <div className="target">
      <div className="titleTarget">
        {renderTitle()}
      </div>
      <p className='targetDescription'>
        {props.description}
      </p>
      <div className="actions">
        <Link to={props.redirectHref} id={props.linkId} className='redirecPageTo'>{props.redirect}</Link>
        <div>
          {renderButton()}
        </div>
      </div>
    </div>
  );
}

export default Card;
