import React from "react";
import Card from "../../../components/card/card";
import { useRef } from "react";
import {
  FaAngleRight,
  FaAngleLeft,
  FaSyringe,
  FaCube,
  FaStar,
  FaClock,
  FaRegEnvelope,
  FaPagelines,
  FaDiceD20,
} from "react-icons/fa";

import "./cardSection-style.css";
import TitleSection from "../../../components/title-section/title-section";
import { useTranslation } from "react-i18next";

function CardSection({ title, data, category }) {
  const containerRef = useRef(null);
  const [tNabvar] = useTranslation("navbar");
  const [tInformation] = useTranslation("information");
  const iconComponents = {
    FaSyringe,
    FaCube,
    FaStar,
    FaClock,
    FaRegEnvelope,
    FaPagelines,
    FaDiceD20,
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 415;
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 415;
    }
  };

  const renderData = () => {
    return data.map((item, index) => (
      <div className="targetContainer" key={index}>
        <Card
          title={item.titulo}
          description={item.texto}
          icon={
            iconComponents[item.icon] &&
            React.createElement(iconComponents[item.icon])
          }
          redirect={item["texto-botón-linea"]}
          redirectHref={category ? `${tNabvar("category-services.services")}/${category}/${item["link-botón-linea"]}` : tInformation("contact-text.contact") }
          linkId={`contact${item["link-botón-linea"]}Home`}
          btnMessage={item["texto-botón-borde"]}
          redirectBtn={item["link-botón-borde"]}
          image={item.imagen}
        />
      </div>
    ));
  };

  return (
    <>
      <TitleSection title={title} />

      <div className="container">
        <button className="btnLeft btnOption" onClick={scrollLeft}>
          <FaAngleLeft />
        </button>
        <button className="btnRight btnOption" onClick={scrollRight}>
          <FaAngleRight />
        </button>
        <div className="cardsContainer" ref={containerRef}>
          {renderData()}
        </div>
      </div>
    </>
  );
}

export default CardSection;
