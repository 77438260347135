import { AiOutlineSend } from "react-icons/ai";
import "./forms-style.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import { useEffect, useState } from "react";
import Loader from "../../../components/loader/loader";

function TrackGuideForm() {
  const [tHome] = useTranslation("homePage");
  const [tTrack] = useTranslation("trackPage")
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({mode: 'onChange'});
  const navigate = useNavigate();
  const [statePass, setStatePass] = useState(true);

  const prefixValue = watch("prefix");
  const nguideValue = watch("waybillNumber");

  
  useEffect(() => {
    if(prefixValue) {
      const onlyNumbers = prefixValue.replace(/\D/g, '');
      if (prefixValue !== onlyNumbers) {
        setValue("prefix", onlyNumbers);
      }
    }

    if(nguideValue) {
      const onlyNumbers = nguideValue.replace(/\D/g, '');
      if (nguideValue !== onlyNumbers) {
        setValue("waybillNumber", onlyNumbers);
      }
    }
  }, [prefixValue, setValue, nguideValue])

  const handleTrackSubmit = (data) => {
    if(statePass === true) {
      setStatePass(false);
    } else {
      return;
    }
    const fetchDataTrack = async () => {
      let redirectPage = '';
      if(localStorage.getItem("lng")) {
        if(localStorage.getItem("lng") === 'us') {
          redirectPage = `${navBarEn["category-services"].track}`;
        } else {
          redirectPage = `${navBarEs["category-services"].track}`;
        }
      } else {
        redirectPage = `${navBarEs["category-services"].track}`;
      }
      try {
        if (!data.prefix || !data.waybillNumber) {
          return;
        }
        const url = `${process.env.REACT_APP_URL_BACKEND}/api/track/v3/${data.prefix}/${data.waybillNumber}`;
        const response = await axios.get(url);

        navigate(`${redirectPage}/${tTrack("result")}/${data.prefix}-${data.waybillNumber}`, 
            {state: {formData: {
                prefix: data.prefix, 
                waybillNumber: data.waybillNumber, 
                data: response.data
            }}}
        );

      } catch (error) {
        if (error.response.data.message === "Track not found") {
          navigate(`${redirectPage}/track-not-found`, {state: {formData: {
            prefix: data.prefix, 
            waybillNumber: data.waybillNumber, 
            data: "error404"
          }}})
        } else if (error.response.data.message === "Track not information") {
          navigate(`${redirectPage}/track-not-info`, {state: {formData: {
            prefix: data.prefix, 
            waybillNumber: data.waybillNumber, 
            data: "notInformation"
          }}})
        } else if (error.response.data.message === "server error") {
          navigate(`${redirectPage}/track-not-info`, {state: {formData: {
            prefix: data.prefix, 
            waybillNumber: data.waybillNumber, 
            data: "serverError"
          }}})
        }
      }
    };

    fetchDataTrack();
  };

  const buttonClass = isValid ? "validClass" : "invalidClass";
  const cursorType = isValid ? "pointer" : "not-allowed";

  return (
    <>
      <form onSubmit={handleSubmit(handleTrackSubmit)}>
        <div className="row">
          <div className="col-12 formGroup">
            <input
              id="prefix"
              type="text"
              placeholder=" "
              maxLength={"3"}
              className="formInput"
              {...register("prefix", {
                required: true,
                maxLength: 3,
                minLength: 3,
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Solo se permiten números'
                }
              })}
            />
            <label htmlFor="prefix" className="formLabel">
              {tHome("track-guide-form.prefix-label")}
            </label>
            <div className="alertTrackForm">
              {errors.prefix?.type === "pattern" && (
                <p>Solo se permiten números</p>
              )}
              {errors.prefix?.type === "required" && (
                <p>El campo es requerido</p>
              )}
              {errors.prefix?.type === "maxLength" && (
                <p>El campo tiene que ser de 3 digitos</p>
              )}
              {errors.prefix?.type === "minLength" && (
                <p>El campo tiene que ser de 3 digitos</p>
              )}
            </div>
          </div>
          <div className="col-12 formGroup">
            <input
              id="nguide"
              type="text"
              placeholder=" "
              className="formInput"
              maxLength={"8"}
              {...register("waybillNumber", { 
                required: true, 
                maxLength: 8,
                minLength: 8, 
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Solo se permiten números'
                } 
              })}
            />
            <label htmlFor="nguide" className="formLabel">
              {tHome("track-guide-form.waybill-number-label")}
            </label>
            <div className="alertTrackForm">
              {errors.waybillNumber?.type === "pattern" && (
                <p>Solo se permiten números</p>
              )}
              {errors.waybillNumber?.type === "required" && (
                <p>El campo es requerido</p>
              )}
              {errors.waybillNumber?.type === "maxLength" && (
                <p>El campo tiene que ser de 3 digitos</p>
              )}
              {errors.waybillNumber?.type === "minLength" && (
                <p>El campo tiene que ser de 8 digitos</p>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="searchButton">
              <button
                id="btnTrackHome"
                type="submit"
                style={{ cursor: `${cursorType}` }}
                className={`${buttonClass}`}
                disabled={!isValid}
              >
                {statePass ? <p>{tHome("track-guide-form.btn-text")}</p> : <p>{tTrack("form.loader")}</p>}
                {statePass ? <AiOutlineSend /> : <Loader />}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="textCont">
        <a
          className="redirecTo"
          id="linkTrackDeltaHome"
          href="https://www.deltacargo.com/Cargo/#/?selectedId=21"
          target="_blank"
          rel="noreferrer"
        >
          {tHome("track-guide-form.redirect-text")}
        </a>
      </div>
    </>
  );
}

export default TrackGuideForm;
