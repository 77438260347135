/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTimes, FaInfo } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import "./style.css";

function PostalCodes() {
  const [postalCodes, setPostalCodes] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [nationals, setNationals] = useState([])
  const stations = JSON.parse(localStorage.getItem("stations"));

  useEffect(() => {
    getStations()
  }, [])

  const getStations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/stations?lng=es`, {
        headers: {
          authorization: process.env.REACT_APP_PUBLIC_KEY,
        }
      })
      setNationals(response.data.stations);
    } catch (error) {
      console.error(error);
    }
  }

  const filteredPostalCodes = searchValue
    ? postalCodes.filter((postalCode) =>
        postalCode.station.toUpperCase().includes(searchValue.toUpperCase())
      )
    : postalCodes;

  const getPostalCodes = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/postalCode`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );
      if (res.data.code === 1) {
        setPostalCodes(res.data.postalCodes);
        setLoading(false);
      }
    } catch (error) {
      setLoading(true);
    }
  };

  const getPostaCode = async (city) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/postalCode/${city}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const postalCodes = res.data.postalCode.codes;

      const postalCodeList = postalCodes
        .map((code) => `<li>${code}</li>`)
        .join("");

      // eslint-disable-next-line no-unused-vars
      const result = await Swal.fire({
        title: `Editar Código Postal de ${city}`,
        html: `<div class="postal-code-list-container">
            <p class="postal-code-list-title">Lista de Códigos Postales:</p>
            <ul class="postal-code-list">${postalCodeList}</ul>
            </div>
            `,
        showCancelButton: false,
        confirmButtonText: "Cerrar",
        customClass: {
          popup: "modalCp",
        },
      });
    } catch (error) {
      Swal.fire("Error", "Algo salió mal");
    }
  };

  const createPostaCode = async () => {
    try {
      const result = await Swal.fire({
        title: "Crear Codigo Postal",
        html:
          '<select id="station" class="swal2-input">' +
          stations
            .map(
              (city) =>
                `<option value="${city.code_text}">${city.name} (${city.code_text})</option>`
            )
            .join("") +
          "</select>" +
          "</br>" +
          '<input type="file" name="fileInput" id="fileInput" class="file-input"/>' +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Crear",
        cancelButtonText: "Cancelar",
        customClass: {
          popup: "modalCp",
        },
        preConfirm: function () {
          return new Promise(function (resolve) {
            const fileInput = document.getElementById("fileInput");
            resolve({
              station: document.getElementById("station").value,
              excelFile: fileInput.files[0],
            });
          });
        },
      });

      if (result.isConfirmed) {
        const CodePostalData = result.value;
        const response = await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}/api/postalCode/createExcel`,
          CodePostalData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.data.code === 1) {
          addDeliveryStatios(response.data.station);
        }
        Swal.fire("¡Creado!", "creado exitosamente.", "success");
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Accion cancelada.", "error");
      }
    } catch (error) {
      if (error.response.data.code === 0) {
        Swal.fire("Error", error.response.data.message);
      } else if (error.response.data.code === -3) {
        Swal.fire("Error", error.response.data.message);
      } else if (error.response.data.code === -2) {
        Swal.fire("Error", error.response.data.menssage);
      } else if (error.response.data.code === -4) {
        Swal.fire("Error", error.response.data.message);
      } else {
        Swal.fire("Error", "Algo salio mal");
      }
    }
  };

  const updatePostaCode = async (city) => {
    try {
      const result = await Swal.fire({
        title: `editar Codigo Postal de ${city}`,
        html:
          '<input type="file" name="fileInput" id="fileInput" class="file-input"/>' +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Editar",
        cancelButtonText: "Cancelar",
        customClass: {
          popup: "modalCp",
        },
        preConfirm: function () {
          return new Promise(function (resolve) {
            const fileInput = document.getElementById("fileInput");
            resolve({
              excelFile: fileInput.files[0],
            });
          });
        },
      });

      if (result.isConfirmed) {
        const CodePostalData = result.value;

        const response = await axios.put(
          `${process.env.REACT_APP_URL_BACKEND}/api/postalCode/updateExcel/${city}`,
          CodePostalData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        Swal.fire("¡Editado!", "Editado exitosamente.", "success");
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Accion cancelada.", "error");
      }
    } catch (error) {
      if (error.response.data.code === 0) {
        Swal.fire("Error", error.response.data.message);
      } else if (error.response.data.code === -3) {
        Swal.fire("Error", error.response.data.message);
      } else if (error.response.data.code === -2) {
        Swal.fire("Error", error.response.data.menssage);
      } else if (error.response.data.code === -4) {
        Swal.fire("Error", error.response.data.message);
      } else {
        Swal.fire("Error", "Algo salio mal");
      }
    }
  };

  const deletePostaCode = async (city) => {
    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_URL_BACKEND}/api/postalCode/${city}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
            },
          }
        );

        Swal.fire("¡Eliminado!", "Eliminado Exitosamente.", "success");
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "No se realizo ninguna accion)", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Algo salió mal");
    }
  };

  const addDeliveryStatios = async (code) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations/${code}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = {
        name: res.data.station.name,
        add_quote: res.data.station.add_quote,
        add_postal_code: 1,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations/update/${code}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.code === 1) {
        setUpdateTrigger(!updateTrigger);
      }
    } catch (error) {}
  };

  const handleSearch = () => {
    const input = document.getElementById("searchInput");
    setSearchValue(input.value.trim());
  };

  useEffect(() => {
    getPostalCodes();
  }, [updateTrigger]);

  return (
    <>
      <div className="panel-table">
        <div className="colum-left">
          <input
            type="text"
            className="searchInput"
            id="searchInput"
            placeholder="Buscar..."
          />
          <button className="btnn" onClick={handleSearch}>
            Buscar
          </button>
        </div>
        <div className="colum-rigth">
          <button className="btnn" onClick={createPostaCode}>
            Crear Codigo Postal
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-preve" id="tableToSearch">
          <thead>
            <tr>
              <th>Estacion</th>
              <td className="accions">Acciones</td>
            </tr>
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <td>Cargando...</td>
              </tr>
            ) : (
              filteredPostalCodes.map((postalCode) => (
                <tr
                  key={postalCode.station}
                  data-master-id={postalCode.station}
                >
                  <td>{`${
                    nationals.find(
                      (city) => city.code_text === postalCode.station
                    )?.name ?? 'No name'
                  } (${
                    nationals.find(
                      (city) => city.code_text === postalCode.station
                    )?.code_text ?? 'No codeText'
                  })`}</td>
                  <td>
                    <FaInfo
                      className="editButton"
                      onClick={() => {
                        getPostaCode(postalCode.station);
                      }}
                    />
                    <FaEdit
                      className="editButton"
                      onClick={() => {
                        updatePostaCode(postalCode.station);
                      }}
                    />
                    <FaTimes
                      className="deleteButton"
                      onClick={() => {
                        deletePostaCode(postalCode.station);
                      }}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default PostalCodes;
