import { useTranslation } from "react-i18next";
import ImageHeader from "../../../../components/image-header/image-header";
import SpecializedLiveImg from "../../../../assets/imgs/services/international/specialized-live.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";
import Helmet from "react-helmet";

function SpecializedLive() {
  const [tSpecializedLive] = useTranslation("specializedLivePage");
  const [tSMetaData] = useTranslation("metaData");
  const idSpecializedLive = "b47ea075-c22b-4fc9-bffe-ff91ba1e4949";
  const [textDescription, setTextDescription] = useState("");

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].services}/${
      Object.keys(navBarEs["products-submenu"])[1]
    }/${Object.keys(navBarEs["especializado-submenu"])[4]}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].services}/${
      Object.keys(navBarEn["products-submenu"])[1]
    }/${Object.keys(navBarEn["specialized-submenu"])[4]}`
  );

  useEffect(() => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    axios
      .get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idSpecializedLive}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      )
      .then((response) => {
        if (currentLng === "es") {
          setTextDescription(
            response.data.textWeb.text_es["01-descripción"].content
          );
        } else {
          setTextDescription(
            response.data.textWeb.text_us["01-description"].content
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{tSMetaData("specializedLive.title")}</title>
        <meta
          name="description"
          content={tSMetaData("specializedLive.description")}
        />
      </Helmet>
      <ImageHeader
        section={tSpecializedLive("header.section")}
        title={tSpecializedLive("header.title")}
        description={
          textDescription
            ? JSON.parse(textDescription)
            : JSON.parse(tSpecializedLive("description.content"))
        }
        contactSales={tSpecializedLive("header.contact-sales")}
        imgBackground={SpecializedLiveImg}
      />
    </>
  );
}

export default SpecializedLive;
