import "./image-header.css";
import { Link } from 'react-router-dom';
import { useQuill } from "react-quilljs";
import 'quill/dist/quill.snow.css';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function ImageHeader({
  section,
  title,
  description,
  quoteBtn,
  quoteProductID,
  contactSales,
  contactProductID,
  imgBackground,
}) {
  const [tNabvar] = useTranslation("navbar");
  const {quill, quillRef} = useQuill({
    readOnly: true,
    modules: {toolbar: false}
  });

  useEffect(() => {
    if(quill) {
      quill.setContents(description);
    }
  }, [description, quill]);

  return (
    <div
      className="imgHeaderContainer"
      style={{ backgroundImage: `url(${imgBackground})` }}
    >
      <div className="container">
        <div className="textImgHeader">
          <h5>{section}</h5>
          <h2>{title}</h2>
          <div className="description">
            <div ref={quillRef}></div>
          </div>
          <div className="btnsContainer">
            { quoteBtn !== undefined ? <Link id={quoteProductID} className="btn-quote" to={`/${quoteBtn.toLowerCase()}`}>{quoteBtn}</Link> : null }
            <Link id={contactProductID} className="btn-contact" to={`/${tNabvar("category-services.contact")}`}>{contactSales}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageHeader;
