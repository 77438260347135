const toolbar = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    [{'image': ''}],
  
    ['link'],
    [{ 'image-url': '' }],
    ['clean']      
];

const handleImageUrl = function(value) {
    if (value) {
      const url = prompt('Ingrese la URL de la imagen:');
      if (url) {
        this.quill.format('image', url);
      }
    } else {
      this.quill.format('image', false);
    }
};

// Asignamos el manejador personalizado al botón de 'image-url'
toolbar.handlers = {
  'image-url': handleImageUrl,
};

export default toolbar;