import './loading.css';

function Loading() {
    return (
        <>
            <div className="loadingContainer">
                <span className="loader"></span>
                <p>Cargando ...</p>
            </div>
        </>
    )
}

export default Loading;