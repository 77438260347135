import React, { useState, useEffect } from 'react'
import Swal from "sweetalert2";
import axios from 'axios';
import { FaEdit, FaTimes } from "react-icons/fa";
import "./style.css"

function Service() {
  const [services, setServices] = useState([])
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState("");
  
  const handleSearch = () => {
    const input = document.getElementById("searchInput");
    setSearchValue(input.value.trim());
  };
  
  const filteredServices = searchValue
  ? services.filter(
      (service) =>
        service.service_us.name
          .toUpperCase()
          .includes(searchValue.toUpperCase()) ||
        service.service_es.name.toUpperCase().includes(searchValue.toUpperCase()) ||
        service.code.toUpperCase().includes(searchValue.toUpperCase())
    )
  : services
  
  const getServices = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/services`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY, // Agrega la cadena de texto para la authorization en endponids publicos"
          },
        }
      );
      if (res.data.code === 1) {
        const data = res.data.services;
        setServices(data);
        setLoading(false);
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
      setLoading(true);
    }
  };
  
  const createService = async () => {
    try {
    
      const result = await Swal.fire({
        title: `Crear Servico`,
        html:
          '<div class="forma">' +
          '<ul class="nav nav-tabs" id="myTabs">' +
          '<li class="nav-item">' +
          '<a class="nav-link active" data-tab="tab1">Es</a>' +
          "</li>" +
          '<li class="nav-item">' +
          '<a class="nav-link" data-tab="tab2">En</a>' +
          "</li>" +
          "</ul>" +
      
          '<div class="tab-content">' +
      
          '<div class="tab-pane active" id="tab1">' +
          "<label>Es</label>" +
          "</br>" +
          "<div class='fields' >" +
          '<div class="input-container">' +
          '<label for="code">code: </label>' +
          `<input type="text" id="code" class="swal2-input">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="thumb_es">Thumb: </label>' +
          `<input type="text" id="thumb_es" class="swal2-input">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="name_es">Nombre: </label>' +
          `<input type="text" id="name_es" class="swal2-input">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="alert_es">Alerta: </label>' +
          `<input type="text" id="alert_es" class="swal2-input">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="description_es">Descripcion: </label>' +
          `<input type="text" id="description_es" class="swal2-input">` +
          "</div>" +
          "</div>" +
          "</div>" +
      
          '<div class="tab-pane" id="tab2">' +
          "<label>En</label>" +
          "</br>" +
          "<div class='fields' >" +
          '<div class="input-container">' +
          '<label for="thumb_en">Thumb: </label>' +
          `<input type="text" id="thumb_en" class="swal2-input">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="name_en">Name: </label>' +
          `<input type="text" id="name_en" class="swal2-input">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="alert_en">Alert: </label>' +
          `<input type="text" id="alert_en" class="swal2-input">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="descripcion_en">Descripcion: </label>' +
          `<input type="text" id="descripcion_en" class="swal2-input">` +
          "</div>" +
          "</div>" +
          "</div>" +
      
          "</div>" +
          "</div>" +
          "</div>" +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Crear",
        cancelButtonText: "Cancelar",
        customClass: {
          popup: "popupServicios",
        },
        preConfirm: function () {
          return new Promise(function (resolve) {
            resolve({
              code: document.getElementById("code").value,
              service_us: {
                name: document.getElementById("name_en").value,
                thumb: document.getElementById("thumb_en").value,
                alert: document.getElementById("alert_en").value,
                description: document.getElementById("descripcion_en").value,
              },
              service_es: {
                name: document.getElementById("name_es").value,
                thumb: document.getElementById("thumb_es").value,
                alert: document.getElementById("alert_es").value,
                description: document.getElementById("description_es").value,
              },
            });
          });
        },
        didRender: () => {
          // Agregar evento para cambiar de pestaña después de que se renderice el cuadro de diálogo
          document
            .getElementById("myTabs")
            .addEventListener("click", function (event) {
              const tabId = event.target.getAttribute("data-tab");
              if (tabId) {
                // Ocultar todas las pestañas y luego mostrar la pestaña seleccionada
                document.querySelectorAll(".tab-pane").forEach(function (tab) {
                  tab.classList.remove("active");
                });
                document.getElementById(tabId).classList.add("active");
              }
            });
        },
      });
  
      if (result.isConfirmed) {
        const serviceData = result.value;
  
        const response = await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}/api/services/create`,
          serviceData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        
        if (response.data.code === 1){
          Swal.fire(
            "¡Creado!",
            "creado exitosamente.",
            "success"
          );
          setUpdateTrigger(!updateTrigger);
        }
        
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Accion cancelada.", "error");
        setUpdateTrigger(!updateTrigger);
      }
    } catch (error) {
      console.error(error)
    }
  };
  
  const UpdateService = async ( code ) => {
    try {
      
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/services/${code}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      });
      const serviceData = response.data.service;
      
      const result = await Swal.fire({
        title: `Editar Servicio de ${serviceData.service_es.name}`,
        html:
          '<div class="forma">' +
          '<ul class="nav nav-tabs" id="myTabs">' +
          '<li class="nav-item">' +
          '<a class="nav-link active" data-tab="tab1">Es</a>' +
          "</li>" +
          '<li class="nav-item">' +
          '<a class="nav-link" data-tab="tab2">En</a>' +
          "</li>" +
          "</ul>" +
      
          '<div class="tab-content">' +
      
          '<div class="tab-pane active" id="tab1">' +
          "<label>Es</label>" +
          "</br>" +
          "<div class='fields' >" +
          '<div class="input-container">' +
          '<label for="thumb_es">Thumb: </label>' +
          `<input type="text" id="thumb_es" class="swal2-input" value="${serviceData.service_es.thumb}">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="name_es">Nombre: </label>' +
          `<input type="text" id="name_es" class="swal2-input" value="${serviceData.service_es.name}">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="alert_es">Alerta: </label>' +
          `<input type="text" id="alert_es" class="swal2-input" value="${serviceData.service_es.alert}">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="description_es">Descripcion: </label>' +
          `<input type="text" id="description_es" class="swal2-input" value="${serviceData.service_es.description}">` +
          "</div>" +
          "</div>" +
          "</div>" +
      
          '<div class="tab-pane" id="tab2">' +
          "<label>En</label>" +
          "</br>" +
          "<div class='fields' >" +
          '<div class="input-container">' +
          '<label for="thumb_en">Thumb: </label>' +
          `<input type="text" id="thumb_en" class="swal2-input" value="${serviceData.service_us.thumb}">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="name_en">Name: </label>' +
          `<input type="text" id="name_en" class="swal2-input" value="${serviceData.service_us.name}">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="alert_en">Alert: </label>' +
          `<input type="text" id="alert_en" class="swal2-input" value="${serviceData.service_us.alert}">` +
          "</div>" +
          '<div class="input-container">' +
          '<label for="descripcion_en">Descripcion: </label>' +
          `<input type="text" id="descripcion_en" class="swal2-input" value="${serviceData.service_us.description}">` +
          "</div>" +
          "</div>" +
          "</div>" +
      
          "</div>" +
          "</div>" +
          "</div>" +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Editar",
        cancelButtonText: "Cancelar",
        customClass: {
          popup: "popupServicios",
        },
        preConfirm: function () {
          return new Promise(function (resolve) {
            resolve({
              code: serviceData.code,
              service_us: {
                name: document.getElementById("name_en").value,
                thumb: document.getElementById("thumb_en").value,
                alert: document.getElementById("alert_en").value,
                description: document.getElementById("descripcion_en").value,
              },
              service_es: {
                name: document.getElementById("name_es").value,
                thumb: document.getElementById("thumb_es").value,
                alert: document.getElementById("alert_es").value,
                description: document.getElementById("description_es").value,
              },
            });
          });
        },
        didRender: () => {
          // Agregar evento para cambiar de pestaña después de que se renderice el cuadro de diálogo
          document
            .getElementById("myTabs")
            .addEventListener("click", function (event) {
              const tabId = event.target.getAttribute("data-tab");
              if (tabId) {
                // Ocultar todas las pestañas y luego mostrar la pestaña seleccionada
                document.querySelectorAll(".tab-pane").forEach(function (tab) {
                  tab.classList.remove("active");
                });
                document.getElementById(tabId).classList.add("active");
              }
            });
        },
      });
  
      if (result.isConfirmed) {
        const serviceData = result.value;
  
        const response = await axios.put(
          `${process.env.REACT_APP_URL_BACKEND}/api/services/update/`,
          serviceData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        
        if (response.data.code === 1){
          Swal.fire(
            "¡Editado!",
            "Editado exitosamente.",
            "success"
          );
          setUpdateTrigger(!updateTrigger);
        }
        
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Accion cancelada.", "error");
        setUpdateTrigger(!updateTrigger);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const deleteService = async (code) => {
    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_URL_BACKEND}/api/services/${code}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
            },
          }
        );

        Swal.fire("¡Eliminado!", "Eliminado Exitosamente.", "success");
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "No se realizo ninguna accion)", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Algo salió mal");
    }
  };
  
  useEffect(() => {
    getServices()
  },[updateTrigger])
  
  
  return (
    <>
    <div className="panel-table">
    <div className='colum-left'>
      <input type="text" className='searchInput' id="searchInput" placeholder="Buscar..." />
      <button className='btnn' onClick={handleSearch}>Buscar</button>
    </div>
    <div className='colum-rigth'>
      <button className="btnn" onClick={createService}>
        Crear Servicio
      </button>
    </div>
    </div>
    <div className='table-responsive'>
      <table className='table table-preve' id='tableToSearch'>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <td className='accions'>Acciones</td>
          </tr>
        </thead>
        
        <tbody>
        {loading ? (<tr><td>Cargando...</td></tr>) : 
        ( filteredServices.map((service) => (
          <tr key={service.id} data-master-id={service.code}>
            <td>{service.code}</td>
            <td>{service.service_es.name}</td>
            <td>
            <FaEdit className='editButton' onClick={() =>{ UpdateService(service.code) }}/>
            <FaTimes className='deleteButton' onClick={() =>{ deleteService(service.code) }}/>
           
            </td>
          </tr>
        )))}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default Service