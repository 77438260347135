import { useQuill } from "react-quilljs";
import 'quill/dist/quill.snow.css';
import toolbar from './toolbar';
import './style.css'
import { useEffect } from "react";

function CreateQuillComponent({text, onQuillData}) {
    const {quill, quillRef} = useQuill({
        modules: {
            toolbar: toolbar,
        }
    });

    useEffect(() => {
        if (quill && text) {
            // quill.setText(text);
            quill.setContents(text);
        }
    }, [quill, text]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = JSON.stringify(quill.getContents())

        onQuillData(data);
    }

    return (
        <>
            <div>
                <label>Horario:</label>
                <div className="editorStations">
                    <div ref={quillRef}></div>
                </div>
                <button type="button" onClick={handleSubmit}>guardar horario</button>
            </div>
        </>
    )
}

export default CreateQuillComponent;