import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import TitleSection from "../../components/title-section/title-section";
import { useTranslation } from "react-i18next";
import "./contracts.css";
import navBarEn from "../../translations/en/navbar.json";
import navBarEs from "../../translations/es/navbar.json";
import Helmet from "react-helmet";

function Contracts() {
  const [tNabvar] = useTranslation("navbar");
  const [tContracts] = useTranslation("contracts");
  const [tSMetaData] = useTranslation("metaData");
  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].contracts}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].contracts}`
  );

  return (
    <>
      <Helmet>
        <title>{tSMetaData("contratos.title")}</title>
        <meta
          name="description"
          content={tSMetaData("contratos.description")}
        />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <TitleSection title={tContracts("title.title-page")} />

      <div className="container fs-14 arriba">
        <div className="container overflow-hidden">
          <div className="row gy-5">
            <div className="col">
              <div className="p-4 borde">
                <h4 className="nacional">{tContracts("nacional.titulo")}</h4>
                <div className="row gy-5">
                  <div className="col">
                    <p className="carga_nac">
                      {tContracts("nacional.parrafo")}
                    </p>
                  </div>
                </div>

                <div className="row gy-5">
                  <div className="col mb-2">
                    <a
                      href={`${tNabvar("category-services.nationalContract")}`}
                      className="button leer_btn"
                      role="button"
                    >
                      {tContracts("nacional.boton")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row gy-5 mt-1 abajo">
            <div className="col">
              <div className="p-3 borde">
                <h4 className="internacional">
                  {tContracts("internacional.titulo")}
                </h4>
                <div className="row gy-5">
                  <div className="col">
                    <p className="carga_inter">
                      {tContracts("internacional.parrafo")}
                    </p>
                  </div>
                </div>
                <div className="row gy-5">
                  <div className="col mb-3">
                    <a
                      href={`${tNabvar(
                        "category-services.internationalContract"
                      )}`}
                      className="button leer_btn"
                      role="button"
                    >
                      {tContracts("internacional.boton")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Contracts;
