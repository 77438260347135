import { Link } from 'react-router-dom';

function NoticesContent(props) {
  return (
    <div className="container">
        <div className="border-top py-5">
            <p className="px-3 px-md-0 mt-3 mt-md-5 title-alert">{props.title}</p>
            <p className="px-3 px-md-0 date-alert">{props.date}</p>
            <div className="my-5 p-3 notice-conteiner">
                <p className="fs-14 text-gray m-3">{props.info}<Link to={props.to} target="_blank" rel="noopener noreferrer">{props.here}</Link></p>
            </div>
            <div className="d-flex justify-content-center justify-content-md-start">
                <Link className="btn-return" to="../">{props.btn}</Link>
            </div>
        </div>
    </div>
  )
}

export default NoticesContent