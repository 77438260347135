import React from 'react'
import logo from "../../../../assets/imgs/navbar/logo_AMC_azul.svg";


function Bienvenido() {
  

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <img className="logo-amc-admin" src={logo} alt="logo amc"/>
        </div>
        <div className="col-12 d-flex align-items-center justify-content-center">
          <h1 className="text-menu-item">Bienvenido</h1>
        </div>
      </div>
    </>
  )
}

export default Bienvenido