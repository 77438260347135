import Footer from "../footer/Footer";
import Navbar from "../navbar/navbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./error404.css";
import ScrollToTop from "../scrollToTop/scrollToTop";

function Error404() {
  const [tError404] = useTranslation("error404");
  const navigate = useNavigate();

  const redirectHome = () => {
    navigate("/");
  };

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div className="container errorContainer">
        <p className="errorTitlte">{tError404("title")}</p>
        <p className="errorDescription">{tError404("description")}</p>
        <button onClick={redirectHome}>{tError404("button")}</button>
      </div>
      <Footer />
    </>
  );
}

export default Error404;
