import React from "react";
import { Link } from "react-router-dom";
import ".././fleet.css";
import ScrollToTop from "../../../../components/scrollToTop/scrollToTop";
import Navbar from "../../../../components/navbar/navbar";
import TitleSection from "../../../../components/title-section/title-section";
import Footer from "../../../../components/footer/Footer";
import erj190Img from "../../../../assets/imgs/about-us/fleet/erj-190.svg";
import { useTranslation } from "react-i18next";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";
import Helmet from "react-helmet";

function Erj190() {
  const [tFleet] = useTranslation("fleet");
  const [tSMetaData] = useTranslation("metaData");
  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"]["about-us"]}/${
      Object.keys(navBarEs["about-us"])[1]
    }/erj-190`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"]["about-us"]}/${
      Object.keys(navBarEn["about-us"])[1]
    }/erj-190`
  );

  return (
    <>
      <Helmet>
        <title>{tSMetaData("erj-190.title")}</title>
        <meta name="description" content={tSMetaData("erj-190.description")} />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <TitleSection title={tFleet("title.title-page")} />
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="pt-5 ps-md-5 p-0 col-12 col-md-9 col-xl-5">
            <div className="px-4 py-lg-4">
              <p className="fs-2 fw-bold font-semibold pb-0 pb-xl-5">
                {tFleet("erj-190.erj-190")}
              </p>
            </div>
            <div className="mt-0 mt-xl-5 px-4 py-4">
              <p className="fs-6 fw-bold text-premium-blue">
                {tFleet("erj-190.text1")}
              </p>
              <table className="table">
                <tbody>
                  <tr>
                    <th className="fs-6 text-gray" scope="row">
                      {tFleet("erj-190.text2")}
                    </th>
                    <td className="fs-6 text-gray text-end">
                      {tFleet("erj-190.text3")}
                    </td>
                  </tr>
                  <tr>
                    <th className="fs-6 text-gray" scope="row">
                      {tFleet("erj-190.text4")}
                    </th>
                    <td className="fs-6 text-gray text-end">
                      {tFleet("erj-190.text5")}
                      <sup>3</sup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-0 mt-xl-5 px-4 py-4">
              <p className="fs-6 fw-bold text-premium-blue">
                {tFleet("erj-190.text6")}
              </p>
              <table className="table">
                <tbody>
                  <tr>
                    <th className="fs-6 text-gray" scope="row">
                      {tFleet("erj-190.text7")}
                    </th>
                    <td className="fs-6 text-gray text-end">
                      {tFleet("erj-190.text8")}
                    </td>
                  </tr>
                  <tr>
                    <th className="fs-6 text-gray" scope="row">
                      {tFleet("erj-190.text9")}
                    </th>
                    <td className="fs-6 text-gray text-end">
                      {tFleet("erj-190.text10")}
                    </td>
                  </tr>
                  <tr>
                    <th className="fs-6 text-gray" scope="row">
                      {tFleet("erj-190.text11")}
                    </th>
                    <td className="fs-6 text-gray text-end">
                      {tFleet("erj-190.text12")}
                    </td>
                  </tr>
                  <tr>
                    <th className="fs-6 text-gray" scope="row">
                      {tFleet("erj-190.text13")}
                    </th>
                    <td className="fs-6 text-gray text-end">
                      {tFleet("erj-190.text14")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-3 col-xl-2 d-none d-md-block align-self-end">
            <p className="m-0 ps-3 ps-xl-0 p-0 text-rotate">
              {tFleet("erj-190.erj-190")}
            </p>
          </div>
          <div className="p-0 col-12 col-xl-5">
            <div className="fleet-img-container">
              <img className="" src={erj190Img} alt="ERJ 190" />
            </div>
            <div className="mt-0 mt-xl-5 p-4">
              <p className="fs-6 fw-bold text-premium-blue">
                {tFleet("erj-190.text15")}
              </p>
              <ul className="fleet-data text-gray">
                <li>{tFleet("erj-190.text16")}</li>
                <li>{tFleet("erj-190.text17")}</li>
              </ul>
              <div className="d-flex justify-content-center align-items-center pt-5">
                <Link className="btn-fleet" to="../b737-700" id="btnSiguiente190">
                  {tFleet("btn-fleet.next")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Erj190;
