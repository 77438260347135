import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/Footer";
import ScrollToTop from "../../../components/scrollToTop/scrollToTop";
import Swal from "sweetalert2";
import {
  msjService,
  goldService,
  animalesVivosService,
  dmdService,
  hivService,
  prsService,
  hitService,
  stdService,
  xspService,
} from "./functions";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "./style.css";

function ShippingInformation() {
  const [tNabvar] = useTranslation("navbar");
  const [tErrorCotizador] = useTranslation("errorCotizador");
  const [tSGetQuoteStepOne] = useTranslation("getQuoteStepOne");
  const [tSGetQuoteShippingInformation] = useTranslation(
    "getQuoteStepShipping"
  );
  const [tSStatiosNac] = useTranslation("stationsNationals");
  const cities = tSStatiosNac("nationals", { returnObjects: true });
  const services = tSGetQuoteStepOne("services", { returnObjects: true });
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(() => {
    const initialData = location.state?.formData || {};
    if (!initialData) {
      // Manejar formData vacío o inválido
      // console.error("Error: formData está vacío o inválido.");
      navigate(`/${tErrorCotizador("link")}`);
    }
    return initialData;
  });

  const codeService = services.find(
    (code) => code.name === formData.tipo_servicio
  );
  const [saveQuote, setSaveQuote] = useState(false);
  const [idQuote, setIdQuote] = useState("");

  const origen = cities.find((city) => city.abbreviation === formData.origen);
  const destino = cities.find((city) => city.abbreviation === formData.destino);

  const peso =
    formData.peso > formData.pesoCobrable
      ? formData.peso
      : formData.pesoCobrable;

  function summary(formData) {
    if (!formData) {
      console.error("Error: formData está vacío o inválido.");
      navigate(`/${tErrorCotizador("link")}`);
    }

    try {
      var bill;
      switch (formData.tipo_servicio) {
        case "Mensajería":
        case "Courier":
          bill = msjService(
            formData.tipo_servicio,
            codeService.code,
            formData.tipo_servicio_extra_animales,
            formData.tipo_servicio_extra_humanos,
            formData.origen,
            formData.destino,
            peso,
            parseFloat(formData.valor),
            formData.pago,
            formData.domicilio_cp
          );
          break;
        case "Gold":
          bill = goldService(
            formData.tipo_servicio,
            codeService.code,
            formData.tipo_servicio_extra_animales,
            formData.tipo_servicio_extra_humanos,
            formData.origen,
            formData.destino,
            peso,
            parseFloat(formData.valor),
            formData.pago,
            formData.domicilio_cp
          );
          break;
        case "Animales Vivos":
        case "Live Animals":
          bill = animalesVivosService(
            formData.tipo_servicio,
            codeService.code,
            formData.tipo_servicio_extra_animales,
            formData.tipo_servicio_extra_humanos,
            formData.origen,
            formData.destino,
            peso,
            parseFloat(formData.valor),
            formData.pago,
            formData.domicilio_cp
          );
          break;
        case "12 + 12":
          bill = dmdService(
            formData.tipo_servicio,
            codeService.code,
            formData.tipo_servicio_extra_animales,
            formData.tipo_servicio_extra_humanos,
            formData.origen,
            formData.destino,
            peso,
            parseFloat(formData.valor),
            formData.pago,
            formData.domicilio_cp
          );
          break;
        case "Alto Valor":
        case "High Value":
          bill = hivService(
            formData.tipo_servicio,
            codeService.code,
            formData.tipo_servicio_extra_animales,
            formData.tipo_servicio_extra_humanos,
            formData.origen,
            formData.destino,
            peso,
            parseFloat(formData.valor),
            formData.pago,
            formData.domicilio_cp
          );
          break;
        case "Perecederos":
        case "Perishable Goods":
          bill = prsService(
            formData.tipo_servicio,
            codeService.code,
            formData.tipo_servicio_extra_animales,
            formData.tipo_servicio_extra_humanos,
            formData.origen,
            formData.destino,
            peso,
            parseFloat(formData.valor),
            formData.pago,
            formData.domicilio_cp
          );
          break;
        case "Alta Tecnología":
        case "High Technology":
          bill = hitService(
            formData.tipo_servicio,
            codeService.code,
            formData.tipo_servicio_extra_animales,
            formData.tipo_servicio_extra_humanos,
            formData.origen,
            formData.destino,
            peso,
            parseFloat(formData.valor),
            formData.pago,
            formData.domicilio_cp
          );
          break;
        case "Pharma STD":
          bill = stdService(
            formData.tipo_servicio,
            codeService.code,
            formData.tipo_servicio_extra_animales,
            formData.tipo_servicio_extra_humanos,
            formData.origen,
            formData.destino,
            peso,
            parseFloat(formData.valor),
            formData.pago,
            formData.domicilio_cp
          );
          break;
        case "Pharma XPS":
          bill = xspService(
            formData.tipo_servicio,
            codeService.code,
            formData.tipo_servicio_extra_animales,
            formData.tipo_servicio_extra_humanos,
            formData.origen,
            formData.destino,
            peso,
            parseFloat(formData.valor),
            formData.pago,
            formData.domicilio_cp
          );
          break;
        default:
          break;
      }
      return bill;
    } catch (error) {
      console.error("Error en la función summary:", error);
    }
  }

  const bill = summary(formData);
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const formatWeight = (weight) => {
    const pesoFormateado = Number(weight).toFixed(2);
    return pesoFormateado;
  };

  const formatValue = (valor) => {
    // Redondea el valor a dos decimales
    valor = Math.round(valor * 100) / 100;

    // Formatea el valor como cadena con dos decimales
    const valorFormateado = valor.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return valorFormateado;
  };

  const generarPDF = async () => {
    try {
      const fechaActual = new Date();
      const year = fechaActual.getFullYear();
      const mes = String(fechaActual.getMonth() + 1).padStart(2, "0"); //
      const dia = String(fechaActual.getDate()).padStart(2, "0");
      const fechaFormateada = `${year}-${mes}-${dia}`;
      const content = [
        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: "Aeroméxico Cargo.",
                  style: "header",
                },
              ],
            ],
          },
        },
        {
          text: `${tSGetQuoteShippingInformation("title")}`,
          style: "subheader",
        },
        {
          text: [{ text: "Fecha: ", style: "label" }, `${fechaFormateada}`],
        },
        {
          text: `${tSGetQuoteShippingInformation("numberQuote")}: ${idQuote}`,
          style: "label",
        },
        {
          text: `${tSGetQuoteShippingInformation("subtitile")}`,
          style: "subheader",
        },
        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("product")}: `,
                      style: "labelBold",
                    },
                    `\n ${formData.tipo_servicio}`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("paidAt")}: `,
                      style: "labelBold",
                    },
                    `\n ${formData.pago}`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("origin")}: `,
                      style: "labelBold",
                    },
                    `\n ${origen.name} (${origen.abbreviation})`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("destination")}: `,
                      style: "labelBold",
                    },
                    `\n ${destino.name} (${destino.abbreviation})`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("piece")}: `,
                      style: "labelBold",
                    },
                    `\n ${formData.piezas}`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("weight")}: `,
                      style: "labelBold",
                    },
                    `\n ${formData.peso}`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "chargeableWeight"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${formData.pesoCobrable}`,
                  ],
                  style: "cell1",
                },
              ],
              // Agrega más filas según sea necesario
            ],
          },
        },
        {
          text: "Información adicional.",
          style: "subheader",
        },
        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("description")}: `,
                      style: "labelBold",
                    },
                    `\n ${formData.description}`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "declaredValue"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n $${formData.valor} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("deliveryAt")}: `,
                      style: "labelBold",
                    },
                    `\n ${formData.entrega}`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "otherCharges"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.otrosCargos} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "carriageCharge"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.acarreo} MXN`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "groundOperation"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.operacionTerrestre} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "certificationFee"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.certificationFee} MXN`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "payOnCollection"
                      )}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.pagoCC} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              // Agrega más filas según sea necesario
            ],
          },
        },
        {
          text: "Desglose de costo.",
          style: "subheader",
        },
        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("Freight")}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.flete} MXN`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("fixedCharge")}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.cargoFijo} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation(
                        "declaredValue"
                      )} (VA): `,
                      style: "labelBold",
                    },
                    `\n ${bill.valoracion} MXN`,
                  ],
                  style: "cell1",
                },
              ],

              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("fuelCharge")}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.combistible} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("subtotal")}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.subTotal} MXN`,
                  ],
                  style: "cell1",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("vat")}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.IVA} MXN`,
                  ],
                  style: "cell2",
                },
              ],
              [
                {
                  text: [
                    {
                      text: `${tSGetQuoteShippingInformation("total")}: `,
                      style: "labelBold",
                    },
                    `\n ${bill.total} MXN`,
                  ],
                  style: "cell1",
                },
              ],
              // Agrega más filas según sea necesario
            ],
          },
        },
        {
          text: [
            "Para mayor información consulte a nuestros ejecutivos en los teléfonos LADA SIN COSTO 800 ENVIA AM o al Tel. (55)5133 0237",
            {
              text: "\n\nEste documento es una estimación conforme Los datos ingresados en el formulario, el cálculo que se presenta está en función de nuestra tarifa de fletes y precios de zona. la cotización puede variar en función de las medidas, peso y manejo especial que pueda requerir su embarque al momento de documentarse. No incluye, servicios adicionales. Esta cotización es presentada sólo con fines informativos; No constituye un documento de obligatoriedad legal.",
              style: "footerText1",
            },
            {
              text: "\n\n* Precios sujetos a cambio sin previo aviso.",
              style: "footerText2",
            },
            {
              text: "\n\n© Copyright 2024 | Todos los derechos reservados, Aeroméxico Cargo.",
              style: "footerText3",
            },
          ],
          style: "footer",
        },
      ];

      const styles = {
        header: {
          fontSize: 20,
          color: "white",
          fillColor: "#0b2343",
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 18,
          color: "#0b2343",
          bold: true,
          margin: [0, 30, 40, 15],
        },
        label: {
          fontSize: 16,
          color: "#212b36",
          bold: true,
        },
        label2: {
          fontSize: 16,
          color: "#212b36",
          bold: true,
          margin: [0, 30, 40, 15],
        },
        cell1: {
          fontSize: 12,
          color: "#65758d",
          bold: true,
          border: [2, 2, 2, 2],
          fillColor: "#f4f6f8",
          padding: 10,
          margin: [3, 0, 0, 2],
        },
        cell2: {
          fontSize: 12,
          color: "#65758d",
          bold: true,
          border: [2, 2, 2, 2],
          fillColor: "#ffffff",
          padding: 10,
          margin: [3, 0, 0, 2],
        },
        footer: {
          bold: true,
          fontSize: 13,
          color: "#0b2343",
          margin: [0, 60, 0, 0],
        },
        footerText1: {
          fontSize: 10,
          color: "#637488",
        },
        footerText2: {
          fontSize: 10,
          color: "#212b36",
          bold: true,
        },
        footerText3: {
          fontSize: 10,
          color: "#0b2343",
          bold: true,
        },
        labelBold: {
          bold: true,
          fontSize: 14,
          color: "#212b36",
        },
      };

      const documentDefinition = {
        content: content,
        styles: styles,
      };

      pdfMake.createPdf(documentDefinition).open();
    } catch (error) {
      Swal.fire("Algo salió mal", "Por favor, intenta nuevamente.", "warning");
    }
  };

  const saveQuotation = async () => {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const mes = String(fechaActual.getMonth() + 1).padStart(2, "0"); //
    const dia = String(fechaActual.getDate()).padStart(2, "0");
    const fechaFormateada = `${year}-${mes}-${dia}`;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_BACKEND}/api/quotation/create`,
        {
          service: formData.tipo_servicio,
          origin: `${origen.name} (${origen.abbreviation})`,
          destination: `${destino.name} (${destino.abbreviation})`,
          pieces: formData.piezas,
          weight: formData.peso,
          payment: formData.pago,
          delivery: formData.entrega,
          description: formData.description,
          declarate_value: formData.valor,
          postal_code: formData.domicilio_cp,
          other_charges: bill.otrosCargos,
          chargeable_weight: peso,
          freight: bill.flete,
          FixedCharge: bill.cargoFijo,
          fuel: bill.combistible,
          certificationFB: bill.certificationFee,
          valuation: bill.valoracion,
          groundOperation: bill.operacionTerrestre,
          acarreo: bill.acarreo,
          pagoCC: bill.pagoCC,
          subTotal: bill.subTotal,
          iva: bill.IVA,
          total: bill.total,
          date: fechaFormateada,
        },
        {
          headers: {
            authorization: `${process.env.REACT_APP_PUBLIC_KEY}`,
          },
        }
      );
      setSaveQuote(true);
      setIdQuote(response.data.id);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!formData) navigate(`/${tErrorCotizador("link")}`);

    if (saveQuote === false) {
      saveQuotation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !bill ? (
    navigate(`/${tErrorCotizador("link")}`)
  ) : (
    <>
      <ScrollToTop />
      <Navbar />
      <main
        className="cotizacion page js-page"
        style={{ visibility: "inherit", opacity: "1" }}
      >
        <section className="cotizacion__section">
          <div className="cotizacion__title">
            <div>
              <h2>{tSGetQuoteShippingInformation("title")}</h2>
            </div>
            <div>
              <h2 className="idQuote">
                {tSGetQuoteShippingInformation("numberQuote")}
              </h2>
              <span>{idQuote}</span>
            </div>
          </div>
          <div className="cotizacion__table">
            <div className="shipment__table__layout">
              <div className="cotizacion__table__information">
                <div className="cotizacion__table__title">
                  <h4>
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"></path>
                    </svg>
                    {tSGetQuoteShippingInformation("subtitile")}
                  </h4>
                </div>
                <ul className="cotizacion__table__component">
                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M0 448V64h18v384H0zm26.857-.273V64H36v383.727h-9.143zm27.143 0V64h8.857v383.727H54zm44.857 0V64h8.857v383.727h-8.857zm36 0V64h17.714v383.727h-17.714zm44.857 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm35.715 0V64h18v383.727h-18zm44.857 0V64h18v383.727h-18zm35.999 0V64h18.001v383.727h-18.001zm36.001 0V64h18.001v383.727h-18.001zm26.857 0V64h18v383.727h-18zm45.143 0V64h26.857v383.727h-26.857zm35.714 0V64h9.143v383.727H476zm18 .273V64h18v384h-18z"></path>
                      </svg>
                      {tSGetQuoteShippingInformation("serviceCode")}
                    </label>
                    <p>{codeService.code}</p>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M128 480h256V80c0-26.5-21.5-48-48-48H176c-26.5 0-48 21.5-48 48v400zm64-384h128v32H192V96zm320 80v256c0 26.5-21.5 48-48 48h-48V128h48c26.5 0 48 21.5 48 48zM96 480H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48h48v352z"></path>
                      </svg>
                      {tSGetQuoteShippingInformation("product")}
                    </label>
                    <p>{formData.tipo_servicio}</p>
                    <p
                      style={
                        formData.tipo_servicio_extra_animales === ""
                          ? { visibility: "hidden", opacity: "0" }
                          : { visibility: "visible", opacity: "1" }
                      }
                    >
                      ({formData.tipo_servicio_extra_animales})
                    </p>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM80.55 341.27c6.28 6.84 15.1 10.72 24.33 10.71l130.54-.18a65.62 65.62 0 0 0 29.64-7.12l290.96-147.65c26.74-13.57 50.71-32.94 67.02-58.31 18.31-28.48 20.3-49.09 13.07-63.65-7.21-14.57-24.74-25.27-58.25-27.45-29.85-1.94-59.54 5.92-86.28 19.48l-98.51 49.99-218.7-82.06a17.799 17.799 0 0 0-18-1.11L90.62 67.29c-10.67 5.41-13.25 19.65-5.17 28.53l156.22 98.1-103.21 52.38-72.35-36.47a17.804 17.804 0 0 0-16.07.02L9.91 230.22c-10.44 5.3-13.19 19.12-5.57 28.08l76.21 82.97z"></path>
                      </svg>{" "}
                      {tSGetQuoteShippingInformation("origin")}
                    </label>
                    <data>
                      {origen.name} ({origen.abbreviation})
                    </data>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM44.81 205.66l88.74 80a62.607 62.607 0 0 0 25.47 13.93l287.6 78.35c26.48 7.21 54.56 8.72 81 1.36 29.67-8.27 43.44-21.21 47.25-35.71 3.83-14.5-1.73-32.71-23.37-54.96-19.28-19.82-44.35-32.79-70.83-40l-97.51-26.56L282.8 30.22c-1.51-5.81-5.95-10.35-11.66-11.91L206.05.58c-10.56-2.88-20.9 5.32-20.71 16.44l47.92 164.21-102.2-27.84-27.59-67.88c-1.93-4.89-6.01-8.57-11.02-9.93L52.72 64.75c-10.34-2.82-20.53 5-20.72 15.88l.23 101.78c.19 8.91 6.03 17.34 12.58 23.25z"></path>
                      </svg>{" "}
                      {tSGetQuoteShippingInformation("destination")}
                    </label>
                    <data>
                      {destino.name} ({destino.abbreviation})
                    </data>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M128 96c26.5 0 48-21.5 48-48S154.5 0 128 0 80 21.5 80 48s21.5 48 48 48zm384 0c26.5 0 48-21.5 48-48S538.5 0 512 0s-48 21.5-48 48 21.5 48 48 48zm125.7 372.1l-44-110-41.1 46.4-2 18.2 27.7 69.2c5 12.5 17 20.1 29.7 20.1 4 0 8-.7 11.9-2.3 16.4-6.6 24.4-25.2 17.8-41.6zm-34.2-209.8L585 178.1c-4.6-20-18.6-36.8-37.5-44.9-18.5-8-39-6.7-56.1 3.3-22.7 13.4-39.7 34.5-48.1 59.4L432 229.8 416 240v-96c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16v96l-16.1-10.2-11.3-33.9c-8.3-25-25.4-46-48.1-59.4-17.2-10-37.6-11.3-56.1-3.3-18.9 8.1-32.9 24.9-37.5 44.9l-18.4 80.2c-4.6 20 .7 41.2 14.4 56.7l67.2 75.9 10.1 92.6C130 499.8 143.8 512 160 512c1.2 0 2.3-.1 3.5-.2 17.6-1.9 30.2-17.7 28.3-35.3l-10.1-92.8c-1.5-13-6.9-25.1-15.6-35l-43.3-49 17.6-70.3 6.8 20.4c4.1 12.5 11.9 23.4 24.5 32.6l51.1 32.5c4.6 2.9 12.1 4.6 17.2 5h160c5.1-.4 12.6-2.1 17.2-5l51.1-32.5c12.6-9.2 20.4-20 24.5-32.6l6.8-20.4 17.6 70.3-43.3 49c-8.7 9.9-14.1 22-15.6 35l-10.1 92.8c-1.9 17.6 10.8 33.4 28.3 35.3 1.2.1 2.3.2 3.5.2 16.1 0 30-12.1 31.8-28.5l10.1-92.6 67.2-75.9c13.6-15.5 19-36.7 14.4-56.7zM46.3 358.1l-44 110c-6.6 16.4 1.4 35 17.8 41.6 16.8 6.6 35.1-1.7 41.6-17.8l27.7-69.2-2-18.2-41.1-46.4z"></path>
                      </svg>{" "}
                      {tSGetQuoteShippingInformation("deliveryAt")}
                    </label>
                    <data>{formData.entrega}</data>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M224 320h32V96h-32c-17.67 0-32 14.33-32 32v160c0 17.67 14.33 32 32 32zm352-32V128c0-17.67-14.33-32-32-32h-32v224h32c17.67 0 32-14.33 32-32zm48 96H128V16c0-8.84-7.16-16-16-16H16C7.16 0 0 7.16 0 16v32c0 8.84 7.16 16 16 16h48v368c0 8.84 7.16 16 16 16h82.94c-1.79 5.03-2.94 10.36-2.94 16 0 26.51 21.49 48 48 48s48-21.49 48-48c0-5.64-1.15-10.97-2.94-16h197.88c-1.79 5.03-2.94 10.36-2.94 16 0 26.51 21.49 48 48 48s48-21.49 48-48c0-5.64-1.15-10.97-2.94-16H624c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM480 96V48c0-26.51-21.49-48-48-48h-96c-26.51 0-48 21.49-48 48v272h192V96zm-48 0h-96V48h96v48z"></path>
                      </svg>{" "}
                      {tSGetQuoteShippingInformation("piece")}
                    </label>
                    <data>{formData.piezas} Pza.</data>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M510.28 445.86l-73.03-292.13c-3.8-15.19-16.44-25.72-30.87-25.72h-60.25c3.57-10.05 5.88-20.72 5.88-32 0-53.02-42.98-96-96-96s-96 42.98-96 96c0 11.28 2.3 21.95 5.88 32h-60.25c-14.43 0-27.08 10.54-30.87 25.72L1.72 445.86C-6.61 479.17 16.38 512 48.03 512h415.95c31.64 0 54.63-32.83 46.3-66.14zM256 128c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32z"></path>
                      </svg>{" "}
                      {tSGetQuoteShippingInformation("weight")}
                    </label>
                    <data>{formatWeight(formData.peso)} Kg</data>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M208 320h384c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H448v128l-48-32-48 32V32H208c-8.8 0-16 7.2-16 16v256c0 8.8 7.2 16 16 16zm416 64H128V16c0-8.8-7.2-16-16-16H16C7.2 0 0 7.2 0 16v32c0 8.8 7.2 16 16 16h48v368c0 8.8 7.2 16 16 16h82.9c-1.8 5-2.9 10.4-2.9 16 0 26.5 21.5 48 48 48s48-21.5 48-48c0-5.6-1.2-11-2.9-16H451c-1.8 5-2.9 10.4-2.9 16 0 26.5 21.5 48 48 48s48-21.5 48-48c0-5.6-1.2-11-2.9-16H624c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"></path>
                      </svg>{" "}
                      {tSGetQuoteShippingInformation("chargeableWeight")}
                    </label>
                    <data>{formatWeight(peso)} Kg</data>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M12.83 352h262.34A12.82 12.82 0 0 0 288 339.17v-38.34A12.82 12.82 0 0 0 275.17 288H12.83A12.82 12.82 0 0 0 0 300.83v38.34A12.82 12.82 0 0 0 12.83 352zm0-256h262.34A12.82 12.82 0 0 0 288 83.17V44.83A12.82 12.82 0 0 0 275.17 32H12.83A12.82 12.82 0 0 0 0 44.83v38.34A12.82 12.82 0 0 0 12.83 96zM432 160H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0 256H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"></path>
                      </svg>{" "}
                      {tSGetQuoteShippingInformation("description")}
                    </label>
                    <data>{formData.description}</data>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M608 32H32C14.33 32 0 46.33 0 64v384c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zM176 327.88V344c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V152c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07zM416 312c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h112c4.42 0 8 3.58 8 8v16zm160 0c0 4.42-3.58 8-8 8h-80c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16zm0-96c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h272c4.42 0 8 3.58 8 8v16z"></path>
                      </svg>{" "}
                      {tSGetQuoteShippingInformation("paidAt")}
                    </label>
                    <data>{formData.pago}</data>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"></path>
                      </svg>{" "}
                      {tSGetQuoteShippingInformation("postalCode")}
                    </label>
                    <data>
                      {formData.domicilio_cp !== ""
                        ? formData.domicilio_cp
                        : "N/A"}
                    </data>
                  </li>

                  <li>
                    <label>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M400 0H48C22.4 0 0 22.4 0 48v416c0 25.6 22.4 48 48 48h352c25.6 0 48-22.4 48-48V48c0-25.6-22.4-48-48-48zM128 435.2c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8V268.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v166.4zm0-256c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8V76.8C64 70.4 70.4 64 76.8 64h294.4c6.4 0 12.8 6.4 12.8 12.8v102.4z"></path>
                      </svg>{" "}
                      {tSGetQuoteShippingInformation("declaredValue")}
                    </label>
                    <data>
                      {formData.valor !== ""
                        ? `${formatValue(formData.valor)} MXN`
                        : "N/A"}
                    </data>
                  </li>
                </ul>
              </div>
              <div
                className="cotizacion__table__notes is-alert-important"
                role="alert"
              >
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path>
                </svg>
                <p>
                  <strong>
                    {tSGetQuoteShippingInformation("info1.text1")}
                  </strong>{" "}
                  {tSGetQuoteShippingInformation("info1.text2")}
                </p>
              </div>
            </div>

            <div className="cotizacion__table__prices">
              <ul>
                <li>
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>{tSGetQuoteShippingInformation("Freight")}</span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.flete}</data>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>
                        {tSGetQuoteShippingInformation("fixedCharge")}
                      </span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.cargoFijo}</data>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>{tSGetQuoteShippingInformation("fuelCharge")}</span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.combistible}</data>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>
                        {tSGetQuoteShippingInformation("certificationFee")}
                      </span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.certificationFee}</data>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>{tSGetQuoteShippingInformation("valuation")}</span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.valoracion}</data>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>
                        {tSGetQuoteShippingInformation("otherCharges")}
                      </span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.otrosCargos}</data>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>
                        {tSGetQuoteShippingInformation("groundOperation")}
                      </span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.operacionTerrestre}</data>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>
                        {tSGetQuoteShippingInformation("carriageCharge")}
                      </span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.acarreo}</data>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>
                        {tSGetQuoteShippingInformation("payOnCollection")}
                      </span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.pagoCC}</data>
                    </div>
                  </div>
                </li>

                <li className="is-subtotal">
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>{tSGetQuoteShippingInformation("subtotal")}</span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.subTotal}</data>
                    </div>
                  </div>
                </li>

                <li className="is-iva">
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path d="M358.4 3.2L320 48 265.6 3.2a15.9 15.9 0 0 0-19.2 0L192 48 137.6 3.2a15.9 15.9 0 0 0-19.2 0L64 48 25.6 3.2C15-4.7 0 2.8 0 16v480c0 13.2 15 20.7 25.6 12.8L64 464l54.4 44.8a15.9 15.9 0 0 0 19.2 0L192 464l54.4 44.8a15.9 15.9 0 0 0 19.2 0L320 464l38.4 44.8c10.5 7.9 25.6.4 25.6-12.8V16c0-13.2-15-20.7-25.6-12.8zM320 360c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v16z"></path>
                        </svg>{" "}
                        {tSGetQuoteShippingInformation("vat")}
                      </span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.IVA}</data>
                    </div>
                  </div>
                </li>

                <li className="is-total">
                  <div className="cotizacion__table__prices__row">
                    <div className="cotizacion__table__prices__left">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 58.63 44.91"
                        >
                          <path
                            id="Path_37"
                            data-name="Path 37"
                            d="M59.21,28.15a1,1,0,0,0-1.29-.53L47.74,31.85V28.19l10.94-4.58a1,1,0,0,0-.7-1.84l-.06,0L47.74,26.05v-4l10.94-4.55a1,1,0,0,0-.75-1.81L47.75,20v-3.5l11-4.84.11-.08a.66.66,0,0,0,.17-.12.61.61,0,0,0,.12-.16.44.44,0,0,0,.08-.11v0a.65.65,0,0,0,.05-.2.57.57,0,0,0,0-.18,1,1,0,0,0,0-.17,1.18,1.18,0,0,0,0-.2v0a.76.76,0,0,0-.08-.11.88.88,0,0,0-.12-.17.61.61,0,0,0-.16-.12l-.11-.08L40.25.65a1,1,0,0,0-.82,0l-38.11,16-.11.08L1,16.81A.76.76,0,0,0,.91,17a.44.44,0,0,0-.08.11v0a1.52,1.52,0,0,0-.06.2.47.47,0,0,0,0,.17,1,1,0,0,0,0,.17,1.55,1.55,0,0,0,0,.21v0s0,0,0,0a1,1,0,0,0,.28.34l.09.07s0,0,.06,0L19.5,27.56a1,1,0,0,0,.44.09,1.1,1.1,0,0,0,.4-.08l11.55-5.09v3.8L20,31.49,2.15,22.54a1,1,0,0,0-1,1.71l.09.05L19.5,33.45a1,1,0,0,0,.84,0l11.56-5v4L20,37.57,2.16,28.63a1,1,0,0,0-1,1.71l.09.05,18.23,9.15a1,1,0,0,0,.44.1,1,1,0,0,0,.39-.08l11.56-5v3.9L20,43.39l-17.83-9a1,1,0,0,0-.89,1.76L19.5,45.35a1,1,0,0,0,.44.1,1,1,0,0,0,.38-.08L33.26,40h0L58.69,29.44A1,1,0,0,0,59.21,28.15ZM39.78,2.61,56,10.68l-9.2,4.06L30.52,6.49ZM20,25.59l-15.91-8,11.14-4.67,15.4,8Z"
                            transform="translate(-0.68 -0.55)"
                          ></path>
                        </svg>{" "}
                        {tSGetQuoteShippingInformation("total")}
                      </span>
                    </div>
                    <div className="cotizacion__table__prices__right">
                      <data>{bill.total} MXN</data>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="cotizacion__table__buttons">
              <div className="cotizacion__table__buttons__row" role="row">
                <div className="cotizacion__table__buttons__column__left">
                  <a
                    href={`/${tNabvar("category-services.quote")}`}
                    className="button button-primary"
                    name="newQuote"
                    id="newQuote"
                    role="button"
                  >
                    {tSGetQuoteShippingInformation("buttons.new")}
                  </a>
                </div>
                <div className="cotizacion__table__buttons__column__right">
                  <a
                    href={`/${tNabvar("category-services.contact")}`}
                    className="button button-primary"
                    name="contact"
                    id="quoteContac"
                    role="button"
                    target="blank"
                  >
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                    </svg>{" "}
                    {tSGetQuoteShippingInformation("buttons.contac")}
                  </a>
                  <button
                    type="button"
                    name="download"
                    id="printQuote"
                    className="button button-primary"
                    formTarget="_blank"
                    onClick={generarPDF}
                  >
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path>
                    </svg>{" "}
                    {tSGetQuoteShippingInformation("buttons.print")}
                  </button>
                </div>
              </div>
            </div>

            <div className="cotizacion__table__help">
              <h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 15.975 15.975"
                >
                  <g transform="translate(-0.006)">
                    <g transform="translate(0.006)">
                      <path
                        d="M12.151,55.172a1.119,1.119,0,0,0-1.692,0c-.4.392-.791.785-1.18,1.183a.233.233,0,0,1-.326.06c-.256-.14-.529-.253-.775-.406A12.273,12.273,0,0,1,5.22,53.317a7.009,7.009,0,0,1-1.06-1.7.243.243,0,0,1,.06-.312c.4-.382.781-.775,1.17-1.167a1.124,1.124,0,0,0,0-1.732c-.309-.312-.618-.618-.927-.931s-.635-.642-.957-.957a1.126,1.126,0,0,0-1.692,0c-.4.392-.781.795-1.187,1.18A1.926,1.926,0,0,0,.017,49a5.507,5.507,0,0,0,.426,2.37A14.4,14.4,0,0,0,3,55.63a15.82,15.82,0,0,0,5.239,4.1,7.577,7.577,0,0,0,2.9.844,2.127,2.127,0,0,0,1.825-.695c.339-.379.721-.725,1.08-1.087a1.131,1.131,0,0,0,.007-1.722Q13.105,56.117,12.151,55.172Z"
                        transform="translate(-0.006 -44.604)"
                      ></path>
                      <path
                        d="M241.458,100.727l1.227-.209A5.506,5.506,0,0,0,238.027,96l-.173,1.233a4.255,4.255,0,0,1,3.6,3.494Z"
                        transform="translate(-229.947 -92.809)"
                      ></path>
                      <path
                        id="Path_73"
                        d="M248.113,2.586A9.045,9.045,0,0,0,242.927,0l-.173,1.233a7.892,7.892,0,0,1,6.679,6.472l1.227-.209A9.111,9.111,0,0,0,248.113,2.586Z"
                        transform="translate(-234.684)"
                      ></path>
                    </g>
                  </g>
                </svg>
                {tSGetQuoteShippingInformation("info2.title")}
              </h4>
              <p>{tSGetQuoteShippingInformation("info2.text1")}</p>
              <span>{tSGetQuoteShippingInformation("info2.text2")}</span>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default ShippingInformation;
