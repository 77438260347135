import { Link } from 'react-router-dom';

function NoticesAlerts(props) {
  return (
    <div className="container pt-5">
        <div className="border-top py-4">
            <p className="title-alert">{props.title}</p>
            <p className="date-alert">{props.date}</p>
            <p className="fs-14 text-gray mb-5">{props.info}</p>
            <Link className="btn-alert" to={props.to} id={props.id}>{props.btn}</Link>
        </div>
    </div>
  )
}

export default NoticesAlerts