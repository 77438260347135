import { Navigate, useLocation } from "react-router-dom";
import Thanks from "../../views/contact/thanks";

function ProtectedRoute() {
    const location = useLocation();
    const statusThanks = location.state?.statusThanks || 'false';

    return (
        statusThanks === 'true' ? <Thanks /> : <Navigate to={'/'} replace />
    );
}

export default ProtectedRoute;