import React, { useState } from 'react';
import logo from "../../assets/imgs/navbar/logo_AMC.svg";
import { Link } from "react-router-dom";
import "./navbar.css";
import menuIcon from "../../assets/imgs/navbar/menu-open.svg"
import menuIconClicked from "../../assets/imgs/navbar/menu-closed.svg"
import mexIcon from "../../assets/imgs/navbar/mex_svg.svg";
import usaIcon from "../../assets/imgs/navbar/usa_svg.svg";
import { useTranslation } from "react-i18next";
import BtnSubMenu from "./btnSubMenu/btnSubMenu";
import { useNavigate } from 'react-router-dom';

function Navbar() {
    const navigate = useNavigate();
    const [tNabvar] = useTranslation("navbar")
    const [activeButton, setActiveButton] = useState(localStorage.getItem('lng') || "es");
    const handleLanguageChange = (language) => {
        localStorage.setItem("lng", language)
        if(language === 'us') {
            navigate(sessionStorage.getItem('routeEn'));
        } else {
            navigate(sessionStorage.getItem('routeEs'));
        }
        window.location.reload();
    };
    const productsData = tNabvar("products-submenu", { returnObjects: true });
    const stationsData = tNabvar("stations-submenu", { returnObjects: true });
    const prepareShipmentData = tNabvar("prepare-shipment-submenu", { returnObjects: true });
    const aboutUsData = tNabvar("about-us", { returnObjects: true });
    const closeMobileMenu = () => setClick(false);

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    return (
        <div className='static-nav-mb'>
            <div className="container-fluid navbar-aero">
                <div className="">
                    <Link to='/' id='btnNavbarHome' onClick={closeMobileMenu}>
                        <img className="logo-amc" src={logo} alt="logo amc" />
                    </Link>
                </div>
                <div className="menu-icon" onClick={handleClick}>
                    <img src={click ? menuIconClicked : menuIcon} alt={click ? 'Closed Menu' : 'Open Menu'} />
                </div>
                <div className={click ? 'nav-menu active' : 'nav-menu'}>
                    <div className="py-2 nav-item border-btn web-item-none">
                        <p className="fw-bold mobile-menu-item py-2">{tNabvar("principal-buttons.main-menu")}</p>
                    </div>
                    <div className="nav-item border-btn">
                        <BtnSubMenu 
                            title={tNabvar("principal-buttons.products")}
                            subMenu={productsData}
                            category={tNabvar("category-services.services")}
                            subCategory={'products'}
                        />
                    </div>
                    <div className="nav-item border-btn">
                        <BtnSubMenu 
                            title={tNabvar("principal-buttons.stations")}
                            subMenu={stationsData}
                            category={tNabvar("category-services.stations")}
                            subCategory={'stations'}
                        />
                    </div>
                    <div className="nav-item border-btn">
                        <BtnSubMenu 
                            title={tNabvar("principal-buttons.prepare-shipment")}
                            subMenu={prepareShipmentData}
                            category={tNabvar("category-services.shipments")}
                            subCategory={"shipments"}
                        />
                    </div>
                    <div className="nav-item border-btn">
                        <BtnSubMenu 
                            title={tNabvar("principal-buttons.track-waybill")}
                            category={tNabvar("category-services.track")}
                        />
                    </div>
                    <div className="nav-item border-btn">
                        <BtnSubMenu 
                            title={tNabvar("principal-buttons.get-a-quote")}
                            category={tNabvar("category-services.quote")}
                        />
                    </div>
                    <div className="nav-item border-btn web-item-none">
                        <BtnSubMenu 
                            title={tNabvar("about-us.aeromexico-cargo")}
                            subMenu={aboutUsData}
                            category={tNabvar("category-services.about-us")}
                            subCategory={'products'}
                        />
                    </div>
                    <div className="nav-item border-btn web-item-none">
                        <Link to={`/${tNabvar("category-services.alert")}`} onClick={closeMobileMenu} className='nav-links'>
                            {tNabvar("principal-buttons.alerts-and-news")}
                        </Link>
                    </div>
                    {/* <div className="nav-item web-item-none">
                        <Link to='/' onClick={closeMobileMenu} className='nav-links'>
                            {tNabvar("principal-buttons.online-help")}
                        </Link>
                    </div> */}
                    <div className="nav-item mobile-item-none">
                        <BtnSubMenu 
                            title={tNabvar("principal-buttons.about-us")}
                            subMenu={aboutUsData}
                            category={tNabvar("category-services.about-us")}
                            subCategory={'products'}
                        />
                    </div>
                    <div className="nav-item border-btn web-item-none">
                        <p className="fw-bold py-3 mobile-menu-item">{tNabvar("principal-buttons.information")}</p>
                    </div>
                    <div className="py-2 nav-item web-item-none">
                        <Link to={`/${tNabvar("category-services.contracts")}`} onClick={closeMobileMenu} className='mobile-menu-item text-decoration-none'>
                            {tNabvar("principal-buttons.contracts")}
                        </Link>
                    </div>
                    <div className="nav-item web-item-none">
                        <Link to={`/${tNabvar("category-services.privacy")}`} onClick={closeMobileMenu} className='mobile-menu-item text-decoration-none'>
                            {tNabvar("principal-buttons.privacy-notice")}
                        </Link>
                    </div>
                    <div className="py-2 nav-item border-btn web-item-none">
                        <Link to={`/${tNabvar("category-services.compliance")}`} onClick={closeMobileMenu} className='mobile-menu-item text-decoration-none'>
                            {tNabvar("principal-buttons.compliance")}
                        </Link>
                    </div>
                    <div className="py-2 nav-item web-item-none">
                        <p className="mobile-menu-item">{tNabvar("principal-buttons.copyright")}</p>
                        <p className="mobile-menu-item">{tNabvar("principal-buttons.rights-reserved")}</p>
                    </div>
                    <div className="py-2 py-lg-0 ps-3 gap-2 d-flex justify-content-start bg-gray order-first order-lg-last">
                        <button id='btnLangEsp' onClick={() => handleLanguageChange("es")} 
                            className={`btn-language ${activeButton === "es" ? "btn-language-active" : ""}`}>
                            <p>ESP</p>
                            <img src={mexIcon} alt="México" />
                        </button>
                        <div className="btn-divider align-self-center">|</div>
                        <button id='btnLangEng' onClick={() => handleLanguageChange("us")}
                            className={`btn-language ${activeButton === "us" ? "btn-language-active" : ""}`}>
                            <p>ENG</p>
                            <img src={usaIcon} alt="México" />
                        </button>
                    </div>
                </div>    
            </div>
        </div>
    );
}

export default Navbar;