import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaCircleInfo } from "react-icons/fa6";
import React from 'react';
import './style.css'

function TooltipComponet( { text, position } ) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className='text'>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={position}
      delay={{ show: 50, hide: 50 }}
      overlay={renderTooltip}
    >
      <div className='iconContainer' style={{ display: 'inline-block' }}>
        <FaCircleInfo className='icon' />
      </div>
    </OverlayTrigger>
  );
}

export default TooltipComponet;
