import './trackBtnContact.css';
import contactWhite from "../../assets/imgs/track/contacto_blanco.svg";
import contactBlue from "../../assets/imgs/track/contacto_Azul.svg";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function TrackBtnContact({x, y}) {
    const trackInfoBox = document.querySelector('.trackInfoBox');
    const windowWidth = window.innerWidth;
    const imgSrc = windowWidth < 600 ? contactBlue : contactWhite;
    const [modalOpen, setModalOpen] = useState(false);
    const [tTrack] = useTranslation("trackPage");
    const [tNabvar] = useTranslation("navbar");

    useEffect(() => {
        applyTranslateValues();
    }, [x, y])

    const handleOpenModal = () => {
        applyTranslateValues();
        setModalOpen(true);
    };
    
      const handleCloseModal = () => {
        trackInfoBox.style.transform = `translate(0%, 0%)`;
        setModalOpen(false);
    };

    function applyTranslateValues() {
        if(!trackInfoBox) { return; }
        trackInfoBox.style.transform = `translate(${x}px, ${y}px)`;
    }

    return (
        <div className='containerButtonContact'>
            {modalOpen && (
                <div className="backgroundModal" onClick={handleCloseModal}></div>
            )}
            <div className='btnActionModal'>
                <button onClick={handleOpenModal} id='btnTrackBox'>
                    <img src={imgSrc} alt="" />
                </button>
                <p>{tTrack("btnContact.btn-Contact")}</p>
            </div>

            <div className={`trackInfoBox ${modalOpen ? "active" : "disabled"}`}>
                <div className="contentModal">
                    <p className="title">{tTrack("btnContact.title")}</p>
                    <p className="customService">{tTrack("btnContact.subTitle")}</p>
                    <Link
                        id="btnPhoneTrack"
                        className="no-a text-white d-none d-md-flex mt-2 phoneText"
                        to={`/${tNabvar("category-services.contact")}`}
                    >
                        (55) 5133 0237
                    </Link>
                    <a
                        href="tel:5551330237"
                        target="_blank"
                        rel="noreferrer"
                        className="no-a d-block d-md-none mt-4 mb-4 phoneText"
                    >
                        (55) 5133 0237
                    </a>
                    <div className="infoBox">
                        <p className="title">{tTrack("btnContact.customer-service-txt")}</p>
                        <div className="infoText">
                        <p>{tTrack("btnContact.national-txt")}</p>{" "}
                        <span>cadomestico@aeromexico.com</span>
                        </div>
                        <div className="infoText">
                        <p>{tTrack("btnContact.export")}</p>{" "}
                        <span>caatencionexport@aeromexico.com</span>
                        </div>
                        <div className="infoText">
                        <p>{tTrack("btnContact.import")}</p>{" "}
                        <span>caimport@aeromexico.com</span>
                        </div>
                    </div>
                    <div className="infoBox">
                        <p className="title">{tTrack("btnContact.quotes-txt")}</p>
                        <div className="infoText">
                        <p>{tTrack("btnContact.nationals-txt")}</p>{" "}
                        <span>cacotizacionesdomesticas@aeromexico.com</span>
                        </div>
                        <div className="infoText">
                        <p>{tTrack("btnContact.export")}</p>{" "}
                        <span>amsalessupportexportacion@aeromexico.com</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default TrackBtnContact;