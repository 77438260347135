
function RestrictionsIcons(props) {
    const { iconSrc, iconAlt, info } = props;
    return (
        <div className="d-flex flex-column">
            <img 
                src={iconSrc}
                alt={iconAlt}
                style={{
                    height:props.height,
                }}  
            />
            <p 
                className="my-4 text-center text-wrap px-1" style={{fontSize:props.fontSize}}>{info}</p>
        </div>
    )
}

export default RestrictionsIcons;
