import { React, useEffect } from "react";
import ScrollToTop from "../../../components/scrollToTop/scrollToTop";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";

function ICS2Regulation() {
  const [tAlerts] = useTranslation("alerts");
  const location = useLocation();
  const navigate = useNavigate();

  sessionStorage.setItem("routeEs", `/${navBarEs["category-services"]["alert"]}/${navBarEs["alerts-section"]['ics2-regulacion']}`);
  sessionStorage.setItem("routeEn", `/${navBarEn["category-services"]["alert"]}/${navBarEn["alerts-section"]['ics2-regulacion']}`);

  useEffect(() => {
    let lng = localStorage.getItem("lng") || 'es';
    let pathEs = sessionStorage.getItem('routeEs');
    let pathUs = sessionStorage.getItem('routeEn');
    
    if(lng === 'es' && pathEs !== location.pathname) {
      navigate(pathEs);
      window.location.reload();
    } else if (lng === 'us' && pathUs !== location.pathname){
      navigate(pathUs);
      window.location.reload();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div className="container">
        <div className="border-top py-5">
          <p className="px-3 px-md-0 mt-3 mt-md-5 title-alert">
            {tAlerts("notice1.text1")}
          </p>
          <p className="px-3 px-md-0 date-alert">{tAlerts("notice1.text2")}</p>
          <div className="my-5 p-3 notice-conteiner">
            <p className="fs-14 text-gray m-3">{tAlerts("notice1.info1")}</p>
            <ul className="fs-14 text-gray">
              <li>{tAlerts("notice1.info2")}</li>
              <li>{tAlerts("notice1.info3")}</li>
              <li>{tAlerts("notice1.info4")}</li>
              <li>{tAlerts("notice1.info5")}</li>
              <ul>
                <li>{tAlerts("notice1.info6")}</li>
                <li>{tAlerts("notice1.info7")}</li>
                <li>{tAlerts("notice1.info8")}</li>
                <li>{tAlerts("notice1.info9")}</li>
              </ul>
            </ul>
            <p className="fs-14 text-gray m-3">
              {tAlerts("notice1.info10")}
              <Link
                to="https://amcargo.aeromexico.com/content/8-alertas/1-regulacion-ics2/cumplimiento-ics2_17ago_esp_v2.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {tAlerts("notice1.info11")}
              </Link>
            </p>
            <p className="fs-14 text-gray m-3">{tAlerts("notice1.info12")}</p>
            <p className="fs-14 text-gray m-3">
              <Link
                to="https://taxation-customs.ec.europa.eu/customs-4/customs-security_en"
                target="_blank"
                rel="noopener noreferrer"
              >
                {tAlerts("notice1.info13")}
              </Link>
            </p>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start">
            <Link className="btn-return" to="../">
              {tAlerts("btn-return.return")}
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ICS2Regulation;
