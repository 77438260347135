import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import CardSection from "../cardsSection/cardSection";
import HeaderHome from "../header/header-home";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../../components/scrollToTop/scrollToTop";
import axios from "axios";

function MainHome() {
  const [tNabvar] = useTranslation("navbar");
  const dinamicCategory = tNabvar("products-submenu", { returnObjects: true });

  const [tInformation] = useTranslation("information");
  const dataInformation = tInformation("data", { returnObjects: true });
  const idInformation = "77f20c78-2974-421b-8d9e-275c6307c053";
  const [titleInformation, setTitleInformation] = useState("");
  const [dataInformationDB, setDataInformationDB] = useState([]);

  const [tNational] = useTranslation("national");
  const dataNational = tNational("data", { returnObjects: true });
  const idNational = "3e5e8148-45f8-4bdf-99fe-3ce7644f04a7";
  const [titleNational, setTitleNational] = useState("");
  const [dataNationalDB, setDataNationalDB] = useState([]);

  const [tInternational] = useTranslation("international");
  const dataInternational = tInternational("data", { returnObjects: true });
  const idInternational = "d45a04ef-618d-40de-ada3-cbe93ffa9770";
  const [titleInternational, setTitleInternational] = useState("");
  const [dataInternationalDB, setDataInternationalDB] = useState([]);

  const [tSGetQuoteStepOne] = useTranslation("getQuoteStepOne");
  const [tSMetaData] = useTranslation("metaData");
  const listServices = tSGetQuoteStepOne("services", { returnObjects: true });
  const [stations, setStations] = useState([]);
  const [services, setServices] = useState([]);
  let currentLng = "es";

  sessionStorage.setItem("routeEs", `/`);
  sessionStorage.setItem("routeEn", `/`);

  const getTexts = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const resInformation = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idInformation}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );
      const resNational = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idNational}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );
      const resInternational = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idInternational}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (currentLng === "es") {
        setTitleInformation(
          resInformation.data.textWeb.text_es.title["titulo de sección"]
        );
        setDataInformationDB([
          resInformation.data.textWeb.text_es["Charters"],
          resInformation.data.textWeb.text_es["Vacunas"],
        ]);

        setTitleNational(
          resNational.data.textWeb.text_es.title["titulo de sección"]
        );
        setDataNationalDB([
          resNational.data.textWeb.text_es["Gold"],
          resNational.data.textWeb.text_es["12+12"],
          resNational.data.textWeb.text_es["Mensajeria"],
          resNational.data.textWeb.text_es["Perecederos"],
          resNational.data.textWeb.text_es["Alto Valor"],
        ]);

        setTitleInternational(
          resInternational.data.textWeb.text_es.title["titulo de sección"]
        );
        setDataInternationalDB([
          resInternational.data.textWeb.text_es["express"],
          resInternational.data.textWeb.text_es["general"],
          resInternational.data.textWeb.text_es["specialized"],
          resInternational.data.textWeb.text_es["customized"],
        ]);
      } else {
        setTitleInformation(
          resInformation.data.textWeb.text_us.title["titulo de sección"]
        );
        setDataInformationDB([
          resInformation.data.textWeb.text_us["Charters"],
          resInformation.data.textWeb.text_us["Vaccines"],
        ]);

        setTitleNational(
          resNational.data.textWeb.text_us.title["titulo de sección"]
        );
        setDataNationalDB([
          resNational.data.textWeb.text_us["Gold"],
          resNational.data.textWeb.text_us["12+12"],
          resNational.data.textWeb.text_us["Mensajeria"],
          resNational.data.textWeb.text_us["Perecederos"],
          resNational.data.textWeb.text_us["Alto Valor"],
        ]);

        setTitleInternational(
          resInternational.data.textWeb.text_us.title["titulo de sección"]
        );
        setDataInternationalDB([
          resInternational.data.textWeb.text_us["express"],
          resInternational.data.textWeb.text_us["general"],
          resInternational.data.textWeb.text_us["specialized"],
          resInternational.data.textWeb.text_us["customized"],
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStations = async () => {
    if (localStorage.getItem("lng")) {
      currentLng = localStorage.getItem("lng");
    }
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations?lng=${currentLng}`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY, // Agrega la cadena de texto para la authorization en endponids publicos"
          },
        }
      );

      if (res.data.code === 1) {
        let data = res.data.stations
          .filter((station) => station.add_quote === 1)
          .sort((a, b) => {
            const isMEXA = a.code_text.startsWith("MEX");
            const isMEXB = b.code_text.startsWith("MEX");

            const isEdoMexA = a.name.toLowerCase().startsWith("edomex");
            const isEdoMexB = b.name.toLowerCase().startsWith("edomex");

            if (isMEXA && !isMEXB) return -1;
            if (!isMEXA && isMEXB) return 1;

            if (isMEXA && isMEXB) {
              if (isEdoMexA && !isEdoMexB) return 1;
              if (!isEdoMexA && isEdoMexB) return -1;

              const numA = parseInt(a.code_text.substring(3), 10);
              const numB = parseInt(b.code_text.substring(3), 10);
              return numA - numB;
            }

            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

            return 0;
          });
        setStations(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getServices = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/services`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY, // Agrega la cadena de texto para la authorization en endponids publicos"
          },
        }
      );
      if (res.data.code === 1) {
        const data = res.data.services;

        if (currentLng === "es") {
          const servicesEs = [];
          data.forEach((service) => {
            servicesEs.push(service.service_es);
          });
          setServices(servicesEs);
        } else if (currentLng === "us") {
          const servicesUs = [];
          data.forEach((service) => {
            servicesUs.push(service.service_us);
          });
          setServices(servicesUs);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTexts();
    getStations();
    getServices();
  }, []);

  if (stations.length !== 0) {
    localStorage.setItem("stations", JSON.stringify(stations));
  }

  if (services.length !== 0) {
    localStorage.setItem("services", JSON.stringify(services));
  } else {
    localStorage.setItem("services", JSON.stringify(listServices));
  }

  return (
    <React.StrictMode>
      <ScrollToTop />
      <Helmet>
        <title>{tSMetaData("inicio.title")}</title>
        <meta name="description" content={tSMetaData("inicio.description")} />
      </Helmet>
      <Navbar />
      <HeaderHome />
      {/* <CardSection
        title={titleInformation ?? tInformation("title.title")}
        data={
          dataInformationDB.length === 0 ? dataInformation : dataInformationDB
        }
      /> */}
      <CardSection
        title={titleNational ?? tNational("title.title")}
        data={dataNationalDB.length === 0 ? dataNational : dataNationalDB}
        category={Object.keys(dinamicCategory)[0]}
      />
      <CardSection
        title={titleInternational ?? tInternational("title.title")}
        data={
          dataInternationalDB.length === 0
            ? dataInternational
            : dataInternationalDB
        }
        category={Object.keys(dinamicCategory)[1]}
      />
      <Footer />
    </React.StrictMode>
  );
}

export default MainHome;
