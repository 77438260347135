import './title-section.css';
import iconAeromexico from "../../assets/imgs/shared/icono_amc.svg";

function TitleSection({title}) {
    return (
        <>
            <div className="titleSection">
                <div className="container">
                <div className="titleContainer">
                <img src={iconAeromexico} alt="aeromexico logo" />
                    <h2>{title} </h2>
                </div>
                </div>
            </div>
        </>
    )
};

export default TitleSection;