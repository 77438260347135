import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaMinus } from "react-icons/fa6";
import React from 'react';
import './pending-movement';
import './track-tooltip.css';

function PendingMovement( { text, position } ) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className='text bg-tooltip'>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={position}
      delay={{ show: 50, hide: 50 }}
      overlay={renderTooltip}
    >
      <div className='iconContainerMovement' style={{ display: 'inline-block' }}>
        <FaMinus className='icon' style={{color: '#949494'}} />
      </div>
    </OverlayTrigger>
  );
}

export default PendingMovement;
