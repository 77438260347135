import { useTranslation } from "react-i18next";
import { AiOutlineSend } from "react-icons/ai";
import "./track-form.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";
import { useEffect, useState } from "react";
import Loader from "../../../../components/loader/loader";

function TrackForm() {
  const [tTrack] = useTranslation("trackPage");
  const {
      register,
      handleSubmit,
      watch,
      setValue,
      formState: { errors, isValid },
  } = useForm({mode: 'onChange'});
  const navigate = useNavigate();
  const [statePass, setStatePass] = useState(true);

  const prefixValue = watch("prefix");
  const nguideValue = watch("waybillNumber");

  useEffect(() => {
    if(prefixValue) {
      const onlyNumbers = prefixValue.replace(/\D/g, '');
      if (prefixValue !== onlyNumbers) {
        setValue("prefix", onlyNumbers);
      }
    }

    if(nguideValue) {
      const onlyNumbers = nguideValue.replace(/\D/g, '');
      if (nguideValue !== onlyNumbers) {
        setValue("waybillNumber", onlyNumbers);
      }
    }
  }, [prefixValue, setValue, nguideValue])

  sessionStorage.setItem("routeEs", `/${navBarEs["category-services"].track}`);
  sessionStorage.setItem("routeEn", `/${navBarEn["category-services"].track}`);

  const handleTrackSubmit = (data) => {
    if(statePass === true) {
      setStatePass(false)
    } else {
      return;
    }
    const fetchData = async () => {
      try {
        if (!data.prefix || !data.waybillNumber) {
          return;
        }
        const url = `${process.env.REACT_APP_URL_BACKEND}/api/track/v3/${data.prefix}/${data.waybillNumber}`;
        const response = await axios.get(url);

        navigate(`${tTrack("result")}/${data.prefix}-${data.waybillNumber}`, 
            {state: {formData: {
                prefix: data.prefix, 
                waybillNumber: data.waybillNumber, 
                data: response.data
            }}}
        );
      } catch (error) {
        if (error.response.data.message === "Track not found") {
            console.error("error in request: ", error);
            navigate(`track-not-found`, {state: {formData: {
              prefix: data.prefix, 
              waybillNumber: data.waybillNumber, 
              data: "error404"
            }}})
        } else if (error.response.data.message === "Track not information") {
            console.error("error in request: ", error);
            navigate(`track-not-info`, {state: {formData: {
              prefix: data.prefix, 
              waybillNumber: data.waybillNumber, 
              data: "notInformation"
            }}})
        } else if(error.response.data.message === "server error") {
            console.error("error in request: ", error);
            navigate(`track-not-info`, {state: {formData: {
              prefix: data.prefix, 
              waybillNumber: data.waybillNumber, 
              data: "serverError"
            }}})
        }
      }
    };

    fetchData();
  };

  const buttonClass = isValid ? "validClass" : "invalidClass";
  const cursorType = isValid ? "pointer" : "auto";

  return (
    <div className="container">
      <p className="trackIndicators">{tTrack("form.indicators")}</p>

      <form onSubmit={handleSubmit(handleTrackSubmit)}>
        <div className="row">
          <div className="col-12 col-md-4 formGroup">
            <input
              id="prefix"
              type="text"
              placeholder=" "
              className="formInput"
              maxLength={"3"}
              {...register("prefix", {
                required: true,
                maxLength: 3,
                minLength: 3,
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Solo se permiten números'
                }
              })}
            />
            <label htmlFor="prefix" className="formLabel">
              {tTrack("form.prefix-label")}
            </label>
            <div className="alertTrackForm">
              {errors.prefix?.type === "pattern" && (
                <p>Solo se permiten números</p>
              )}
              {errors.prefix?.type === "required" && (
                <p>{tTrack("form.input-required")}</p>
              )}
              {errors.prefix?.type === "maxLength" && (
                <p>{tTrack("form.input-length")}</p>
              )}
              {errors.prefix?.type === "minLength" && (
                <p>{tTrack("form.input-length")}</p>
              )}
            </div>
          </div>
          <div className="col-12 col-md-4 formGroup">
            <input
              id="nguide"
              type="text"
              placeholder=" "
              className="formInput"
              maxLength={"8"}
              {...register("waybillNumber", { 
                required: true,
                maxLength: 8,
                minLength: 8, 
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Solo se permiten números'
                }
              })}
              onKeyUp={(event) => {
                if(event.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
            <label htmlFor="nguide" className="formLabel">
              {tTrack("form.waybill-number-label")}
            </label>
            <div className="alertTrackForm">
              {errors.waybillNumber?.type === "pattern" && (
                <p>Solo se permiten números</p>
              )}
              {errors.waybillNumber?.type === "required" && (
                <p>El campo es requerido</p>
              )}
              {errors.waybillNumber?.type === "maxLength" && (
                <p>El campo tiene que ser de 3 digitos</p>
              )}
              {errors.waybillNumber?.type === "minLength" && (
                <p>El campo tiene que ser de 8 digitos</p>
              )}
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="searchButton">
              <button
                id="btnTrackTrack"
                type="submit"
                style={{ cursor: `${cursorType}` }}
                className={buttonClass}
              >
                {statePass ? <p>{tTrack("form.btn-form")}</p> : <p>{tTrack("form.loader")}</p>}
                {statePass ? <AiOutlineSend /> : <Loader />}
              </button>
            </div>
          </div>
        </div>
      </form>

      <p className="delta-text">{tTrack("form.delta-text")}</p>

      <div className="redirectWaybill">
        <a
          className="redirectTrack"
          id="linkTrackDelta"
          href="https://www.deltacargo.com/Cargo/#/?selectedId=21"
          target="_blank"
          rel="noreferrer"
        >
          <p>
            <span className="redirecTo">{tTrack("form.redirect-to")}</span>
          </p>
        </a>
      </div>
    </div>
  );
}

export default TrackForm;
