import React from 'react'
import "./contact.css";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

function Thanks() {

  const [tContact] = useTranslation("contact");

  return (
    <>
        <ScrollToTop />
        <Navbar />
        <div className='container-fluid bg-thanks'>
            <div className='container errorContainer thanksContainerHeight'>
                <p className='errorTitlte'>{tContact("thanks.thank-you")}</p>
                <p className='errorDescription'>{tContact("thanks.received")}</p>
                <Link to="/">
                    <button>{tContact("thanks.btn-return")}</button>
                </Link>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Thanks