import { useState } from 'react';
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/imgs/navbar/logo_AMC.svg";
import "./privacy.css";
import navBarEn from "../../translations/en/navbar.json";
import navBarEs from "../../translations/es/navbar.json";
import Helmet from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

function PrivacyAcceptance() {
  const [tPrivacy] = useTranslation("privacy");
  const [tSMetaData] = useTranslation("metaData");
  const navigate = useNavigate();
  const location = useLocation();
  const { us, es } = location.state || "";
  
  const [click, setClick] = useState(false);
  const closeMobileMenu = () => setClick(false);

  console.log(us, es);

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].privacy}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].privacy}`
  );

  return (
    <>
      <Helmet>
        <title>{tSMetaData("avisoPrivacidad.title")}</title>
        <meta
          name="description"
          content={tSMetaData("avisoPrivacidad.description")}
        />
      </Helmet>
      <ScrollToTop />
      <div className="container-fluid bg-premium-blue pricacySectionPink">
        <div className='container py-3 px-0'>
          <div className="row px-lg-5">
            <div className='col-6'>
              <Link to='/' id='btnNavbarHome' onClick={closeMobileMenu}>
                  <img className="logo-amc" src={logo} alt="logo amc" />
              </Link>
            </div>
            <div className='col-6 px-0 d-flex justify-content-start justify-content-sm-center align-items-center align-items-md-end'>
              <p className="font-semibold text-white fs-16-res mb-0">
                {tPrivacy("privacidad.title_privacy")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid bg-azul-consistencia'>
        <div className='container'>
          <p className='text-white fs-12 py-2 text-end'>{tPrivacy("privacidad.update")}</p>
        </div>
      </div>
      <section className="privacy_box mt-0">
        <div className="row">
          <div className="col">
            <div className="texto_box">
              <div className="container conta_privacy">
                <p className="ley">
                  <strong>{tPrivacy("texto_privacy.ley")}</strong>
                </p>

                <h3>{tPrivacy("texto_privacy.uno")}</h3>
                <p>
                  <strong>{tPrivacy("texto_privacy.pa_uno")}</strong>{" "}
                  {tPrivacy("texto_privacy.pa_uno_uno")}
                  <strong>{tPrivacy("texto_privacy.cargo")}</strong>
                  {tPrivacy("texto_privacy.p")}
                </p>

                <h3>{tPrivacy("texto_privacy.dos")}</h3>
                <p>{tPrivacy("texto_privacy.pa_dos")}</p>
                <ul>
                  <li>
                    <p>{tPrivacy("texto_privacy.atender")}</p>
                  </li>
                  <li>
                    <p>{tPrivacy("texto_privacy.datos")}</p>
                  </li>
                </ul>
                <p>
                  {tPrivacy("texto_privacy.todo")}{" "}
                  <a href="http://">cadatospersonales@aeromexico.com.</a>
                </p>
                <h3>{tPrivacy("texto_privacy.tres")}</h3>
                <p>{tPrivacy("texto_privacy.pa_tres")}</p>

                <h3>{tPrivacy("texto_privacy.tresuno")}</h3>
                <p>{tPrivacy("texto_privacy.pa_tres_uno")}</p>

                <h3>{tPrivacy("texto_privacy.tresdos")}</h3>
                <p>{tPrivacy("texto_privacy.pa_tres_dos")}</p>

                <h3>{tPrivacy("texto_privacy.cuatro")}</h3>
                <p>{tPrivacy("texto_privacy.pa_cuatro")}</p>
                <p>
                  <strong>{tPrivacy("texto_privacy.inc_a")}</strong>{" "}
                  {tPrivacy("texto_privacy.parr_inc_a")}
                </p>
                <ul>
                  <li>{tPrivacy("texto_privacy.contactarlo")}</li>
                  <li>{tPrivacy("texto_privacy.info")}</li>
                  <li>{tPrivacy("texto_privacy.contrato")}</li>
                  <li>{tPrivacy("texto_privacy.especial")}</li>
                  <li>{tPrivacy("texto_privacy.registro")}</li>
                  <li>{tPrivacy("texto_privacy.reembolsos")}</li>
                  <li>{tPrivacy("texto_privacy.alguna")}</li>
                  <li>{tPrivacy("texto_privacy.aplicable")}</li>
                  <li>{tPrivacy("texto_privacy.elaboracion")}</li>
                  <li>{tPrivacy("texto_privacy.obligaciones")}</li>
                  <li>{tPrivacy("texto_privacy.envio")}</li>
                  <li>{tPrivacy("texto_privacy.seguridad")}</li>
                </ul>

                <p>
                  <strong>{tPrivacy("texto_privacy.inc_b")}</strong>{" "}
                  {tPrivacy("texto_privacy.parr_inc_b")}
                </p>
                <ul>
                  <li>{tPrivacy("texto_privacy.informarle")}</li>
                  <li>{tPrivacy("texto_privacy.publicidad")}</li>
                  <li>{tPrivacy("texto_privacy.comercial")}</li>
                  <li>{tPrivacy("texto_privacy.encuestas")}</li>
                </ul>

                <h3>{tPrivacy("texto_privacy.cuatrouno")}</h3>
                <p>{tPrivacy("texto_privacy.pa_cuatrouno")}</p>

                <h3>{tPrivacy("texto_privacy.cinco")}</h3>
                <p>{tPrivacy("texto_privacy.parr_cinco")}</p>
                <ul>
                  <li>{tPrivacy("texto_privacy.servicio")}</li>
                  <li>{tPrivacy("texto_privacy.empresas")}</li>
                  <li>{tPrivacy("texto_privacy.aerea")}</li>
                  <li>{tPrivacy("texto_privacy.gha")}</li>
                  <li>{tPrivacy("texto_privacy.gsa")}</li>
                  <li>{tPrivacy("texto_privacy.paqueteria")}</li>
                  {/* <li>{tPrivacy("texto_privacy.autoridades")}</li> */}
                  <li>{tPrivacy("texto_privacy.judiciales")}</li>
                  <li>{tPrivacy("texto_privacy.sociedades")}</li>
                  <li>{tPrivacy("texto_privacy.medicos")}</li>
                  <li>{tPrivacy("texto_privacy.am")}</li>
                  <p>{tPrivacy("texto_privacy.realizar")}</p>
                  <li>{tPrivacy("texto_privacy.fusion")}</li>
                  <li>{tPrivacy("texto_privacy.legal")}</li>
                  <p>{tPrivacy("texto_privacy.lfpd")}</p>
                </ul>

                <h3>{tPrivacy("texto_privacy.seis")}</h3>
                <p>{tPrivacy("texto_privacy.pa_seis")}</p>
                <p>
                  {tPrivacy("texto_privacy.arco")} (
                  <a href="http://">cadatadospersonales@aeromexico.com</a>)
                  {tPrivacy("texto_privacy.arco1")}
                </p>

                <h3>{tPrivacy("texto_privacy.siete")}</h3>
                <p>{tPrivacy("texto_privacy.pa_siete")}</p>
                <p>{tPrivacy("texto_privacy.revocar")}</p>

                <h3>{tPrivacy("texto_privacy.ocho")}</h3>
                <p>{tPrivacy("texto_privacy.pa_ocho")}</p>
                <ul>
                  <li>{tPrivacy("texto_privacy.finalidades")}</li>
                  <li>{tPrivacy("texto_privacy.enviar")}</li>
                  <li>
                    {tPrivacy("texto_privacy.evitar")}{" "}
                    <a href="http://www.rpc.profeco.gob.mx/">
                      www.rpc.profeco.gob.mx
                    </a>
                  </li>
                </ul>

                <h3>{tPrivacy("texto_privacy.nueve")}</h3>
                <p>
                  {tPrivacy("texto_privacy.pa_nueve")}{" "}
                  <a href="https://amcargo.aeromexico.com/">
                    amcargo.aeromexico.com.
                  </a>{" "}
                  {tPrivacy("texto_privacy.cookies")}
                </p>
                <p>{tPrivacy("texto_privacy.datos0")}</p>
                <ul>
                  <li>{tPrivacy("texto_privacy.tipo")}</li>
                  <li>{tPrivacy("texto_privacy.horario")}</li>
                  <li>{tPrivacy("texto_privacy.secciones")}</li>
                  <li>
                    {tPrivacy("texto_privacy.previo")}{" "}
                    <a href="https://amcargo.aeromexico.com/">
                      amcargo.aeromexico.com.
                    </a>
                  </li>
                  <li>
                    {tPrivacy("texto_privacy.posterior")}{" "}
                    <a href="https://amcargo.aeromexico.com/">
                      amcargo.aeromexico.com.
                    </a>
                  </li>
                  <li>{tPrivacy("texto_privacy.ip")}</li>
                </ul>

                <h3>{tPrivacy("texto_privacy.finalidades1")}</h3>
                <ul>
                  <li>{tPrivacy("texto_privacy.opciones")}</li>
                  <li>{tPrivacy("texto_privacy.web")}</li>
                </ul>

                <p>
                  <strong>{tPrivacy("texto_privacy.explorer")}</strong>
                  <br />
                  <a href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies">
                    http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies
                  </a>
                </p>

                <p>
                  <strong>{tPrivacy("texto_privacy.firefox")}</strong>
                  <br />
                  <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">
                    https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                  </a>
                </p>

                <p>
                  <strong>{tPrivacy("texto_privacy.chrome")}</strong>
                  <br />
                  <a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647">
                    http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647
                  </a>
                </p>

                <p>
                  <strong>{tPrivacy("texto_privacy.opera")}</strong>
                  <br />
                  <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">
                    http://help.opera.com/Windows/11.50/es-ES/cookies.html
                  </a>
                </p>

                <p>
                  <strong>{tPrivacy("texto_privacy.safari")}</strong>
                  <br />
                  <a href="http://www.apple.com/legal/privacy/es/cookies/">
                    http://www.apple.com/legal/privacy/es/cookies/
                  </a>
                </p>

                <h3>{tPrivacy("texto_privacy.diez")}</h3>
                <p>{tPrivacy("texto_privacy.efectuar")}</p>
                <ul>
                  <li>{tPrivacy("texto_privacy.anuncios")}</li>
                  <li>{tPrivacy("texto_privacy.internet")}</li>
                  <li>{tPrivacy("texto_privacy.ultimo")}</li>
                </ul>
                <p>
                  {tPrivacy("texto_privacy.relacion")}{" "}
                  <a href="https://amcargo.aeromexico.com/">
                  amcargo.aeromexico.com.
                  </a>{" "}
                  {tPrivacy("texto_privacy.haber")}
                </p>

                <h3>{tPrivacy("texto_privacy.once")}</h3>
                <p>{tPrivacy("texto_privacy.pa_once")}</p>
                <p>
                  {tPrivacy("texto_privacy.mayor")}{" "}
                  <a href="https://home.inai.org.mx/">www.inai.org.mx</a>
                </p>

                <h3>{tPrivacy("texto_privacy.transferencia")}</h3>
                <p className='mb-0'>{tPrivacy("texto_privacy.transferencia-1")}</p>
                <p>{tPrivacy("texto_privacy.transferencia-2")}</p>
              
                <div className="container p-0 my-5">
                  <div className="row g-3 border-form d-flex justify-content-center">
                    <div className='bg-premium-blue mt-0 py-3'>
                      <p className='text-white text-center m-0 px-2'>{tPrivacy("form.data")}</p>
                    </div>
                    <div className="col-md-5 ">
                      <label htmlFor="name" className="form-label">
                        {tPrivacy("form.name")}
                        <span className="text-mexican-pink"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input-info"
                        id="name"
                        name="name"
                        placeholder={tPrivacy("form.name-placeholder")}
                        required
                        // onChange={(e) => handleFormData(e)}
                      ></input>
                    </div>
                    <div className="col-md-5 ">
                      <label htmlFor="surname" className="form-label">
                        {tPrivacy("form.surname")}
                        <span className="text-mexican-pink"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input-info"
                        id="surname"
                        name="surname"
                        placeholder={tPrivacy("form.surname-placeholder")}
                        required
                        // onChange={(e) => handleFormData(e)}
                      ></input>
                    </div>
                    <div className="col-md-5 ">
                      <label htmlFor="birthdate" className="form-label">
                        {tPrivacy("form.birthdate")}
                        <span className="text-mexican-pink"> *</span>
                      </label>
                      <input
                        type="date"
                        className="form-control input-info"
                        id="birthdate"
                        name="birthdate"
                        placeholder={tPrivacy("form.birthdate-placeholder")}
                        required
                        // onChange={(e) => handleFormData(e)}
                      ></input>
                    </div>
                    <div className="col-md-5 ">
                      <label htmlFor="company" className="form-label">
                        {tPrivacy("form.company")}
                        <span className="text-mexican-pink"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input-info"
                        id="company"
                        name="company"
                        placeholder={tPrivacy("form.company-placeholder")}
                        required
                        // onChange={(e) => handleFormData(e)}
                      ></input>
                    </div>
                    <div className="col-md-5 ">
                      <label htmlFor="email" className="form-label">
                        {tPrivacy("form.email")}
                        <span className="text-mexican-pink"> *</span>
                      </label>
                      <input
                        type="email"
                        className="form-control input-info"
                        id="email"
                        name="email"
                        placeholder={tPrivacy("form.email")}
                        required
                        // onChange={(e) => handleFormData(e)}
                      ></input>
                    </div>
                    <div className="col-md-5 ">
                      <label htmlFor="phone" className="form-label">
                        {tPrivacy("form.phone")}
                        <span className="text-mexican-pink"> *</span>
                      </label>
                      <input
                        type="number"
                        className="form-control input-info"
                        id="phone"
                        name="phone"
                        placeholder={tPrivacy("form.phone")}
                        required
                        // onChange={(e) => handleFormData(e)}
                      ></input>
                    </div>
                    <div className="col-md-10 mb-4">
                      <label htmlFor="station" className="form-label">
                        {tPrivacy("form.station")}
                        <span className="text-mexican-pink"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input-info"
                        id="station"
                        name="station"
                        placeholder={tPrivacy("form.station-placeholder")}
                        required
                        // onChange={(e) => handleFormData(e)}
                      ></input>
                    </div>
                  </div>
                  <div className='row g-3 mt-3 border-form'>
                    <div className='bg-premium-blue mt-0 py-3'>
                      <p className='text-white text-center m-0 px-2'>{tPrivacy("form.shipments-of-interest")}</p>
                    </div>
                    <div className='container-lg py-4 px-lg-5'>
                      <div className='row justify-content-center ms-2'>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.documents")}
                          </label>
                        </div>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.art")}
                          </label>
                        </div>
                      </div>
                      <div className='row justify-content-center ms-2'>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.animals")}
                          </label>
                        </div>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.high-value")}
                          </label>
                        </div>
                      </div>
                      <div className='row justify-content-center ms-2'>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.perishables")}
                          </label>
                        </div>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.auto-parts")}
                          </label>
                        </div>
                      </div>
                      <div className='row justify-content-center ms-2'>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.pharmaceutical")}
                          </label>
                        </div>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.supplies")}
                          </label>
                        </div>
                      </div>
                      <div className='row justify-content-center ms-2'>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.high-tech")}
                          </label>
                        </div>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.general-cargo")}
                          </label>
                        </div>
                      </div>
                      <div className='row justify-content-center ms-2'>
                        <div class="col-12 col-lg-5 form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                          <label class="form-check-label" for="flexCheckDefault">
                            {tPrivacy("form.musical")}
                          </label>
                        </div>
                        <div class="col-12 col-lg-5 form-check d-none d-lg-block"></div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-5 mb-3'>
                    <div class="form-check form-check-reverse">
                      <input class="form-check-input" type="checkbox" value="" id="reverseCheck1"></input>
                      <label class="form-check-label" for="reverseCheck1">
                        {tPrivacy("btn-contact.agree-privacy")}
                      </label>
                    </div>
                    <div class="form-check form-check-reverse">
                      <input class="form-check-input" type="checkbox" value="" id="reverseCheck1"></input>
                      <label class="form-check-label" for="reverseCheck1">
                        {tPrivacy("btn-contact.information-advertising")}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 py-3 d-flex justify-content-end align-items-center">
                    <button
                      id="btnSendContact"
                      className='btn-blue auto-with'
                      // className={`btn-blue auto-with ${loading ? "" : "d-none"} ${
                      //   !isFormValid ? "is-disabled" : ""
                      // }`}
                      // onClick={() => {
                      //   postContact();
                      // }}
                      // disabled={!isFormValid}
                    >
                      {tPrivacy("btn-contact.contact")}
                    </button>
                      {/* <FaSpinner className={`spinner ${!loading ? "" : "d-none"}`} /> */}
                    </div>
                    <div className="col-12 d-flex justify-content-end align-items-center">
                      {/* <p className={`text-mexican-pink ${!errorMessage ? "" : "d-none"}`}>
                        {tPrivacy("form.error")}
                      </p>
                      <p className={`text-success ${!successMessage ? "" : "d-none"}`}>
                        {tPrivacy("form.success")}
                      </p> */}
                    </div>
                </div>

                  
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyAcceptance;
