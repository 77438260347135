import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import ImageHeader from "../../../../components/image-header/image-header";
import imgPerishable from "../../../../assets/imgs/services/nationals/perishable.jpg";
import Accordion from "../../../../components/acordion/accordion";
import { useEffect, useState } from "react";
import axios from "axios";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";

function PerishableGoods() {
  const [tPerishable] = useTranslation("perishableGoodPage");
  const [tSMetaData] = useTranslation("metaData");
  const idPerishable = "158bdddf-e801-4157-9720-40346a29601e";
  const [textDescription, setTextDescription] = useState("");
  const [accordion1, setAccordion1] = useState("");
  const [accordion2, setAccordion2] = useState("");

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].services}/${
      Object.keys(navBarEs["products-submenu"])[0]
    }/${Object.keys(navBarEs["products-nacionales-submenu"])[3]}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].services}/${
      Object.keys(navBarEn["products-submenu"])[0]
    }/${Object.keys(navBarEn["products-nationals-submenu"])[3]}`
  );

  useEffect(() => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    axios
      .get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idPerishable}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      )
      .then((response) => {
        if (currentLng === "es") {
          setTextDescription(
            response.data.textWeb.text_es["01-descripción"].content
          );
          setAccordion1(
            response.data.textWeb.text_es["02-Restricciones y Regulaciones"]
              .content
          );
          setAccordion2(
            response.data.textWeb.text_es[
              "03-Documentación, Aceptación y Recolección"
            ].content
          );
        } else {
          setTextDescription(
            response.data.textWeb.text_us["01-description"].content
          );
          setAccordion1(
            response.data.textWeb.text_us["02-Restrictions and Regulations"]
              .content
          );
          setAccordion2(
            response.data.textWeb.text_us["03-Check-in, Acceptance and Pickup"]
              .content
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{tSMetaData("perecederos.title")}</title>
        <meta
          name="description"
          content={tSMetaData("perecederos.description")}
        />
      </Helmet>
      <ImageHeader
        section={tPerishable("header.section")}
        title={tPerishable("header.title")}
        description={
          textDescription
            ? JSON.parse(textDescription)
            : JSON.parse(tPerishable("description.content"))
        }
        quoteBtn={tPerishable("header.quote-btn")}
        quoteProductID="btnQuotePerishable"
        contactSales={tPerishable("header.contact-sales")}
        contactProductID="btnContactPerishable"
        imgBackground={imgPerishable}
      />
      <Accordion
        title={tPerishable("accordion-1.title")}
        content={
          accordion1
            ? JSON.parse(accordion1)
            : JSON.parse(tPerishable("accordion-1.content"))
        }
      />
      <div className="divider"></div>
      <Accordion
        title={tPerishable("accordion-2.title")}
        content={
          accordion2
            ? JSON.parse(accordion2)
            : JSON.parse(tPerishable("accordion-2.content"))
        }
      />
      <div className="divider"></div>
      <Accordion
        title={tPerishable("accordion-3.title")}
        content={JSON.parse(tPerishable("accordion-3.content"))}
      />
    </>
  );
}

export default PerishableGoods;
