import RestrictionsIcons from "../../../../components/restrictions-icons/restrictions-icons";
import iconEggBox from "../../../../assets/imgs/prepare-shipment/restrictions/icon-egg-box.svg";
import iconNewspaper from "../../../../assets/imgs/prepare-shipment/restrictions/icon-newspaper.svg";
import iconGiftWrapping from "../../../../assets/imgs/prepare-shipment/restrictions/icon-gift-wrapping.svg";
import iconKraftPaper from "../../../../assets/imgs/prepare-shipment/restrictions/icon-kraft-paper.svg";
import iconDrainPump from "../../../../assets/imgs/prepare-shipment/restrictions/icon-drain-pump.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";

function PackagingGuidelines() {
  const [tRestrictions] = useTranslation("restrictions");
  const idComponent = "508803f5-4212-489b-8406-cd64b05c9057";
  const [dataInformation, setDataInformation] = useState(() => {
    const newData = {};
    for (let i = 1; i <= 13; i++) {
      newData[`text-${i}`] = "";
    }
    newData["título"] = "";
    return {
      "Disposiciones generales de embalaje": newData,
    };
  });

  const getTexts = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idComponent}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (currentLng === "es") {
        setDataInformation(
          response.data.textWeb.text_es["Disposiciones generales de embalaje"]
        );
      } else {
        setDataInformation(
          response.data.textWeb.text_us["Disposiciones generales de embalaje"]
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTexts();
  }, []);

  return (
    <div>
      <div className="container">
        <p className="mt-5 text-center text-premium-blue fs-5">
          {dataInformation["título"] ?? tRestrictions("packaging-guidelines.packaging-guidelines")}
        </p>
        <div className="row justify-content-center">
          <p className="col-11 col-md-10 col-lg-7 text-center text-premium-blue fs-14">
            <span className="fw-bold font-semibold">
              {dataInformation["text-1"] ?? tRestrictions("packaging-guidelines.text-1")}
            </span>
            {dataInformation["text-2"] ?? tRestrictions("packaging-guidelines.text-2")}
          </p>
        </div>
      </div>
      <div className="container px-5 d-flex justify-content-center">
        <div className="col-10">
          <div className="row">
            <div className="col-12 col-md-6 pt-3 border-end">
              <ul className="li-space text-premium-blue">
                <li>{dataInformation["text-3"] ?? tRestrictions("packaging-guidelines.text-3")}</li>
                <li>{dataInformation["text-4"] ?? tRestrictions("packaging-guidelines.text-4")}</li>
                <li>{dataInformation["text-5"] ?? tRestrictions("packaging-guidelines.text-5")}</li>
              </ul>
            </div>
            <div className="col-12 col-md-6 pt-3">
              <ul className="li-space text-premium-blue">
                <li>{dataInformation["text-6"] ?? tRestrictions("packaging-guidelines.text-6")}</li>
                <li>{dataInformation["text-7"] ?? tRestrictions("packaging-guidelines.text-7")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-flex justify-content-center pb-5">
        <div className="col-10">
          <p className="my-4 text-mexican-pink fs-5">
            {dataInformation["text-8"] ?? tRestrictions("packaging-guidelines.text-8")}
          </p>
          <div className="pt-3 border">
            <div className="row justify-content-center">
              <div className="col-4 col-lg">
                <RestrictionsIcons
                  iconSrc={iconEggBox}
                  iconAlt="Egg Box Icon"
                  height="50px"
                  info={dataInformation["text-9"] ?? tRestrictions("packaging-guidelines.text-9")}
                  fontSize="10px"
                />
              </div>
              <div className="col-4 col-lg">
                <RestrictionsIcons
                  iconSrc={iconNewspaper}
                  iconAlt="Newspaper Icon"
                  height="50px"
                  info={dataInformation["text-10"] ?? tRestrictions("packaging-guidelines.text-10")}
                  fontSize="10px"
                />
              </div>
              <div className="col-4 col-lg">
                <RestrictionsIcons
                  iconSrc={iconGiftWrapping}
                  iconAlt="Gift Wrapping Icon"
                  height="50px"
                  info={dataInformation["text-11"] ?? tRestrictions("packaging-guidelines.text-11")}
                  fontSize="10px"
                />
              </div>
              <div className="col-4 col-lg">
                <RestrictionsIcons
                  iconSrc={iconKraftPaper}
                  iconAlt="Kraft Paper Icon"
                  height="50px"
                  info={dataInformation["text-12"] ?? tRestrictions("packaging-guidelines.text-12")}
                  fontSize="10px"
                />
              </div>
              <div className="col-4 col-lg">
                <RestrictionsIcons
                  iconSrc={iconDrainPump}
                  iconAlt="Drain Pump Icon"
                  height="50px"
                  info={dataInformation["text-13"] ?? tRestrictions("packaging-guidelines.text-13")}
                  fontSize="10px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackagingGuidelines;
