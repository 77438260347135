import React, { useState } from 'react';
import "./textos.css";
import { FaAngleDown, FaAngleUp, FaTimes } from "react-icons/fa";
import mexIcon from '../../../../assets/imgs/navbar/mex_svg.svg';
import usaIcon from '../../../../assets/imgs/navbar/usa_svg.svg';
import axios from 'axios';
import QuillComponent from './quillComponent';
import ViewQuillComponent from './viewQuillComponent';
import Swal from 'sweetalert2';
import QuillComponentText from './QuillComponentText';

function Textos() {
  const [openIndexes, setOpenIndexes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalText, setOpenModalText] = useState(false);
  const [currentText, setCurrentText] = useState({});
  const [currentId, setCurrentId] = useState("");
  const [currentLng, setCurrentLng] = useState(0);
  const [quillEditBoxState, setQuillEditBoxState] = useState({});
  const [titleModal, setTitleModal] = useState("")
  const [typeText, setTypeText] = useState("")

  const toggleAccordeon = (index) => {
    if(openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  }

  const handleOpenModal = async (idText, lng, type) => {
    setTypeText(type)
    if(type === 'json') {
      setOpenModal(true);
    } else {
      setOpenModalText(true)
    }
    setCurrentId(idText)
    setCurrentLng(lng)

    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/textWeb/${idText}?language=${lng}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      })
      setTitleModal(response.data.textWeb.view)
      if(lng === 1){
        setCurrentText(response.data.textWeb.text_es)
      } else {
        setCurrentText(response.data.textWeb.text_us);
      }
    } catch(error) {
      console.error(error)
    }

  }

  const handleCloseModal = () => {
    Swal.fire({
      title: "Cancelar Edicion",
      text: "Tus cambios se perderan",
      showCancelButton: true,
      confirmButtonText: "Salir",
      confirmButtonColor: "#dc3741",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if(result.isConfirmed) {
        setOpenModal(false);
        setCurrentText({});
        setCurrentId("")
        setCurrentLng(0);
        setOpenModalText(false);
        setTitleModal("");
        setQuillEditBoxState({});
      }
    })
  }

  const handleModalClick = (event) => {
    event.stopPropagation();
  }

  const handleStatusQuillBox = (key) => {
    setQuillEditBoxState((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }

  const handleQuillDataText = (data, key, propKey) => {
    setQuillEditBoxState((prev) => ({
      ...prev,
      [propKey]: !prev[propKey],
    }));

    let updateData = {...currentText};
    updateData[key][propKey] = data;

    setCurrentText(updateData)
  }

  const handleQuillData = (data, key) => {
    setQuillEditBoxState((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
    
    let updateData = {...currentText};
    updateData[key] = data
    
    setCurrentText(updateData)
  };

  const handlePrintTitle = (title) => {
    const improveTitle = title.replace(/.*?-/, "");
    return improveTitle;
  }

  const handleUpdateData = async () => {
    const data = {
      "id": currentId,
      "text": currentText,
      "language": currentLng.toString()
    }
    try {
      await axios.put(`${process.env.REACT_APP_URL_BACKEND}/api/textWeb/update/`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      setOpenModal(false);
      setOpenModalText(false);
      setCurrentText({});
      setCurrentId("")
      setCurrentLng(0)
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Datos guardados correctamente",
        showConfirmButton: false,
        timer: 1500
      });
    } catch(error) {
      console.error(error)
    }
  }

  const renderModalText = () => {
    return (
      <div className='bgModalAdmin' onClick={handleCloseModal}>
        <div className={`modalAdmin animate__animated ${!openModalText ? "animate__zoomOut" : "animate__bounceIn"}`} onClick={handleModalClick}>

          <div className='modalAdminHeader'>
            <h3>{titleModal}</h3>
            <button onClick={handleCloseModal}><FaTimes /></button>
          </div>

          {Object.keys(currentText).map((key) => (
            <div key={key}>

              <div className='modalAdminHeader'>
                <h3>{key}</h3>
              </div>

              <div className='modalAdminBody'>

              {Object.keys(currentText[key]).map((propKey) => (
                <div key={propKey} className='itemModalAdmin'>
                  <div className='buttonsEditor'>
                    <h4>{propKey}</h4>
                    {!quillEditBoxState[propKey] ? <button onClick={() => handleStatusQuillBox(propKey)}>Edit</button> : null}
                  </div>

                  {!quillEditBoxState[propKey] ? <ViewQuillComponent content={currentText[key][propKey]} type={typeText} /> : <QuillComponentText text={currentText[key][propKey]} onQuillDataText={(data) => handleQuillDataText(data, key, propKey)} />}
                  
                </div>
              ))}
              </div>
            </div>
          ))}

          <div className='modalAdminFooter'>
            <button onClick={handleCloseModal}>Cancelar</button>
            <button onClick={handleUpdateData}>Aceptar</button>
          </div>

        </div>
      </div>
    );
  }


  const renderModal = () => {
    return (
      <div className='bgModalAdmin' onClick={handleCloseModal}>
        <div className={`modalAdmin animate__animated ${!openModal ? "animate__zoomOut" : "animate__bounceIn"}`} onClick={handleModalClick}>

          <div className='modalAdminHeader'>
            <h3>{titleModal}</h3>
            <button onClick={handleCloseModal}><FaTimes /></button>
          </div>

          <div className='modalAdminBody'>

            {Object.keys(currentText).map((key) => (
              <div key={key} className='itemModalAdmin'>
                <div className='buttonsEditor'>
                  <h4>{handlePrintTitle(key)}</h4>
                  {!quillEditBoxState[key] ? <button onClick={() => handleStatusQuillBox(key)}>Edit</button> : null}
                </div>

                {Object.keys(currentText[key]).map((propKey) => {
                  const content = currentText[key][propKey];

                  if (content.startsWith('{"ops"')) {
                    try {
                      const parsedContent = JSON.parse(content);
                      return (
                        <div key={propKey}>
                          {!quillEditBoxState[key] ? <ViewQuillComponent content={parsedContent} type={typeText} /> : <QuillComponent text={currentText[key][propKey]} onQuillData={(data) => handleQuillData(data, key)} />}
                        </div>
                      );
                    } catch (error) {
                      console.error('Error parsing JSON:', error);
                    }
                  }
                  return null;
                })}
                
              </div>
            ))}
            
          </div>

          <div className='modalAdminFooter'>
            <button onClick={handleCloseModal}>Cancelar</button>
            <button onClick={handleUpdateData}>Aceptar</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='colum-left'>
        <input type="text" className='searchInput' id="searchInput" placeholder="Buscar..." />
        <button className='btnn'>Buscar</button>
      </div>

      <div className='textContainer'>

        <div className='headerTextField'>
          <p className='titleAccordion'>
            Home
            <button onClick={() => toggleAccordeon(0)}>
              {openIndexes.includes(0) ? <FaAngleUp /> : <FaAngleDown />}
            </button>
          </p>
          <div className={`bodyTextField ${openIndexes.includes(0) ? "open" : "closed"}`}>
            <div className='itemTextField'>
              <p>Home</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("29016553-ee6b-45ee-aafe-6e451d29e398", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("29016553-ee6b-45ee-aafe-6e451d29e398", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Seccion de información</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("77f20c78-2974-421b-8d9e-275c6307c053", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("77f20c78-2974-421b-8d9e-275c6307c053", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Seccion de Servicios Nacionales</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("3e5e8148-45f8-4bdf-99fe-3ce7644f04a7", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("3e5e8148-45f8-4bdf-99fe-3ce7644f04a7", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Seccion de Servicios Internacionales</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("d45a04ef-618d-40de-ada3-cbe93ffa9770", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("d45a04ef-618d-40de-ada3-cbe93ffa9770", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='headerTextField'>
          <p className='titleAccordion'>
            Productos Nacionales 
            <button onClick={() => toggleAccordeon(1)}>
              {openIndexes.includes(1) ? <FaAngleUp /> : <FaAngleDown />}
            </button>
          </p>
          <div className={`bodyTextField ${openIndexes.includes(1) ? "open" : "closed"}`}>
            <div className='itemTextField'>
              <p>Nacionales Home</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("90b413b8-6b8a-48cd-92ee-a844807ac1a4", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("90b413b8-6b8a-48cd-92ee-a844807ac1a4", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Gold</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("a84034f2-01c6-417f-b05b-267a81f27c06", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("a84034f2-01c6-417f-b05b-267a81f27c06", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>12+12</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("41bd6b29-f877-4cfe-b40e-38d216751e64", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("41bd6b29-f877-4cfe-b40e-38d216751e64", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Mensajería</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("407138e3-da91-4725-b44e-ce97858a0fc3", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("407138e3-da91-4725-b44e-ce97858a0fc3", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Perrecederos</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("158bdddf-e801-4157-9720-40346a29601e", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("158bdddf-e801-4157-9720-40346a29601e", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Alto Valor</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("c25cf9ca-9fcf-4b25-8db0-a9e7bb2bed53", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("c25cf9ca-9fcf-4b25-8db0-a9e7bb2bed53", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Alta Tecnología</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("657ae65a-eb11-4666-b84d-3b3e1e953590", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("657ae65a-eb11-4666-b84d-3b3e1e953590", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Animales Vivos</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("4c7bc6a5-2883-403f-9cf4-b1312507e7f3", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("4c7bc6a5-2883-403f-9cf4-b1312507e7f3", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Restos Humanos</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("6a1613dd-4bc4-4d29-b519-24eb5e068063", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("6a1613dd-4bc4-4d29-b519-24eb5e068063", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Pharma XPS</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("baded5a0-8456-4843-bdde-8e3e3abb27a9", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("baded5a0-8456-4843-bdde-8e3e3abb27a9", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Pharma STD</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("49e9dc7b-4c41-4938-b800-ee5b5c7a5269", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("49e9dc7b-4c41-4938-b800-ee5b5c7a5269", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Valija</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("2128300e-9022-4966-907f-e50f0fad2942", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("2128300e-9022-4966-907f-e50f0fad2942", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Mercancía Peligrosa</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("d5593ad8-15cd-4168-90d9-27ed9b86cfb9", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("d5593ad8-15cd-4168-90d9-27ed9b86cfb9", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Servicios para la Industria</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("265e5380-2d8b-4a8c-a120-ffa9cd820e54", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("265e5380-2d8b-4a8c-a120-ffa9cd820e54", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='headerTextField'>
          <p className='titleAccordion'>
            Productos Internacionales
            <button onClick={() => toggleAccordeon(2)}>
              {openIndexes.includes(2) ? <FaAngleUp /> : <FaAngleDown />}
            </button>
          </p>
          <div className={`bodyTextField ${openIndexes.includes(2) ? "open" : "closed"}`}>
            <div className='itemTextField'>
              <p>Home Servicios Internacionales</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("d45a04ef-618d-40de-ada3-cbe93ffa9770", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("d45a04ef-618d-40de-ada3-cbe93ffa9770", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Express</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("1c52c4f5-068a-452b-9375-10110634d25b", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("1c52c4f5-068a-452b-9375-10110634d25b", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>General</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("c4f4cd6a-cc0f-48cc-812c-d4daff768ab5", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("c4f4cd6a-cc0f-48cc-812c-d4daff768ab5", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Specialized</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("fa1b236b-b81e-47b0-87e8-960dde11a627", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("fa1b236b-b81e-47b0-87e8-960dde11a627", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Specialized Art</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("c6a88cd9-764e-42dd-ac60-ebeacbc914ab", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("c6a88cd9-764e-42dd-ac60-ebeacbc914ab", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Specialized Care</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("4a986cde-471f-4b26-a932-356472583a96", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("4a986cde-471f-4b26-a932-356472583a96", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Specialized DGR</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("2e101fed-3ff2-4650-8f94-0c9c11839493", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("2e101fed-3ff2-4650-8f94-0c9c11839493", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Specialized Fresh</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("1bbc1e07-50f9-4019-acb4-7f2023410d7b", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("1bbc1e07-50f9-4019-acb4-7f2023410d7b", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Specialized Live</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("b47ea075-c22b-4fc9-bffe-ff91ba1e4949", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("b47ea075-c22b-4fc9-bffe-ff91ba1e4949", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Specialized Pharma</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("4265fdc7-f1b4-4d0d-aaeb-7ea3213c4cb6", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("4265fdc7-f1b4-4d0d-aaeb-7ea3213c4cb6", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Specialized Safe</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("dfe55c44-7e9a-4d3f-8846-0e16d04fe7bb", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("dfe55c44-7e9a-4d3f-8846-0e16d04fe7bb", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Customized</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("7818a77c-3634-4a44-b753-354c0d8f16bb", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("7818a77c-3634-4a44-b753-354c0d8f16bb", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='headerTextField'>
          <p className='titleAccordion'>
            Restricciones Embarques
            <button onClick={() => toggleAccordeon(3)}>
              {openIndexes.includes(3) ? <FaAngleUp /> : <FaAngleDown />}
            </button>
          </p>
          <div className={`bodyTextField ${openIndexes.includes(3) ? "open" : "closed"}`}>
            <div className='itemTextField'>
              <p>Por tipo de mercancía</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("adee7153-f8ae-4881-8923-b9dc6bea5295", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("adee7153-f8ae-4881-8923-b9dc6bea5295", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Mercancías prohibidas para su transporte</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("35b4cb80-b8c1-41b9-b935-39b258fa2676", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("35b4cb80-b8c1-41b9-b935-39b258fa2676", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Mercancías de uso común</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("1c77175f-5556-4d23-8d5c-885fe9196efa", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("1c77175f-5556-4d23-8d5c-885fe9196efa", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Mercancías peligrosas no aceptadas por Aeroméxico Cargo</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("50c1bc14-7da9-4592-a7a8-009b58af3621", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("50c1bc14-7da9-4592-a7a8-009b58af3621", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Por condiciones de embalaje</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("cac431ac-23a0-4103-b8c3-d8a7ef952e8a", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("cac431ac-23a0-4103-b8c3-d8a7ef952e8a", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>Disposiciones generales de embalaje</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("508803f5-4212-489b-8406-cd64b05c9057", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("508803f5-4212-489b-8406-cd64b05c9057", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='headerTextField'>
          <p className='titleAccordion'>
            General cargo
            <button onClick={() => toggleAccordeon(4)}>
              {openIndexes.includes(4) ? <FaAngleUp /> : <FaAngleDown />}
            </button>
          </p>
          <div className={`bodyTextField ${openIndexes.includes(4) ? "open" : "closed"}`}>
            <div className='itemTextField'>
              <p>General cargo</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("19c35b1b-1e1f-40e8-a3c6-db448732b90b", 1, "text")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("19c35b1b-1e1f-40e8-a3c6-db448732b90b", 2, "text")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
            <div className='itemTextField'>
              <p>General cargo items</p>
              <div className='lngTextIcon'>
                <button onClick={() => handleOpenModal("279132ba-7945-43d3-bc88-022df0740024", 1, "json")}>
                  <span>Esp </span>
                  <img src={mexIcon} alt='mexico icon' />
                </button>
                <button onClick={() => handleOpenModal("279132ba-7945-43d3-bc88-022df0740024", 2, "json")}>
                  <span>Eng </span>
                  <img src={usaIcon} alt='mexico icon' />
                </button>
              </div>
            </div>
          </div>
        </div>
        
      </ div>

      {openModalText && renderModalText()}
      {openModal && renderModal()}
    </div>
    
  )
}

export default Textos;