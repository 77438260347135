import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import ImageHeader from "../../../../components/image-header/image-header";
import expressImg from "../../../../assets/imgs/services/international/express.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";

function Express() {
  const [tExpress] = useTranslation("expresPage");
  const [tSMetaData] = useTranslation("metaData");
  const idExpress = "1c52c4f5-068a-452b-9375-10110634d25b";
  const [textDescription, setTextDescription] = useState("");

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].services}/${
      Object.keys(navBarEs["products-submenu"])[1]
    }/${Object.keys(navBarEs["products-internacional-submenu"])[0]}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].services}/${
      Object.keys(navBarEn["products-submenu"])[1]
    }/${Object.keys(navBarEn["products-international-submenu"])[0]}`
  );

  useEffect(() => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    axios
      .get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idExpress}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      )
      .then((response) => {
        if (currentLng === "es") {
          setTextDescription(
            response.data.textWeb.text_es["01-descripción"].content
          );
        } else {
          setTextDescription(
            response.data.textWeb.text_us["01-description"].content
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{tSMetaData("express.title")}</title>
        <meta name="description" content={tSMetaData("express.description")} />
      </Helmet>
      <ImageHeader
        section={tExpress("header.section")}
        title={tExpress("header.title")}
        description={
          textDescription
            ? JSON.parse(textDescription)
            : JSON.parse(tExpress("description.content"))
        }
        contactSales={tExpress("header.contact-sales")}
        contactProductID="btnContactExpress"
        imgBackground={expressImg}
      />
    </>
  );
}

export default Express;
