import Footer from "../../../components/footer/Footer";
import Navbar from "../../../components/navbar/navbar";
import TitleSection from "../../../components/title-section/title-section";
import "./gen-cargo-main.css";
import boxIcon from "../../../assets/imgs/prepare-shipment/general_cargo/boxIcon.svg";
import dropIcon from "../../../assets/imgs/prepare-shipment/general_cargo/dropIcon.svg";
import fragileIcon from "../../../assets/imgs/prepare-shipment/general_cargo/fragilIcon.svg";
import courierIcon from "../../../assets/imgs/prepare-shipment/general_cargo/courierIcon.svg";
import heavyWeightIcon from "../../../assets/imgs/prepare-shipment/general_cargo/heavyWeightIcon.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ViewQuillComponent from "../../dashboard/admin/textos/viewQuillComponent";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import Helmet from "react-helmet";

function GenCargoMain() {
  const [tGenCargo] = useTranslation("genCargoPage");
  const [tSMetaData] = useTranslation("metaData");
  const idComponent = "19c35b1b-1e1f-40e8-a3c6-db448732b90b";
  const idGeneralSections = "279132ba-7945-43d3-bc88-022df0740024";
  const [item1, setItem1] = useState("");
  const [item2, setItem2] = useState("");
  const [item3, setItem3] = useState("");
  const [item4, setItem4] = useState("");
  const [item5, setItem5] = useState("");
  const [dataInformation, setDataInformation] = useState({
    "General cargo": {
      título: "General cargo",
      nota: "NOTA",
      text: "Un embarque puede considerarse con más de una clasificación según el tipo de carga (ej. botellas de vino: húmeda y frágil), por lo que en esos casos se deben contemplar todos los materiales de empaque que apliquen respectivamente.",
    },
  });

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].shipments}/${
      Object.keys(navBarEs["prepare-shipment-submenu"])[1]
    }`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].shipments}/${
      Object.keys(navBarEn["prepare-shipment-submenu"])[1]
    }`
  );

  const getTexts = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idComponent}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      const responseItems = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idGeneralSections}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (currentLng === "es") {
        setDataInformation(response.data.textWeb.text_es["General cargo"]);
        setItem1(responseItems.data.textWeb.text_es["Seca"].content);
        setItem2(responseItems.data.textWeb.text_es["Húmedos"].content);
        setItem3(responseItems.data.textWeb.text_es["Carga Frágil"].content);
        setItem4(responseItems.data.textWeb.text_es["Mensajería"].content);
        setItem5(responseItems.data.textWeb.text_es["Carga Pesada"].content);
      } else {
        setDataInformation(response.data.textWeb.text_us["General cargo"]);
        setItem1(responseItems.data.textWeb.text_us["Seca"].content);
        setItem2(responseItems.data.textWeb.text_us["Húmedos"].content);
        setItem3(responseItems.data.textWeb.text_us["Carga Frágil"].content);
        setItem4(responseItems.data.textWeb.text_us["Mensajería"].content);
        setItem5(responseItems.data.textWeb.text_us["Carga Pesada"].content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTexts();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{tSMetaData("preparaEmbarqueCargaGeneral.title")}</title>
        <meta
          name="description"
          content={tSMetaData("preparaEmbarqueCargaGeneral.description")}
        />
      </Helmet>
      <Navbar />
      <TitleSection title={tGenCargo("general-cargo.general-cargo")} />
      <div className="container mt-5">
        <p className="title-h3">
          {dataInformation["nota"] ?? tGenCargo("general-cargo.note")}
        </p>
        <p className="text-gray">
          {dataInformation["text"] ?? tGenCargo("general-cargo.text-1")}
        </p>
        <div className="article-container my-4">
          <article className="container text-gray">
            <div className="row">
              <div className="icon-container col-12 col-md-2 d-flex flex-column justify-content-center align-items-center">
                <img className="icons" src={boxIcon} alt="Box Icon" />
                <p className="d-flex justify-content-center py-3 text-premium-blue text-center">
                  {tGenCargo("article1.title")}
                </p>
              </div>
              <div className="col-12 col-md-10 p-3">
                <ViewQuillComponent
                  content={
                    item1
                      ? JSON.parse(item1)
                      : JSON.parse(tGenCargo("article1.content"))
                  }
                  type={"json"}
                />
              </div>
            </div>
          </article>
          <article className="container text-gray">
            <div className="row">
              <div className="icon-container col-12 col-md-2 d-flex flex-column justify-content-center align-items-center">
                <img className="icons" src={dropIcon} alt="Drop Icon" />
                <p className="d-flex justify-content-center py-3 text-premium-blue text-center">
                  {tGenCargo("article2.title")}
                </p>
              </div>
              <div className="col-12 col-md-10 p-3">
                <ViewQuillComponent
                  content={
                    item2
                      ? JSON.parse(item2)
                      : JSON.parse(tGenCargo("article2.content"))
                  }
                  type={"json"}
                />
              </div>
            </div>
          </article>
          <article className="container text-gray">
            <div className="row">
              <div className="icon-container col-12 col-md-2 d-flex flex-column justify-content-center align-items-center">
                <img className="icons" src={fragileIcon} alt="Fragil Icon" />
                <p className="d-flex justify-content-center py-3 text-premium-blue text-center">
                  {tGenCargo("article3.title")}
                </p>
              </div>
              <div className="col-12 col-md-10 p-3">
                <ViewQuillComponent
                  content={
                    item3
                      ? JSON.parse(item3)
                      : JSON.parse(tGenCargo("article3.content"))
                  }
                  type={"json"}
                />
              </div>
            </div>
          </article>
          <article className="container text-gray">
            <div className="row">
              <div className="icon-container col-12 col-md-2 d-flex flex-column justify-content-center align-items-center">
                <img
                  className="icons pt-3"
                  src={courierIcon}
                  alt="Messenger service Icon"
                />
                <p className="d-flex justify-content-center py-3 text-premium-blue text-center">
                  {tGenCargo("article4.title")}
                </p>
              </div>
              <div className="col-12 col-md-10 p-3">
                <ViewQuillComponent
                  content={
                    item4
                      ? JSON.parse(item4)
                      : JSON.parse(tGenCargo("article4.content"))
                  }
                  type={"json"}
                />
              </div>
            </div>
          </article>
          <article className="container text-gray">
            <div className="row">
              <div className="icon-container col-12 col-md-2 d-flex flex-column justify-content-center align-items-center">
                <img
                  className="icons"
                  src={heavyWeightIcon}
                  alt="Heavy weight Icon"
                />
                <p className="d-flex justify-content-center py-3 text-premium-blue text-center">
                  {tGenCargo("article5.title")}
                  <br />
                  {tGenCargo("article5.title-2")}
                </p>
              </div>
              <div className="col-12 col-md-10 p-3 d-flex">
                <ViewQuillComponent
                  content={
                    item5
                      ? JSON.parse(item5)
                      : JSON.parse(tGenCargo("article5.content"))
                  }
                  type={"json"}
                />
              </div>
            </div>
          </article>
        </div>
        <div className="d-flex justify-content-center justify-content-lg-end align-items-center my-5 py-2 py-md-5">
          <Link id="btnQuoteGeneralCargo" className="btn-blue" to="/quote">
            {tGenCargo("general-cargo.quote")}
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GenCargoMain;
