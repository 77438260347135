import RestrictionsIcons from "../../../../components/restrictions-icons/restrictions-icons";
import iconSharpCorners from "../../../../assets/imgs/prepare-shipment/restrictions/icon-sharp-corners.svg";
import iconInsufficientResistance from "../../../../assets/imgs/prepare-shipment/restrictions/icon-insufficient-resistance.svg";
import iconCrushedBoxes from "../../../../assets/imgs/prepare-shipment/restrictions/icon-crushed-boxes.svg";
import iconWithoutPallets from "../../../../assets/imgs/prepare-shipment/restrictions/icon-without-pallets.svg";
import iconReuseBoxes from "../../../../assets/imgs/prepare-shipment/restrictions/icon-reuse-boxes.svg";
import iconConveyor from "../../../../assets/imgs/prepare-shipment/restrictions/icon-conveyor.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";

function PackagingConditions() {
  const [tRestrictions] = useTranslation("restrictions");
  const idComponent = "cac431ac-23a0-4103-b8c3-d8a7ef952e8a";
  const [dataInformation, setDataInformation] = useState(() => {
    const newData = {};
    for (let i = 1; i <= 6; i++) {
      newData[`text-${i}`] = "";
    }
    newData["título"] = "";
    return {
      "Por condiciones de embalaje": newData,
    };
  });

  const getTexts = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idComponent}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (currentLng === "es") {
        setDataInformation(
          response.data.textWeb.text_es[
            "Por condiciones de embalaje"
          ]
        );
      } else {
        setDataInformation(
          response.data.textWeb.text_us[
            "Por condiciones de embalaje"
          ]
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTexts();
  }, []);

  return (
    <div className="container-fluid bg-light-gray">
      <div className="container p-0 d-flex flex-column">
        <p className="mt-5 mb-md-4 text-center text-premium-blue fs-3">
          {dataInformation["título"] ?? tRestrictions("packaging-conditions.packaging-conditions")}
        </p>
        <div className="row my-4 d-flex justify-content-center align-items-start">
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconSharpCorners}
              iconAlt="Sharp corner icon"
              height="70px"
              info={dataInformation["text-1"] ?? tRestrictions("packaging-conditions.text-1")}
              fontSize="14px"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconInsufficientResistance}
              iconAlt="Insufficient Resistance Icon"
              height="70px"
              info={dataInformation["text-2"] ?? tRestrictions("packaging-conditions.text-2")}
              fontSize="14px"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconCrushedBoxes}
              iconAlt="Crushed Box Icon"
              height="70px"
              info={dataInformation["text-3"] ?? tRestrictions("packaging-conditions.text-3")}
              fontSize="14px"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconWithoutPallets}
              iconAlt="Without Pallets Icon"
              height="70px"
              info={dataInformation["text-4"] ?? tRestrictions("packaging-conditions.text-4")}
              fontSize="14px"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconReuseBoxes}
              iconAlt="Reuse Boxes Icon"
              height="70px"
              info={dataInformation["text-5"] ?? tRestrictions("packaging-conditions.text-5")}
              fontSize="14px"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <RestrictionsIcons
              iconSrc={iconConveyor}
              iconAlt="Animal Conveyor Icon"
              height="70px"
              info={dataInformation["text-6"] ?? tRestrictions("packaging-conditions.text-6")}
              fontSize="14px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackagingConditions;
