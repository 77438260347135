import React, { useState, useEffect } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

function StepTwo({ onhandleStepTwo, dataOne, dataFour, register, errors }) {
  const [tSGetQuoteStepTwo] = useTranslation("getQuoteStepTwo");
  const [tSGetQuoteStepSumary] = useTranslation("getQuoteStepSumary");
  const stations = JSON.parse(localStorage.getItem("stations"));

  const listCities =
    dataOne.tipo_servicio === "Pharma XPS"
      ? stations.filter((city) => city.code_text !== "PBC")
      : stations;

  const [piece, setPiece] = useState(1);
  const [paidSelect, setPaidSelect] = useState(
    tSGetQuoteStepSumary("values.origin")
  );
  const [formData, setFormData] = useState({
    origen: "",
    destino: "",
    pago: paidSelect,
    piezas: piece,
    peso: "",
    valor: 0.0,
    description: "",
  });

  const addPiece = () => {
    const newPiece = piece + 1;
    setPiece(newPiece);
    updateFormData({ piezas: newPiece });
  };

  const removePiece = () => {
    if (piece > 1) {
      const newPiece = piece - 1;
      setPiece(newPiece);
      updateFormData({ piezas: newPiece });
    }
  };

  const updateFormData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleStepTwo = (e) => {
    const { name, value } = e.target;
    const newValue = name === "peso" ? parseInt(value, 10) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  useEffect(() => {
    setPaidSelect(formData.pago);
    onhandleStepTwo(formData);
  }, [formData, formData.pago, onhandleStepTwo]);

  useEffect(() => {
    const firstOptionOrigin = document.querySelector(
      ".js-shipment-quote-form-origen option:first-child"
    );
    const firstOptionDestination = document.querySelector(
      ".js-shipment-quote-form-destino option:first-child"
    );
    if (firstOptionOrigin && firstOptionDestination) {
      const initialValueOrigin = firstOptionOrigin.value;
      const initialValueDestination = firstOptionDestination.value;

      setFormData((prevState) => ({
        ...prevState,
        origen: initialValueOrigin,
        destino: initialValueDestination,
      }));
    }
  }, []);

  return (
    <>
      <div
        className="shipment__step set fadeInUp"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        <h4>
          <span>{tSGetQuoteStepTwo("step1")}</span>
          {tSGetQuoteStepTwo("step2")}
        </h4>
      </div>

      <div className="shipment__form__row" role="row">
        <div className="shipment__form__column">
          <label htmlFor="origen">
            <i>*</i>
            {tSGetQuoteStepTwo("origin")}
          </label>
          <div className="select">
            <select
              name="origen"
              className={`js-shipment-quote-form-origen ${
                errors.origen ? "missing-field" : ""
              }`}
              autoComplete="off"
              aria-hidden="true"
              aria-disabled="false"
              pattern="[a-zA-Z]*"
              id="origen"
              {...register("origen", {
                pattern: /^(?!Seleccione...$|Choose...$).*$/,
              })}
              onChange={handleStepTwo}
            >
              <option value={tSGetQuoteStepTwo("choose")}>
                {tSGetQuoteStepTwo("choose")}
              </option>
              {listCities.map((city, index) => (
                <option
                  key={index}
                  data-code={city.code_text}
                  value={city.code_text}
                  data-num={index + 1}
                >
                  {city.name} ({city.code_text.substring(0, 3)})
                </option>
              ))}
            </select>
          </div>
          {errors.origen?.type === "pattern" && (
            <div
              className="shipment__form__column__message__services show_alert"
              role="alert"
            >
              {tSGetQuoteStepTwo("missingField")}
            </div>
          )}
        </div>
        <div className="shipment__form__column">
          <label htmlFor="destino">
            <i>*</i>
            {tSGetQuoteStepTwo("destination")}
          </label>
          <div className="select">
            <select
              name="destino"
              className={`js-shipment-quote-form-destino ${
                errors.destino ? "missing-field" : ""
              }`}
              autoComplete="off"
              aria-hidden="true"
              aria-disabled="false"
              pattern="[a-zA-Z]*"
              id="destino"
              data-name="destino"
              {...register("destino", {
                pattern: /^(?!Seleccione...$|Choose...$).*$/,
                validate: {
                  validate: (value) => {
                    const origen = document.getElementById("origen").value;

                    if (origen === value) {
                      return false;
                    }
                    return true;
                  },
                },
              })}
              onChange={handleStepTwo}
            >
              <option value={tSGetQuoteStepTwo("choose")}>
                {tSGetQuoteStepTwo("choose")}
              </option>
              {stations.map((city, index) => (
                <option
                  key={index}
                  data-code={city.code_text}
                  value={city.code_text}
                  data-num={index + 1}
                >
                  {city.name} ({city.code_text.substring(0, 3)})
                </option>
              ))}
            </select>
          </div>
          {errors.destino?.type === "pattern" && (
            <div
              className="shipment__form__column__message__services show_alert"
              role="alert"
            >
              {tSGetQuoteStepTwo("missingField")}
            </div>
          )}
          {errors.destino?.type === "validate" && (
            <div
              className="shipment__form__column__message__services show_alert"
              role="alert"
            >
              {tSGetQuoteStepTwo("duplicate")}
            </div>
          )}
        </div>
      </div>

      <div className="shipment__form__row" role="row">
        <div className="shipment__form__column">
          <label htmlFor="pago_origen">
            <i>*</i>
            {tSGetQuoteStepTwo("paidIn")}
          </label>
          <div className="shipment__form__radio">
            <div>
              <input
                type="radio"
                name="pago"
                id="pago_origen"
                value={tSGetQuoteStepSumary("values.origin")}
                className="js-shipment-quote-form-pago"
                onChange={handleStepTwo}
              />
              <label
                htmlFor="pago_origen"
                className={`paidSelect ${
                  paidSelect === tSGetQuoteStepSumary("values.origin")
                    ? "buttonActive"
                    : ""
                }`}
                role="button"
              >
                {tSGetQuoteStepTwo("origin")}
              </label>
              <div
                className="shipment__form__column__message"
                role="alert"
              ></div>
            </div>
            <div>
              <input
                type="radio"
                name="pago"
                id="pago_destino"
                value={tSGetQuoteStepSumary("values.destination")}
                className="js-shipment-quote-form-pago"
                onChange={handleStepTwo}
              />
              <label
                htmlFor="pago_destino"
                className={`paidSelect ${
                  paidSelect === tSGetQuoteStepSumary("values.destination")
                    ? "buttonActive"
                    : ""
                } ${
                  dataFour.entrega ===
                  tSGetQuoteStepSumary("values.paymentAtArrival")
                    ? "botonInactivo"
                    : ""
                }`}
                role="button"
              >
                {tSGetQuoteStepTwo("destination")}
              </label>
              <div
                className="shipment__form__column__message"
                role="alert"
              ></div>
            </div>
          </div>
        </div>

        <div className="shipment__form__column">
          <label htmlFor="piezas">
            <i>*</i>
            {tSGetQuoteStepTwo("Parts")}
          </label>
          <div className="shipment__form__number">
            <input
              type="number"
              name="piezas"
              id="piezas"
              value={piece > 0 ? piece : 1}
              pattern="[0-9]*"
              maxLength={10}
              className="js-shipment-quote-form-piezas"
              onChange={handleStepTwo}
            />
            <div className="shipment__form__number__controls">
              <button
                type="button"
                className="button js-button-control-piezas-add"
                onClick={addPiece}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 9.323 4.662"
                >
                  <g transform="translate(9.323 68.412) rotate(180)">
                    <g transform="translate(0 63.75)">
                      <path
                        d="M0,63.75l4.662,4.662L9.323,63.75Z"
                        transform="translate(0 -63.75)"
                      ></path>
                    </g>
                  </g>
                </svg>
              </button>
              <button
                type="button"
                className="button js-button-control-piezas-remove"
                onClick={removePiece}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 9.323 4.662"
                >
                  <g transform="translate(0 -63.75)">
                    <g transform="translate(0 63.75)">
                      <path
                        d="M0,63.75l4.662,4.662L9.323,63.75Z"
                        transform="translate(0 -63.75)"
                      ></path>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div className="shipment__form__column__message" role="alert"></div>
        </div>
      </div>

      <div className="shipment__form__row" role="row">
        <div className="shipment__form__column">
          <label htmlFor="peso">
            <i>*</i>
            {tSGetQuoteStepTwo("shippingWeight")}
          </label>
          <div className="shipment__form__weight">
            <div className="column">
              <input
                type="text"
                name="peso"
                id="peso"
                {...register("peso", {
                  required: "Este campo es obligatorio",
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Ingresa un número válido",
                  },
                  validate: {
                    notGreaterThan3ForMensajeria: (value) => {
                      const servicio = dataOne.tipo_servicio || "";
                      const isMensajeriaOrCourier =
                        servicio === "Mensajería" || servicio === "Courier";

                      // Validar si tipod_de_servicio es igual a 'Mensajería' o 'Courier' y el valor del peso no es mayor a 3
                      if (isMensajeriaOrCourier && parseFloat(value) > 3) {
                        return "El peso no puede ser mayor a 3 para Mensajería o Courier";
                      }

                      return true;
                    },
                  },
                })}
                pattern="^[0-9]+(\.[0-9]+)?$"
                maxLength={10}
                placeholder="1"
                className={`js-shipment-quote-form-peso ${
                  errors.peso?.type === "required" ? "missing-field" : ""
                }`}
                onChange={handleStepTwo}
              />
              {/* {errors.peso && <p>{errors.peso.message}</p>} */}
            </div>
            <div className="column">
              <div className="select">
                <select
                  name="unidades"
                  className="js-shipment-quote-form-unidades"
                >
                  <option value="kilogramo">Kg</option>
                </select>
              </div>
            </div>
          </div>
          {errors.peso?.type === "required" && (
            <div
              className="shipment__form__column__message__services show_alert"
              role="alert"
            >
              {tSGetQuoteStepTwo("missingField")}
            </div>
          )}
          <div
            className={`shipment__form__column__message__services ${
              formData.peso > 80 ? "show_alert" : ""
            }`}
          >
            {tSGetQuoteStepTwo("alertWeight2")}
          </div>
          <div
            className={`shipment__form__column__message__services ${
              dataOne.tipo_servicio === "Mensajería" && formData.peso > 3
                ? "show_alert"
                : ""
            }`}
          >
            {tSGetQuoteStepTwo("alertWeight")}
          </div>
        </div>

        <div className="shipment__form__column">
          <label htmlFor="valor">
            <i className="js-shipment-quote-form-valor-label">*</i>
            {tSGetQuoteStepTwo("declaredValue")}
          </label>
          <div className="shipment__form__money">
            <input
              type="number"
              name="valor"
              id="valor"
              placeholder="MXN"
              pattern="[0-9]*"
              maxLength={10}
              className={`js-shipment-quote-form-valor ${
                dataOne.tipo_servicio === "Animales Vivos" ||
                dataOne.tipo_servicio === "Live Animals"
                  ? "is-disabled"
                  : ""
              } ${errors.valor ? "missing-field" : ""}`}
              {...register("valor", {
                validate: {
                  validate: (value) => {
                    const numberValue = parseFloat(value);

                    // Verificar si el valor es negativo
                    if (numberValue < 0) {
                      return "No se aceptan valores negativos";
                    }

                    // Si no es negativo, el valor es válido
                    return true;
                  },
                },
              })}
              onChange={handleStepTwo}
              disabled={
                dataOne.tipo_servicio === "Animales Vivos" ||
                dataOne.tipo_servicio === "Live Animals"
              }
            />
            <div className="shipment__form__money__icon">$</div>
          </div>
          {errors.valor?.type === "validate" && (
            <div
              className="shipment__form__column__message__services show_alert"
              role="alert"
            >
              {tSGetQuoteStepTwo("noNegativeValues")}
            </div>
          )}
        </div>
      </div>

      <div className="shipment__form__description">
        <label htmlFor="description">
          <i>*</i>
          {tSGetQuoteStepTwo("description")}
        </label>
        <textarea
          name="description"
          id="description"
          {...register("description", { required: true })}
          rows={4}
          className={`js-shipment-quote-form-description ${
            errors.description?.type === "required" ? "missing-field" : ""
          }`}
          onChange={handleStepTwo}
        ></textarea>
        {errors.description?.type === "required" && (
          <div
            className="shipment__form__column__message__services show_alert"
            role="alert"
          >
            {tSGetQuoteStepTwo("missingField")}
          </div>
        )}
      </div>
    </>
  );
}

export default StepTwo;
