import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import TitleSection from "../../../../components/title-section/title-section";
import Card from "../../../../components/card/card";
import axios from "axios";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";

function InternationalHome() {
  const [tInternational] = useTranslation("international");
  const [tSMetaData] = useTranslation("metaData");
  const dataInternational = tInternational("data", { returnObjects: true });
  const idInternationals = "d45a04ef-618d-40de-ada3-cbe93ffa9770";
  const [titleInternational, setTitleInternational] = useState("");
  const [dataInternationalDB, setDataInternationalDB] = useState([]);

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].services}/${
      Object.keys(navBarEs["products-submenu"])[1]
    }`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].services}/${
      Object.keys(navBarEn["products-submenu"])[1]
    }`
  );

  const getTexts = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const resInternational = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idInternationals}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (currentLng === "es") {
        setTitleInternational(
          resInternational.data.textWeb.text_es.title["titulo de sección"]
        );
        setDataInternationalDB([
          resInternational.data.textWeb.text_es["express"],
          resInternational.data.textWeb.text_es["general"],
          resInternational.data.textWeb.text_es["specialized"],
          resInternational.data.textWeb.text_es["customized"],
        ]);
      } else {
        setTitleInternational(
          resInternational.data.textWeb.text_us.title["titulo de sección"]
        );
        setDataInternationalDB([
          resInternational.data.textWeb.text_us["express"],
          resInternational.data.textWeb.text_us["general"],
          resInternational.data.textWeb.text_us["specialized"],
          resInternational.data.textWeb.text_us["customized"],
        ]);
      }
    } catch (error) {
      setDataInternationalDB([...dataInternational]);
      console.error(error);
    }
  };

  useEffect(() => {
    getTexts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCards = () => {
    return dataInternationalDB.map((card, index) => (
      <div key={index} className="col-12 col-md-6 col-xl-4">
        <div className="nationalHomeContainer">
          <Card
            description={card?.texto || ""}
            redirect={card?.["texto-botón-linea"] || ""}
            redirectHref={card?.["link-botón-linea"] || ""}
            linkId={"linkInternational" + index || ""}
            image={card?.imagen}
          />
        </div>
      </div>
    ));
  };

  return (
    <>
      <Helmet>
        <title>{tSMetaData("internacionales.title")}</title>
        <meta
          name="description"
          content={tSMetaData("internacionales.description")}
        />
      </Helmet>
      <TitleSection
        title={titleInternational ?? tInternational("title.title-page")}
      />

      <div
        style={{ marginTop: "20px", marginBottom: "40px" }}
        className="container"
      >
        <div className="row">
          {dataInternationalDB.length > 0 && renderCards()}
        </div>
      </div>
    </>
  );
}

export default InternationalHome;
