import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import ImageHeader from "../../../../components/image-header/image-header";
import highTechnologyImg from "../../../../assets/imgs/services/nationals/high-technology.jpg";
import Accordion from "../../../../components/acordion/accordion";
import { useEffect, useState } from "react";
import axios from "axios";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";

function HighTechnology() {
  const [tHighTechnology] = useTranslation("highTechnologyPage");
  const [tSMetaData] = useTranslation("metaData");
  const idHighTechnology = "657ae65a-eb11-4666-b84d-3b3e1e953590";
  const [textDescription, setTextDescription] = useState("");
  const [accordion2, setAccordion2] = useState("");

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].services}/${
      Object.keys(navBarEs["products-submenu"])[0]
    }/${Object.keys(navBarEs["products-nacionales-submenu"])[5]}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].services}/${
      Object.keys(navBarEn["products-submenu"])[0]
    }/${Object.keys(navBarEn["products-nationals-submenu"])[5]}`
  );

  useEffect(() => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    axios
      .get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idHighTechnology}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      )
      .then((response) => {
        if (currentLng === "es") {
          setTextDescription(
            response.data.textWeb.text_es["01-descripción"].content
          );
          setAccordion2(
            response.data.textWeb.text_es[
              "03-Documentación, Aceptación y Recolección"
            ].content
          );
        } else {
          setTextDescription(
            response.data.textWeb.text_us["01-description"].content
          );
          setAccordion2(
            response.data.textWeb.text_us["03-Check-in, Acceptance and Pickup"]
              .content
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{tSMetaData("altaTecnología.title")}</title>
        <meta
          name="description"
          content={tSMetaData("altaTecnología.description")}
        />
      </Helmet>
      <ImageHeader
        section={tHighTechnology("header.section")}
        title={tHighTechnology("header.title")}
        description={
          textDescription
            ? JSON.parse(textDescription)
            : JSON.parse(tHighTechnology("description.content"))
        }
        quoteBtn={tHighTechnology("header.quote-btn")}
        quoteProductID="btnQuoteTech"
        contactSales={tHighTechnology("header.contact-sales")}
        contactProductID="btnContactTech"
        imgBackground={highTechnologyImg}
      />
      <Accordion
        title={tHighTechnology("accordion-1.title")}
        content={JSON.parse(tHighTechnology("accordion-1.content"))}
      />
      <div className="divider"></div>
      <Accordion
        title={tHighTechnology("accordion-2.title")}
        content={
          accordion2
            ? JSON.parse(accordion2)
            : JSON.parse(tHighTechnology("accordion-2.content"))
        }
      />
      <div className="divider"></div>
      <Accordion
        title={tHighTechnology("accordion-3.title")}
        content={JSON.parse(tHighTechnology("accordion-3.content"))}
      />
    </>
  );
}

export default HighTechnology;
