import Footer from '../footer/Footer';
import Navbar from '../navbar/navbar';
import { useNavigate } from 'react-router-dom';
import '../error404/error404.css';

function SessionExpired() {
    const navigate = useNavigate();

    const redirectHome = () => {
        navigate('/dashboard/login');
    }

    return (
        <>
            <Navbar />
            <div className='container errorContainer'>
                <p className='errorTitlte'>Sesión Expirada</p>
                <p className='errorDescription'>Al parecer tu sesión expiró, por favor ingresa de nuevo tus credenciales</p>
                <button onClick={redirectHome}>REGRESAR AL INICIO</button>
            </div>
            <Footer />
        </>
    )
}

export default SessionExpired;