import { useState } from "react";
import "./btnSubMenu.css";
import { Link } from "react-router-dom";
import BtnRightSubmenu from "../btnRightSubmenu/btnRightSubmenu";
import { AiFillCaretDown } from 'react-icons/ai';
import { useTranslation } from "react-i18next";

function BtnSubMenu({ id, title, subMenu, category, subCategory }) {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showSubMenuRight, setShowSubMenuRight] = useState(false);
  const [subCategoryKey, setSubCategoryKey] = useState('');
  const [top, setTop] = useState(0);
  const [dataSend, setDataSend] = useState(undefined);
  const [tNabvar] = useTranslation("navbar");
 
  const handleMouseEnter = () => {
    if (window.innerWidth > 1024) {
      setShowSubMenu(true);
    }
  };

  const handleClick = () => {
    if (window.innerWidth < 1024) {
      setShowSubMenu(!showSubMenu);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 1024) {
      setShowSubMenu(false);
      setShowSubMenuRight(false);
    }
  };

  const handleShowSubmenuRight = (key, index) => {
    if (window.innerWidth > 1024) {
      setTop(index * 33);
      setShowSubMenuRight(true);
      if (subCategory) {

        if(typeof(tNabvar(`${subCategory}-${key}-submenu`, { returnObjects: true })) == "string"){
          setDataSend(undefined);
        } else {
          setDataSend(tNabvar(`${subCategory}-${key}-submenu`, { returnObjects: true }));
          setSubCategoryKey(key);
        }
      }
    }
  };

  return (
    <>
      <p
        className="nav-text"
        onMouseEnter={handleMouseEnter}
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
      >
        
        {subCategory === undefined ? (
          <Link to={`/${category}`} className="nav-redirect" id={`${category}-link`}>
            {title}
          </Link>
        ) : (
          <span>
            {title} <AiFillCaretDown className="d-lg-none text-end-normal fs-9" />
          </span>
        )}
        
      </p>
      <div className="submenu">
        {showSubMenu && subMenu && (
          <ul
            className="submenu-item"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {Object.keys(subMenu).map((key, index) => (
              // Si se agrega target='blank' todo el menú se afecta. 
              <Link to={`/${category}/${key}`} key={key} className="nav-redirect" id={`${category}-${key}-link`}>
                <li
                  key={key}
                  onMouseEnter={() => handleShowSubmenuRight(key, index)}
                >
                  {subMenu[key]}
                </li>
              </Link>
            ))}
            <div className="submenuRight" style={{ top: `${top}px` }}>
              {showSubMenuRight && dataSend && (
                <BtnRightSubmenu category={category} subCategoryKey={subCategoryKey} submenuRight={dataSend} />
              )}
            </div>
          </ul>
        )}
      </div>
    </>
  );
}

export default BtnSubMenu;
