import React from 'react';
import Routes from '../routes/routes';

function App() {
	return (
		<React.StrictMode>
			<Routes />
		</React.StrictMode>
	);
};

export default App;