import { useEffect, useRef, useState } from 'react';
import './track-details.css';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaArrowRightLong } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import { useNavigate } from 'react-router-dom';
import TrackBtnContact from '../../../components/trackBtnContact/trackBtnContact';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function TrackDetails({ details }) {
    const miBotonRef = useRef();
    const divElements = document.querySelectorAll('.contador_parcialidades');
    const totalParcialidades = divElements.length;
    const table_text = document.querySelectorAll('.table_text');
    const pieces_exp = document.querySelectorAll('.pieces_exp');
    const [tTrack] = useTranslation("trackPage");
    const currentDate = new Date();
    const navigate = useNavigate();

    const [waybillNumber, setWaybillNumber] = useState('');
    const [historialMovimientos, setHistorialMovimientos] = useState([]);
    const [originCode, setOriginCode] = useState('');
    const [destinationCode, setDestinationCode] = useState('');
    const [numberPieces, setNumberPieces] = useState('');
    const [kgNumber, setKgNumber] = useState('');
    const [statusCode, setStatusCode] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const existingCodes = ["BKD", "RCS", "RCF", "DEP", "DLV", "RCT", "TFD"];

    useEffect(() => {

        if(details && details.length > 0) {
            setTrackData(details);
            clickSimulator();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details, destinationCode]);

    function clickSimulator() {
        if(details && details.length <= 0) {
            return;
        }
        // Simular un clic después de que el componente se monta
        setTimeout(() => {
              // Simular un clic después de 1000 milisegundos (1 segundo)
              miBotonRef.current.click();
            }, 1000);
        setTimeout(() => {
              // Simular un clic después de 1000 milisegundos (1 segundo)
              miBotonRef.current.click();
            }, 1000);
        setTimeout(() => {
              // Simular un clic después de 1000 milisegundos (1 segundo)
              miBotonRef.current.click();
            }, 1000);
        setTimeout(() => {
              // Simular un clic después de 1000 milisegundos (1 segundo)
              miBotonRef.current.click();
            }, 1000);
        setTimeout(() => {
              // Simular un clic después de 1000 milisegundos (1 segundo)
              miBotonRef.current.click();
            }, 1000);
        setTimeout(() => {
              // Simular un clic después de 1000 milisegundos (1 segundo)
              miBotonRef.current.click();
            }, 1000);
        setTimeout(() => {
              // Simular un clic después de 1000 milisegundos (1 segundo)
              miBotonRef.current.click();
            }, 1000);
        setTimeout(() => {
              // Simular un clic después de 1000 milisegundos (1 segundo)
              miBotonRef.current.click();
            }, 1000);
    };

    function setTrackData(data) {

        const regexWaybill = /(\d{3}-\d{8})/;
        const regexOriginCode = /(\d{3}-\d{8})([A-Z]{3})/;
        const regexDestinationCode = /(\d{3}-\d{8}[A-Z]{3})([A-Z]{3})/;
        const regexNumberPices = /[PT](\d+)K/;
        const regexKgNumber = /K(\d+(\.\d+)?)/;

        const waybillNumberMatch = data[1].match(regexWaybill);
        const originCodeMatch = data[1].match(regexOriginCode);
        const destinationCodeMatch = data[1].match(regexDestinationCode);
        const numberPiecesMatch = data[1].match(regexNumberPices);
        const kgNumberMatch = data[1].match(regexKgNumber);

        const waybill = waybillNumberMatch ? waybillNumberMatch[0] : '';
        const originCode = originCodeMatch ? originCodeMatch[2] : '';
        const destinationCode = destinationCodeMatch ? destinationCodeMatch[2] : '';
        const numberPieces = numberPiecesMatch ? numberPiecesMatch[1] : '';
        const kgNumber = kgNumberMatch ? kgNumberMatch[1] : '';

        const lines = data.slice(2, -1);
        const pairLines = lines.filter((element, index) => index % 2 === 0);
        let statusArr = [];

        pairLines.forEach((line) => {
            const lettersBeforeSlash = line.split("/")[0];

            if(existingCodes.includes(lettersBeforeSlash)) {
                statusArr.push(line)
            }
        })

        if(statusArr[statusArr.length -1].split("/")[0] !== "DLV") {

            if(statusArr.length >= 3) {
                if(statusArr.at(-1).split("/")[0] === "BKD" && 
                    statusArr.at(-2).split("/")[0] !== "RCF" &&
                    statusArr.at(-3).split("/")[0] !== "DEP")
                {
                    setStatusCode(`${tTrack("status-code-details.rcf-txt-pending")}`);
                } else if(statusArr.at(-1).split("/")[0] === "BKD" && 
                    statusArr.at(-2).split("/")[0] === "RCF" &&
                    statusArr.at(-3).split("/")[0] === "DEP") 
                {
                    setStatusCode(`${tTrack("status-code-details.rcf-txt")}`);
                } else {
                    const statusLine = statusArr[statusArr.length -1];
                    setStatusCode(setStatusValue(statusLine.split("/")[0], statusLine) || '');
                }
            } else {
                const statusLine = statusArr[statusArr.length -1];
                setStatusCode(setStatusValue(statusLine.split("/")[0], statusLine) || '');
            }


        } else {

            const statusLine = statusArr[statusArr.length -1];
            const statusDLV = statusArr.filter(value => /^DLV/.test(value.trim()));
            let totalNumberPieces = 0;
            let piecesText = "";

            if(statusDLV.length > 1) {
                for(let i = 0; i < statusDLV.length; i++) {
                    totalNumberPieces += Number(statusDLV[i].match(regexNumberPices)[1]);
                }
            } else {
                totalNumberPieces += Number(statusDLV[0].match(regexNumberPices)[1]);
            }

            if(totalNumberPieces === 1 && Number(numberPieces) === 1) {
                piecesText = `${tTrack('status.piece')}`
            } else {
                piecesText = `${tTrack('status.piece')}s`
            }

            if(totalNumberPieces < Number(numberPieces)) {
                setStatusCode(`${tTrack("status-code-details.dlv-txt")} ${totalNumberPieces}/${numberPieces} ${piecesText}`);
            } else {
                setStatusCode(setStatusValue("DLV", statusLine))
            }
                        
        }

        
        setWaybillNumber(waybill);
        setOriginCode(originCode);
        setDestinationCode(destinationCode);
        setNumberPieces(numberPieces);
        setKgNumber(parseFloat(kgNumber).toFixed(1));
    }

    function setStatusValue(status, statusLine) {

        let statusValue = '';
        switch (status) {
            case 'BKD':
                if(statusLine.split("/")[1].match(/[A-Z]+(\d+)/)[1] >= 6000 && statusLine.split("/")[1].match(/[A-Z]+(\d+)/)[1] <= 6999) {
                    statusValue = `${tTrack("status-code-details.bkd-transport-txt")}`
                } else {
                    statusValue = `${tTrack("status-code-details.bkd-txt")}`
                }
                break;
            case 'RCS':
                statusValue = `${tTrack("status-code-details.rcs-txt")}`
                break;
            case 'RCF':
                if(statusLine.split("/")[3] === destinationCode) {
                    const piecesForPartial = statusLine.split("/")[4].match(/[PT](\d+)K/)[1];
                    statusValue = `${tTrack("status-code-details.rcf-txt-arrival")} ${piecesForPartial}/${numberPieces}`
                } else {
                    statusValue = `${tTrack("status-code-details.rcf-txt")}`
                }
                break;
            case 'DEP':
                if(statusLine.split("/")[1].match(/[A-Z]+(\d+)/)[1] >= 6000 && statusLine.split("/")[1].match(/[A-Z]+(\d+)/)[1] <= 6999) {
                    statusValue = `${tTrack("status-code-details.dep-txt-ground-delivery")}`
                } else {
                    statusValue = `${tTrack("status-code-details.dep-txt")}`
                }
                break;
            case 'DLV':
                statusValue = `${tTrack("status-code-details.dlv-txt")}`
                break;
            case 'RCT':
                statusValue = `${tTrack("status-code-details.rct-txt")}`
                break;
            case 'TFD':
                statusValue = `${tTrack("status-code-details.tfd-txt")}`
                break;
            default:
                statusValue = `${tTrack("status-code-details.default-txt")}`;
        }
        return statusValue;
    }

    const setNumberPiecesText = () => {
        if(parseFloat(numberPieces) === 1.00) {
            return `1 ${tTrack('status.piece')}`;
        } else {
            return `${numberPieces} ${tTrack('status.piece')}s`
        }
    }

    const handleResetWaybill = () => {
        if(localStorage.getItem("lng")) {
            if(localStorage.getItem("lng") === 'us') {
              navigate(`/${navBarEn["category-services"].track}`)
            } else {
              navigate(`/${navBarEs["category-services"].track}`)
            }
        } else {
            navigate(`/${navBarEs["category-services"].track}`)
        }
    }

    const toggleAcordeon = () => {
        setIsOpen(!isOpen);
    };

    const loadData = () => {
        let detalles =[];
        let pieces_arr = [];
        let contador_para_ir_creando = 1;
        const parcialidades = [];
        for(let i = 1; i<=totalParcialidades; i++){
            const parcialidadExport = [];
            parcialidades.push(parcialidadExport);
        }

        pieces_exp.forEach((pieces, index) =>{
            const pieces_aqui = pieces.dataset.pieces;
            pieces_arr.push(pieces_aqui);
        });

        table_text.forEach((texto, index) => {
            const texto_p = texto.dataset.texto;
            const fecha = texto.dataset.fecha;
            const hora = texto.dataset.hora;
            const weight = texto.dataset.weight;
            const orides = texto.dataset.orides;
            const flaight = texto.dataset.flaight;
            const piezas_aqui = pieces_arr[contador_para_ir_creando-1];
            parcialidades[contador_para_ir_creando-1].push([fecha, hora, texto_p, piezas_aqui, weight, orides, flaight]);
            contador_para_ir_creando++;
            if(contador_para_ir_creando > totalParcialidades){
                contador_para_ir_creando = 1;
            }
        });        

        detalles.push([{
            border: [false, false, false, false],
            fillColor: '#eeeeee',
            fontSize: 8,
            text: `${tTrack("file.t11")}`,
        }]);

        detalles.push([{
            border: [false, false, false, false],
            fillColor: '#eeeeee',
            fontSize: 8,
            text: `${tTrack("file.t12")}`,
        }]);

        detalles.push([{
            border: [false, false, false, false],
            fillColor: '#eeeeee',
            fontSize: 8,
            text: `${tTrack("file.t13")}`,
        }]);
        
        for (let o = 0; o < totalParcialidades; o++) {
            for(let o2 = 0; o2 < parcialidades[o].length  ; o2++){
                if( parcialidades[o][o2][0] ){
                    detalles[0].push([{
                        border: [false, false, false, false],
                        fillColor: '#eeeeee',
                        fontSize: 8,
                        text: parcialidades[o][o2][0] ? parcialidades[o][o2][0] : '-',
                    }]);  

                    detalles[1].push([{
                        border: [false, false, false, false],
                        fillColor: '#eeeeee',
                        fontSize: 8,
                        text: parcialidades[o][o2][1] ? parcialidades[o][o2][1] : '-',
                    }]);

                    let mensaje = "";
                    if(parcialidades[o][o2][2]){
                        if(parcialidades[o][o2][2] === "Despegó" || parcialidades[o][o2][2] === "Took of"){
                            mensaje = parcialidades[o][o2][3]+" "+tTrack("file.t14")+" "+parcialidades[o][o2][3]+" "+tTrack("file.t15")+" "+parcialidades[o][o2][4]+"kg "+parcialidades[o][o2][2]+" "+tTrack("file.t18")+" -> "+parcialidades[o][o2][5].substring(0, 3)+" "+tTrack("file.t19")+" "+parcialidades[o][o2][6];
                        }if(parcialidades[o][o2][2] === "Recibido en Almacén Destino" || parcialidades[o][o2][2] === "Received at Destination Warehouse"){
                            mensaje = parcialidades[o][o2][3]+" "+tTrack("file.t14")+" "+parcialidades[o][o2][3]+" "+tTrack("file.t15")+" "+parcialidades[o][o2][4]+"kg "+parcialidades[o][o2][2]+" "+tTrack("file.t18")+" -> "+parcialidades[o][o2][5]+" "+tTrack("file.t19")+" "+parcialidades[o][o2][6];
                        }else{
                            mensaje = parcialidades[o][o2][3]+" "+tTrack("file.t14")+" "+parcialidades[o][o2][3]+" "+tTrack("file.t15")+" "+parcialidades[o][o2][4]+"kg "+parcialidades[o][o2][2];
                        }
                    }else{
                        mensaje = '-';
                    }
                    mensaje = mensaje.replace(/(\r\n|\n|\r)/gm," ");
                    detalles[2].push([{
                        border: [false, false, false, false],
                        fillColor: '#eeeeee',
                        fontSize: 8,
                        text: mensaje,
                    }]);
                }   
            }
            if (o < totalParcialidades - 1) {
                detalles[0].push([{
                    border: [false, false, false, false],
                    fillColor: '#eeeeee',
                    fontSize: 8,
                    text: `Fecha`,
                }]);

                detalles[1].push([{
                    border: [false, false, false, false],
                    fillColor: '#eeeeee',
                    fontSize: 8,
                    text: `Hora`,
                }]);

                detalles[2].push([{
                    border: [false, false, false, false],
                    fillColor: '#eeeeee',
                    fontSize: 8,
                    text: `Estatus`,
                }]);
            }
        }
        setHistorialMovimientos(detalles);
    }

    const createPdf = () => {const pdfGenerator = pdfMake.createPdf(docDefinition);
        pdfGenerator.getBlob((blob) => {
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank')
        })
    }
    const docDefinition = {
        content: [
            {
                table: {
                    widths: ['*'],
                    body: [
                        [
                            {
                                text: 'Aeroméxico Cargo',
                                style: {
                                    fontSize: 20,
                                    color: '#FFFFFF',
                                    fillColor: '#020C41',
                                    alignment: 'start',
                                    bold: true
                                }
                            }
                        ]
                    ]
                }
            },
            {
                text: `${tTrack("file.t1")}`,
                style: 'headerTitle'
            },
            {
                text: [`${tTrack("file.t2")}: `, {text: `${currentDate.getDate()}-${currentDate.getMonth()+ 1}-${currentDate.getFullYear()}`, style: {bold: true}}],
                style: 'currentDate'
            },
            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 5,
                        x2: 500,
                        y2: 5,
                        lineWidth: 1,
                        lineColor: '#eeeeee'
                    }
                ]
            },
            {
                text: `${tTrack("file.t3")}.`,
                style: 'guideInformation'
            },
            {
                table: {
                    widths: ['*'],
                    body: [
                        [{
                            border: [false, false, false, false],
                            fillColor: '#eeeeee',
                            fontSize: 8,
                            text: `${tTrack("file.t4")}:\n${waybillNumber}`,
                        }],
                        [{
                            border: [false, false, false, false],
                            fillColor: '#ffffff',
                            fontSize: 8,
                            text: `${tTrack("file.t5")}:\n ${originCode}`
                        }],
                        [{
                            border: [false, false, false, false],
                            fillColor: '#eeeeee',
                            fontSize: 8,
                            text: `${tTrack("file.t6")}:\n${destinationCode}`,
                        }],
                        [{
                            border: [false, false, false, false],
                            fillColor: '#ffffff',
                            fontSize: 8,
                            text: `${tTrack("file.t7")}:\n ${numberPieces}`
                        }],
                        [{
                            border: [false, false, false, false],
                            fillColor: '#eeeeee',
                            fontSize: 8,
                            text: `${tTrack("file.t8")}:\n${kgNumber}`,
                        }],
                        [{
                            border: [false, false, false, false],
                            fillColor: '#ffffff',
                            fontSize: 8,
                            text: `${tTrack("file.t9")}:\n ${statusCode}`
                        }],
                    ]
                }
            },
            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 5,
                        x2: 500,
                        y2: 5,
                        lineWidth: 1,
                        lineColor: '#eeeeee'
                    }
                ]
            },
            {
                text: `${tTrack("file.t10")}`,
                style: 'guideInformation'
            },
            {
                table: {
                    widths: [ '10%', '10%', '80%'],
                    body:[historialMovimientos],
                    
                }
            },

            {
                text: `${tTrack("file.t16")}`,
                style: 'guideInformation'
            },
            {
                text: [{text:`© Copyright ${currentDate.getFullYear()} ${tTrack("file.t17")}`, style: {bold: true, color:'gray', fontSize:8}}],
            }
        ],
        styles: {
            headerTitle: {
                fontSize: 22,
                bold: true,
                alignment: 'left',
                margin: [0, 20, 0, 10],
                color: '#017cc1'
            },
            currentDate: {
                fontSize: 10,
                margin: [0, 0, 0, 5],
            },
            guideInformation: {
                fontSize: 15, color: '#020C41', bold: true, margin: [0, 10, 0, 10]
            }
        }
    }

    return (
        <div className="" style={{marginBottom: '2rem'}}>
            <div className='universalDetails'>
                <div className='row status-container-track'>
                    <div className='btnsStatus d-flex col-12'>
                    <button onClick={handleResetWaybill} className='btnWaybill' id='track-another-guide'>{tTrack("details.btn-track")}</button>
                    <button onClick={createPdf} className='btnPrint print_bueno' id={`track-print`}>{tTrack("details.btn-print")}</button>
                        <button onClick={loadData} className='btnPrint d-none' ref={miBotonRef}>{tTrack("details.btn-print")}</button>
                    </div>
                </div>

                <div className="containerDetails">
                    <div className='waibillNumberBox trackBox'>
                        <p className='boldText' style={{fontSize: '.8rem'}}>{tTrack("details.guide-number-txt")}</p>
                        <p className='textBlueHard' style={{fontSize: '1.7rem',}}>{ waybillNumber }</p>
                    </div>
                    <div className='detailsBox'>
                        <div className='arrDepContainer'>
                            <div className='arrDeplBox'>
                                <p className='boldText' style={{fontSize: '.8rem'}}>{tTrack("details.origin-txt")}</p>
                                <p className='trackTextDetails' style={{fontSize: '1rem', color: '#828282'}}>{ originCode }</p>
                            </div>
                            <div className='iconDepArr'><FaArrowRightLong /></div>
                            <div className='arrDeplBox'>
                                <p className='boldText' style={{fontSize: '.8rem'}}>{tTrack("details.destination-txt")}</p>
                                <p className='trackTextDetails' style={{fontSize: '1rem', color: '#828282'}}>{ destinationCode }</p>
                            </div>
                        </div>
                        <div className='detailsPieces'>
                            <div className='arrDeplBox'>
                                <p className='boldText' style={{fontSize: '.8rem'}}>{tTrack("details.guide-details-txt")}</p>
                                <p className='trackTextDetails' style={{fontSize: '1rem', color: '#828282'}}>{ setNumberPiecesText() } / { kgNumber } kg</p>
                            </div>
                        </div>
                    </div>
                    <div className='statusBox'>
                        <div className='statusText'>
                            <p style={{fontWeight: 'bold'}}>{tTrack("details.status-txt")}</p>
                        </div>
                        <div className='statusType'>
                            <div className='type'>
                                <p>{statusCode}</p>
                            </div>
                        </div>
                    </div>
                    <div className='btnInfoDetails'>
                        <TrackBtnContact x={70} y={-370} />
                    </div>
                </div>

                <div className='infoTextDetails'>
                    <p>
                        <span style={{fontWeight: "bold"}}>{tTrack("details.important")}: </span> 
                        {tTrack("details.important-txt")}
                    </p>
                </div>
            </div>

            <div className='universalDetailsMb'>
                <div className="row containerDetails">
                    <div className="col-5 col-md-4 col-lg-3 trackBox">
                        <p className='boldText'>{tTrack("details.guide-number-txt")}</p>
                        <p className='textBlueHard'>{ waybillNumber }</p>
                    </div>
                    <div className="d-none d-md-flex col-md-2 col-lg-2 trackBox">
                        <p className='boldText'>{tTrack("details.origin-txt")}</p>
                        <p className='trackTextDetails'>{ originCode }</p>
                    </div>
                    <div className="d-none d-md-flex col-md-2 col-lg-2 trackBox">
                        <p className='boldText'>{tTrack("details.destination-txt")}</p>
                        <p className='trackTextDetails'>{ destinationCode }</p>
                    </div>
                    <div className="d-none d-md-flex col-md-4 col-lg-2 trackBox">
                        <p className='boldText'>{tTrack("details.guide-details-txt")}</p>
                        <p className='trackTextDetails'>{ setNumberPiecesText() } / { kgNumber } kg</p>
                    </div>
                    <div className="col-5 col-md-12 col-lg-3 trackBox">
                        <p className='boldText'>{tTrack("details.status-txt")}</p>
                        <p className='textGreenHard'>{statusCode}</p>
                    </div>
                    <div className="d-flex d-md-none col-2 trackBox">
                        <button className='trackAccordion' onClick={toggleAcordeon}>
                            {isOpen ? <AiFillCaretUp /> : <AiFillCaretDown />}
                        </button>
                    </div>
                </div>
                <div className={`trackContainerBoxMbl ${isOpen ? "open" : "closed"}`}>
                    <div className="row d-flex d-md-none">
                        <div className="col-3 trackBoxMbl">
                            <p className='boldText'>{tTrack("details.origin-txt")}</p>
                            <p className='trackTextDetails'>{ originCode }</p>
                        </div>
                        <div className="col-3 trackBoxMbl">
                            <p className='boldText'>{tTrack("details.destination-txt")}</p>
                            <p className='trackTextDetails'>{ destinationCode }</p>
                        </div>
                        <div className="col-6 trackBoxMbl">
                            <p className='boldText'>{tTrack("details.guide-details-txt")}</p>
                            <p className='trackTextDetails'>{ setNumberPiecesText() } / { kgNumber } kg</p>
                        </div>
                    </div>
                </div>
            </div>
                
        </div>
    )
}

export default TrackDetails;