import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import "./track-tooltip.css";
import axios from "axios";
import ViewQuillComponent from "../../dashboard/admin/stations/viewQuillComponent";

function TrackTooltip({ station, children }) {
  const [destinationInfo, setDestinationInfo] = useState("");

  const [tTrack] = useTranslation("trackPage");
  const [tStationsNationals] = useTranslation("stationsNationals");
  const nationals = tStationsNationals("nationals", { returnObjects: true });
  const [tStationsInternationals] = useTranslation("stationsInternationals");
  const internationals = tStationsInternationals("stations", {
    returnObjects: true,
  });
  let currentLng = "es";

  const handleNationalsRoutes = async () => {
    let stationsArr = [];
    let addedStationsInitials = {};
    let resultStationArr = [];
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      response.data.stations.forEach((stations) => {
        stationsArr.push(stations.code_text);
      });

      for (let i = 0; i < stationsArr.length; i++) {
        const currentStation = stationsArr[i];
        const initials = currentStation;

        if (!addedStationsInitials[initials]) {
          resultStationArr.push(initials);
          addedStationsInitials[initials] = true;
        }
      }
      return resultStationArr
      // setNationalsStationsArr(resultStationArr)
    } catch (error) {
      console.error(error);
    }
  };

  const getSchedule = async (station, result) => {
    let finalStation = '';

    if(station === "MEX") {
      finalStation = "MEX1";
    } else {
      finalStation = station;
    }

    if(result.includes(finalStation)) {

      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations/?code_text=${finalStation}&lng=${currentLng}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if(response.data.stations.length !== 0){
        setDestinationInfo(response.data.stations[0])
      }

    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/international-stations/?code_text=${station}&lng=${currentLng}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );
      setDestinationInfo(response.data.stations[0])
    }
  };

  useEffect(() => {
    if(localStorage.getItem('lng')) {
      currentLng = localStorage.getItem("lng")
    }
    const fetchData = async () => {
      const result = await handleNationalsRoutes();
      getSchedule(station, result);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="text bg-tooltip-icon">
      {!destinationInfo ||
      destinationInfo.name.includes("*Verify itineraries") ? (
        tTrack("toltip")
      ) : (
        <>
          {destinationInfo.schedule !== undefined &&
            destinationInfo.schedule !== "" && (
              <div className="schedule">
                <ViewQuillComponent content={JSON.parse(destinationInfo.schedule)} type={"json"} />
              </div>
            )}
        </>
      )}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 50, hide: 50 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
}

export default TrackTooltip;
