import { Outlet } from "react-router-dom";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import Footer from "../../../../components/footer/Footer";
import Navbar from "../../../../components/navbar/navbar";
import ScrollToTop from "../../../../components/scrollToTop/scrollToTop";

function InternationalMain() {
  const [tSMetaData] = useTranslation("metaData");
  return (
    <div>
      <Helmet>
        <title>{tSMetaData("internacionales.title")}</title>
        <meta
          name="description"
          content={tSMetaData("internacionales.description")}
        />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default InternationalMain;
