/*
===================================
    Tarifas fijas y
    Campos adicionales
===================================
*/
const value_aditional_HIT = 2;
const tarifa_Gold = 59.4;
const tarifa_Gold_MIN = 1146.75;

const tarifa_perecederos = 1.18;
const tarifa_mensajeria = 161.4;

const tarifa_restos_humanos = 43.55;
const tarifa_restos_humanos_MIN = 6063.2;
const tarifa_restos_cenizas = 661.44;

const tarifa_animales_perros_especial = 45.04;
const tarifa_animales_perros_especial_MIN = 869.07;

const tarifa_animales_perros_general = 75.04;
const tarifa_animales_perros_general_MIN = 1448.47;

const tarifa_animales_aves = 37.12;
const tarifa_animales_aves_MIN = 599.4;

const tarifa_animales_otros = 57.87;
const tarifa_animales_otros_MIN = 936.76;

/*
===================================
    Zonas
    arrays
===================================
*/
const Zona_01 = ["CUN", "MID", "CPE"];
const Zona_02 = ["CME", "TAP", "TGZ", "VSA"];
const Zona_03 = ["MTT", "OAX", "VER"];
const Zona_04 = ["MEX", "TAM", "PBC", "ZIH"];
const Zona_05 = ["QRO", "BJX", "SLP"];
const Zona_06 = ["AGU", "GDL", "PVR"];
const Zona_07 = ["MTY", "REX", "SLW"];
const Zona_08 = ["CUL", "DGO", "LMM", "MZT", "TRC"];
const Zona_09 = ["CEN", "CJS", "CUU", "HMO"];
const Zona_10 = ["LAP", "SJD"];
const Zona_11 = ["MXL", "TIJ"];

const matriz = [
  ["A", "B", "B", "B", "C", "D", "C", "E", "F", "G", "F"],
  ["A", "B", "B", "B", "B", "D", "C", "F", "G", "G", "G"],
  ["C", "A", "A", "A", "A", "B", "C", "E", "F", "G", "F"],
  ["D", "C", "A", "A", "A", "B", "C", "D", "F", "G", "F"],
  ["D", "C", "B", "A", "A", "A", "C", "D", "E", "F", "F"],
  ["D", "C", "B", "A", "A", "B", "C", "C", "D", "E", "D"],
  ["E", "E", "C", "B", "C", "B", "A", "D", "E", "G", "F"],
  ["F", "E", "E", "C", "C", "C", "C", "A", "C", "B", "C"],
  ["G", "F", "F", "E", "E", "D", "D", "C", "B", "C", "B"],
  ["G", "G", "G", "E", "C", "C", "D", "B", "C", "A", "C"],
  ["F", "F", "E", "E", "E", "D", "F", "F", "C", "D", "A"],
];

export {
  value_aditional_HIT,
  tarifa_Gold,
  tarifa_Gold_MIN,
  tarifa_perecederos,
  tarifa_mensajeria,
  tarifa_restos_humanos,
  tarifa_restos_humanos_MIN,
  tarifa_restos_cenizas,
  tarifa_animales_perros_especial,
  tarifa_animales_perros_especial_MIN,
  tarifa_animales_perros_general,
  tarifa_animales_perros_general_MIN,
  tarifa_animales_aves,
  tarifa_animales_aves_MIN,
  tarifa_animales_otros,
  tarifa_animales_otros_MIN,
  Zona_01,
  Zona_02,
  Zona_03,
  Zona_04,
  Zona_05,
  Zona_06,
  Zona_07,
  Zona_08,
  Zona_09,
  Zona_10,
  Zona_11,
  matriz,
};
