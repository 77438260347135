import { useTranslation } from "react-i18next";
import ImageHeader from "../../../../components/image-header/image-header";
import Accordion from "../../../../components/acordion/accordion";
import specializedSafeImg from "../../../../assets/imgs/services/international/specialized-safe.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import navBarEn from "../../../../translations/en/navbar.json";
import navBarEs from "../../../../translations/es/navbar.json";
import Helmet from "react-helmet";

function SpecializedSafe() {
  const [tSpecializedSafe] = useTranslation("specializedSafePage");
  const [tSMetaData] = useTranslation("metaData");
  const idSpecializedSafe = "dfe55c44-7e9a-4d3f-8846-0e16d04fe7bb";
  const [textDescription, setTextDescription] = useState("");
  const [accordion1, setAccordion1] = useState("");
  const [accordion2, setAccordion2] = useState("");
  const [accordion3, setAccordion3] = useState("");

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].services}/${
      Object.keys(navBarEs["products-submenu"])[1]
    }/${Object.keys(navBarEs["especializado-submenu"])[6]}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].services}/${
      Object.keys(navBarEn["products-submenu"])[1]
    }/${Object.keys(navBarEn["specialized-submenu"])[6]}`
  );

  useEffect(() => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    axios
      .get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idSpecializedSafe}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      )
      .then((response) => {
        if (currentLng === "es") {
          setTextDescription(
            response.data.textWeb.text_es["01-descripción"].content
          );
          setAccordion1(
            response.data.textWeb.text_es["02-Restricciones y Regulaciones"]
              .content
          );
          setAccordion2(
            response.data.textWeb.text_es[
              "03-Documentación, Aceptación y Recolección"
            ].content
          );
          setAccordion3(
            response.data.textWeb.text_es["04-Manejo Especial"].content
          );
        } else {
          setTextDescription(
            response.data.textWeb.text_us["01-description"].content
          );
          setAccordion1(
            response.data.textWeb.text_us["02-Restrictions and Regulations"]
              .content
          );
          setAccordion2(
            response.data.textWeb.text_us["03-Check-in, Acceptance and Pickup"]
              .content
          );
          setAccordion3(
            response.data.textWeb.text_us["04-Special Handling"].content
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{tSMetaData("specializedSafe.title")}</title>
        <meta
          name="description"
          content={tSMetaData("specializedSafe.description")}
        />
      </Helmet>
      <ImageHeader
        section={tSpecializedSafe("header.section")}
        title={tSpecializedSafe("header.title")}
        description={
          textDescription
            ? JSON.parse(textDescription)
            : JSON.parse(tSpecializedSafe("description.content"))
        }
        contactSales={tSpecializedSafe("header.contact-sales")}
        imgBackground={specializedSafeImg}
      />
      <Accordion
        title={tSpecializedSafe("accordion-1.title")}
        content={
          accordion1
            ? JSON.parse(accordion1)
            : JSON.parse(tSpecializedSafe("accordion-1.content"))
        }
      />
      <div className="divider"></div>
      <Accordion
        title={tSpecializedSafe("accordion-2.title")}
        content={
          accordion2
            ? JSON.parse(accordion2)
            : JSON.parse(tSpecializedSafe("accordion-2.content"))
        }
      />
      <div className="divider"></div>
      <Accordion
        title={tSpecializedSafe("accordion-3.title")}
        content={
          accordion3
            ? JSON.parse(accordion3)
            : JSON.parse(tSpecializedSafe("accordion-3.content"))
        }
      />
    </>
  );
}

export default SpecializedSafe;
