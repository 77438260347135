import "./add-serv-item.css";

function AddServItem({ title, icon, description }) {
    const descriptionLines = description.split("\n");
    return (
        <div className="additional-service-content">
            <div className="icon-content">
                { icon }
            </div>
            <div className="data-content">
                <h5>{title}</h5>
                {descriptionLines.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
        </div>
    )
};

export default AddServItem;