import { useQuill } from "react-quilljs";
import 'quill/dist/quill.snow.css';
import toolbar from './toolbar';
import { useEffect } from "react";

function QuillComponent({text, onQuillData}) {
    const {quill, quillRef} = useQuill({
        modules: {
            toolbar: toolbar,
        }
    });

    useEffect(() => {
        if (quill && text) {
            quill.setContents(JSON.parse(text));
        }
    }, [quill, text]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            content: JSON.stringify(quill.getContents())
        }

        if (onQuillData) {
            onQuillData(data);
        }
    }

    return(
        <form onSubmit={handleSubmit}>
            <div className="editor">
                <div ref={quillRef}></div>
            </div>
            <button>Guardar</button>
        </form>
    )
};

export default QuillComponent;