import { React, useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEdit, FaTimes, FaExchangeAlt } from "react-icons/fa";
import "./styles.css";
import DOMPurify from "dompurify";

function Banners() {
  const [banners, setBanners] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const getBanners = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/banners`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY, // Agrega la cadena de texto para la authorization en endponids publicos"
          },
        }
      );
      if (res.data.code === 1) {
        setBanners(res.data.banners);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createBanner = async () => {
    try {
      const result = await Swal.fire({
        title: "Agregar Banner",
        html:
          '<div class="form-grid">' +
          '<font size="2">Banner en español</font><br />' +
          '<input type="file" id="image" class="" placeholder="Imagen"><br />' +
          '<font size="2">Banner en inglés</font><br />' +
          '<input type="file" id="image_en" class="" placeholder="Imagen"><br />' +
          '<input type="text" id="link" class="form-control mt-4" placeholder="Enlace">' +
          '<input type="text" id="link_en" class="form-control mt-4" placeholder="Enlace en inglés"><br />' +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Agregar",
        cancelButtonText: "Cancelar",
        preConfirm: function () {
          const imageInput = document.getElementById("image");
          const imageInput_en = document.getElementById("image_en");

          if (!imageInput.files[0] || !imageInput_en.files[0]) {
            Swal.showValidationMessage("Debes seleccionar ambas imagenes");
            return false;
          }

          return new Promise(function (resolve) {
            resolve({
              link: document.getElementById("link").value,
              link_en: document.getElementById("link_en").value,
              myfile: imageInput.files[0],
              myfile_en: imageInput_en.files[0],
            });
          });
        },
      });

      if (result.isConfirmed) {
        const userData = result.value;

        const response = await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}/api/banners/create`,
          userData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        Swal.fire(
          "¡Creado!",
          "El banner ha sido creado exitosamente.",
          "success"
        );
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Accion cancelada.", "error");
      }
    } catch (error) {
      if (error.response.data.code === 0) {
        Swal.fire("Error al crear el banner", "Revisar información.", "error");
      } else {
        Swal.fire("Error al crear el banner", "No se ha creado ningún banner.");
      }
      console.error("Error al crear el banner:", error.response);
      console.error("Status:", error.response.status);
    }
  };

  const handleSearch = () => {
    const input = document.getElementById("searchInput");
    setSearchValue(input.value.trim());
  };

  const deleteBanner = async (id) => {
    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_URL_BACKEND}/api/banners/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
            },
          }
        );

        Swal.fire("¡Eliminado!", "El banner ha sido eliminado.", "success");
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "error");
      }
    } catch (error) {
      console.error("Error al Eliminar el usuario:", error);
    }
  };

  const updateBanner = async (id) => {
    try {
      // Obtener los datos del usuario a editar del servidor
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/banners/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      const bannerData = response.data.banner;

      const result = await Swal.fire({
        title: "Editar Banner",
        html:
          '<div class="form-grid ">' +
          '<font size="2">Banner en español actual</font><br />' +
          `<img src="${bannerData.image}" width="150" height="100"/>` +
          `<input type="hidden" id="edit_id" class="swal2-input" value="${bannerData.id}" disabled>` +
          '<input type="file" id="edit_image" class="" placeholder="Imagen">' +
          '<font size="2">Dejar vacío si no desea actualizar</font>' +
          '<font size="2">Banner en inglés actual</font>' +
          `<img src="${bannerData.image_en}" width="150" height="100"/>` +
          '<input type="file" id="edit_image_en" class="" placeholder="Imagen"><br />' +
          '<font size="2">Dejar vacío si no desea actualizar</font><br />' +
          '<font size="2">Link en español</font>' +
          `<input type="text" id="edit_link" class="swal2-input" placeholder="Enlace" value="${bannerData.link}">` +
          '<font size="2">Link en inglés</font>' +
          `<input type="text" id="edit_link_en" class="swal2-input" placeholder="Enlace en ingles" value="${bannerData.link_en}">` +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        cancelButtonText: "Cancelar",
        preConfirm: function () {
          const imageInput = document.getElementById("edit_image");
          const imageInput_en = document.getElementById("edit_image_en");
          return new Promise(function (resolve) {
            resolve({
              id: document.getElementById("edit_id").value,
              image: document
                .getElementById("edit_image")
                .value.replace(/\s/g, ""),
              link: document.getElementById("edit_link").value,
              link_en: document.getElementById("edit_link_en").value,
              myfile: imageInput.files[0],
              myfile_en: imageInput_en.files[0],
              position: bannerData.posicion,
            });
          });
        },
      });

      if (result.isConfirmed) {
        const updatedUserData = result.value;
        // Realizar la solicitud PUT al servidor para actualizar el usuario
        const updateResponse = await axios.put(
          `${process.env.REACT_APP_URL_BACKEND}/api/banners/update`,
          updatedUserData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
              "Content-Type": "multipart/form-data",
            },
          }
        );
        Swal.fire(
          "¡Actualizado!",
          "El banner ha sido actualizado exitosamente.",
          "success"
        );
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelado",
          "No se ha realizado ninguna actualización.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error al actualizar usuario:", error);
    }
  };

  const updatePositionBanner = async (id) => {
    try {
      // Obtener los datos del usuario a editar del servidor
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/banners/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      const bannerData = response.data.banner;

      const result = await Swal.fire({
        title: "Editar Posicion",
        html:
          '<div class="form-grid ">' +
          `<input type="text" id="position" class="swal2-input" placeholder="posicion" value="${bannerData.posicion}">` +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        cancelButtonText: "Cancelar",
        preConfirm: function () {
          return new Promise(function (resolve) {
            resolve({
              new_position: parseInt(document.getElementById("position").value),
            });
          });
        },
      });

      if (result.isConfirmed) {
        const updatedUserData = result.value;
        const updateResponse = await axios.put(
          `${process.env.REACT_APP_URL_BACKEND}/api/banners/${id}`,
          updatedUserData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
            },
          }
        );

        if (updateResponse.data.code === 1) {
          Swal.fire(
            "¡Actualizado!",
            "posicion actualizada exitosamente.",
            "success"
          );
          setUpdateTrigger(!updateTrigger);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelado",
          "No se ha realizado ninguna actualización.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error al actualizar posicion:", error);
    }
  };

  const filteredUsers = searchValue
    ? banners.filter((banner) =>
        banner.image.toUpperCase().includes(searchValue.toUpperCase())
      )
    : banners;

  useEffect(() => {
    getBanners();
  }, [updateTrigger]);

  return (
    <>
      <div className="table-responsive">
        <div className="panel-table">
          <div className="colum-left">
            <input
              type="text"
              className="searchInput"
              id="searchInput"
              placeholder="Buscar..."
            />
            <button className="btnn" onClick={handleSearch}>
              Buscar
            </button>
          </div>
          <div className="colum-rigth">
            <button className="btnn" onClick={createBanner}>
              Agregar Banner
            </button>
          </div>
        </div>
        <table className="table table-preve" id="tableToSearch">
          <thead>
            <tr>
              <th>Imagen</th>
              <th>Enlace</th>
              <th>posicion</th>
              <td className="accions">Acciones</td>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td>Cargando</td>
              </tr>
            ) : (
              filteredUsers.map((banner) => (
                <tr key={banner.id} data-master-id={banner.id}>
                  <td>
                    <a href={DOMPurify.sanitize(banner.image)} target="_blank">
                      Ver Imagen
                    </a>
                  </td>
                  <td>{banner.link}</td>
                  <td>{banner.posicion}</td>
                  <td>
                    <FaEdit
                      className="editButton"
                      onClick={() => {
                        updateBanner(banner.id);
                      }}
                    />
                    <FaExchangeAlt
                      className="editButton"
                      onClick={() => {
                        updatePositionBanner(banner.id);
                      }}
                    />
                    <FaTimes
                      className="deleteButton"
                      onClick={() => {
                        deleteBanner(banner.id);
                      }}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Banners;
