import React, { useEffect, useRef, useState } from "react";
import "./track-status.css";
import TrackTooltip from "../track-tooltip/track-tooltip";
import rcsIcon from "../../../assets/imgs/track/rcs.svg";
import bkdIcon from "../../../assets/imgs/track/bkd.svg";
import rcfIcon from "../../../assets/imgs/track/rcf.svg";
import depIcon from "../../../assets/imgs/track/dep.svg";
import depTIcon from "../../../assets/imgs/track/depT.svg";
import dlvIcon from "../../../assets/imgs/track/dlv.svg";
import rctIcon from "../../../assets/imgs/track/rct.svg";
import tfdIcon from "../../../assets/imgs/track/tfd.svg";
import tansit from "../../../assets/imgs/track/Iconos AMC_Transito.svg";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";

import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PendingMovement from "../track-tooltip/pending-movement";

function TrackStatus({ data, nationalsCode }) {
  const [numberPieces, setNumberPieces] = useState("");
  const [kgNumber, setKgNumber] = useState("");
  const existingCodes = ["BKD", "RCS", "RCF", "DEP", "DLV", "RCT", "TFD"];
  const [statusLength, setStatusLength] = useState(0);
  const [openIndexes, setOpenIndexes] = useState([]);
  const [tTrack] = useTranslation("trackPage");
  const [lineCodeArr, setLineCodeArr] = useState([]);
  const [destinationCodeFinal, setDestinationCode] = useState("");
  const [lastStatus, setLastStatus] = useState("");
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  const [codeStatusArrInv, setCodeStatusArrInv] = useState([]);
  const [codeStatusArr, setCodeStatusArr] = useState([]);
  const [codeStatusObj, setCodeStatusObj] = useState([]);
  const [partial, setPartial] = useState([]);
  const [dlvTotalPieces, setDlvTotalPieces] = useState(0);

  useEffect(() => {
    if (data && data.length > 0) {
      setKgPieces(data);
      if(nationalsCode.length !== 0) {
        getStatusArray(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, numberPieces, kgNumber, nationalsCode]);

  function setKgPieces(data) {
    const regexNumberPices = /T(\d+)K/;
    const regexKgNumber = /K(.*?)(?=\r)/;

    if (data[1]) {
      const numberPiecesMatch = data[1].match(regexNumberPices);
      const kgNumberMatch = data[1].match(regexKgNumber);

      const numberPieces = numberPiecesMatch ? numberPiecesMatch[1] : "";
      const kgNumber = kgNumberMatch ? kgNumberMatch[1] : "";

      setNumberPieces(numberPieces);
      setKgNumber(kgNumber);
    }
  }

  function getStatusArray(data) {
    const getPieces = /[TP](\d)\w*\.?\w*(?=[K])/;
    const lines = data.slice(2, -1);
    const pairLines = lines.filter((element, index) => index % 2 === 0);
    const statusArr = [];
    const resultBKD = [];
    const regexOriginCode = /(\d{3}-\d{8})([A-Z]{3})/;
    const regexDestinationCode = /(\d{3}-\d{8}[A-Z]{3})([A-Z]{3})/;
    const destinationCodeMatch = data[1].match(regexDestinationCode);
    const originCodeMatch = data[1].match(regexOriginCode);
    const destinationCode = destinationCodeMatch ? destinationCodeMatch[2] : "";
    const originCode = originCodeMatch ? originCodeMatch[2] : "";
    let executeResult = false;
    const regexNumberPices = /T(\d+)K/;
    const numberPiecesMatch = data[1].match(regexNumberPices);
    const totalPieces = numberPiecesMatch ? numberPiecesMatch[1] : "";

    setDestinationCode(destinationCode);

    pairLines.forEach((line, index) => {
      const lettersBeforeSlash = line.split("/")[0];

      if (existingCodes.includes(lettersBeforeSlash)) {
        if (lettersBeforeSlash === "RCT" && index > 0) {
          const previousLettersBeforeSlash = pairLines[index - 1].split("/")[0];

          if (previousLettersBeforeSlash === "BKD") {
            statusArr.pop();
            statusArr.push(`${line}`);
          } else {
            statusArr.push(line);
          }
        } else {
          statusArr.push(line);
        }
      }
    });

    if(statusArr.length >= 3) {
      if(statusArr.at(-1).split("/")[0] === "BKD" && 
        statusArr.at(-2).split("/")[0] !== "RCF" && 
        statusArr.at(-3).split("/")[0] !== "DEP") 
      {
        let lastElement = statusArr[statusArr.length - 1];
        lastElement = lastElement.trim() + "/NOADD";
        statusArr[statusArr.length - 1] = lastElement;
      }
    }

    if (statusArr.length < 2) {
      uniquePartial(statusArr, totalPieces, kgNumber, destinationCode);
      executeResult = true;
      return;
    }

    // Partial 1/1: with BKD[0] different to BKD[1]
    let sameOrigenBKD = 0;
    let sumPieces = 0;
    let numberPartials = 0;

    if (statusArr[0].split("/")[0] !== statusArr[1].split("/")[0]) {
      statusArr.forEach((line) => {
        if (line.split("/")[0] === "BKD") {
          resultBKD.push(line);
        }
      });

      const countPartials = {}
      resultBKD.forEach(item => {
        const parts = item.split('/');
        if(parts.length > 3) {
          const text = parts[3];
          if(!countPartials[text]) {
            countPartials[text] = 1;
          } else {
            countPartials[text]++
          }

          if(countPartials[text] > numberPartials) {
            numberPartials = countPartials[text]
          }
        }
      })

      if (numberPartials <= 1) {
        uniquePartial(statusArr, totalPieces, kgNumber, destinationCode);
        executeResult = true;
        return;
      } else {
        addPartial(statusArr, totalPieces, numberPartials, destinationCode);
        executeResult = true;
        return;
      }
    }

    //Partial 1/1: with 2 or more BKDs but with the same pieces and destination and origin
    for (let line of statusArr) {
      if (line.split("/")[0] === "BKD") {
        resultBKD.push(line);
      } else {
        break;
      }
    }

    for (let line of resultBKD) {
      const lineParts = line.split("/");
      const pieces = lineParts[4].match(getPieces);

      if (pieces[0].slice(1) === totalPieces) {
        sameOrigenBKD += 1;
      } else {
        sumPieces += Number(pieces[0].slice(1));
        numberPartials += 1;
      }
    }

    if (sameOrigenBKD === resultBKD.length) {
      uniquePartial(statusArr, totalPieces, kgNumber, destinationCode);
      executeResult = true;
      return;
    }

    //Partial n/n: When the partialities added give the correct pieces
    if (sumPieces === Number(totalPieces) && sameOrigenBKD === 0) {
      partialDone(statusArr, totalPieces, numberPartials, destinationCode);
      executeResult = true;
      return;
    }

    //Partial n/n: when the first partiality is equal to the number of pieces but has more partialities
    if (
      statusArr[0].split("/")[4].match(getPieces)[0].slice(1) === totalPieces && resultBKD.length !== numberPartials
    ) {
      firstPartialDone(statusArr, totalPieces, resultBKD.length, destinationCode);
      executeResult = true;
      return;
    }

    if (executeResult === false) {
      firstPartialDone(
        statusArr,
        totalPieces,
        resultBKD.length,
        destinationCode
      );
    }
  }

  function uniquePartial(statusArr, pieces, kg, destinationCode) {
    let codeArr = [];
    let resObjCode = {};
    let codeCounts = {};
    let lineDataArr = [];
    let positionLastStatus = 1;

    let datosFiltrados = statusArr.filter((item) => !item.endsWith("/NOADD"));
    if (datosFiltrados.length === 0) {
      return;
    }
    const lastStatusShared = datosFiltrados[datosFiltrados.length - 1].split("/")[0];

    if (lastStatusShared !== "DLV") {
      const pendingStatus = addPendingStatus(
        datosFiltrados[datosFiltrados.length - 1],
        destinationCode
      );

      datosFiltrados = datosFiltrados.concat(pendingStatus.status);
      positionLastStatus = pendingStatus.positionSearch;
    }

    datosFiltrados.forEach((line) => {
      const lettersBeforeSlash = line.split("/")[0];
      let code = lettersBeforeSlash + 1;
      let count = 2;

      if (existingCodes.includes(lettersBeforeSlash)) {
        while (codeArr.includes(code)) {
          code = lettersBeforeSlash + count;
          count++;
        }
        codeArr.push(code);
        lineDataArr.push(line);
      }
    });

    datosFiltrados.forEach((line) => {
      const codePrefix = line.split("/")[0];
      const key = `${codePrefix}${codeCounts[codePrefix] || 1}`;

      if (!resObjCode[key]) {
        resObjCode[key] = [line];
      } else {
        resObjCode[key].push(line);
      }

      codeCounts[codePrefix] = (codeCounts[codePrefix] || 1) + 1;
    });

    const foundDLVNumberPieces = datosFiltrados.find(item => item.startsWith("DLV"));
    if(foundDLVNumberPieces) {
      if(foundDLVNumberPieces.match(/[TP](\d+)K/)) {
        setDlvTotalPieces(foundDLVNumberPieces.match(/[TP](\d+)K/)[1]);
      } else {
        setDlvTotalPieces(0);
      }
    } else {
      setDlvTotalPieces(0);
    }

    setLastStatus(
      searchLastStatus(positionLastStatus, lastStatusShared, codeArr)
    );
    setLineCodeArr(lineDataArr);
    setStatusLength(codeArr.length);
    setPartial([{ text: ``, pieces: `${pieces} pz` }]);
    setCodeStatusArr(codeArr);
    setCodeStatusObj(resObjCode);
    setCodeStatusArrInv(codeArr.slice().reverse());
  }

  function partialDone(
    statusArr,
    totalPieces,
    numberPartials,
    destinationCode
  ) {
    const getPieces = /[TP](\d)\w*\.?\w*(?=[K])/;

    let arrObjDetails = [];
    let reduceCodeArr = [];
    let maxLength = 0;
    let lineDataArr = [];
    let positionLastStatus = 1;

    let printPartial = [];
    let codeArr = [];
    let resObjCode = {};

    let datosFiltrados = statusArr.filter((item) => !item.endsWith("/NOADD"));
    if (datosFiltrados.length === 0) {
      return;
    }
    const lastStatusShared =
      datosFiltrados[datosFiltrados.length - 1].split("/")[0];

    if (lastStatusShared !== "DLV") {
      const pendingStatus = addPendingStatus(
        datosFiltrados[datosFiltrados.length - 1],
        destinationCode
      );

      datosFiltrados = datosFiltrados.concat(pendingStatus.status);
      positionLastStatus = pendingStatus.positionSearch;
    }

    datosFiltrados.forEach((line) => {
      const lettersBeforeSlash = line.split("/");
      const code = lettersBeforeSlash[0];
      let piecesCode = "";

      if (lettersBeforeSlash[4]) {
        piecesCode = lettersBeforeSlash[4].match(getPieces)[0].slice(1);
      } else if (lettersBeforeSlash[3]) {
        if (lettersBeforeSlash[3].match(getPieces)) {
          piecesCode = lettersBeforeSlash[3].match(getPieces)[0].slice(1);
        }
      } else {
        piecesCode = "";
      }
      arrObjDetails.push({
        code: `${code}`,
        pieces: `${piecesCode}`,
        status: line,
      });
    });

    arrObjDetails.forEach(function (item) {
      let code = item.code;
      let pieces = parseInt(item.pieces);
      let count = 1;

      let existingObject = reduceCodeArr.find(
        (obj) => obj.code === code + count
      );

      if (existingObject) {
        if (existingObject.pieces + pieces <= totalPieces) {
          existingObject.pieces += pieces;
          existingObject.status.push(item.status.trim());
        } else {
          count++;
          reduceCodeArr.push({
            code: code + count,
            pieces: pieces,
            status: [item.status.trim()],
          });
        }
      } else {
        reduceCodeArr.push({
          code: code + count,
          pieces: pieces,
          status: [item.status.trim()],
        });
      }

      reduceCodeArr.push(code + count);
    });

    reduceCodeArr.forEach(function (item) {
      let statusArr = item.status || [];
      maxLength = Math.max(maxLength, statusArr.length);
    });

    let reduceCodeObj = reduceCodeArr.filter(function (item) {
      return typeof item === "object" && item !== null;
    });

    const foundDLVNumberPieces = reduceCodeObj.find(item => item.code.startsWith("DLV"));
    foundDLVNumberPieces ? setDlvTotalPieces(foundDLVNumberPieces.pieces) : setDlvTotalPieces(0);

    reduceCodeObj.forEach(function (item) {
      let code = item.code;
      let statusArr = item.status;

      if (!resObjCode[code]) {
        resObjCode[code] = statusArr;
      } else {
        resObjCode[code] = resObjCode[code].concat(statusArr);
      }
    });

    const lastRcfObject = reduceCodeObj
      .slice()
      .reverse()
      .find((obj) => obj.code.substring(0, obj.code.length - 1) === "RCF");

    if (lastRcfObject && lastStatusShared === "DLV") {
      const rcfNumbers = lastRcfObject.status.map((status) => {
        let piecesStatus = status.split("/")[4];
        return parseFloat(piecesStatus.match(/[PT](.*?)K/)[1]);
      });

      reduceCodeObj = ordenarStatusDLV(reduceCodeObj, rcfNumbers);
    }

    codeArr = reduceCodeObj.map(function (item) {
      lineDataArr.push(item.status[0]);
      return item.code;
    });

    reduceCodeArr.forEach(function (item) {
      let code = item.code;
      let statusArr = item.status || [];

      let filledStatusArr = Array.from({ length: maxLength }, (_, index) =>
        statusArr[index] !== undefined ? statusArr[index] : statusArr[0]
      );

      resObjCode[code] = filledStatusArr;
    });

    for (let i = 0; i < numberPartials; i++) {
      const lettersBeforeSlash = statusArr[i]
        .split("/")[4]
        .match(getPieces)[0]
        .slice(1);
      printPartial.push({
        text: `${tTrack("status.partial")} ${i + 1} ${tTrack("status.of")} ${numberPartials}`,
        pieces: `${lettersBeforeSlash}`,
      });
    }

    setLastStatus(
      searchLastStatus(positionLastStatus, lastStatusShared, codeArr)
    );
    setLineCodeArr(lineDataArr);
    setStatusLength(codeArr.length);
    setPartial(printPartial);
    setCodeStatusArr(codeArr);
    setCodeStatusObj(resObjCode);
    setCodeStatusArrInv(codeArr.slice().reverse());
  }

  function addPartial(statusArr, totalPieces, numberPartials, destinationCode) {
    const getPieces = /[TP](\d)\w*\.?\w*(?=[K])/;

    let arrObjDetails = [];
    let reduceCodeArr = [];
    let lineDataArr = [];
    let codesTemp = [];
    let positionLastStatus = 1;

    let printPartial = [];
    let codeArr = [];
    let resObjCode = {};

    let datosFiltrados = statusArr.filter((item) => !item.endsWith("/NOADD"));
    if (datosFiltrados.length === 0) {
      return;
    }
    const lastStatusShared = datosFiltrados[datosFiltrados.length - 1].split("/")[0];
    if (lastStatusShared !== "DLV") {
      const pendingStatus = addPendingStatus(
        datosFiltrados[datosFiltrados.length - 1],
        destinationCode
      );

      datosFiltrados = datosFiltrados.concat(pendingStatus.status);
      positionLastStatus = pendingStatus.positionSearch;
    }

    datosFiltrados.forEach((line) => {
      const lettersBeforeSlash = line.split("/");
      const code = lettersBeforeSlash[0];
      let piecesCode = "";
      let places = ""

      if (lettersBeforeSlash[4]) {
        piecesCode = lettersBeforeSlash[4].match(getPieces)[0].slice(1);
      } else if (lettersBeforeSlash[3]) {
        if (lettersBeforeSlash[3].match(getPieces)) {
          piecesCode = lettersBeforeSlash[3].match(getPieces)[0].slice(1);
        }
      } else {
        piecesCode = "";
      }

      if(lettersBeforeSlash[0] === "BKD" ||
      lettersBeforeSlash[0] === "RCT" ||
      lettersBeforeSlash[0] === "DEP" ||
      lettersBeforeSlash[0] === "TFD" ||
      lettersBeforeSlash[0] === "RCF"
      ) {
        places = lettersBeforeSlash[3]
      } else if(lettersBeforeSlash[0] === "DLV" ||
        lettersBeforeSlash[0] === "RCS"
      ) {
        places = lettersBeforeSlash[2];
      } else {
        places = "";
      }

      arrObjDetails.push({
        code: `${code}`,
        pieces: `${piecesCode}`,
        status: line,
        places: places
      });
    });

    arrObjDetails.forEach(function (item) {
      let code = item.code;
      let pieces = parseInt(item.pieces);

      let existingObject = reduceCodeArr.find(
        (obj) => obj.code === code && obj.places === item.places
      );

      if(existingObject) {
        if (existingObject.places === item.places) {
          existingObject.pieces += pieces;
          existingObject.status.push(item.status.trim());
        } else {
          reduceCodeArr.push({
            code: code,
            pieces: pieces,
            places: item.places,
            status: [item.status.trim()],
          });
        }
      } else {
        reduceCodeArr.push({
          code: code,
          pieces: pieces,
          places: item.places,
          status: [item.status.trim()],
        });
      }
    });

    const foundDLVNumberPieces = reduceCodeArr.find(item => item.code.startsWith("DLV"));
    foundDLVNumberPieces ? setDlvTotalPieces(foundDLVNumberPieces.pieces) : setDlvTotalPieces(0);

    reduceCodeArr.forEach((status) => {
      lineDataArr.push(status.status[0]);
      codesTemp.push(status.code);
    });

    let countCode = {};
    codeArr = codesTemp.map((code) => {
      if (!countCode.hasOwnProperty(code)) {
        countCode[code] = 1;
      } else {
        countCode[code]++;
      }

      return code + countCode[code];
    });

    for (let i = 0; i < numberPartials; i++) {
      printPartial.push({ text: `${tTrack("status.partial")} ${i + 1} ${tTrack("status.of")} ${numberPartials}` });
    }

    const count2 = {};

    reduceCodeArr.forEach((item) => {
      if (!count2[item.code]) {
        count2[item.code] = 1;
      } else {
        count2[item.code]++;
      }

      const codeWithIndex = item.code + count2[item.code];

      let newItemStatus = [...item.status];
      if (item.status.length < numberPartials) {
        for(let i = 0; i < (numberPartials - item.status.length); i++){
          newItemStatus.push(item.status[item.status.length - 1]);
        }
      }

      if (!resObjCode[codeWithIndex]) {
        resObjCode[codeWithIndex] = newItemStatus;
      } else {
        resObjCode[codeWithIndex] =
          resObjCode[codeWithIndex].concat(newItemStatus);
      }
    });

    setLastStatus(
      searchLastStatus(positionLastStatus, lastStatusShared, codeArr)
    );
    setLineCodeArr(lineDataArr);
    setStatusLength(codeArr.length);
    setPartial(printPartial);
    setCodeStatusArr(codeArr);
    setCodeStatusObj(resObjCode);
    setCodeStatusArrInv(codeArr.slice().reverse());
  }

  function firstPartialDone(
    statusArr,
    totalPieces,
    numberPartials,
    destinationCode
  ) {
    const getPieces = /[TP](\d)\w*\.?\w*(?=[K])/;
    let arrObjDetails = [];
    let reduceCodeArr = [];
    let maxLength = 0;
    let lineDataArr = [];
    let positionLastStatus = 1;

    let printPartial = [];
    let codeArr = [];
    let resObjCode = {};

    let datosFiltrados = statusArr.filter((item) => !item.endsWith("/NOADD"));
    if (datosFiltrados.length === 0) {
      return;
    }
    const lastStatusShared = datosFiltrados[datosFiltrados.length - 1].split("/")[0];

    if (lastStatusShared !== "DLV") {
      const pendingStatus = addPendingStatus(
        datosFiltrados[datosFiltrados.length - 1],
        destinationCode
      );

      datosFiltrados = datosFiltrados.concat(pendingStatus.status);
      positionLastStatus = pendingStatus.positionSearch;
    }

    datosFiltrados.forEach((line) => {
      const lettersBeforeSlash = line.split("/");
      const code = lettersBeforeSlash[0];
      let piecesCode = "";
      let weightCode = "";

      const depArr = setArrivalDeparture(line);

      if (lettersBeforeSlash[4]) {
        piecesCode = lettersBeforeSlash[4].match(getPieces)[0].slice(1);
        weightCode = lettersBeforeSlash[4];
        weightCode = weightCode.substring(weightCode.indexOf("K") + 1);
      } else if (lettersBeforeSlash[3]) {
        if (lettersBeforeSlash[3].match(getPieces)) {
          piecesCode = lettersBeforeSlash[3].match(getPieces)[0].slice(1);
          weightCode = weightCode.substring(weightCode.indexOf("K") + 1);
          weightCode = lettersBeforeSlash[3];
        }
      } else {
        piecesCode = "";
        weightCode = "";
      }

      arrObjDetails.push({
        code: `${code}`,
        pieces: `${piecesCode}`,
        status: line,
        weightCode: weightCode,
        depArr: depArr
      });
    });

    arrObjDetails.forEach(function (item) {
      let code = item.code;
      let pieces = parseInt(item.pieces);
      let count = 1;
    
      let lastCode = reduceCodeArr.reduce((acc, obj) => {
        if (obj.code.startsWith(code)) {
          let number = parseInt(obj.code.replace(code, ''));
          return number > acc ? number : acc;
        }
        return acc;
      }, 0);
    
      count = lastCode;
    
      let existingObject = reduceCodeArr.find(obj => obj.code === `${code}${count}`);
    
      if (existingObject) {
        if (existingObject.depArr === item.depArr) {
          existingObject.pieces += pieces;
          existingObject.status.push(item.status.trim());
        } else {
          reduceCodeArr.push({
            code: `${code}${++count}`,
            pieces: pieces,
            status: [item.status.trim()],
            depArr: item.depArr
          });
        }
      } else {
        reduceCodeArr.push({
          code: `${code}${count}`,
          pieces: pieces,
          status: [item.status.trim()],
          depArr: item.depArr
        });
      }

      // if (existingObject) {
      //   if (existingObject.pieces + pieces <= totalPieces) {
      //     existingObject.pieces += pieces;
      //     existingObject.status.push(item.status.trim());
      //   } else {
      //     reduceCodeArr.push({
      //       code: `${code}${++count}`,
      //       pieces: pieces,
      //       status: [item.status.trim()],
      //     });
      //   }
      // } else {
      //   reduceCodeArr.push({
      //     code: `${code}${count}`,
      //     pieces: pieces,
      //     status: [item.status.trim()],
      //     depArr: item.depArr
      //   });
      // }
    });

    const foundDLVNumberPieces = reduceCodeArr.find(item => item.code.startsWith("DLV"));
    foundDLVNumberPieces ? setDlvTotalPieces(foundDLVNumberPieces.pieces) : setDlvTotalPieces(0);

    reduceCodeArr.forEach(function (item) {
      let statusArr = item.status || [];
      maxLength = Math.max(maxLength, statusArr.length);
    });
    
    let reduceCodeObj = reduceCodeArr.reduce(function (
      accumulator,
      currentItem
    ) {
      if (typeof currentItem === "object" && currentItem !== null) {
        let existingCode = accumulator.find(
          (item) => item.code === currentItem.code
        );

        if (existingCode) {
          existingCode.pieces += currentItem.pieces;
          existingCode.status.push(...currentItem.status);
        } else {
          accumulator.push({
            code: currentItem.code,
            pieces: currentItem.pieces,
            status: [...currentItem.status],
          });
        }
      }

      return accumulator;
    },
    []);

    const lastRcfObject = reduceCodeObj
    .slice()
    .reverse()
    .find((obj) => obj.code.substring(0, obj.code.length - 1) === "RCF");

    let countDLV = 0;
    for(let i = 0; i < datosFiltrados.length; i++) {
      const elements = datosFiltrados[i].split('/');
      if(elements[0] === 'DLV'){
        countDLV++;
      }
    }
    
    if (lastRcfObject && lastStatusShared === "DLV" && countDLV > 1) {

      const rcfNumbers = lastRcfObject.status.map((status) => {
        let piecesStatus = status.split("/")[4];
        return parseFloat(piecesStatus.match(/[PT](.*?)K/)[1]);
      });

      reduceCodeObj = ordenarStatusDLV(reduceCodeObj, rcfNumbers);
    }

    codeArr = reduceCodeObj.map(function (item) {
      lineDataArr.push(item.status[0]);
      return item.code;
    });

    reduceCodeObj.forEach(function (item) {
      let code = item.code;
      let statusArr = item.status || [];

      if (statusArr.length < numberPartials) {

        if(statusArr[0].split("/")[0] === "DLV") {

          let duplicatedStatus = Array.from(
            { length: numberPartials },
            () => statusArr[statusArr.length-1]
          );
  
          resObjCode[code] = duplicatedStatus;
        } else if(statusArr[0].split("/")[0] === "RCF" || statusArr[0].split("/")[0] === "DEP") {

          let additionalStatus = [];
          for(let i = statusArr.length; i < numberPartials; i++){
            additionalStatus.push('')
          }
          let updatedStatusArr = statusArr.concat(additionalStatus);
          resObjCode[code] = updatedStatusArr;

        } else {

          let additionalStatus = [];
          for(let i = statusArr.length; i < numberPartials; i++){
            additionalStatus.push(statusArr[statusArr.length-1])
          }
          let updatedStatusArr = statusArr.concat(additionalStatus);
          resObjCode[code] = updatedStatusArr;

        }
        
      } else {
        let filledStatusArr = Array.from({ length: maxLength }, (_, index) =>
          statusArr[index] !== undefined ? statusArr[index] : statusArr[0]
        );

        resObjCode[code] = filledStatusArr;
      }
    });

    for (let i = 0; i < numberPartials; i++) {
      const lettersBeforeSlash = statusArr[i]
        .split("/")[4]
        .match(getPieces)[0]
        .slice(1);
      printPartial.push({
        text: `${tTrack("status.partial")} ${i + 1} ${tTrack("status.of")} ${numberPartials}`,
        pieces: `${lettersBeforeSlash}`,
      });
    }

    setLastStatus(
      searchLastStatus(positionLastStatus, lastStatusShared, codeArr)
    );
    setLineCodeArr(lineDataArr);
    setStatusLength(codeArr.length);
    setPartial(printPartial);
    setCodeStatusArr(codeArr);
    setCodeStatusObj(resObjCode);
    setCodeStatusArrInv(codeArr.slice().reverse());
  }

  function setArrivalDeparture(line) {
    const code = line.split("/")[0];
    const lineBeforeSlash = line.split("/")

    if(code === "BKD" || code === "RCF" || code === "DEP") {
      return lineBeforeSlash[3];
    } else if(code === "RCS" || code === "DLV") {
      return lineBeforeSlash[2];
    } else {
      return "NOT";
    }
  }

  const ordenarStatusDLV = (reduceCodeObj, rcfNumbers) => {

    const dlvObjectIndex = reduceCodeObj.findIndex(
      (obj) => obj.code.substring(0, obj.code.length - 1) === "DLV"
    );

    if (dlvObjectIndex === -1){
      return reduceCodeObj;
    }

    const dlvObject = reduceCodeObj[dlvObjectIndex];
    const numbersArray = dlvObject.status.map((status) => {
      let piecesStatus = status.split("/")[3];
      return parseFloat(piecesStatus.match(/[PT](.*?)K/)[1]);
    });

    if(rcfNumbers.length !== numbersArray.length) {
      return reduceCodeObj;
    }
    const sortedStatus = rcfNumbers.map((num) => {
      const index = numbersArray.indexOf(num);
      return dlvObject.status[index];
    });

    const updatedDLVObject = {
      ...dlvObject,
      status: sortedStatus,
    };

    return [
      ...reduceCodeObj.slice(0, dlvObjectIndex),
      updatedDLVObject,
      ...reduceCodeObj.slice(dlvObjectIndex + 1),
    ];
  };

  function getstatusDetails(status, index = null) {
    const codeStatus = status.slice(0, -1);
    let statusValue = {};
    let depText = "";
    let rcfText = "";
    let rcfFinalIcon = "";
    let rcfFinalCode = false;
    let bkdText = ''

    if (codeStatus === "DEP" && index && lineCodeArr[index]) {
      const regexFlights = /[A-Z]+(\d+)/;
      const matchesFlights = lineCodeArr[index]
        .split("/")[1]
        .match(regexFlights);

      if(!matchesFlights) {
        depText = depIcon;
      } else if (
        Number(matchesFlights[1]) >= 6000 &&
        Number(matchesFlights[1]) <= 6999
      ) {
        depText = depTIcon;
      } else {
        depText = depIcon;
      }
    }

    if(codeStatus === "BKD" && lineCodeArr[index]) {
      const regexFlights = /[A-Z]+(\d+)/;
      const matchesFlights = lineCodeArr[index]
        .split("/")[1]
        .match(regexFlights);
      
      if(!matchesFlights) {
        bkdText = tTrack("status-code.bkd-txt");
      } else if (Number(matchesFlights[1]) >= 6000 && Number(matchesFlights[1]) <= 6999) {
        bkdText = tTrack("status-code.bkd-transport-txt");
      } else {
        bkdText = tTrack("status-code.bkd-txt");
      }
      
    }

    if (codeStatus === "RCF" && index && lineCodeArr[index]) {
      if(lineCodeArr[index].split("/")[1] === "pending") {
        rcfText = tTrack("status-code.rcf-txt-pending");
        rcfFinalIcon = tansit;
        rcfFinalCode = false;
      } else if (lineCodeArr[index].split("/")[3] === destinationCodeFinal) {
        rcfText = tTrack("status-code.rcf-destination-txt");
        rcfFinalIcon = rcfIcon;
        rcfFinalCode = true;
      } else {
        rcfText = tTrack("status-code.rcf-txt");
        rcfFinalIcon = tansit;
        rcfFinalCode = false;
      }
    }

    switch (codeStatus) {
      case "BKD":
        statusValue = {
          title: bkdText,
          code: "BKD",
          icon: bkdIcon,
        };
        break;
      case "RCS":
        statusValue = {
          title: `${tTrack("status-code.rcs-txt")}`,
          code: "RCS",
          icon: rcsIcon,
        };
        break;
      case "RCF":
        statusValue = {
          title: `${rcfText}`,
          code: "RCF",
          icon: rcfFinalIcon,
          finalCode: rcfFinalCode,
        };
        break;
      case "DEP":
        statusValue = {
          title: `${tTrack("status-code.dep-txt")}`,
          code: "DEP",
          icon: depText,
        };
        break;
      case "DLV":
        statusValue = {
          title: `${tTrack("status-code.dlv-txt")}`,
          code: "DLV",
          icon: dlvIcon,
        };
        break;
      case "RCT":
        statusValue = {
          title: `${tTrack("status-code.rct-txt")}`,
          code: "RCT",
          icon: rctIcon,
        };
        break;
      case "TFD":
        statusValue = {
          title: `${tTrack("status-code.tfd-txt")}`,
          code: "TFD",
          icon: tfdIcon,
        };
        break;
      default:
        statusValue = {};
    }
    return statusValue;
  }

  function getStatusPartial(status) {
    if(!status) {
      const partialStatus = {
        code: '',
        date: '',
        day: '',
        month: '',
        hour: '',
        weightCode: '',
        orides: '',
        flaight: '',
      };
      return partialStatus;
    }
    const codeStatus = status.split("/")[0];
    let hourStatus = "";
    let dateStatus = "";
    let dayStatus = "";
    let monthStatus = "";

    if (status === "") {
      const partialStatus = {
        code: codeStatus,
        date: "",
        day: "",
        month: "",
        hour: "",
      };
      return partialStatus;
    }

    if(
      codeStatus === 'BKD' || 
      codeStatus === 'RCT' || 
      codeStatus === 'TFD'
    ) {
      if(status.split("/")[2]) {
        dateStatus = `${status.split("/")[2].match(/\d+/)[0]} / ${status.split("/")[2].match(/[a-zA-Z]+/)[0]}`
      } else {
        dateStatus = `${tTrack("status.not-available")}`;
      }
    } else if(
      codeStatus === 'RCS' || 
      codeStatus === 'DLV'
    ) {
      if(status.split("/")[1]){
        dateStatus = `${status.split("/")[1].match(/\d+/)[0]} / ${status.split("/")[1].match(/[a-zA-Z]+/)[0]}`
      } else {
        dateStatus = `${tTrack("status.not-available")}`;
      }
    } else if(
      codeStatus === 'DEP' || 
      codeStatus === 'RCF'
    ) {
      if(status.split("/")[2]) {
        if(status.split("/")[2].match(/\d+/) && status.split("/")[2].match(/[a-zA-Z]+/)) {
          dateStatus = `${status.split("/")[2].match(/\d+/)[0]} / ${status.split("/")[2].match(/[a-zA-Z]+/)[0]}`
        } else {
          let monthStatus = convertMonth(status.split("/")[2].substring(5, 7))
          dateStatus = `${status.split("/")[2].substring(8, 10)} / ${monthStatus}`;
        }
      }
    }

    if(
      codeStatus === 'RCS' || 
      codeStatus === 'DLV'
    ) {
      if(status.split("/")[1]) {
        hourStatus = `${status.split("/")[1].match(/\d{4}(?=[^\d]*$)/)[0].slice(0, 2)}:${status.split("/")[1].match(/\d{4}(?=[^\d]*$)/)[0].slice(2)}*`
      }
    } else if (
      codeStatus === 'DEP'
    ) {
      const regularExpresionHour = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
      if(status.split("/")[5]){
        if(regularExpresionHour.test(status.split("/")[5])){
          hourStatus = `${status.split("/")[5]}*`
        } else {
          hourStatus = `${status.split("/")[5].match(/\d{4}(?=[^\d]*$)/)[0].slice(0, 2)}:${status.split("/")[5].match(/\d{4}(?=[^\d]*$)/)[0].slice(2)}*`;
        }
      }
    } else if(codeStatus === 'RCF') {
      const found = codeStatusObj[lastRFCArr()].find(item => item === status);
      let addE = '';

      if(found) {
        addE = "E-"
      }

      if(status.split("/")[5] !== undefined) {
        hourStatus = `${addE}${status.split("/")[5]}*`
      } else {
        if(status.split("/")[2]) {
          hourStatus = `${addE}${status.split("/")[2].match(/\d{4}(?=[^\d]*$)/)[0].slice(0, 2)}:${status.split("/")[2].match(/\d{4}(?=[^\d]*$)/)[0].slice(2)}*`;
        } else {
          hourStatus = ''
        }
      }
    }else if( 
      codeStatus === 'RCT' || 
      codeStatus === 'TFD'
    ) {
      if(status.split("/")[2]){
        hourStatus = `${status.split("/")[2].match(/\d{4}(?=[^\d]*$)/)[0].slice(0, 2)}:${status.split("/")[2].match(/\d{4}(?=[^\d]*$)/)[0].slice(2)}*`;
      }
    } else if(codeStatus === "BKD") {
      if(status.split("/")[5]) {
        hourStatus = `E-${status.split("/")[5].match(/\d{4}(?=[^\d]*$)/)[0].slice(0, 2)}:${status.split("/")[5].match(/\d{4}(?=[^\d]*$)/)[0].slice(2)}*`;
      }
    }

    let weightCode = "";
    let orides = "";
    let flaight = "";
    const lettersBeforeSlash = status.split("/");
    if (lettersBeforeSlash[4]) {
      weightCode = lettersBeforeSlash[4];
      weightCode = weightCode.substring(weightCode.indexOf("K") + 1);
      orides = lettersBeforeSlash[3];
      flaight = lettersBeforeSlash[1];
    } else {
      weightCode = lettersBeforeSlash[3];
      weightCode = weightCode.substring(weightCode.indexOf("K") + 1);
      orides = lettersBeforeSlash[2];
    }
    const partialStatus = {
      code: codeStatus,
      date: dateStatus,
      day: dayStatus,
      month: monthStatus,
      hour: hourStatus,
      weightCode: weightCode,
      orides: orides,
      flaight: flaight,
    };
    return partialStatus;
  }

  function lastRFCArr() {
    const keys = Object.keys(codeStatusObj)
    const rcfKeys = keys.filter(key => key.startsWith('RCF'));

    rcfKeys.sort((a, b) => {
      const numA = parseInt(a.replace('RCF', ''));
      const numB = parseInt(b.replace('RCF', ''));
      return numA - numB;
    });
    const lastRCFKey = rcfKeys[rcfKeys.length - 1];

    return lastRCFKey;
  }

  function convertMonth(month){
    let finalMonth = "";

    switch (month) {
      case "01":
        finalMonth = "JAN";
        break;

      case "02":
        finalMonth = "FEB";
        break;

      case "03":
        finalMonth = "MAR";
        break;

      case "04":
        finalMonth = "APR";
        break;

      case "05":
        finalMonth = "MAY";
        break;

      case "06":
        finalMonth = "JUN";
        break;

      case "07":
        finalMonth = "JUL";
        break;

      case "08":
        finalMonth = "AUG";
        break;

      case "09":
        finalMonth = "SEP";
        break;

      case "10":
        finalMonth = "OCT";
        break;

      case "11":
        finalMonth = "NOV";
        break;
      
      case "12":
        finalMonth = "DEC";
        break;
      
      default:
        finalMonth = month;
        break;
    }
    return finalMonth;
  }

  function addPendingStatus(lastLine, destinationCode) {
    if (lastLine.split("/")[0] === "RCF") {
      const destination = lastLine.split("/")[3];
      if (destination === destinationCode) {
        return {
          status: [`DLV///`],
          positionSearch: 1,
        };
      } else {
        return {
          status: [
            `BKD//////`,
            "DEP/AM0014/////",
            `RCF///${destinationCode}/`,
            `DLV///`,
          ],
          positionSearch: 2,
        };
      }
    } else if (lastLine.split("/")[0] === "DEP") {
      const destination = lastLine
        .split("/")[3]
        .substring(lastLine.split("/")[3].length - 3);
      if (destination === destinationCode) {
        return {
          status: [`RCF///${destination}/`, `DLV///`],
          positionSearch: 1,
        };
      } else {
        return {
          status: [
            `RCF/pending//${destination}/`,
            `BKD//////`,
            "DEP/AM0014/////",
            `RCF///${destinationCode}/`,
            `DLV///`,
          ],
          positionSearch: 2,
        };
      }
    } else if (lastLine.split("/")[0] === "RCS") {
      return {
        status: [`DEP/AM0014/////`, `RCF///${destinationCode}/`, `DLV///`],
        positionSearch: 1,
      };
    } else if (lastLine.split("/")[0] === "RCT") {
      return {
        status: [
          `BKD//////`,
          "DEP/AM0014/////",
          `RCF///${destinationCode}/`,
          `DLV///`,
        ],
        positionSearch: 1,
      };
    } else if (lastLine.split("/")[0] === "TFD") {
      return {
        status: [""],
        positionSearch: 1,
      };
    } else if (lastLine.split("/")[0] === "BKD") {
      return {
        status: [
          "RCS///",
          `DEP/AM0014/////`,
          `RCF///${destinationCode}/`,
          `DLV///`,
        ],
        positionSearch: 1,
      };
    } else {
      return {
        status: [""],
        positionSearch: 1,
      };
    }
  }

  function searchLastStatus(positionLastStatus, lastStatusShared, codeArr) {
    let position = positionLastStatus;
    let count = 0;
    for (let i = codeArr.length - 1; i >= 0; i--) {
      if (codeArr[i].startsWith(lastStatusShared)) {
        count++;
        if (count === position) {
          return codeArr[i];
        }
      }
    }
    return null;
  }

  function setAnimationRCF(index, type) {
    const lastIndex = codeStatusArr.indexOf(lastStatus);
    const lastIndexMb = codeStatusArrInv.indexOf(lastStatus);

    if(type === "mb"){
      if(index < lastIndexMb) {
        return "rcfAnimationNoneMb"
      } else {
        return "rcfAnimationMb"
      }
    } else {
      if(index > lastIndex) {
        return "rcfAnimationNone"
      } else {
        return "rcfAnimation"
      }
    }
  }

  function setBackgroundStyle(index, type) {
    const lastIndex = codeStatusArr.indexOf(lastStatus);

    if (type === "bg") {
      if (index > lastIndex) {
        return "backgroundSolidOff";
      } else {
        return "backgroundSolid";
      }
    } else {
      if (index > lastIndex) {
        return "colorSolidOff";
      } else {
        return "colorSolid";
      }
    }
  }

  function setVerticalLineLeftStyle(index) {
    const lastIndex = codeStatusArr.indexOf(lastStatus);

    if (index === 0) {
      return "verticalLineNone";
    } else {
      if (index > lastIndex) {
        return "verticalLineOff";
      } else {
        return "verticalLine";
      }
    }
  }

  function checkStatusPrint(index) {
    const lastIndex = codeStatusArr.indexOf(lastStatus);

    if (index > lastIndex) {
      return "noActive";
    } else {
      return "active";
    }
  }

  function setVerticalLineRightStyle(index) {
    const lastIndex = codeStatusArr.indexOf(lastStatus);
    
    if (index === codeStatusArr.length - 1) {
      return "verticalLineNone";
    } else {
      if(index < lastIndex){
        return "verticalLine";
      } else {
        return "verticalLineOff";
      }
    }
  }

  const toggleAcordeon = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }

    if(openIndexes.includes(index)) {
      return;
    } else {
      setTimeout(() => {
        if (scrollRef.current && index !== 0) {
          scrollRef.current.scrollBy({
            top: 250,
            behavior: 'smooth'
          });
        }
      }, 300)
    }
  };

  const toggleAcordeonMbl = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }

    if(openIndexes.includes(index)) {
      return;
    } else {
      window.scrollTo({
        top: window.scrollY + 130,
        behavior: 'smooth'
      });
    }
  };

  const handleResetWaybill = () => {
    if(localStorage.getItem("lng")) {
      if(localStorage.getItem("lng") === 'us') {
        navigate(`/${navBarEn["category-services"].track}`)
      } else {
        navigate(`/${navBarEs["category-services"].track}`)
      }
    } else {
        navigate(`/${navBarEs["category-services"].track}`)
    }
  };

  const clickbueno = () => {
    const element = document.querySelector(".print_bueno");
    if (element) {
      element.click();
    }
  };

  const getAirport = (status) => {
    if (!status || status === undefined) {
      return;
    }
    let airportText = "";
    const codeAirport = status.split("/");
    
    if (
      codeAirport[0] === "BKD" ||
      codeAirport[0] === "RCF" ||
      codeAirport[0] === "DEP" ||
      codeAirport[0] === "RCT" ||
      codeAirport[0] === "TFD"
    ) {
      airportText = codeAirport[3];
    } else if(
      codeAirport[0] === "RCS" || 
      codeAirport[0] === 'DLV'
    ) {
      airportText = codeAirport[2];
    } else {
      return;
    }

    if (airportText.length === 6) {
      const primeraParte = airportText.substring(0, 3);
      const segundaParte = airportText.substring(3);
      return `${primeraParte} - ${segundaParte}`;
    } else {
      return airportText;
    }
  };

  const getFlights = (status, code) => {
    if (!status) {
      return;
    }
    if (code.slice(0, -1) === "RCS" || code.slice(0, -1) === "DLV") {
      return;
    }
    return status.split("/")[1];
  };

  const getPiecesPrint = (status, code) => {
    if (!status) {
      return;
    }
    const regexPieces = /[TP](\d)\w*\.?\w*(?=[K])/;

    if (code.slice(0, -1) === "DEP" || code.slice(0, -1) === "RCF") {
      if (!status.match(regexPieces)) {
        return;
      }

      if (Number(status.match(regexPieces)[0].slice(1) > 1)) {
        return `${status.match(regexPieces)[0].slice(1)} ${tTrack('status.piece')}s`;
      } else {
        return `${status.match(regexPieces)[0].slice(1)} ${tTrack('status.piece')}`;
      }
    } else {
      return;
    }
  };

  const getStation = (statusCode) => {

    if (
      codeStatusObj.hasOwnProperty(statusCode) &&
      codeStatusObj[statusCode].length > 0 &&
      statusCode.slice(0, -1) === "RCF"
    ) {
      const line = codeStatusObj[statusCode][0];

      if(line.split("/")[3] === "MEX") {
        return "MEX3";
      } else {
        return line.split("/")[3];
      }
    }

    return;
  };

  return (
    <div className="" style={{paddingBottom: '10px'}}>

      {/* ======================================================
      Desktop
      ====================================================== */}
      <div className="trackStatusContainer d-none d-md-flex">
        <div className="d-flex flex-column" style={{width: "100%"}}>
          <div className="trackHeaderTitle">
            <h5>{tTrack("status.shipment-update")}</h5>
          </div>

          <div className="d-none d-md-flex flex-column scrollPartial" ref={scrollRef}>
            {partial.map((item, index) => (
              <div className="partialContainerMbl" key={index}>
                { partial.length !== 1 ? (
                    <div className="headerPartialMbl" onClick={() => toggleAcordeon(index)}>
                      <p>{item.text}</p>
                      <button>
                        {openIndexes.includes(index) ? (
                          <AiFillCaretUp />
                        ) : (
                          <AiFillCaretDown />
                        )}
                      </button>
                    </div>
                  ) : null
                }
                {codeStatusArr.map((statusCode, indexStatus) => {
                  return codeStatusObj[statusCode][index] !== "" ? (
                    <div
                      className={`bodyPartialMbl ${
                        openIndexes.includes(index) || partial.length === 1 ? "open" : "closed"
                      }`}
                      key={indexStatus}
                    >
                      <div className="imgSectionMbl">
                        <div
                          className={`${setVerticalLineLeftStyle(indexStatus)}`}
                        ></div>
                        { statusCode.includes("RCF") && 
                          getstatusDetails(statusCode, indexStatus).finalCode && 
                          (lastStatus.substring(0,3) === "DLV" || lastStatus.substring(0,3) === "RCF") && 
                          dlvTotalPieces != numberPieces ? 
                          (<div className="contAnimation">
                            <div className={`${setAnimationRCF(indexStatus, "mb")}`}></div>
                          </div>) 
                          : null
                        }
                        <div className={`icon ${setBackgroundStyle(indexStatus, "bg")}`}>
                          {statusCode.includes("RCF") ? (
                            <TrackTooltip station={getStation(statusCode)}>
                              <img
                                src={
                                  getstatusDetails(statusCode, indexStatus).icon
                                }
                                alt=""
                              />
                            </TrackTooltip>
                          ) : (
                            <img
                              src={
                                getstatusDetails(statusCode, indexStatus).icon
                              }
                              alt=""
                            />
                          )}
                        </div>
                        <div
                          className={`${setVerticalLineRightStyle(indexStatus)}`}
                        ></div>
                      </div>
                      <div className="infoPartialMbl">
                        <div className="status">
                          <p className={`${setBackgroundStyle(indexStatus, "cl")}`}>
                            {getstatusDetails(statusCode, indexStatus).title}
                          </p>
                        </div>
                        {`${checkStatusPrint(indexStatus)}` === "active" ? (
                          <div className="details">
                            <p>{getAirport(codeStatusObj[statusCode][index])}</p>
                            <p>{`${
                              getStatusPartial(codeStatusObj[statusCode][index]).date
                            }`}</p>
                            <p>
                              {
                                getStatusPartial(codeStatusObj[statusCode][index])
                                  .hour
                              }
                            </p>
                            <p>
                              {getFlights(
                                codeStatusObj[statusCode][index],
                                statusCode
                              )}
                            </p>
                            <p>
                              {getPiecesPrint(
                                codeStatusObj[statusCode][index],
                                statusCode
                              )}
                            </p>
                          </div>
                        ) : (
                          <div className="details">
                            <PendingMovement 
                              text={tTrack("status.pending-movement")}
                              position="top"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            ))}

            <div className="TrackBtnsStatusMbl d-flex d-md-none col-12">
              <button onClick={handleResetWaybill} className="btnWaybill">
                Rastrear otra guía
              </button>
              <button className="btnPrint" onClick={clickbueno}>
                Imprimir
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* ======================================================
      Mobile
      ====================================================== */}
      <div className="d-flex d-md-none d-flex trackHeaderTitle">
        <h5>{tTrack("status.shipment-update")}</h5>
      </div>
      <div className="d-flex d-md-none d-flex flex-column">
        {partial.map((item, index) => (
          <div className="partialContainerMbl" key={index}>
            { partial.length !== 1 ? (
              <div className="headerPartialMbl" onClick={() => toggleAcordeonMbl(index)}>
                <p>{item.text}</p>
                <button>
                  {openIndexes.includes(index) ? (
                    <AiFillCaretUp />
                  ) : (
                    <AiFillCaretDown />
                  )}
                </button>
              </div>
            ) : null
            }
            {codeStatusArr.map((statusCode, indexStatus) => {
              return codeStatusObj[statusCode][index] !== "" ? (
                <div
                  className={`bodyPartialMbl ${
                    openIndexes.includes(index) || partial.length === 1 ? "open" : "closed"
                  }`}
                  key={indexStatus}
                >
                  <div className="imgSectionMbl">
                    <div
                      className={`${setVerticalLineLeftStyle(indexStatus)}`}
                    ></div>
                    {statusCode.includes("RCF") && getstatusDetails(statusCode, indexStatus).finalCode && lastStatus.substring(0,3) === "DLV" && dlvTotalPieces != numberPieces ? 
                      (<div className="contAnimation">
                        <div className={`${setAnimationRCF(indexStatus, "mb")}`}></div>
                      </div>) 
                      : null
                    }
                    <div className={`icon ${setBackgroundStyle(indexStatus, "bg")}`}>
                      {statusCode.includes("RCF") ? (
                        <TrackTooltip station={getStation(statusCode)}>
                          <img
                            src={
                              getstatusDetails(statusCode, indexStatus).icon
                            }
                            alt=""
                          />
                        </TrackTooltip>
                      ) : (
                        <img
                          src={
                            getstatusDetails(statusCode, indexStatus).icon
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <div
                      className={`${setVerticalLineRightStyle(indexStatus)}`}
                    ></div>
                  </div>
                  <div className="infoPartialMbl">
                    <div className="status">
                      <p className={`${setBackgroundStyle(indexStatus, "cl")}`}>
                        {getstatusDetails(statusCode, indexStatus).title}
                      </p>
                    </div>
                    {`${checkStatusPrint(indexStatus)}` === "active" ? (
                      <div className="details">
                        <p>{getAirport(codeStatusObj[statusCode][index])}</p>
                        <p>{`${
                          getStatusPartial(codeStatusObj[statusCode][index]).date
                        }`}</p>
                        <p>
                          {
                            getStatusPartial(codeStatusObj[statusCode][index])
                              .hour
                          }
                        </p>
                        <p>
                          {getFlights(
                            codeStatusObj[statusCode][index],
                            statusCode
                          )}
                        </p>
                        <p>
                          {getPiecesPrint(
                            codeStatusObj[statusCode][index],
                            statusCode
                          )}
                        </p>
                      </div>
                    ) : (
                      <div className="details">
                        <PendingMovement 
                          text={tTrack("status.pending-movement")}
                          position="top"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : null;
            })}
          </div>
        ))}

        <div className="TrackBtnsStatusMbl d-flex d-md-none col-12">
          <button onClick={handleResetWaybill} className="btnWaybill">
            Rastrear otra guía
          </button>
          <button className="btnPrint" onClick={clickbueno}>
            Imprimir
          </button>
        </div>
      </div>

      <p className="localTime">{tTrack("status.local-time")}</p>
      <p className="PendingStatuses">{tTrack("status.text-1")}</p>
    </div>
  );
}

export default TrackStatus;
