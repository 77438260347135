import RestrictionsIcons from "../../../../components/restrictions-icons/restrictions-icons";
import iconSharkFin from "../../../../assets/imgs/prepare-shipment/restrictions/icon-shark-fin.svg";
import iconInfectedAnimals from "../../../../assets/imgs/prepare-shipment/restrictions/icon-infected-animals.svg";
import iconPrimatesFerrets from "../../../../assets/imgs/prepare-shipment/restrictions/icon-primates-ferrets.svg";
import iconDeadAnimals from "../../../../assets/imgs/prepare-shipment/restrictions/icon-dead-animals.svg";
import iconHuntingTrophies from "../../../../assets/imgs/prepare-shipment/restrictions/icon-hunting-trophies.svg";
import iconPregnantAnimals from "../../../../assets/imgs/prepare-shipment/restrictions/icon-pregnant-animals.svg";
import iconPuppyFootprints from "../../../../assets/imgs/prepare-shipment/restrictions/icon-puppy-footprints.svg";
import iconPenetratingOdors from "../../../../assets/imgs/prepare-shipment/restrictions/icon-penetrating-odors.svg";
import iconPiracy from "../../../../assets/imgs/prepare-shipment/restrictions/icon-piracy.svg";
import iconDrugs from "../../../../assets/imgs/prepare-shipment/restrictions/icon-drugs.svg";
import iconFirearms from "../../../../assets/imgs/prepare-shipment/restrictions/icon-firearms.svg";
import iconLithiumBatterie from "../../../../assets/imgs/prepare-shipment/restrictions/icon-lithium-batterie.svg";
import iconRadioactive from "../../../../assets/imgs/prepare-shipment/restrictions/icon-radioactive.svg";
import iconSelfBalancing from "../../../../assets/imgs/prepare-shipment/restrictions/icon-self-balancing.svg";
import iconBlood from "../../../../assets/imgs/prepare-shipment/restrictions/icon-blood.svg";
import catImg from "../../../../assets/imgs/prepare-shipment/restrictions/cat.png";
import dogImg from "../../../../assets/imgs/prepare-shipment/restrictions/dog.png";
import alertIcon from "../../../../assets/imgs/prepare-shipment/restrictions/icon-alert.svg";
import chemicalPrecursorsImg from "../../../../assets/imgs/prepare-shipment/restrictions/chemical_precursors.png";
import essentialChemicalsImg from "../../../../assets/imgs/prepare-shipment/restrictions/essential_chemicals.png";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";

function ProhibitedGoods() {
  const [tRestrictions] = useTranslation("restrictions");
  const idComponent = "35b4cb80-b8c1-41b9-b935-39b258fa2676";
  const [dataInformation, setDataInformation] = useState(() => {
    const newData = {};

    for (let i = 1; i <= 83; i++) {
      newData[`text-${i}`] = "";
    }

    newData["titulo"] = "";

    return {
      "Mercancías prohibidas para su transporte": newData,
    };
  });

  const getTexts = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/textWeb/text/${idComponent}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (currentLng === "es") {
        setDataInformation(
          response.data.textWeb.text_es[
            "Mercancías prohibidas para su transporte"
          ]
        );
      } else {
        setDataInformation(
          response.data.textWeb.text_us[
            "Mercancías prohibidas para su transporte"
          ]
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTexts();
  }, []);

  return (
    <div className="container">
      <p className="mt-5 mb-md-4 text-center text-premium-blue fs-5">
        {dataInformation["titulo"] ??
          tRestrictions("prohibited-goods.prohibited-goods")}
      </p>
      <div className="row justify-content-center">
        <p className="col-11 col-md-10 col-lg-7 text-center text-premium-blue fs-14">
          {dataInformation["text-1"] ??
            tRestrictions("prohibited-goods.text-1")}
          <span className="fw-bold font-semibold">
            {dataInformation["text-2"] ??
              tRestrictions("prohibited-goods.text-2")}
          </span>
        </p>
      </div>
      <div className="container border py-4 mb-4">
        <div className="row justify-content-center">
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconSharkFin}
              iconAlt="Shark Fin Icon"
              height="40px"
              info={
                dataInformation["text-3"] ??
                tRestrictions("prohibited-goods.text-3")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconInfectedAnimals}
              iconAlt="Infected Animal Icon"
              height="40px"
              info={
                dataInformation["text-4"] ??
                tRestrictions("prohibited-goods.text-4")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconPrimatesFerrets}
              iconAlt="Primates Ferrets Icon"
              height="40px"
              info={
                dataInformation["text-5"] ??
                tRestrictions("prohibited-goods.text-5")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconDeadAnimals}
              iconAlt="Dead Animals Icon"
              height="40px"
              info={
                dataInformation["text-6"] ??
                tRestrictions("prohibited-goods.text-6")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconHuntingTrophies}
              iconAlt="Hunting Trophies Icon"
              height="40px"
              info={
                dataInformation["text-7"] ??
                tRestrictions("prohibited-goods.text-7")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconPregnantAnimals}
              iconAlt="Pregnant Animals Icon"
              height="40px"
              info={
                dataInformation["text-8"] ??
                tRestrictions("prohibited-goods.text-8")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconPuppyFootprints}
              iconAlt="Puppy Footprint Icon"
              height="40px"
              info={
                dataInformation["text-9"] ??
                tRestrictions("prohibited-goods.text-9")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconPenetratingOdors}
              iconAlt="Penetrating Odors Icon"
              height="40px"
              info={
                dataInformation["text-10"] ??
                tRestrictions("prohibited-goods.text-10")
              }
              fontSize="10px"
            />
          </div>
        </div>
        <div className="px-lg-5 row justify-content-center">
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconPiracy}
              iconAlt="Piracy Icon"
              height="40px"
              info={
                dataInformation["text-11"] ??
                tRestrictions("prohibited-goods.text-11")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconDrugs}
              iconAlt="Drugs Icon"
              height="40px"
              info={
                dataInformation["text-12"] ??
                tRestrictions("prohibited-goods.text-12")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconFirearms}
              iconAlt="Firearms Icon"
              height="40px"
              info={
                dataInformation["text-13"] ??
                tRestrictions("prohibited-goods.text-13")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconLithiumBatterie}
              iconAlt="Lithium Batterie Icon"
              height="40px"
              info={
                dataInformation["text-14"] ??
                tRestrictions("prohibited-goods.text-14")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconRadioactive}
              iconAlt="Radioactive Icon"
              height="40px"
              info={
                dataInformation["text-15"] ??
                tRestrictions("prohibited-goods.text-15")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconSelfBalancing}
              iconAlt="Self Balancing Icon"
              height="40px"
              info={
                dataInformation["text-16"] ??
                tRestrictions("prohibited-goods.text-16")
              }
              fontSize="10px"
            />
          </div>
          <div className="col-3 col-lg">
            <RestrictionsIcons
              iconSrc={iconBlood}
              iconAlt="Blood Icon"
              height="40px"
              info={
                dataInformation["text-17"] ??
                tRestrictions("prohibited-goods.text-17")
              }
              fontSize="10px"
            />
          </div>
        </div>
      </div>
      <div className="row py-3 justify-content-center">
        <p className="col-11 col-md-10 col-lg-7 text-center text-premium-blue fs-14">
          {dataInformation["text-18"] ??
            tRestrictions("prohibited-goods.text-18")}
        </p>
      </div>
      <div className="container border-top">
        <div className="row my-0 my-md-3">
          <div className="col-12 col-md-7 pt-3 border-end">
            <div className="row">
              <div className="col-12 col-lg-2 d-flex justify-content-center">
                <div>
                  <img
                    className="brachycephalic-img"
                    src={dogImg}
                    alt="Brachycephalic dog"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-10 p-3">
                <p className="mt-2 mt-lg-5 mb-md-4 text-center text-lg-start text-premium-blue fs-5">
                  {dataInformation["text-19"] ??
                    tRestrictions("prohibited-goods.text-19")}
                </p>
                <div className="row">
                  <div className="col-9 offset-3 col-sm offset-sm-0">
                    <ul className="fs-12">
                      <li>
                        {dataInformation["text-20"] ??
                          tRestrictions("prohibited-goods.text-20")}
                      </li>
                      <li>
                        {dataInformation["text-21"] ??
                          tRestrictions("prohibited-goods.text-21")}
                      </li>
                      <li>
                        {dataInformation["text-22"] ??
                          tRestrictions("prohibited-goods.text-23")}
                      </li>
                      <li>
                        {dataInformation["text-23"] ??
                          tRestrictions("prohibited-goods.text-22")}
                      </li>
                      <li>
                        {dataInformation["text-24"] ??
                          tRestrictions("prohibited-goods.text-24")}
                      </li>
                      <li>
                        {dataInformation["text-25"] ??
                          tRestrictions("prohibited-goods.text-25")}
                      </li>
                      <li>
                        {dataInformation["text-26"] ??
                          tRestrictions("prohibited-goods.text-26")}
                      </li>
                      <li>
                        {dataInformation["text-27"] ??
                          tRestrictions("prohibited-goods.text-27")}
                      </li>
                      <li>
                        {dataInformation["text-28"] ??
                          tRestrictions("prohibited-goods.text-28")}
                      </li>
                      <li>
                        {dataInformation["text-29"] ??
                          tRestrictions("prohibited-goods.text-29")}
                      </li>
                      <li>
                        {dataInformation["text-30"] ??
                          tRestrictions("prohibited-goods.text-30")}
                      </li>
                    </ul>
                  </div>
                  <div className="col-9 offset-3 col-sm offset-sm-0">
                    <ul className="fs-12">
                      <li>
                        {dataInformation["text-31"] ??
                          tRestrictions("prohibited-goods.text-31")}
                      </li>
                      <li>
                        {dataInformation["text-32"] ??
                          tRestrictions("prohibited-goods.text-32")}
                      </li>
                      <li>
                        {dataInformation["text-33"] ??
                          tRestrictions("prohibited-goods.text-33")}
                      </li>
                      <li>
                        {dataInformation["text-34"] ??
                          tRestrictions("prohibited-goods.text-34")}
                      </li>
                      <li>
                        {dataInformation["text-35"] ??
                          tRestrictions("prohibited-goods.text-35")}
                      </li>
                      <li>
                        {dataInformation["text-36"] ??
                          tRestrictions("prohibited-goods.text-36")}
                      </li>
                      <li>
                        {dataInformation["text-37"] ??
                          tRestrictions("prohibited-goods.text-37")}
                      </li>
                      <li>
                        {dataInformation["text-38"] ??
                          tRestrictions("prohibited-goods.text-38")}
                      </li>
                      <li>
                        {dataInformation["text-39"] ??
                          tRestrictions("prohibited-goods.text-39")}
                      </li>
                      <li>
                        {dataInformation["text-40"] ??
                          tRestrictions("prohibited-goods.text-40")}
                      </li>
                      <li>
                        {dataInformation["text-41"] ??
                          "prohibited-goods.text-41"}
                      </li>
                    </ul>
                  </div>
                  <div className="col-9 offset-3 col-sm offset-sm-0">
                    <ul className="fs-12">
                      <li>
                        {dataInformation["text-42"] ??
                          tRestrictions("prohibited-goods.text-42")}
                      </li>
                      <li>
                        {dataInformation["text-43"] ??
                          tRestrictions("prohibited-goods.text-43")}
                      </li>
                      <li>
                        {dataInformation["text-44"] ??
                          tRestrictions("prohibited-goods.text-44")}
                      </li>
                      <li>
                        {dataInformation["text-45"] ??
                          tRestrictions("prohibited-goods.text-45")}
                      </li>
                      <li>
                        {dataInformation["text-46"] ??
                          tRestrictions("prohibited-goods.text-46")}
                      </li>
                      <li>
                        {dataInformation["text-47"] ??
                          tRestrictions("prohibited-goods.text-47")}
                      </li>
                      <li>
                        {dataInformation["text-48"] ??
                          tRestrictions("prohibited-goods.text-48")}
                      </li>
                      <li>
                        {dataInformation["text-49"] ??
                          tRestrictions("prohibited-goods.text-49")}
                      </li>
                      <li>
                        {dataInformation["text-50"] ??
                          tRestrictions("prohibited-goods.text-50")}
                      </li>
                      <li>
                        {dataInformation["text-51"] ??
                          tRestrictions("prohibited-goods.text-51")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 pt-3">
            <div className="row">
              <div className="col-12 col-lg-4 d-flex justify-content-center">
                <div>
                  <img
                    className="brachycephalic-img"
                    src={catImg}
                    alt="Brachycephalic cat"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-8 p-3">
                <p className="mt-2 mt-lg-5 mb-md-4 text-center text-lg-start text-premium-blue fs-5">
                  {dataInformation["text-52"] ??
                    tRestrictions("prohibited-goods.text-52")}
                </p>
                <div className="row">
                  <div className="col-9 offset-3 col-md offset-md-0">
                    <ul className="fs-12">
                      <li>
                        {dataInformation["text-53"] ??
                          tRestrictions("prohibited-goods.text-53")}
                      </li>
                      <li>
                        {dataInformation["text-54"] ??
                          tRestrictions("prohibited-goods.text-54")}
                      </li>
                      <li>
                        {dataInformation["text-55"] ??
                          tRestrictions("prohibited-goods.text-55")}
                      </li>
                      <li>
                        {dataInformation["text-56"] ??
                          tRestrictions("prohibited-goods.text-56")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-light-gray">
        <div className="row p-3">
          <div className="col-12 col-sm-2 col-md-1 d-flex justify-content-center">
            <img className="p-4" src={alertIcon} alt="Alert Icon" />
          </div>
          <div className="col-12 col-sm-10 col-md-11 d-flex align-items-center">
            <p className="m-0 px-3 text-center text-sm-start text-premium-blue fs-14">
              {dataInformation["text-57"] ??
                tRestrictions("prohibited-goods.text-57")}
            </p>
          </div>
        </div>
      </div>
      <div className="container border-bottom mb-5">
        <div className="row my-0 my-md-3">
          <div className="col-12 col-md-6 pt-3 border-end">
            <div className="row">
              <div className="col-12 col-lg-2 d-flex justify-content-center">
                <div>
                  <img
                    className="brachycephalic-img"
                    src={chemicalPrecursorsImg}
                    alt="Chemical Precursors"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-10 p-3">
                <p className="mt-2 mt-lg-5 mb-md-4 text-center text-lg-start text-premium-blue fs-5">
                  {dataInformation["text-58"] ??
                    tRestrictions("prohibited-goods.text-58")}
                </p>
                <div className="row">
                  <div className="col-9 offset-3 col-sm offset-sm-0">
                    <ul className="fs-12">
                      <li>
                        {dataInformation["text-59"] ??
                          tRestrictions("prohibited-goods.text-59")}
                      </li>
                      <li>
                        {dataInformation["text-60"] ??
                          tRestrictions("prohibited-goods.text-60")}
                      </li>
                      <li>
                        {dataInformation["text-61"] ??
                          tRestrictions("prohibited-goods.text-61")}
                      </li>
                      <li>
                        {dataInformation["text-62"] ??
                          tRestrictions("prohibited-goods.text-62")}
                      </li>
                      <li>
                        {dataInformation["text-63"] ??
                          tRestrictions("prohibited-goods.text-63")}
                      </li>
                      <li>
                        {dataInformation["text-64"] ??
                          tRestrictions("prohibited-goods.text-64")}
                      </li>
                      <li>
                        {dataInformation["text-65"] ??
                          tRestrictions("prohibited-goods.text-65")}
                      </li>
                    </ul>
                  </div>
                  <div className="col-9 offset-3 col-sm offset-sm-0">
                    <ul className="fs-12">
                      <li>
                        {dataInformation["text-66"] ??
                          tRestrictions("prohibited-goods.text-66")}
                      </li>
                      <li>
                        {dataInformation["text-67"] ??
                          tRestrictions("prohibited-goods.text-67")}
                      </li>
                      <li>
                        {dataInformation["text-68"] ??
                          tRestrictions("prohibited-goods.text-68")}
                      </li>
                      <li>
                        {dataInformation["text-69"] ??
                          tRestrictions("prohibited-goods.text-69")}
                      </li>
                      <li>
                        {dataInformation["text-70"] ??
                          tRestrictions("prohibited-goods.text-70")}
                      </li>
                      {/* <li name="borrar">
                        {dataInformation["text-71"] ??
                          tRestrictions("prohibited-goods.text-71")}
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 pt-3">
            <div className="row ps-3">
              <div className="col-12 col-lg-2 d-flex justify-content-center">
                <div>
                  <img
                    className="brachycephalic-img"
                    src={essentialChemicalsImg}
                    alt="Essential Chemicals"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-10 p-3">
                <p className="mt-2 mt-lg-5 mb-md-4 text-center text-lg-start text-premium-blue fs-5">
                  {dataInformation["text-72"] ??
                    tRestrictions("prohibited-goods.text-72")}
                </p>
                <div className="row">
                  <div className="col-9 offset-3 col-sm offset-sm-0">
                    <ul className="fs-12">
                      <li>
                        {dataInformation["text-73"] ??
                          tRestrictions("prohibited-goods.text-73")}
                      </li>
                      <li>
                        {dataInformation["text-74"] ??
                          tRestrictions("prohibited-goods.text-74")}
                      </li>
                      <li>
                        {dataInformation["text-75"] ??
                          tRestrictions("prohibited-goods.text-75")}
                      </li>
                      <li>
                        {dataInformation["text-76"] ??
                          tRestrictions("prohibited-goods.text-76")}
                      </li>
                      <li>
                        {dataInformation["text-77"] ??
                          tRestrictions("prohibited-goods.text-77")}
                      </li>
                      <li>
                        {dataInformation["text-78"] ??
                          tRestrictions("prohibited-goods.text-78")}
                      </li>
                    </ul>
                  </div>
                  <div className="col-9 offset-3 col-sm offset-sm-0">
                    <ul className="fs-12">
                      <li>
                        {dataInformation["text-79"] ??
                          tRestrictions("prohibited-goods.text-79")}
                      </li>
                      <li>
                        {dataInformation["text-80"] ??
                          tRestrictions("prohibited-goods.text-80")}
                      </li>
                      <li>
                        {dataInformation["text-81"] ??
                          tRestrictions("prohibited-goods.text-81")}
                      </li>
                      <li>
                        {dataInformation["text-82"] ??
                          tRestrictions("prohibited-goods.text-82")}
                      </li>
                      <li>
                        {dataInformation["text-83"] ??
                          tRestrictions("prohibited-goods.text-83")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProhibitedGoods;
