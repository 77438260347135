import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import "./accordion.css";
import { useQuill } from "react-quilljs";
import 'quill/dist/quill.snow.css';

function Accordion({ title, content }) {
  const [isOpen, setIsOpen] = useState(false);
  const {quill, quillRef} = useQuill({
    readOnly: true,
    modules: {toolbar: false}
  });

  useEffect(() => {
    if(quill) {
      quill.setContents(content);
    }
  }, [content, quill]);

  const toggleAcordeon = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="container">
        <div className="acordeonContainer">
          <div className="acrodeonItem" onClick={toggleAcordeon}>
            <h5>{title}</h5>
            <i>{isOpen ? <AiOutlineUp /> : <AiOutlineDown />}</i>
          </div>
          <div className={`acordeonInfo ${isOpen ? "open" : "closed"}`}>
            <div className="editor">
                <div ref={quillRef}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Accordion;
