import axios from "axios";
import "./pdf-logger.css";
import Swal from "sweetalert2";

function PdfLogger() {

    const createLogger = async () => {
        try {
            const result = await Swal.fire({
                title: "Agregar Logger",
                html: 
                '<div class="form-grid">' +
                '<font size="2">Logger en español</font><br />' +
                '<input type="file" id="file_es" class="" placeholder="File"><br /><br />' +
                '<font size="2">Logger en inglés</font><br />' +
                '<input type="file" id="file_en" class="" placeholder="File"><br /><br />' +
                "</div>",
                showCancelButton: true,
                confirmButtonText: 'Agregar',
                cancelButtonText: 'Cancelar',
                preConfirm: function() {
                    const fileInput_es = document.getElementById("file_es");
                    const fileInput_en = document.getElementById("file_en");

                    if(!fileInput_en.files[0]) {
                        Swal.showValidationMessage("Debes agregar un archivo en ingles");
                    }
                    if(!fileInput_es.files[0]) {
                        Swal.showValidationMessage("Debes agregar un archivo en español");
                    }

                    return new Promise(function (resolve) {
                        resolve({
                            myFile_es: fileInput_es.files[0],
                            myFile_us: fileInput_en.files[0],
                        });
                    });
                }
            });

            if(result.isConfirmed) {
                const userData = result.value;

                const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/data-logger/add-logger`, userData,
                {
                   headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "multipart/form-data",
                   } 
                })

                Swal.fire({
                    title: "¡Creado!",
                    html: `<div>El logger ha sido creado exitosamente.</div><br />` +
                    `<div>link español: ${response.data.urls.es}</div><br />` +
                    `<div>link ingles: ${response.data.urls.us}</div><br /><br />` +
                    `<div>Se recomienda guardar ambas URLs en un archivo local para su uso</div>`,
                    icon: "success"
                });
                
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelado", "Accion cancelada.", "error");
            }
        } catch(error) {

        }
    }

    return (
        <>
        <div className="cont-btn-logger">
            <button onClick={createLogger}>Agregar archivo</button>
        </div>
        </>
    )
}

export default PdfLogger;