const toolbar = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
    ['clean']
];

const handleImageUrl = function(value) {
    if (value) {
      const url = prompt('Ingrese la URL de la imagen:');
      if (url) {
        this.quill.format('image', url);
      }
    } else {
      this.quill.format('image', false);
    }
};

export default toolbar;