import { React, useEffect } from "react";
import ScrollToTop from "../../../components/scrollToTop/scrollToTop";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import NoticesContent from "../../../components/notices-alerts/notices-content";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import { useLocation, useNavigate } from "react-router-dom";

function PharmaServices() {
  const [tAlerts] = useTranslation("alerts");
  const location = useLocation();
  const navigate = useNavigate();

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"]["alert"]}/${navBarEs["alerts-section"]["pharma-services"]}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"]["alert"]}/${navBarEn["alerts-section"]["pharma-services"]}`
  );

  useEffect(() => {
    let lng = localStorage.getItem("lng") || 'es';
    let pathEs = sessionStorage.getItem('routeEs');
    let pathUs = sessionStorage.getItem('routeEn');
    
    if(lng === 'es' && pathEs !== location.pathname) {
      navigate(pathEs);
      window.location.reload();
    } else if (lng === 'us' && pathUs !== location.pathname){
      navigate(pathUs);
      window.location.reload();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <NoticesContent
        title={tAlerts("notice2.text1")}
        date={tAlerts("notice2.text2")}
        info={tAlerts("notice2.notice-info")}
        to="/servicios/nacionales/pharma-estandar"
        here={tAlerts("notice2.link")}
        btn={tAlerts("btn-return.return")}
      />
      <Footer />
    </>
  );
}

export default PharmaServices;
