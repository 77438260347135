import React, { useState } from "react";
import Navbar from "../../../components/navbar/navbar";
import TitleSection from "../../../components/title-section/title-section";
import Footer from "../../../components/footer/Footer";
import "./aeromexico-cargo.css";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../../components/scrollToTop/scrollToTop";
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import Helmet from "react-helmet";

function AeromexicoCargo() {
  const [tAeroMexicoCargo] = useTranslation("aboutUs");
  const [tSMetaData] = useTranslation("metaData");

  const [selectedYear, setSelectedYear] = useState(1989);

  const handleYearClick = (year) => {
    setSelectedYear(year);
  };

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"]["about-us"]}/${
      Object.keys(navBarEs["about-us"])[0]
    }`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"]["about-us"]}/${
      Object.keys(navBarEn["about-us"])[0]
    }`
  );

  const contentByYear = {
    1989: {
      title: tAeroMexicoCargo("1989.title"),
      text: tAeroMexicoCargo("1989.text"),
      imageClass: "bg-1989",
    },
    1990: {
      title: tAeroMexicoCargo("1990.title"),
      text: tAeroMexicoCargo("1990.text"),
      imageClass: "bg-1990",
    },
    1992: {
      title: tAeroMexicoCargo("1992.title"),
      text: tAeroMexicoCargo("1992.text"),
      imageClass: "bg-1992",
    },
    1994: {
      title: tAeroMexicoCargo("1994.title"),
      text: tAeroMexicoCargo("1994.text"),
      imageClass: "bg-1994",
    },
    1998: {
      title: tAeroMexicoCargo("1998.title"),
      text: tAeroMexicoCargo("1998.text"),
      imageClass: "bg-1998",
    },
    2005: {
      title: tAeroMexicoCargo("2005.title"),
      text: tAeroMexicoCargo("2005.text"),
      imageClass: "bg-2005",
    },
    2007: {
      title: tAeroMexicoCargo("2007.title"),
      text: tAeroMexicoCargo("2007.text"),
      imageClass: "bg-2007",
    },
    2012: {
      title: tAeroMexicoCargo("2012.title"),
      text: tAeroMexicoCargo("2012.text"),
      imageClass: "bg-2012",
    },
  };

  const currentContent = selectedYear ? contentByYear[selectedYear] : null;

  return (
    <>
      <Helmet>
        <title>{tSMetaData("nosotros.title")}</title>
        <meta name="description" content={tSMetaData("nosotros.description")} />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <TitleSection title={tAeroMexicoCargo("title.title-page")} />
      <div className="container-fluid px-0">
        <div className="row m-0 d-flex">
          <div className="col-3 col-xl-2 order-2 align-self-center text-center fs-2">
            <ul className="li-none m-0 p-0 py-4">
              <li
                onClick={() => handleYearClick(1989)}
                className={
                  selectedYear === 1989 ? "selected-year" : "text-clear-gray"
                }
              >
                1989
              </li>
              <li
                onClick={() => handleYearClick(1990)}
                className={
                  selectedYear === 1990 ? "selected-year" : "text-clear-gray"
                }
              >
                1990
              </li>
              <li
                onClick={() => handleYearClick(1992)}
                className={
                  selectedYear === 1992 ? "selected-year" : "text-clear-gray"
                }
              >
                1992
              </li>
              <li
                onClick={() => handleYearClick(1994)}
                className={
                  selectedYear === 1994 ? "selected-year" : "text-clear-gray"
                }
              >
                1994
              </li>
              <li
                onClick={() => handleYearClick(1998)}
                className={
                  selectedYear === 1998 ? "selected-year" : "text-clear-gray"
                }
              >
                1998
              </li>
              <li
                onClick={() => handleYearClick(2005)}
                className={
                  selectedYear === 2005 ? "selected-year" : "text-clear-gray"
                }
              >
                2005
              </li>
              <li
                onClick={() => handleYearClick(2007)}
                className={
                  selectedYear === 2007 ? "selected-year" : "text-clear-gray"
                }
              >
                2007
              </li>
              <li
                onClick={() => handleYearClick(2012)}
                className={
                  selectedYear === 2012 ? "selected-year" : "text-clear-gray"
                }
              >
                2012
              </li>
            </ul>
          </div>
          <div className="col-9 px-4 my-4 col-xl-4 align-self-center order-3">
            {currentContent && (
              <>
                <p className="text-gray fs-16">{selectedYear}</p>
                <p className="text-premium-blue fs-3">{currentContent.title}</p>
                <p className="text-gray fs-14">{currentContent.text}</p>
              </>
            )}
          </div>
          <div className="col-12 col-xl-6 order-1 order-xl-3 p-0">
            {currentContent && (
              <div
                className={`bg-container ${currentContent.imageClass}`}
              ></div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AeromexicoCargo;
