import './login.css';
import imgLoginBackLeft from "../../../assets/imgs/login/login-back-left.jpg";
import groupAmc from "../../../assets/imgs/login/logo-amc-blanco.svg";
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

function Login() {

    const { loginWithRedirect } = useAuth0()

    useState(() => {
        localStorage.clear();
    })

    return (
        <div className="loginImgBg">
            <div className="loginContainer">
                <div className='loginBackImage'>
                    <img className='imgLoginBackLeft' src={imgLoginBackLeft} alt="" />
                    <img className='groupAmc' src={groupAmc} alt='' />
                </div>

                <div className='loginFormContainer'>
                    <img src={groupAmc} alt='' />

                    <button onClick={() => loginWithRedirect()} className="loginValidClass">
                        Iniciar Sesion
                    </button>
                </div>
            </div>
            <div className='contCopyright'>
                <span>©Copyright 2023 | Derechos Reservados, Aeroméxico Cargo.</span>
            </div>
        </div>
    )
}

export default Login;